import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { DataService } from '../../services/data.service';
import { DateService } from '../../services/date.service';
import { Subscription } from 'rxjs';
import { NvD3Module } from 'ng2-nvd3';
import { WeatherService } from '../../services/weather.service';
import { BreadCrumbService } from '../../services/breadcrumb.service';
import { LoggingService } from '../../services/logging.service';
import { UserService } from '../../services/user.service';


@Component({
  selector: 'app-email-report',
  templateUrl: './email-report.component.html',
  styleUrls: ['./email-report.component.css']
})
export class EmailReportComponent implements OnInit {

  filter: any;
  onSiteCount: any;
  companyCount: any;
  weatherMinMax: any;
  manpowerChartData: any[];
  siteSubscription: Subscription;
  sub: any;
  datetime: string;
  reportDate: string;

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private userService: UserService,
    private loggingService: LoggingService) {
      this.datetime = new Date().toDateString();
      this.siteSubscription = this.userService.getSiteEvent()
        .subscribe(siteId => {
            this.loadData();
        });
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.filter = { userId: params['userid'], dt: params['dt'] };
      this.reportDate = params['dt'];
      this.loadData();
    });
  }

  ngOnDestroy() {
    this.siteSubscription.unsubscribe();
  }

  loadData() {
    this.loadOnsiteCount();
    this.loadCompanyCount();
    this.loadWeatherMinMax();
    this.loadDailyReportChart();
  }

  loadOnsiteCount(): void {
    this.dataService.getById('onsite/count', this.filter.userId + '/' + this.filter.dt)
        .subscribe((data) => {
            this.onSiteCount = { title: 'Total On-site for day', count: data.total };
        });
  }

  loadCompanyCount(): void {
    this.dataService.getById('onsite/company/count', this.filter.userId + '/' + this.filter.dt)
        .subscribe((data) => {
            this.companyCount = { title: 'Companies On-site for day', count: data };
        });
  }

  loadWeatherMinMax(): void {
    this.dataService.getById('weather/date', this.filter.userId + '/' + this.filter.dt)
        .subscribe((data) => {
            this.weatherMinMax = { title: 'Weather Min/Max', count: data.value };
        });
  }

  loadDailyReportChart() {
    this.dataService.getById('dailyreport/chart', this.filter.dt + '/' + this.filter.userId)
        .subscribe((data) => {
            this.manpowerChartData = data;
        });
  }

}
