import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';

@Injectable()
export class DateService {
    private subject = new Subject<any>();
    user: any;

    constructor() {
        this.user = JSON.parse(localStorage.getItem('currentUser'));
    }

    getCurrentDate(): Observable<any> {
        return this.subject.asObservable();
    }

    setCurrentDate(dt: Date) {
        localStorage.setItem('currentDate', dt.toString());
        this.subject.next(dt);
    }

    previousDate(dt: Date): Date {
        dt.setDate(dt.getDate() - 1);
        this.setCurrentDate(dt);
        return dt;
    }

    nextDate(dt: Date): Date {
        dt.setDate(dt.getDate() + 1);
        this.setCurrentDate(dt);
        return dt;
    }

    getLocalTimeDate(): Date {
        const date = new Date();
        const newDate = new Date(date);
        newDate.setTime(date.getTime() - date.getTimezoneOffset() * 60000);
        return newDate;
    }

    formatDate(dt: Date): string {
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();

        return [dt.getFullYear(),
        (mm > 9 ? '' : '0') + mm,
        (dd > 9 ? '' : '0') + dd
        ].join('');
    }

    formatTimeFromHours(hours: number): string {
        const HH = Math.floor(hours);
        const MM = (hours % 1) * 60;
        const mm = Math.floor(MM);
        const ss = Math.floor((MM % 1) * 60);

        return [(HH > 9 ? '' : '0') + HH,
            ':',
        (mm > 9 ? '' : '0') + mm,
            ':',
        (ss > 9 ? '' : '0') + ss
        ].join('');
    }

    formatTime(dt: Date): string {
        const dtVal = new Date(dt);
        const HH = dtVal.getHours();
        const mm = dtVal.getMinutes();

        return [(HH > 9 ? '' : '0') + HH,
            ':',
        (mm > 9 ? '' : '0') + mm
        ].join('');
    }

    formatDateSlash(dt: Date): string {
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();

        return [mm,
            dd,
            dt.getFullYear()
        ].join('/');
    }
    formatDateDash(dt: Date): string {
        const mm = dt.getMonth() + 1;
        const dd = dt.getDate();

        return [dt.getFullYear(),
        (mm > 9 ? '' : '0') + mm,
        (dd > 9 ? '' : '0') + dd
        ].join('-');
    }
    formatDateTime(dt: Date): string {
        return `${this.formatDateSlash(dt)} ${this.formatTime(dt)}`;
    }

    convertDateToLocalTimeDate(dt: Date): Date {
        if (dt === null || dt === undefined) {
            return null;
        }
        const dateIn = new Date(dt);
        const newDate = new Date(dateIn);
        newDate.setTime(dateIn.getTime() - dateIn.getTimezoneOffset() * 60000);
        return newDate;
    }

    convertDateUniversalToSiteTimeDate(dt: Date): Date {
        if (dt === null || dt === undefined) {
            return null;
        }
        const zone = moment.tz.zone(this.user.timeZone);
        if (!zone) {
            console.log('Time Zon not found', this.user)
            return dt;
        }
        const newDate = new Date(dt);
        newDate.setTime(newDate.getTime() - 60000 * zone.parse(Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), newDate.getHours(), newDate.getTime())));
        return newDate;
    }
    convertDateLocalToSiteTimeDate(dt: Date): Date {
        if (dt === null || dt === undefined) {
            return null;
        }
        const zone = moment.tz.zone(this.user.timeZone);
        if (!zone) {
            console.log('Time Zon not found', this.user)
            return dt;
        }
        const newDate = new Date(dt);
        newDate.setTime(newDate.getTime() - newDate.getTimezoneOffset() * 60000 + zone.parse(Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), newDate.getHours(), newDate.getTime())));
        return newDate;
    }
}
