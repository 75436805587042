import { Component, OnInit, DoCheck } from '@angular/core';
import { BreadCrumbService } from '../services/breadcrumb.service';
import { DataService } from '../services/data.service';
import { ActivatedRoute } from '@angular/router';
import { LoggingService } from '../services/logging.service';
import { UserService } from '../services/user.service';
import { DateService } from '../services/date.service';

@Component({
    selector: 'app-phase-quantity',
    templateUrl: './phase-quantity.component.html'
})
export class PhaseQuantityComponent implements OnInit {
    pageInfo: any;
    path: string;
    sub: any;
    data: any[];
    id: string;
    phase: any;
    title = 'Phase Codes';
    name = '';
    hasPendingItems: boolean = false;
    startDateStr: string;
    startDate: Date;

    projects: any[];

    dayOfTheWeek: number;
    subtractDays: number;
    adjustedDate: Date;
    site: any;
    firstDay: Date;
    lastDay: Date;

    constructor(
        private route: ActivatedRoute,
        private dataService: DataService,
        private breadcrumbService: BreadCrumbService,
        private userService: UserService,
        private dateService: DateService,
        private loggingService: LoggingService) { }

    ngOnInit(): void {
        this.startDate = new Date();
        this.setDates();
        
        this.startDateStr = this.dateService.formatDate(new Date(this.startDate));
        this.loadData();
    }

    loadProjects(): void {
        this.dataService.getAll('projects')
          .subscribe((projects) => {
            this.projects = projects;
            this.projects.forEach(m => {
              m.value = m.id;
              m.label = m.name;
            });
            this.projects.unshift({ value: 'all', label: 'All' });
          });
      }

    addDays(num, date) {
        const dateVal = new Date(date);
        dateVal.setDate(dateVal.getDate() + num);
        dateVal.setHours(6, 0, 0);
        return dateVal;
      }

    loadData() {
        this.loggingService.logPageView('Phase Codes - Grid');
        this.phase = null;
        this.dataService.getById('phasecodes', this.startDateStr)
        .subscribe((data) => {
            this.data = data;

            this.data.forEach(element => {
                if (element.status === 'PENDING') {
                    this.hasPendingItems = true;
                } else {
                    this.hasPendingItems = false;
                }
            });
        });
    }

    onStartDateChange() {
        console.log(this.data);
        this.startDateStr = this.dateService.formatDate(new Date(this.startDate));
        this.setDates();
        this.loadData();
    }

    setDates() {
        this.dayOfTheWeek = new Date(this.startDate).getDay();
        this.subtractDays = this.dayOfTheWeek - 1;

        this.firstDay = new Date(this.startDate);
        this.firstDay.setDate(this.startDate.getDate() - this.subtractDays);
        this.firstDay.setHours(6, 0, 0);

        this.lastDay = this.addDays(6, this.firstDay);
    }

    round(num: number, fractionDigits: number): number {
        return Number(num.toFixed(fractionDigits));
    }

    save() {

        this.dataService.addAll('phasecodes/all', this.data)
        .subscribe((data) => {
            this.loadData();
        });

    }

}
