import { Component, Input, OnInit, ViewEncapsulation, ViewChild, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm, FormGroup } from '@angular/forms';
import {SelectItem} from 'primeng/api';
import { Subject } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DataService } from '../../services/data.service';
import { MessageService } from 'primeng/api';
import { environment } from '../../../../environments/environment';
import { LoggingService } from '../../services/logging.service';
import { UserService } from '../../services/user.service';

import {WebcamImage, WebcamInitError, WebcamUtil} from 'ngx-webcam';

import { HttpClient } from '@angular/common/http';
import { set } from 'd3';
import { match } from 'assert';
import { UUID } from 'angular2-uuid';

@Component({
    selector: 'app-badgeholder-required',
    templateUrl: './badgeholder.required.component.html',
    encapsulation: ViewEncapsulation.None
})
export class BadgeHolderRequiredComponent implements OnInit, OnChanges {
@Input() badgeHolder: any;

@Output() badgeHolderChanged: EventEmitter<any> =   new EventEmitter();

    photoObservable: Subject<void>;
    companies: SelectItem[];
    selectedCompany: SelectItem;
    companiesRaw: any[];
    tradeCode: string;
    contracts: SelectItem[];
    laborClasses: SelectItem[];
    selectedLaborClass: SelectItem;
    selectedContract: SelectItem;
    displayUploadDialog = false;
    displayTakePhotoDialog = false;
    displaySelectBadge = false;
    showWebCam = false;
    uploadedFiles: any[] = [];
    uploadImageUrl: string;
    env: any = environment;
    photo: string;
    user: any;
    showPrintBadge = true;
    showPrintBadgeDialog = false;
    showAddPhoto = true;
    imageData: any;
    badge: any;
    pdfFile: any;
    token: string;
    compareData: any[];
    photoMatch: any[];
    showDialogCompare = false;
    compareBadgeHolder: any;
    comparePercent: number;
    badgeTemplates: any[];
    templateItem: any = {
        id: '',
        html: ''
    };

    templateList: any[] = [];

    safeHtml: SafeHtml;

    faceMatch: any = {
        id: '',
        badgeHolderId: '',
        photoId: '',
        matchingBadgeHolderId: '',
        matchingPhotoId: '',
        matchPercent: 0.0,
        isSamePerson: false,
        isFalsePositive: false,
        faceId: ''
    };

    isReadOnly = false;
    showSignupForTextBanner = false;

    @ViewChild('thisForm', { static: true }) thisForm: NgForm;

    constructor(
        private route: ActivatedRoute,
        private messageService: MessageService,
        private dataService: DataService,
        private userService: UserService,
        private httpClient: HttpClient,
        private sanitized: DomSanitizer,
        private loggingService: LoggingService) {
            this.photoObservable = new Subject<void>();
         }

    ngOnInit(): void {
        this.user = this.userService.currentUser();

        this.isReadOnly = this.badgeHolder.isSynced || this.badgeHolder.deleted;

        this.setProfileUploadPath();
        this.checkBadgeHolder();

        this.loadProfilePhoto('profile');
        this.loadCompanies();

        this.loadSiteBadgeTemplates();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.user = this.userService.currentUser();
        for (const property in changes) {
            if (property === 'badgeHolder') {
                this.setProfileUploadPath();
                this.checkBadgeHolder();
                this.loadProfilePhoto('profile');
            }
        }
        this.loadMatches();
    }

    isValid(): boolean {
        return this.thisForm.valid;
    }

    showUploadDialog() {
        this.uploadedFiles = [];
        this.displayUploadDialog = true;
    }

    checkBadgeHolder(): void {
        if (this.badgeHolder.id === 'new'
            || this.badgeHolder.id === ''
            || this.badgeHolder.id === undefined
            || this.badgeHolder.id === null) {
                this.showAddPhoto = true;
        } else {
            this.showAddPhoto = false;
        }
    }

    setProfileUploadPath(): void {
        this.uploadImageUrl = this.env.baseAPIUrl
        + 'images/upload/profile/'
        + this.user.siteId
        + '/'
        + this.badgeHolder.id;
    }

    closeCompareDialog(): void {
        this.showDialogCompare = false;
    }

    closeUploadDialog() {
        this.displayUploadDialog = false;
        this.updatePhoto();
        this.refreshBadgeHolder();
//        this.save();
    }

    showCompare(badgeId: string, percent: number): void {
        this.compareBadgeHolder = null;
        this.showDialogCompare = true;
        this.dataService.getById('badgeholders', badgeId)
            .subscribe((data) => {
                this.comparePercent = percent;
                this.compareBadgeHolder = data;
                setTimeout(() => {
                    this.loadPhoto(this.compareBadgeHolder.id, this.compareBadgeHolder.id, 'compare', 'profile');
                }, 2000);
            });
        setTimeout(() => {
            this.loadProfilePhoto('profilePhoto');
        }, 2000);
    }

    loadProfilePhoto(id: string): void {
        if (this.badgeHolder.photoId === null || this.badgeHolder.photoId === undefined || this.badgeHolder.photoId === '') {
            this.photo = '../assets/images/user_profile.jpg';
            this.showPrintBadge = true;
        } else {
            this.showPrintBadge = false;

            const img = window.document.getElementById(id);
            img.style.display = 'none';

            this.dataService.getById('files/download/profile', this.badgeHolder.id, { responseType: 'arraybuffer' })
            .subscribe((data) => {
                const file = new Blob([data], { type: 'image/jpeg' });
                const fileURL = window.URL.createObjectURL(file);

                img.setAttribute('src', fileURL);
                img.style.display = '';

                setTimeout(() => window.URL.revokeObjectURL(fileURL), 1000);
            });
        }
    }

    loadMatches() {
        this.dataService.getById('face/matchlist', this.badgeHolder.id)
            .subscribe((data) => {
                this.photoMatch = data;
                setTimeout(() => {
                    for (const row of this.photoMatch) {
                        this.loadPhoto(row['matchBadgeHolderId'], row['matchBadgeHolderId'], 'profile', 'profile');
                    }
                  }, 2000);
            });
    }

    save() {
        if (this.badgeHolder.companyId != null && this.badgeHolder.companyId !== '' &&
        this.badgeHolder.contractId != null && this.badgeHolder.contractId !== '') {
            this.dataService.save('badgeholders', this.badgeHolder)
            .subscribe((data) => {
                this.badgeHolder = data;
                this.checkBadgeHolder();
                this.showPrintBadge = true;
                this.compare();
                this.messageService.add({severity: 'success', summary: 'Saved', detail: 'Record saved'});
            });
        }
    }

//    newNumber() {
//        this.badgeHolder.badgeNum = String((new Date()).getTime());
//        this.badgeHolder.badgeNum = this.badgeHolder.badgeNum.substring(this.badgeHolder.badgeNum.length - 8);
//    }

    updatePhoto() {
        this.loadProfilePhoto('profile');
        // this.save();
    }

    onUpload(event) {
        for (const file of event.files) {
            this.uploadedFiles.push(file);
        }

        this.updatePhoto();

        this.messageService.clear();
        this.messageService.add({severity: 'info', summary: 'File Uploaded', detail: ''});
    }

    loadCompanies(): void {
        this.dataService.getAll('companies')
        .subscribe((companies) => {
            this.companiesRaw = companies;
            this.companies = companies;
            for (let x = 0; x < this.companiesRaw.length; x++) {
                if (this.badgeHolder.companyId === this.companiesRaw[x].companyId) {
                    this.tradeCode = this.companiesRaw[x].tradeClass;
                }
            }

            this.loadContracts(false);
        });
    }

    loadContracts(changed: boolean): void {

        if (changed) {
            this.badgeHolder.contractId = null;
            this.badgeHolder.laborClassId = null;
        }

        const url = 'companies/' + this.badgeHolder.companyId + '/contracts';

        this.dataService.getAll(url)
        .subscribe((contracts) => {
            this.contracts = contracts;
            this.loadLaborClasses(changed);
        });
    }

    loadLaborClasses(changed: boolean): void {

        if (changed)
            this.badgeHolder.laborClassId = null;

        for (let x = 0; x < this.companiesRaw.length; x++) {
            if (this.badgeHolder.companyId === this.companiesRaw[x].companyId) {
                this.tradeCode = this.companiesRaw[x].tradeClass;
            }
        }
        this.dataService.getById('lookups', 'laborclass/' + this.tradeCode)
            .subscribe((laborClasses) => {
                this.laborClasses = laborClasses;
        });
    }

    showTakePhotoDialog(): void {
        this.displayTakePhotoDialog = true;
        this.showWebCam = true;
    }

    selectBadge(): void {
        this.templateList = [];
        this.showPrintBadgeDialog = true;
        this.badgeTemplates.forEach(badgeTemplate => {
            this.loadBadgeTemplate(badgeTemplate.id);
        });

    }

    closeTakePhotoDialog(): void {
        this.displayTakePhotoDialog = false;
        this.showWebCam = false;
//        this.dataService.save('images/upload/raw', this.photo)
//        .subscribe((data) => {
//        });
    }

//    public handleImage(webcamImage: WebcamImage): void {
//        this.photo = webcamImage.imageAsDataUrl;
//    }

    closePrintBadgeDialog() : void {
        this.showPrintBadgeDialog = false;
    }

    showBadge(): void {
        this.dataService.getById('badgeholders/token', this.badgeHolder.id)
            .subscribe((data) => {
                this.token = data.data;
                const url = this.env.baseAPIUrl + 'pdf/otp/' + this.badgeHolder.id + '/' + this.token;
                const newWindow = window.open(url);
            });
    }

    showBadgeByTemplateId(templateId: string) {
        this.dataService.getById('badgeholders/token', this.badgeHolder.id)
            .subscribe((data) => {
                this.token = data.data;
                const url = this.env.baseAPIUrl + 'pdf/template/' + this.badgeHolder.id + '/' + templateId;
                const newWindow = window.open(url);
            });
    }

    compare(): void {
        this.dataService.getById('images/upload/compare', this.badgeHolder.id)
            .subscribe((dataCompare) => {
                var path = this.badgeHolder.id + "_" + this.badgeHolder.photoId + ".jpg";
                this.dataService.getCompare('tsmc', path)
                    .subscribe((data) => {
                        console.log(data);
                        this.compareData = data;
                        if (this.compareData.length > 0) {
//                            setTimeout(() => {
                                for (const row of this.compareData) {
                                    this.faceMatch.id = 'new';
                                    this.faceMatch.badgeHolderId = this.badgeHolder.id;
                                    this.faceMatch.photoId = this.badgeHolder.photoId;
                                    this.faceMatch.matchingBadgeHolderId = row['badgeId'];
                                    var matchingPhotoId = row['image'].split('_')[1].replace('.jpg', '');
                                    this.faceMatch.matchingPhotoId = matchingPhotoId;
                                    this.faceMatch.matchPercent = row['percent'];
                                    this.faceMatch.isSamePerson = false;
                                    this.faceMatch.isFalsePositive = false;
                                    this.faceMatch.faceId = row['faceId'];
//                                    this.loadPhoto(row['badgeId'], row['badgeId'], 'profile', 'profile');

                                    this.dataService.save('face/match', this.faceMatch)
                                    .subscribe((data) => {
                                        this.loadMatches();
                                    });
                                }
//                              }, 2000);
                        } else {
                            this.messageService.add({severity: 'info', summary: 'No Matches', detail: 'No matching photos found'});
                        }
                    });
            });
    }

    loadPhoto(id: string, imageId: string, type: string, path: string) {
        if (imageId !== null) {
            const imgId = type + '-' + imageId;
            const img = window.document.getElementById(imgId);
            img.style.display = 'none';

            this.dataService.getById('files/download/' + path, id, { responseType: 'arraybuffer' })
            .subscribe((photo) => {
                const file = new Blob([photo], { type: 'image/jpeg' });
                const fileURL = window.URL.createObjectURL(file);

                img.setAttribute('src', fileURL);
                img.style.display = '';
//              setTimeout(() => window.URL.revokeObjectURL(fileURL), 1000);
            });
        }
    }

    refreshBadgeHolder(): void {
        this.dataService.getById('badgeholders', this.badgeHolder.id)
            .subscribe((data) => {
                this.badgeHolder = data;
                this.badgeHolderChanged.emit(this.badgeHolder);
                this.setProfileUploadPath();
                this.loadProfilePhoto('profile');
            });
    }

    onPictureTaken(image: WebcamImage): void {

        this.photo = image.imageAsDataUrl;

        this.closeTakePhotoDialog();

        const endpoint = this.env.baseAPIUrl + 'images/upload/raw/' + this.badgeHolder.id;
        let formData: FormData = new FormData();
        let blob = this.dataURItoBlob(image.imageAsDataUrl);
        formData.append('file', blob);
        this.httpClient
            .post(endpoint, formData)
//            .pipe(map(() => { return true; }))
            .subscribe((data) =>  {
                this.refreshBadgeHolder();
                this.compare();
            });
    }

    takePhoto(): void {
        this.photoObservable.next();
    }

    dataURItoBlob(dataURI): Blob {
        // convert base64/URLEncoded data component to raw binary data held in a string
        let byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(dataURI.split(',')[1]);
        } else {
            byteString = decodeURI(dataURI.split(',')[1]);
        }

        // separate out the mime component
        let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        let ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], {type: mimeString});
    }

    onBadgeNumberChanged() {
      this.showSignupForTextBanner = this.shouldShowSignupForTextBanner();
    }

    private shouldShowSignupForTextBanner() {
      if (this.badgeHolder.hasRefusedTexts) {
        return false;
      }

      if (!this.badgeHolder.badgeNum || this.badgeHolder.badgeNum.length !== 10) {
        return false;
      }

      if (this.badgeHolder.textPhone) {
        // if it's already checked, don't show message
        return false;
      }

      if (!this.badgeHolder.phone || this.badgeHolder.phone.length === 0) {
        return false;
      }

      return true;
    }

    loadSiteBadgeTemplates() {
        this.templateList = [];
        this.dataService.getAll('sites/badgetemplates')
            .subscribe((data) => {
                this.badgeTemplates = data;
            });
    }

    loadBadgeTemplate(id: string) {
        var url = 'pdf/html/template/' + this.badgeHolder.id + '/' + id;
        this.dataService.getText(url)
            .subscribe((html) => {
                html = html.replace("</table>", "</table><hr class='border border-3 opacity-50' />");

                var item = {
                    id: id,
                    html: this.sanitized.bypassSecurityTrustHtml(html)
                };

                this.templateList.push(item);
            });
    }
}
