import { AnonymousLayoutComponent } from './layouts/anonymous-layout.component';
import { AppComponent } from './components/app/app.component';
import { BadgeHoldersComponent } from './components/badgeholder/badgeholders.component';
import { BadgeHoldersTSMCComponent } from './components/badgeholder/badgeholderstsmc.component';
import { BadgeHolderAddressComponent } from './components/badgeholder/address/badgeholder.address.component';
import { BadgeHolderDocumentsComponent } from './components/badgeholder/documents/badgeholder.documents.component';
import { BadgeHolderEmergencyComponent } from './components/badgeholder/emergency/badgeholder.emergency.component';
import { BadgeHolderProfileComponent } from './components/badgeholder/profile/badgeholder.profile.component';
import { BadgeHolderRequiredComponent } from './components/badgeholder/required/badgeholder.required.component';
import { BadgeHolderOtherComponent } from './components/badgeholder/other/badgeholder.other.component';
import { BadgeHolderTerminationComponent } from './components/badgeholder/termination/badgeholder.termination.component';
import { BadgeHolderVehicleComponent } from './components/badgeholder/vehicle/badgeholder.vehicle.component';
import { BreadCrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CompanyComponent } from './components/company/company.component';
import { CompanyProfileComponent } from './components/company/profile/company.profile.componet';
import { ContractComponent } from './components/contract/contract.component';
import { ContractProfileComponent } from './components/contract/profile/contract.profile.component';
import { ContractStatusComponent } from './components/reports/contract-status/contract-status.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginComponent } from './components/login/login.component';
import { MenuComponent, AppSubMenuComponent } from './components/menu/menu.component';
import { OnsiteComponent } from './components/onsite/onsite.component';
import { PageComponent } from './components/page/page.component';
import { SecureLayoutComponent } from './layouts/secure-layout.component';
import { SiteProfileComponent } from './components/site/siteprofile.component';
import { SiteRequiredComponent } from './components/site/required/site.required.component';
import { SiteLocationComponent } from './components/site/location/site.location.component';
import { SiteFacilityComponent } from './components/site/facility/site.facility.component';
import { SiteWageScaleComponent } from './components/site/wagescale/site.wagescale.component';
import { SiteBadgesComponent } from './components/site/badges/site.badges.component';
import { SiteDocumentCategoriesComponent } from './components/site/document-categories/site.document-categories.component';
import { STTableComponent } from './components/st-table/table.component';
import { TopBarComponent } from './components/topbar/topbar.component';
import { UsersComponent } from './components/user/user.component';
import { UserProfileComponent } from './components/user/profile/user.profile.componet';

import { DailyReportComponent } from './components/reports/daily-report/daily-report.component';
import { ActivityLogComponent } from './components/activitylog/activitylog.component';
import { ForecastComponent } from './components/forecast/forecast.component';
import { DelayComponent } from './components/delay/delay.component';
import { DelayDetailComponent } from './components/delay/detail/delay.detail.component';
import { DelayGridComponent } from './components/delay/grid/delay.grid.component';
import { CompanyHeadcountComponent } from './components/company/headcount/company.headcount.componet';
import { ActivityLogGridComponent } from './components/activitylog/grid/activitylog.grid.component';
import { ApproveLaborComponent } from './components/approve-labor/approve-labor.component';
import { InjuriesComponent } from './components/injuries/injuries.component';
import { TerminalComponent } from './components/terminal/terminal.component';
import { TerminalProfileComponent } from './components/terminal/terminal.profile/terminal.profile.component';
import { StaticReportComponent } from './components/reports/static-report/static-report.component';
import { HaulOffComponent } from './components/hauloff/hauloff.component';
import { HaulOffProfileComponent } from './components/hauloff/profile/hauloff.profile.component';
import { BadgeHolderPhotosComponent } from './components/badgeholder/photos/badgeholder.photos.component';
import { BadgeHolderAuditComponent } from './components/badgeholder/audit/badgeholder.audit.component';
import { PowerBIReportComponent } from './components/reports/powerbi-report/powerbi-report.component';
import { UpdateBlockComponent } from './components/shared/update-block/update-block.component';
import { UploadPhotoComponent } from './components/shared/uploadphoto/uploadphoto.component';
import { BuildingMaterialComponent } from './components/buildingmaterial/buildingmaterial.component';
import { BuildingMaterialProfileComponent } from './components/buildingmaterial/profile/buildingmaterial.profile.component';
import { PhotoListComponent } from './components/shared/photolist/photolist.component';
import { ConcreteComponent } from './components/concrete/concrete.component';
import { ConcreteProfileComponent } from './components/concrete/profile/concrete.profile.component';
import { VisitorsComponent } from './components/visitor/visitors.component';
import { VisitorProfileComponent } from './components/visitor/profile/visitor.profile.component';
import { InspectorsComponent } from './components/inspector/inspectors.component';
import { InspectorProfileComponent } from './components/inspector/profile/inspector.profile.component';
import { HaulOffCountComponent } from './components/hauloff/count/hauloff.count.component';
import { EquipmentDeliveryComponent } from './components/equipment/equipment.component';
import { EquipmentDeliveryProfileComponent } from './components/equipment/profile/equipment.profile.component';
import { HaulOffDailyReportComponent } from './components/hauloff/dailyreport/hauloff.dailyreport.component';
import { BuildingMaterialCountComponent } from './components/buildingmaterial/count/buildingmaterial.count.component';
import { BuildingMaterialDailyReportComponent } from './components/buildingmaterial/dailyreport/buildingmaterial.dailyreport.component';
import { ConcreteCountComponent } from './components/concrete/count/concrete.count.component';
import { ConcreteDailyReportComponent } from './components/concrete/dailyreport/concrete.dailyreport.component';
import { BadgeHolderSearchComponent } from './components/shared/badgeholdersearch/badgeholdersearch';
import { RewardComponent } from './components/rewards/reward.component';
import { RewardProfileComponent } from './components/rewards/profile/reward.profile.component';
import { ViolationComponent } from './components/violation/violation.component';
import { ViolationProfileComponent } from './components/violation/profile/violation.profile.component';
import { IncidentComponent } from './components/incident/incident.component';
import { IncidentProfileComponent } from './components/incident/profile/incident.profile.component';
import { ProjectComponent } from './components/project/project.component';
import { ProjectProfileComponent } from './components/project/profile/project.profile.component';
import { BadgeHolderProjectsComponent } from './components/badgeholder/projects/badgeholder.projects.component';
import { EmailReportComponent } from './components/reports/email-report/email-report.component';
import { CompanyMatrixComponent } from './components/shared/companymatrix/companymatrix.component';
import { TotalOnsiteComponent } from './components/shared/totalonsite/totalonsite.component';
import { STCardComponent } from './components/shared/card/card.component';
import { STAreaChartComponent } from './components/shared/areachart/areachart.component';
import { GatewayProfileComponent } from './components/gateway/profile/gateway.profile.component';
import { GatewaysComponent } from './components/gateway/gateway.component';
import { BadgeHolderActiveScansComponent } from './components/badgeholder/activescans/badgeholder.activescans.component';
import { ParkingSearchComponent } from './components/security/parking-search/parkingsearch.component';

import { BadgeTemplatesComponent } from './components/badge-templates/badge-templates.component';
import { MenusComponent } from './components/menus/menus.component';
import { SecurityComponent } from './components/security/security.component';
import { SiteCreateComponent } from './components/site-create/site-create.component';
import { ClockLogsComponent } from './components/clock-logs/clock-logs.component';
import { DailyOnsiteComponent } from './components/reports/daily-onsite/daily-onsite.component';
import { BadgeholderLastScanComponent } from './components/reports/badgeholder-lastscan/badgeholder-lastscan.component';
import { BadgeholderNoBadgeComponent } from './components/reports/badgeholder-nobadge/badgeholder-nobadge.component';
import { ExecutiveComponent } from './components/reports/executive/executive.component';
import { InjurySMSComponent } from './components/injury-sms/injury-sms.component';
import { WeeklyReportComponent } from './components/reports/weekly-report/weekly-report.component';
import { WeeklyReportHoursComponent } from './components/reports/weekly-report-hours/weekly-report-hours.component';
import { InspectionsComponent } from './components/inspections/inspections.component';
import { InspectionProfileComponent } from './components/inspections/profile/inspection.profile.component';
import { DailyAttendanceComponent } from './components/reports/daily-attendance/daily-attendance.component';
import { SurveyComponent } from './components/survey/survey.component';
import { SurveyProfileComponent } from './components/survey/survey.profile/survey.profile.component';
import { QuestionComponent } from './components/survey/question/question.component';
import { TakeSurveyComponent } from './components/takesurvey/takesurvey.component';
import { TakeSurveyDetailComponent } from './components/takesurvey/takesurvey.detail/takesurvey.detail.component';
import { SurveyAttemptComponent } from './components/survey/attempts/attempt.component';
import { BadgeHolderSurveyAttemptComponent } from './components/badgeholder/surveys/surveyattempt.component';
import { ResourceComponent } from './components/resource/resource.component';
import { ResourceProfileComponent } from './components/resource/profile/resource.profile.component';
import { ResourceScheduleComponent } from './components/resource/schedule/resource.schedule.component';
import { SurveySMSComponent } from './components/survey-sms/survey-sms.component';
import { SMSComponent } from './components/sms/sms.component';
import { InjuryReportComponent } from './components/reports/injury/injury.component';
import { WeatherStatusComponent } from './components/weather-status/weather-status.component';
import { UserAssignmentComponent } from './components/user/assignment/user.assignment.component';
import { DocumentExpirationReportComponent } from './components/reports/expiration/expiration.component';
import { TerminalStatusComponent } from './components/terminal-status/terminal-status.component';
import { DashboardComponent2 } from './components/dashboard/dashboard2.component';
import { PhaseQuantityComponent } from './components/phase-quantity/phase-quantity.component';
import { CrewComponent } from './components/crew/crew.component';
import { PhoneListComponent } from './components/phone-list/phone-list.component';

export const APP_COMPONENTS = [
    ActivityLogComponent,
    ActivityLogGridComponent,
    AnonymousLayoutComponent,
    AppComponent,
    ApproveLaborComponent,
    AppSubMenuComponent,
    BadgeTemplatesComponent,
    BadgeHoldersComponent,
    BadgeHoldersTSMCComponent,
    BadgeHolderOtherComponent,
    BadgeHolderActiveScansComponent,
    BadgeHolderAddressComponent,
    BadgeHolderPhotosComponent,
    BadgeHolderAuditComponent,
    BadgeHolderProfileComponent,
    BadgeHolderVehicleComponent,
    BadgeHolderRequiredComponent,
    BadgeHolderDocumentsComponent,
    BadgeHolderEmergencyComponent,
    BadgeHolderProjectsComponent,
    BadgeHolderSearchComponent,
    BadgeHolderTerminationComponent,
    BadgeholderLastScanComponent,
    BadgeholderNoBadgeComponent,
    BadgeHolderSurveyAttemptComponent,
    BreadCrumbComponent,
    BuildingMaterialComponent,
    BuildingMaterialCountComponent,
    BuildingMaterialDailyReportComponent,
    BuildingMaterialProfileComponent,
    ClockLogsComponent,
    CompanyComponent,
    CompanyHeadcountComponent,
    CompanyMatrixComponent,
    CompanyProfileComponent,
    ContractComponent,
    ContractProfileComponent,
    ContractStatusComponent,
    ConcreteComponent,
    ConcreteCountComponent,
    ConcreteDailyReportComponent,
    ConcreteProfileComponent,
    CrewComponent,
    DailyReportComponent,
    DailyOnsiteComponent,
    DailyAttendanceComponent,
    DashboardComponent,
    DashboardComponent2,
    DelayComponent,
    DelayDetailComponent,
    DelayGridComponent,
    EmailReportComponent,
    EquipmentDeliveryComponent,
    EquipmentDeliveryProfileComponent,
    ExecutiveComponent,
    FooterComponent,
    ForecastComponent,
    GatewayProfileComponent,
    GatewaysComponent,
    HaulOffComponent,
    HaulOffCountComponent,
    HaulOffDailyReportComponent,
    HaulOffProfileComponent,
    IncidentComponent,
    IncidentProfileComponent,
    InjuryReportComponent,
    InjurySMSComponent,
    InjuriesComponent,
    DocumentExpirationReportComponent,
    InspectionsComponent,
    InspectionProfileComponent,
    InspectorsComponent,
    InspectorProfileComponent,
    LoginComponent,
    MenuComponent,
    MenusComponent,
    OnsiteComponent,
    PageComponent,
    ParkingSearchComponent,
    PhaseQuantityComponent,
    PhotoListComponent,
    PhoneListComponent,
    PowerBIReportComponent,
    ProjectComponent,
    ProjectProfileComponent,
    QuestionComponent,
    RewardComponent,
    RewardProfileComponent,
    SecureLayoutComponent,
    SecurityComponent,
    SiteCreateComponent,
    SiteProfileComponent,
    SiteRequiredComponent,
    SiteLocationComponent,
    SiteFacilityComponent,
    SiteWageScaleComponent,
    SiteBadgesComponent,
    SiteDocumentCategoriesComponent,
    SMSComponent,
    StaticReportComponent,
    STCardComponent,
    STAreaChartComponent,
    STTableComponent,
    SurveyAttemptComponent,
    SurveyComponent,
    SurveyProfileComponent,
    SurveySMSComponent,
    TakeSurveyComponent,
    TakeSurveyDetailComponent,
    TerminalComponent,
    TerminalProfileComponent,
    TerminalStatusComponent,
    TopBarComponent,
    TotalOnsiteComponent,
    UpdateBlockComponent,
    UploadPhotoComponent,
    UsersComponent,
    UserAssignmentComponent,
    UserProfileComponent,
    ViolationComponent,
    ViolationProfileComponent,
    VisitorsComponent,
    VisitorProfileComponent,
    WeeklyReportComponent,
    WeeklyReportHoursComponent,
    ResourceComponent,
    ResourceProfileComponent,
    ResourceScheduleComponent,
    WeatherStatusComponent,
];
