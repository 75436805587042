<form #thisForm='ngForm'>
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <h3>{{buildingmaterial?.name}}</h3>
            </div>
            <div class="col-lg-6">
                <div class="float-right">
                    <button pButton (click)="save()" [disabled]="!thisForm.valid" icon="pi pi-save"
                        label="Save"></button>
                    <button pButton (click)="cancel()" icon="pi pi-ban" label="Cancel"></button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="buildingmaterial" class="container">
        <div class="row">
            <div class="col-8">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-lg-6 col-12">
                                    <div class="form-group">
                                        <label>Material Category</label><br />
                                        <p-dropdown required name="materialCategories" [options]="materialCategories"
                                            [filter]="true" (onChange)="loadMaterialTypes()"
                                            placeholder="Select a Category" [(ngModel)]="materialCategory"></p-dropdown>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-12">
                                    <div class="form-group">
                                        <label>Material Type</label><br />
                                        <p-dropdown required name="materialTypes" [options]="materialTypes"
                                            [filter]="true" placeholder="Select a Material Type"
                                            [(ngModel)]="buildingmaterial.materialTypeId"></p-dropdown>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-12">
                                    <label>Scheduled Date</label><br />
                                    <p-calendar required name="scheduledDate" [(ngModel)]="scheduledDate"
                                        [showIcon]="true" (onInput)="loadResourceTypes()" (onSelect)="loadResourceTypes()"></p-calendar>
                                </div>
                                <div class="col-lg-6 col-12">
                                    <label>Actual Date</label><br />
                                    <p-calendar name="actualDate" [(ngModel)]="actualDate" [showTime]="true"
                                        [showIcon]="true" (onInput)="loadResourceTypes()" (onSelect)="loadResourceTypes()"></p-calendar>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-12">
                                    <label>Scheduled Time</label><br />
                                    <p-calendar required name="scheduledTime" [(ngModel)]="scheduledTime"
                                        [timeOnly]="true" [stepMinute]="15" [showIcon]="true" (onInput)="loadResourceTypes()" (onSelect)="loadResourceTypes()"></p-calendar>
                                    <button pButton (click)="schedule()" icon="pi pi-calendar" label="Schedule"></button>
                                </div>
                                <div class="col-lg-6 col-12">
                                    <label>Duration Onsite</label><br />
                                    <p-dropdown [options]="duration" [filter]="true"
                                        [(ngModel)]="buildingmaterial.duration" name="duration" (onChange)="loadResourceTypes()"></p-dropdown>
                                </div>
                            </div>
                            <div class="row" *ngIf="cranes && cranes.length > 0">
                                <div class="col-lg-6 col-12">
                                    <label>Crane Needed</label><br />
                                    <p-radioButton name="craneNeeded" value="true" [(ngModel)]="craneNeeded"
                                        label="Yes"></p-radioButton>
                                    <p-radioButton name="craneNeeded" value="false" [(ngModel)]="craneNeeded"
                                        label="No"></p-radioButton>
                                    <button pButton (click)="schedule(1)" icon="pi pi-calendar" label="Schedule"></button>
                                </div>
                                <div class="col-lg-6 col-12" *ngIf="cranes">
                                    <label>Crane Number</label><br />
                                    <p-dropdown [options]="cranes" placeholder="Select a Number"
                                        [disabled]="craneNeeded==='false'" required [(ngModel)]="buildingmaterial.craneId"
                                        name="craneId"></p-dropdown>
                                </div>
                            </div>
                            <div class="row" *ngIf="hoists && hoists.length > 0">
                                <div class="col-lg-6 col-12">
                                    <label>Man Hoist Needed</label><br />
                                    <p-radioButton name="hoistNeeded" value="true" [(ngModel)]="hoistNeeded"
                                        label="Yes"></p-radioButton>
                                    <p-radioButton name="hoistNeeded" value="false" [(ngModel)]="hoistNeeded"
                                        label="No"></p-radioButton>
                                    <button pButton (click)="schedule(2)" icon="pi pi-calendar" label="Schedule"></button>
                                </div>
                                <div class="col-lg-6 col-12" *ngIf="hoists">
                                    <label>Hoist Number</label><br />
                                    <p-dropdown [options]="hoists" placeholder="Select a Number"
                                        [disabled]="hoistNeeded==='false'" required [(ngModel)]="buildingmaterial.hoistId"
                                        name="hoistId"></p-dropdown>
                                </div>
                            </div>
                            <div class="row" *ngIf="docks && docks.length > 0">
                                <div class="col-lg-6 col-12">
                                    <label>Loading Dock Needed</label><br />
                                    <p-radioButton name="dockNeeded" value="true" [(ngModel)]="dockNeeded" label="Yes">
                                    </p-radioButton>
                                    <p-radioButton name="dockNeeded" value="false" [(ngModel)]="dockNeeded" label="No">
                                    </p-radioButton>
                                    <button pButton (click)="schedule(3)" icon="pi pi-calendar" label="Schedule"></button>
                                </div>
                                <div class="col-lg-6 col-12" *ngIf="docks">
                                    <label>Loading Dock Number</label><br />
                                    <p-dropdown [options]="docks" placeholder="Select a Number"
                                        [disabled]="dockNeeded==='false'" required [(ngModel)]="buildingmaterial.dockId"
                                        name="dockId"></p-dropdown>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Delivery Description</label>
                                        <input pInputText name="description" [(ngModel)]="buildingmaterial.name"
                                            class="form-control" type="text" placeholder="Enter brief description"
                                            maxlength="100" required />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <div class="form-group">
                                        <label>Shipping Company</label>
                                        <input pInputText name="companyFrom" [(ngModel)]="buildingmaterial.companyFrom"
                                            type="text" class="form-control" placeholder="Company" maxlength="36" />
                                    </div>
                                </div>
                                <div class="col-12 col-lg-6">
                                    <div class="form-group">
                                        <label>Shipping to Company</label><br />
                                        <p-dropdown required name="companyContracts" [options]="companyContracts"
                                            [filter]="true" placeholder="Select a Company/Contract"
                                            [(ngModel)]="buildingmaterial.contractId"></p-dropdown>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <div class="form-group">
                                        <label>Receiving First Name</label>
                                        <input pInputText name="receivingFirstName" [(ngModel)]="buildingmaterial.receivingFirstName" required
                                            type="text" class="form-control" placeholder="Receiving First Name" maxlength="50" />
                                    </div>
                                </div>
                                <div class="col-12 col-lg-6">
                                    <div class="form-group">
                                        <label>Receiving Last Name</label>
                                        <input pInputText name="receivingLastName" [(ngModel)]="buildingmaterial.receivingLastName" required
                                            type="text" class="form-control" placeholder="Receiving Last Name" maxlength="50" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <div class="form-group">
                                        <label>Receiving Phone</label><br />
                                        <p-inputMask mask="(999)999-9999" [(ngModel)]="buildingmaterial.receivingPhone" required
                                            name="receivingPhone" placeholder="(999)999-9999"></p-inputMask>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <div class="form-group">
                                        <label>Shipping Ticket #</label>
                                        <input pInputText name="ticketNum" [(ngModel)]="buildingmaterial.ticketNum"
                                            maxlength="50" class="form-control" type="text" placeholder="####" />
                                    </div>
                                </div>
                                <div class="col-lg-6 col-12">
                                    <div class="form-group">
                                        <label>Gate</label><br />
                                        <p-dropdown [options]="gates" placeholder="Select a Number" [(ngModel)]="buildingmaterial.gateId" [showClear]="true" name="gateId"></p-dropdown>
                                        <button pButton (click)="schedule(4)" icon="pi pi-calendar" label="Schedule"></button>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <div class="form-group">
                                        <label>Quantity</label>
                                        <input pInputText name="quantity" [(ngModel)]="buildingmaterial.quantity"
                                            class="form-control" type="number" placeholder="Quantity" />
                                    </div>
                                </div>
                                <div class="col-12 col-lg-6">
                                    <div class="form-group">
                                        <label>Unit</label><br />
                                        <p-dropdown name="unitTypes" [options]="unitTypes" [filter]="true"
                                            placeholder="Select an Unit Type" [(ngModel)]="buildingmaterial.unitId">
                                        </p-dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="row">
                    <div class="col-12">
                        <label>Photo</label><br />
                        <button pButton icon="pi pi-plus" [disabled]="buildingmaterial.id===undefined"
                            (click)="openUploadDialog()" label="Add Photo" class="indigo-btn"></button>
                        <app-photo-list [objectId]="buildingmaterial.id" [(refresh)]="refresh" [category]="category"
                            *ngIf="buildingmaterial.id !== undefined"></app-photo-list>
                    </div>
                </div>
            </div>
        </div>
        <st-update-block [object]='buildingmaterial' *ngIf="buildingmaterial"></st-update-block>
    </div>
</form>



<app-upload-photo [(showDialog)]="showUploadDialog" (showDialogChange)="showDialogChangedHandler($event)"
    [category]="category" [objectId]="buildingmaterial.id" *ngIf="buildingmaterial.id !== undefined"></app-upload-photo>



<p-dialog header="{{dialogResourceName}}" [(visible)]="showDialogFlag" modal="modal" [style]="{ width: '820px', height: '870px', 'min-width': '375px' }" [minY]="70">
    <div style="width: 780px; height: 690px;">
        <div class="row">
            <div class="col-12" [ngClass]="{ 'day-only': (dialogResourceType) }">
                <p-fullCalendar #fc [events]="events" [options]="options"></p-fullCalendar>
            </div>
        </div>
    </div>
</p-dialog>