<div class="row">
   <div class="col-lg-4">
        <div class="row">
            <div class="col-lg-12">
                <label class="control-label">Security Phone Number:</label>
                <p-inputMask mask="(999)999-9999" [(ngModel)]="site.securityPhone" name="securityPhone" class="form-control" placeholder="(999)999-9999"></p-inputMask>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <label class="control-label">Security Email:</label>
                <input type="text" class="form-control" pInputText name="securityEmail" [(ngModel)]="site.securityEmail" maxlength="100" />
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 text-center">
                <button pButton (click)="showBadge()" icon="pi pi-print" label="Print Badge"></button>
            </div>
        </div>
    </div>
    <div class="col-lg-4 text-center">
        <div class="row">
            <div class="col-lg-12 text-center">
                <label class="control-label">Site Logo</label>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 text-center">
                <img width="75" alt="Site Logo" [src]="siteLogo" />
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 text-center">
                <button pButton (click)="showUploadDialog('sitelogo')" icon="pi pi-file-upload" label="Upload site logo"></button>
            </div>
        </div>
    </div>
    <div class="col-lg-4 text-center">
        <div class="row">
            <div class="col-lg-12 text-center">
                <label class="control-label">General Contractor Logo</label>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 text-center">
                <img width="100" alt="General Contractor Logo" [src]="gcLogo" />
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 text-center">
                <button pButton (click)="showUploadDialog('gclogo')" icon="pi pi-file-upload" label="Upload GC logo"></button>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-lg-4"></div>
    <div class="col-lg-8">
        <div class="row">
            <div class="col-lg-12 text-center">
                <label class="control-label">Terminal Home Screen Image</label>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 text-center">
                <img width="300" alt="Terminal Home Screen Image" [src]="badgedown" />
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 text-center">
                <button pButton (click)="showUploadDialog('badgedown')" icon="pi pi-file-upload" label="Upload Terminal Home Image"></button>
            </div>
        </div>
    </div>
</div>


<p-toast position="top-right"></p-toast>

<p-dialog header="Upload Profile" [(visible)]="displayUploadDialog" modal="modal" [style]="{ width: '500px', height: '360px', 'min-width': '200px' }" [minY]="70">
    <p-fileUpload name="files" url="{{uploadImageUrl}}" (onUpload)="onUpload($event)" accept="image/*" maxFileSize="5000000" showCancelButton="false">
        <ng-template pTemplate="content">
            <ul *ngIf="uploadedFiles.length">
                <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
            </ul>
            <span>Drag and Drop a Photo into this area or click the "Choose" button to select a file.</span>
        </ng-template>
    </p-fileUpload>
    <p-footer>
        <button type="button" pButton icon="pi pi-times" (click)="closeUploadDialog()" label="Close"></button>
    </p-footer>
</p-dialog>
