<div class="global-admin-container">
    <div class="row" *ngIf="data">
        <div class="col-lg-12">
            <p-table #dt [value]="data" [scrollable]="true" scrollHeight="500px">
                <ng-template pTemplate="header">
                    <tr>
                        <th [pSortableColumn]="'stationId'">Station Id <p-sortIcon [field]="'stationId'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'location'">Location <p-sortIcon [field]="'location'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'localTime'">Local Time <p-sortIcon [field]="'localTime'"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr>
                        <td>{{rowData.stationId}}</td>
                        <td>{{rowData.location}}</td>
                        <td>{{rowData.localTime | date:'MM/dd/yyyy HH:mm a'}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
