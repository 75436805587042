

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class GridService {
    env: any = environment;
    constructor(private http: HttpClient) {
    }

    public getOptions<T>(grid: string) {
        return this.http.get<any[]>(this.env.baseAPIUrl + '/gridoptions/info/' + grid);
    }
}
