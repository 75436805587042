import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BreadCrumbService } from '../services/breadcrumb.service';
import { DataService } from '../services/data.service';
import { LoggingService } from '../services/logging.service';
import { UserService } from '../services/user.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-badgeholderstsmc',
    templateUrl: './badgeholderstsmc.component.html',
    encapsulation: ViewEncapsulation.None
})
export class BadgeHoldersTSMCComponent implements OnInit {

    pageInfo: any;
    id: string;
    private sub: any;
    badgeHolder: any;
    showTabFlag: boolean;
    years: any[];
    section = 'Sitetraxx Admin';
    title = 'Badge Holders';
    isGlobalAdmin = false;

    constructor(
        private route: ActivatedRoute,
        private dataService: DataService,
        private breadcrumbService: BreadCrumbService,
        private router: Router,
        private loggingService: LoggingService,
        private userService: UserService) {
        this.isGlobalAdmin = this.userService.currentUser().isGlobalAdmin;
    }

    ngOnInit(): void {
        this.breadcrumbService.setItemsByCategory(this.section, this.title, this.router.url);
        this.showTabFlag = false;

        this.sub = this.route.params.subscribe(params => {
            this.id = params['id'];

            if (this.id === undefined) {
//                this.loadData();
            } else {
                if (this.id === 'new') {
                    this.loggingService.logPageView('Badge Holders - New');
                    this.badgeHolder = {};
                } else {
                    this.loggingService.logPageView('Badge Holders - Profile');
                    this.dataService.getById('badgeholders', this.id)
                        .subscribe((data) => {
                            this.badgeHolder = data;
                        });
                }
            }
        });
    }

    loadData() {
        this.loggingService.logPageView('Badge Holders - Grid');
        this.badgeHolder = null;
        this.dataService.getById('', 'badgeholderstsmc')
            .subscribe((data) => {
                this.pageInfo = data.result;
                this.title = this.pageInfo.title;
            });
    }

    createExcel(): void {
        this.dataService.postForm('badgeholders/excel', {}, { responseType: 'arraybuffer' })
            .subscribe((data) => {
                const file = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const fileURL = window.URL.createObjectURL(file);

                const a = document.createElement('a');
                document.body.appendChild(a);
                a.href = fileURL;
                a.download = 'badgeholders.xlsx';
                a.click();

                window.URL.revokeObjectURL(fileURL);
                a.remove();
            });
    }
}
