import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadCrumbService } from '../services/breadcrumb.service';
import { Subscription ,  Subject } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { DateService } from '../services/date.service';
import { UserService } from '../services/user.service';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.css']
})
export class BreadCrumbComponent implements OnInit, OnDestroy {

    subscription: Subscription;
    selectedDate: Date;
    items: MenuItem[];
    maxDate: Date;
    user: any;

    constructor(public breadcrumbService: BreadCrumbService,
         private dateService: DateService,
         private userService: UserService) {
        this.subscription = breadcrumbService.itemsHandler.subscribe(response => {
            this.items = response;
        });
    }

    ngOnInit() {
        const savedDate = localStorage.getItem('currentDate');
        if (savedDate === null) {
            this.selectedDate = new Date();
        } else {
            this.selectedDate = new Date(savedDate);
        }
        this.dateChanged();
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    dateChanged(): void {
        this.dateService.setCurrentDate(this.selectedDate);
    }

    previousDate() {
        this.selectedDate = this.dateService.previousDate(this.selectedDate);
        this.dateService.setCurrentDate(this.selectedDate);
        this.selectedDate = new Date(this.selectedDate);
    }

    nextDate() {
        this.selectedDate = this.dateService.nextDate(this.selectedDate);
        this.dateService.setCurrentDate(this.selectedDate);
        this.selectedDate = new Date(this.selectedDate);
    }
}
