import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

import { DateService } from '../../services/date.service';
import { UserNameService } from '../../services/user-name.service';


@Component({
    selector: 'st-update-block',
    templateUrl: './update-block.component.html',
    encapsulation: ViewEncapsulation.None
})
export class UpdateBlockComponent implements OnInit {
  
    @Input() object: any;

    createdBy: any;
    updatedBy: any;

    cache = [];

    constructor(private userNameService: UserNameService, private dateService: DateService) { }

    ngOnInit(): void {
        if (this.object) {
            this.createdBy = this.userNameService.getUserName(this.object.createdByUser);
            this.updatedBy = this.userNameService.getUserName(this.object.updatedByUser);
        }
    }
}
