<div class="container">
    <form #thisForm='ngForm'>
        <div class="row">
            <div class="col-lg-6">
                <h3>{{user.name}}</h3>
            </div>
            <div class="col-lg-6">
                <div class="float-right">
                    <button pButton (click)="save()" [disabled]="!thisForm.valid" icon="pi pi-save"
                        label="Save"></button>
                    <button pButton (click)="cancel()" icon="pi pi-ban" label="Cancel"></button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label>Display Name</label>
                            <input [(ngModel)]="user.name" maxlength="100" pInputText name="name" type="text"
                                class="form-control" required />
                        </div>
                        <div class="form-group">
                            <label>Username</label>
                            <input [(ngModel)]="user.username" maxlength="50" pInputText name="username" type="text"
                                class="form-control" required />
                            <input [(ngModel)]="user.password" name="password" type="hidden" value="" />
                        </div>
                        <div class="form-group">
                            <label>Email</label>
                            <input [(ngModel)]="user.email" maxlength="100" pInputText name="email" type="email"
                                pattern="[a-zA-Z0-9.'_%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" class="form-control" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <div class="form-group">
                            <label>Role</label><br />
                            <p-dropdown [options]="roles" [filter]="true" placeholder="Select a Role"
                                [(ngModel)]="user.roleId" required name="roleDropdown"></p-dropdown>
                        </div>
                        <div class="form-group">
                            <label>Company</label><br />
                            <p-dropdown [options]="companies" [filter]="true" placeholder="Select a Company"
                                [(ngModel)]="user.companyId" required name="companyDropdown"></p-dropdown>
                        </div>
                        <div class="form-group">
                            <p-checkbox name="canApproveLabor" binary="true" label="Can Approve Labor"
                                [(ngModel)]="user.canApproveLabor"></p-checkbox>
                        </div>
                        <p-button (click)="showDialog(true)" label="Change Password"
                            *ngIf="user.id && user.id != 'new'">
                        </p-button>
                    </div>
                    <div class="col-lg-8" style="background-color: lightyellow" *ngIf="isGlobalAdmin">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label>SMS Number</label>
                                    <input [(ngModel)]="user.sMSNumber" name="sMSNumber" pInputText type="text"
                                        class="form-control" maxlength="12" />
                                </div>
                                <h6>SMS Alerts:</h6>
                                <div class="form-group">
                                    <p-checkbox name="sendInjury" binary="true" label="Send Injury" [(ngModel)]="user.sendInjury"></p-checkbox>
                                </div>
                                <div class="form-group">
                                    <p-checkbox name="sendTerminalAccess" binary="true" label="Send Terminal Access" [(ngModel)]="user.sendTerminalAccess"></p-checkbox>
                                </div>
                                <div class="form-group">
                                    <p-checkbox name="sendGatewayAccess" binary="true" label="Send Gateway Access" [(ngModel)]="user.sendGatewayAccess"></p-checkbox>
                                </div>
                                <div class="form-group">
                                    <p-checkbox name="sendWeather" binary="true" label="Send Weather" [(ngModel)]="user.sendWeather"></p-checkbox>
                                </div>
                                <div class="form-group">
                                    <p-checkbox name="sendSurvey" binary="true" label="Send Survey" [(ngModel)]="user.sendSurvey"></p-checkbox>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <h6>Email Reports:</h6>
                                <div class="form-group" *ngFor="let r of emailReports">
                                    <p-checkbox name="cb{{r.value}}" binary="true" [label]="r.label" [(ngModel)]="r.selected"></p-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <st-update-block [object]='user'></st-update-block>
</div>

<p-dialog header="Add Child User Account" [(visible)]="showAddDialogFlag" modal="modal"
    [style]="{ width: '500px', height: '360px', 'min-width': '200px' }" [minY]="70">
</p-dialog>

<p-dialog header="Change Password" [(visible)]="showDialogFlag" modal="modal"
    [style]="{ width: '500px', height: '360px', 'min-width': '200px' }" [minY]="70">
    <div class="row">
        <div class="col-12 form-group">
            <label>Password:</label>
            <input [(ngModel)]="userPwChange.password" pInputText type="password" class="form-control" required />
        </div>
    </div>
    <div class="row">
        <div class="col-12 form-group">
            <label>Confirm Password:</label>
            <input [(ngModel)]="userPwChange.confirmPassword" pInputText type="password" class="form-control"
                required />
        </div>
    </div>
    <div class="row">
        <p-messages [(value)]="msgs"></p-messages>
    </div>
    <p-footer>
        <p-button (click)="changePassword()"
            [disabled]="userPwChange.password == '' || userPwChange.confirmPassword == '' || userPwChange.password != userPwChange.confirmPassword"
            label="Save"></p-button>
        <p-button (click)="cancelPassword()" label="Cancel"></p-button>
    </p-footer>
</p-dialog>