import { Component, OnInit, Input } from '@angular/core';
import { LoggingService } from '../../services/logging.service';
import { DataService } from '../../services/data.service';

@Component({
    selector: 'st-total-onsite',
    templateUrl: './totalonsite.component.html'
})
export class TotalOnsiteComponent implements OnInit {
    @Input() filter: any;

    dt: string;
    onSiteCount: any = { total: 0 };

    constructor(private dataService: DataService, private loggingService: LoggingService) { }

    ngOnInit(): void {
        this.loadOnsiteCount();
    }

    loadOnsiteCount(): void {
        this.dataService.getById('onsite/count', this.filter.userId + '/' + this.filter.dt)
            .subscribe((data) => {
                this.onSiteCount = data;
            });
    }
}
