import { Component, Input, OnInit, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DataService } from '../../services/data.service';
import { LoggingService } from '../../services/logging.service';
import { DateService } from '../../services/date.service';
import { Message } from 'primeng/api';
import { environment } from '../../../../environments/environment';
import { UserService } from '../../services/user.service';
import { ConfirmationService } from 'primeng/api';
import { BreadCrumbService } from '../../services/breadcrumb.service';

@Component({
    selector: 'app-gateway-profile',
    templateUrl: './gateway.profile.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [ ConfirmationService ]
})
export class GatewayProfileComponent implements OnInit, OnChanges {
@Input() gateway: any;

env: any = environment;
showTabFlag: boolean;
showUploadDialog: boolean;
showPhotoDialog: boolean;
message: Message[] = [];
user: any;
isNew = false;
isReady = false;
baseImageUrl: string;
category = 'gateway';
refresh = new Date();
projectsList: any[];

    constructor(
        private dataService: DataService,
        private loggingService: LoggingService,
        private dateService: DateService,
        private userService: UserService,
        private breadcrumbService: BreadCrumbService,
        private confirmService: ConfirmationService,
        private router: Router) { }

    ngOnInit(): void {
        this.baseImageUrl = this.env.baseImageUrl;
        this.user = this.userService.currentUser();
        if (this.gateway.id === undefined) {
            this.isNew = true;
            this.isReady = true;
        }

        this.loadProjectList();
    }

    loadProjectList(): void {
        this.dataService.getAll('lookups/projects')
        .subscribe((data) => {
            this.projectsList = data;
        });
    }

    showDialogChangedHandler(showDialog: boolean): void {
        this.refresh = new Date();
    }

    ngOnChanges(simpleChanges: SimpleChanges): void {
        this.breadcrumbService.setItemsByCategoryAndName(
                                'Controls',
                                'Gateways',
                                '/gateways/search',
                                this.gateway.name,
                                this.router.url);
    }

    save() {
        this.gateway.threshold = parseInt(this.gateway.threshold);
        this.gateway.motionThreshold = parseInt(this.gateway.motionThreshold);
        
        this.dataService.save('gateways', this.gateway)
            .subscribe((data) => {
                this.loggingService.savedMessage();
            });
    }

    cancel() {
        this.router.navigate(['/gateways/search/']);
    }

    showTab(_tab: boolean) {
        this.showTabFlag = _tab;
    }

    openUploadDialog(): void {
        this.showUploadDialog = true;
    }

    closeUploadDialog(): void {
        this.showUploadDialog = false;
    }
}
