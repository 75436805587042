import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { BreadCrumbService } from '../services/breadcrumb.service';
import { DataService } from '../services/data.service';
import { ActivatedRoute } from '@angular/router';
import { LoggingService } from '../services/logging.service';
import { Subscription } from 'rxjs';
import { UserService } from '../services/user.service';

@Component({
    selector: 'app-gateways',
    templateUrl: './gateway.component.html'
})
export class GatewaysComponent implements OnInit {
    pageInfo: any;
    path: string;
    sub: any;
    id: string;
    gateway: any;

    constructor(
        private route: ActivatedRoute,
        private dataService: DataService,
        private breadcrumbService: BreadCrumbService,
        private loggingService: LoggingService,
        private userService: UserService,
        private router: Router) { }

    ngOnInit(): void {

        this.sub = this.route.params.subscribe(params => {
            this.id = params['id'];

            if (this.id === undefined) {
                this.loadData();
            } else {
                if (this.id === 'new') {
                    this.loggingService.logPageView('Gateway - New');
                    this.gateway = {};
                } else {
                    this.loggingService.logPageView('Gateway - Profile');
                    this.dataService.getById('gateways', this.id)
                    .subscribe((data) => {
                        this.gateway = data;
                    });
                }
            }
         });
    }

    loadData() {
        this.loggingService.logPageView('Gateways - Grid');
        this.gateway = null;
        this.dataService.getById('pages', 'gateways')
        .subscribe((data) => {
            this.pageInfo = data.result;
            this.breadcrumbService.setItemsByCategory('Controls', this.pageInfo.title, this.router.url);
        });
    }
}
