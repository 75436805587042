import { Component, Input, OnInit, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

import { DataService } from '../../services/data.service';
import { LoggingService } from '../../services/logging.service';
import { DateService } from '../../services/date.service';
import { Message } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { BreadCrumbService } from '../../services/breadcrumb.service';

@Component({
    selector: 'app-equipment-delivery-profile',
    templateUrl: './equipment.profile.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [ ConfirmationService ]
})
export class EquipmentDeliveryProfileComponent implements OnInit, OnChanges {
@Input() equipmentDelivery: any;

showUploadDialog: boolean;
showEquipmentDialog: boolean;
companyContracts: any[];
equipmentList: any[];
equipment: any;
message: Message[] = [];
isNew = false;
category = 'equipmentdeliveries';
section = 'Controls';
title = 'Equipment';
path = '/' + this.category + '/search';
refresh = new Date();

    constructor(
        private dataService: DataService,
        private loggingService: LoggingService,
        private dateService: DateService,
        private breadcrumbService: BreadCrumbService,
        private router: Router) {}

    ngOnInit(): void {
        if (this.equipmentDelivery.id === undefined) {
            this.isNew = true;
        }

        if (this.equipmentDelivery.enterTime === undefined) {
            this.equipmentDelivery.enterTime = new Date();
        } else {
            this.equipmentDelivery.enterTime = this.dateService.convertDateUniversalToSiteTimeDate(new Date(this.equipmentDelivery.enterTime));
        }

        if (this.equipmentDelivery.exitTime === undefined) {
            this.equipmentDelivery.exitTime = new Date();
        } else {
            this.equipmentDelivery.exitTime = this.dateService.convertDateUniversalToSiteTimeDate(new Date(this.equipmentDelivery.exitTime));
        }

        this.loadCompanyContracts();
        this.loadEquipment();
        // this.loadMaterialCategories();
        // this.loadUnitTypes();
    }

    ngOnChanges(simpleChanges: SimpleChanges): void {
        this.breadcrumbService.setItemsByCategoryAndName(
                                this.section,
                                this.title,
                                this.path,
                                this.equipmentDelivery.name,
                                this.router.url);
    }

    showDialogChangedHandler(showDialog: boolean): void {
        this.refresh = new Date();
    }

    save() {
        if (this.equipmentDelivery.enterTime) {
            this.equipmentDelivery.enterTime = this.dateService.convertDateLocalToSiteTimeDate(new Date(this.equipmentDelivery.enterTime));
        }
        if (this.equipmentDelivery.exitTime) {
            this.equipmentDelivery.exitTime = this.dateService.convertDateLocalToSiteTimeDate(new Date(this.equipmentDelivery.exitTime));
        }
        this.dataService.save(this.category, this.equipmentDelivery)
            .subscribe((data) => {
                this.loggingService.savedMessage();
            });
    }

    saveEquipment(): void {
        this.closeEquipmentDialog();
        this.dataService.save('equipment', this.equipment)
            .subscribe((data) => {
                this.loggingService.savedMessage();
                this.loadEquipment();
            });
    }

    cancel() {
        this.router.navigate(['/' + this.category + '/search/']);
    }

    openUploadDialog(): void {
        this.showUploadDialog = true;
    }

    openEquipmentDialog(): void {
        this.equipment = {EquipmentDeliveryId: this.equipmentDelivery.id};
        this.showEquipmentDialog = true;
    }

    closeEquipmentDialog(): void {
        this.showEquipmentDialog = false;
    }

    editEquipmentDialog(equipment: any): void {
        this.equipment = equipment;

        this.showEquipmentDialog = true;
    }

    deleteEquipmentDialog(equipment: any): void {
        this.equipment = equipment;
    }

    closeUploadDialog(): void {
        this.showUploadDialog = false;
    }

    loadCompanyContracts(): void {
        this.dataService.getById('lookups', 'companycontracts')
            .subscribe((data) => {
                this.companyContracts = data;
            });
    }

    loadEquipment(): void {
        this.dataService.getById('equipment', this.equipmentDelivery.id)
        .subscribe((data) => {
            this.equipmentList = data;
        });
    }
}
