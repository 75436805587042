<form #thisForm='ngForm'>
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <h3>{{survey.name}}</h3>
                {{survey.description}}
            </div>
            <div class="md-col-6">
                <div>
                    <button pButton (click)="openSearchDialog()" icon="pi pi-search" label="Search Badgeholder"></button><br /><br />
                    <div *ngIf="badgeHolder">
                        <div class="col-md-1">
                            <img [src]="badgeHolder.photoURL" width="100" style="padding: 5px;" />
                        </div>
                        <div class="col-md-4">
                            {{badgeHolder.name}}<br />
                            {{badgeHolder.companyName}}<br />
                            {{badgeHolder.laborClassDesc}}   
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="align-content: center;">
            <button pButton (click)="startSurvey()" [disabled]="enableSurveyButton">Start Survey</button> 
        </div>
        <st-update-block [object]='survey'></st-update-block>
    </div>
</form>
<p-dialog header="Survey" [(visible)]="viewSurveyDialog" [responsive]="true" [style]="{ width: '600px', height: '500px' }">
    <table class="table table-stripped">
        <tr *ngFor="let response of responses" style="padding: 2px;">
            <td>
                {{response.name}}
                <div *ngIf="response.questionType==='multiple'">
                    <table>
                        <tr>
                            <td>
                                <img src="/assets/images/face_q1.png" style="width: 50px" /><br/>
                                <p-radioButton value="1" [(ngModel)]="response.value"></p-radioButton>
                            </td>
                            <td>
                                <img src="/assets/images/face_q2.png" style="width: 50px" /><br/>
                                <p-radioButton value="2" [(ngModel)]="response.value"></p-radioButton>
                            </td>
                            <td>
                                <img src="/assets/images/face_q3.png" style="width: 50px" /><br/>
                                <p-radioButton value="3" [(ngModel)]="response.value"></p-radioButton>
                            </td>
                            <td>
                                <img src="/assets/images/face_q4.png" style="width: 50px" /><br/>
                                <p-radioButton value="4" [(ngModel)]="response.value"></p-radioButton>
                            </td>
                            <td>
                                <img src="/assets/images/face_q5.png" style="width: 50px" /><br/>
                                <p-radioButton value="5" [(ngModel)]="response.value"></p-radioButton>
                            </td>
                        </tr>
                    </table>
                </div>
            </td>
            <td width="150" *ngIf="response.questionType==='yesno'">
                <p-radioButton value="1" [(ngModel)]="response.value" label="Yes"></p-radioButton>
                <p-radioButton value="0" [(ngModel)]="response.value" label="No"></p-radioButton>
            </td>
        </tr>
    </table>
    <p-footer>
        <button pButton label="Submit Survey" (click)="submitSurvey()"></button>
    </p-footer>
</p-dialog>
<app-badgeholder-search [(showDialog)]="showSearchDialog" (badgeHolderChange)="badgeHolderChangedHandler($event)"></app-badgeholder-search>
