<div class="container">
    <div class="row">
        <span class="grid-header">Completed Surveys</span>
    </div>
    <div class="row">
        <div class="col-lg-3">
            <label class="control-label">BadgeHolder:</label>
            <p-dropdown [options]="badgeHolders" [filter]="true" [(ngModel)]="badgeHolderId"></p-dropdown>
        </div>
        <div class="col-lg-3">
            <label class="control-label">Start Date:</label><br>
            <p-calendar [(ngModel)]="startDate" [showIcon]="true"></p-calendar>
        </div>
        <div class="col-lg-3">
            <label class="control-label">End Date:</label><br>
            <p-calendar [(ngModel)]="endDate" [showIcon]="true"></p-calendar>
        </div>
        <div class="col-lg-3">
            <button pButton (click)="loadData()" id="btnSubmit" label="Submit" icon="pi pi-search"></button>
            <!-- <button pButton (click)="createPDF()" id="btnPDF" label="PDF" icon="pi pi-file-pdf"></button> -->
            <button pButton (click)="createExcel()" label="Excel" icon="pi pi-file-excel"></button>
        </div>
    </div>
    <div class="row">
        <p-table #dt [value]="attempts" [columns]="cols" scrollHeight="700px" scrollable="true">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th>View</th>
                    <th *ngFor="let col of columns" [pSortableColumn]="(col.sortable ? col.field : null)">
                        {{col.header}}
                        <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
                    </th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr [pSelectableRow]="rowData">
                    <td>
                        <button type="button" pButton (click)="view(rowData)" icon="pi pi-search" style="max-width: 90%"></button>
                    </td>
                    <td *ngFor="let col of columns">
                        <span *ngIf="col.type==='text'">{{rowData[col.field]}}</span>
                        <span *ngIf="col.type==='datetime'">{{rowData[col.field] | date: "MM/dd/yyyy HH:mm"}}</span>
                    <td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<p-dialog header="Survey Response" [(visible)]="showAddDialog" [responsive]="true" [style]="{ width: '600px', height: '500px' }">
    <div>{{badgeHolderName}}</div>
    <div>Survey: {{surveyName}}</div>
    <label>Questions</label>
    <table class="table table-striped">
        <tr *ngFor="let response of responses" style="padding: 2px;">
            <td>{{response.name}}</td>
            <td width="100">
                <span *ngIf="response.value">Yes</span>
                <span *ngIf="!response.value">No</span>
            </td>
        </tr>
    </table>
    <p-footer>
        <button type="button" pButton icon="pi pi-ban" (click)="cancel()" label="Cancel"></button>
    </p-footer>
</p-dialog>

