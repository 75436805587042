import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BreadCrumbService } from '../services/breadcrumb.service';
import { DataService } from '../services/data.service';
import { Message } from 'primeng/api';
import { LoggingService } from '../services/logging.service';
import { Subscription } from 'rxjs';
import { UserService } from '../services/user.service';

@Component({
    selector: 'app-inspectors',
    templateUrl: './inspectors.component.html',
    encapsulation: ViewEncapsulation.None
})
export class InspectorsComponent implements OnInit {

    pageInfo: any;
    path: string;
    id: string;
    private sub: any;
    inspector: any;
    message: Message[] = [];

    constructor(
        private route: ActivatedRoute,
        private dataService: DataService,
        private breadcrumbService: BreadCrumbService,
        private router: Router,
        private userService: UserService,
        private loggingService: LoggingService) { }

    ngOnInit(): void {
        this.sub = this.route.params.subscribe(params => {
            this.id = params['id'];

            if (this.id === undefined) {
                this.loadData();
            } else {
                if (this.id === 'new') {
                    this.loggingService.logPageView('Inspectors - New');
                    this.inspector = {};
                } else {
                    this.loggingService.logPageView('Inspectors - Profile');
                    this.dataService.getById('inspectors', this.id)
                    .subscribe((data) => {
                        this.inspector = data;
                    });
                }
            }
         });
    }

    loadData() {
        this.loggingService.logPageView('Inspectors - Grid');
        this.inspector = null;
        this.dataService.getById('pages', 'inspectors')
        .subscribe((data) => {
            this.pageInfo = data.result;
            this.breadcrumbService.setItemsByCategory('Controls', this.pageInfo.title, this.router.url);
        });
    }
}
