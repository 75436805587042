<div class="layout-breadcrumb">
    <div class="row">
    <div class="col-lg-4">
        <ul>
            <li><a routerLink=""><i class="pi pi-home"></i></a></li>
            <li>/</li>
            <ng-template ngFor let-item let-last="last" [ngForOf]="items">
                <li>
                    <a [routerLink]="item.routerLink" *ngIf="item.routerLink">{{item.label}}</a>
                    <ng-container *ngIf="!item.routerLink">{{item.label}}</ng-container>
                </li>
                <li *ngIf="!last">/</li>
            </ng-template>
        </ul>
    </div>
    <div class="col-lg-4">
    </div>
    <div class="col-lg-4" [hidden]="false">
        <div class="layout-breadcrumb-options">
            <button type="button" pButton icon="pi pi-chevron-left pi-small-icon" (click)="previousDate()"></button>
            <p-calendar [(ngModel)]="selectedDate" (onSelect)="dateChanged()" [showIcon]="true"></p-calendar>
            <button type="button" pButton icon="pi pi-chevron-right pi-small-icon" (click)="nextDate()"></button>
        </div>
    </div>
</div>
</div>