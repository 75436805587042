// Angular
import { Routes } from '@angular/router';

// Custom Auth
import { AnonymousLayoutComponent } from './layouts/anonymous-layout.component';
import { AuthGuard } from './components/services/authguard.service';
import { LoginComponent } from './components/login/login.component';
import { SecureLayoutComponent } from './layouts/secure-layout.component';

// Components
import { BadgeHoldersComponent } from './components/badgeholder/badgeholders.component';
import { BadgeHoldersTSMCComponent } from './components/badgeholder/badgeholderstsmc.component';
import { CompanyComponent } from './components/company/company.component';
import { ContractComponent } from './components/contract/contract.component';
import { ContractStatusComponent } from './components/reports/contract-status/contract-status.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PageComponent } from './components/page/page.component';
import { SiteProfileComponent } from './components/site/siteprofile.component';
import { OnsiteComponent } from './components/onsite/onsite.component';
import { ForecastComponent } from './components/forecast/forecast.component';
import { UsersComponent } from './components/user/user.component';
import { DailyReportComponent } from './components/reports/daily-report/daily-report.component';
import { DelayComponent } from './components/delay/delay.component';
import { DelayDetailComponent } from './components/delay/detail/delay.detail.component';
import { ActivityLogComponent } from './components/activitylog/activitylog.component';
import { ApproveLaborComponent } from './components/approve-labor/approve-labor.component';
import { InjuriesComponent } from './components/injuries/injuries.component';
import { TerminalComponent } from './components/terminal/terminal.component';
import { StaticReportComponent } from './components/reports/static-report/static-report.component';
import { HaulOffComponent } from './components/hauloff/hauloff.component';
import { PowerBIReportComponent } from './components/reports/powerbi-report/powerbi-report.component';
import { BuildingMaterialComponent } from './components/buildingmaterial/buildingmaterial.component';
import { ConcreteComponent } from './components/concrete/concrete.component';
import { VisitorsComponent } from './components/visitor/visitors.component';
import { InspectorsComponent } from './components/inspector/inspectors.component';
import { EquipmentDeliveryComponent } from './components/equipment/equipment.component';
import { RewardComponent } from './components/rewards/reward.component';
import { ViolationComponent } from './components/violation/violation.component';
import { IncidentComponent } from './components/incident/incident.component';
import { ProjectComponent } from './components/project/project.component';
import { EmailReportComponent } from './components/reports/email-report/email-report.component';
import { GatewaysComponent } from './components/gateway/gateway.component';
import { ParkingSearchComponent } from './components/security/parking-search/parkingsearch.component';

import { BadgeTemplatesComponent } from './components/badge-templates/badge-templates.component';
import { MenusComponent } from './components/menus/menus.component';
import { SecurityComponent } from './components/security/security.component';
import { SiteCreateComponent } from './components/site-create/site-create.component';
import { ClockLogsComponent } from './components/clock-logs/clock-logs.component';
import { DailyOnsiteComponent } from './components/reports/daily-onsite/daily-onsite.component';
import { BadgeholderLastScanComponent } from './components/reports/badgeholder-lastscan/badgeholder-lastscan.component';
import { BadgeholderNoBadgeComponent } from './components/reports/badgeholder-nobadge/badgeholder-nobadge.component';
import { ExecutiveComponent } from './components/reports/executive/executive.component';
import { InjurySMSComponent } from './components/injury-sms/injury-sms.component';
import { WeeklyReportComponent } from './components/reports/weekly-report/weekly-report.component';
import { WeeklyReportHoursComponent } from './components/reports/weekly-report-hours/weekly-report-hours.component';
import { InspectionsComponent } from './components/inspections/inspections.component';
import { DailyAttendanceComponent } from './components/reports/daily-attendance/daily-attendance.component';
import { SurveyComponent } from './components/survey/survey.component';
import { TakeSurveyComponent } from './components/takesurvey/takesurvey.component';
import { ResourceComponent } from './components/resource/resource.component';
import { ResourceScheduleComponent } from './components/resource/schedule/resource.schedule.component';
import { SurveySMSComponent } from './components/survey-sms/survey-sms.component';
import { SMSComponent } from './components/sms/sms.component';
import { InjuryReportComponent } from './components/reports/injury/injury.component';
import { WeatherStatusComponent } from './components/weather-status/weather-status.component';
import { UserAssignmentComponent } from './components/user/assignment/user.assignment.component';
import { TerminalStatusComponent } from './components/terminal-status/terminal-status.component';
import { DashboardComponent2 } from './components/dashboard/dashboard2.component';
import { PhaseQuantityComponent } from './components/phase-quantity/phase-quantity.component';
import { DocumentExpirationReportComponent } from './components/reports/expiration/expiration.component';
import { CrewComponent } from './components/crew/crew.component';
import { PhoneListComponent } from './components/phone-list/phone-list.component';

export const APP_ROUTES: Routes = [
    {
        path: '',
        component: SecureLayoutComponent,
        children: [
            { path: '', component: DashboardComponent, canActivate: [AuthGuard] }
        ]
    },
    {
        path: 'dashboard',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', component: DashboardComponent2 }
        ]
    },
    {
        path: 'activitylog',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', component: ActivityLogComponent }
        ]
    },
    {
        path: 'phonelist',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', component: PhoneListComponent }
        ]
    },
    {
        path: 'forecast',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', component: ForecastComponent }
        ]
    },
    {
        path: 'crews',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', component: CrewComponent }
        ]
    },
    {
        path: 'phase-quantities',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', component: PhaseQuantityComponent }
        ]
    },
    {
        path: 'approvelabor',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', component: ApproveLaborComponent }
        ]
    },
    {
        path: 'login',
        component: AnonymousLayoutComponent,
        children: [
            { path: '', component: LoginComponent }
        ]
    },
    {
        path: 'emailreports/:userid/:dt',
        component: AnonymousLayoutComponent,
        children: [
            { path: '', component: EmailReportComponent }
        ]
    },
    {
        path: 'site',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'detail', component: SiteProfileComponent }
        ]
    },
    {
        path: 'badgeholders',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'detail/:id', component: BadgeHoldersComponent },
            { path: 'search', component: BadgeHoldersComponent }
        ]
    },
    {
        path: 'badgeholderstsmc',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'detail/:id', component: BadgeHoldersComponent },
            { path: 'search', component: BadgeHoldersTSMCComponent }
        ]
    },
    {
        path: 'parking',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'search', component: ParkingSearchComponent }
        ]
    },
    {
        path: 'gateways',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'detail/:id', component: GatewaysComponent },
            { path: 'search', component: GatewaysComponent }
        ]
    },
    {
        path: 'visitors',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'detail/:id', component: VisitorsComponent },
            { path: 'search', component: VisitorsComponent }
        ]
    },
    {
        path: 'inspectors',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'detail/:id', component: InspectorsComponent },
            { path: 'search', component: InspectorsComponent }
        ]
    },
    {
        path: 'inspections',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'detail/:id', component: InspectionsComponent },
            { path: 'search', component: InspectionsComponent }
        ]
    },
    {
        path: 'terminals',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'detail/:id', component: TerminalComponent },
            { path: 'search', component: TerminalComponent }
        ]
    },
    {
        path: 'users',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'detail/:id', component: UsersComponent },
            { path: 'search', component: UsersComponent }
        ]
    },
    {
        path: 'companies',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'detail/:id', component: CompanyComponent },
            { path: 'search', component: CompanyComponent }
        ]
    },
    {
        path: 'projects',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'detail/:id', component: ProjectComponent },
            { path: 'search', component: ProjectComponent }
        ]
    },
    {
        path: 'reports',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'dailyreport', component: DailyReportComponent },
            { path: 'static/:id', component: StaticReportComponent },
            { path: 'powerbi/:id', component: PowerBIReportComponent },
            { path: 'daily-onsite', component: DailyOnsiteComponent },
            { path: 'badgeholder-lastscan', component: BadgeholderLastScanComponent },
            { path: 'badgeholder-nobadge', component: BadgeholderNoBadgeComponent },
            { path: 'executive', component: ExecutiveComponent },
            { path: 'dailyattendance', component: DailyAttendanceComponent },
            { path: 'injury', component: InjuryReportComponent },
            { path: 'expiration', component: DocumentExpirationReportComponent },
            { path: 'weeklyreport', component: WeeklyReportComponent },
            { path: 'weeklyreporthours', component: WeeklyReportHoursComponent },
            { path: 'contract-status', component: ContractStatusComponent }
        ]
    },
    {
        path: 'delays',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'detail/:id', component: DelayComponent },
            { path: 'search', component: DelayComponent }
        ]
    },
    {
        path: 'buildingmaterials',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'detail/:id', component: BuildingMaterialComponent },
            { path: 'search', component: BuildingMaterialComponent }
        ]
    },
    {
        path: 'surveys',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'detail/:id', component: SurveyComponent },
            { path: 'search', component: SurveyComponent }
        ]
    },
    {
        path: 'takesurveys',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'detail/:id', component: TakeSurveyComponent },
            { path: 'search', component: TakeSurveyComponent }
        ]
    },
    {
        path: 'resources',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'detail/:id', component: ResourceComponent },
            { path: 'search', component: ResourceComponent },
            { path: 'schedule', component: ResourceScheduleComponent }
        ]
    },
    {
        path: 'concrete',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'detail/:id', component: ConcreteComponent },
            { path: 'search', component: ConcreteComponent }
        ]
    },
    {
        path: 'rewards',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'detail/:id', component: RewardComponent },
            { path: 'search', component: RewardComponent }
        ]
    },
    {
        path: 'violations',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'detail/:id', component: ViolationComponent },
            { path: 'search', component: ViolationComponent }
        ]
    },
    {
        path: 'incidents',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'detail/:id', component: IncidentComponent },
            { path: 'search', component: IncidentComponent }
        ]
    },
    {
        path: 'injuries',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', component: InjuriesComponent }
        ]
    },
    {
        path: 'equipmentdeliveries',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'detail/:id', component: EquipmentDeliveryComponent },
            { path: 'search', component: EquipmentDeliveryComponent }
        ]
    },
    {
        path: 'hauloffs',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'detail/:id', component: HaulOffComponent },
            { path: 'search', component: HaulOffComponent }
        ]
    },
    {
        path: 'sms',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'search', component: SMSComponent }
        ]
    },
    {
        path: 'onsite',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', component: OnsiteComponent }
        ]
    },
    {
        path: 'contracts',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'detail/:id', component: ContractComponent },
            { path: 'search', component: ContractComponent }
        ]
    },
    {
        path: 'global',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'badge-templates', component: BadgeTemplatesComponent },
            { path: 'security', component: SecurityComponent },
            { path: 'menus', component: MenusComponent },
            { path: 'site-create', component: SiteCreateComponent },
            { path: 'clock-logs', component: ClockLogsComponent },
            { path: 'injury-sms', component: InjurySMSComponent },
            { path: 'survey-sms', component: SurveySMSComponent },
            { path: 'user-assignment', component: UserAssignmentComponent },
            { path: 'weather-status', component: WeatherStatusComponent },
            { path: 'terminal-status', component: TerminalStatusComponent }
        ]
    },
    {
        path: 'page',
        component: SecureLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: ':page', component: PageComponent }
        ]
    },
    { path: '**', redirectTo: 'login' }
];
