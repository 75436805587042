<div class="container">
    <div class="row">
        <div>
            <h3>{{badgeHolder.name}}</h3>
        </div>
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col-lg-6">
            <strong>View:</strong>
            <button type="button" pButton (click)="showTab(false)" icon="pi pi-list"></button>
            <button type="button" pButton (click)="showTab(true)" icon="pi pi-folder"></button>
        </div>
        <div class="col-lg-6">
          <div *ngIf="badgeHolder.isSynced" class="float-right">
            <div class="alert alert-warning">
              This badge holder is synced from another site and therefore cannot be edited.
            </div>
          </div>
          <div *ngIf="badgeHolder.deleted" class="float-right">
            <div class="alert alert-danger">
              This badge holder is deleted and cannot be edited.
            </div>
          </div>
            <div *ngIf="!badgeHolder.isSynced && !badgeHolder.deleted" class="float-right">
                <button pButton (click)="save(true)" [disabled]="required != null && !required.isValid()" icon="pi pi-save" label="New"></button>
                <button pButton (click)="save(false)" [disabled]="required != null && !required.isValid()" icon="pi pi-save" label="Save"></button>
                <button pButton (click)="cancel()" icon="pi pi-ban" label="Cancel"></button>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div *ngIf="!showTabFlag">
                <p-accordion>
                    <p-accordionTab header="Required Information" [selected]="true">
                        <app-badgeholder-required [badgeHolder]="badgeHolder" (badgeHolderChanged)="badgeHolderChangedHandler($event)"></app-badgeholder-required>
                    </p-accordionTab>
                    <br />
                    <div>
                    <p-accordionTab header="Projects" *ngIf="badgeHolder.id">
                        <app-badgeholder-projects [badgeHolder]="badgeHolder"></app-badgeholder-projects>
                    </p-accordionTab>
                    <br />
                    </div>
                    <p-accordionTab header="Address">
                        <badgeholder-address [badgeHolder]="badgeHolder" [states]="states"></badgeholder-address>
                    </p-accordionTab>
                    <br />
                    <div>
                    <p-accordionTab header="Surveys">
                        <app-badgeholder-survey-attempts [badgeHolder]="badgeHolder"></app-badgeholder-survey-attempts>
                    </p-accordionTab>
                    <br />
                    </div>
                    <p-accordionTab header="Other Information">
                        <app-badgeholder-other [badgeHolder]="badgeHolder"></app-badgeholder-other>
                    </p-accordionTab>
                    <br />
                    <p-accordionTab header="Termination/Suspension">
                        <badgeholder-termination [badgeHolder]="badgeHolder"></badgeholder-termination>
                    </p-accordionTab>
                    <br />
                    <p-accordionTab header="Vehicle">
                        <badgeholder-vehicle [badgeHolder]="badgeHolder" [states]="states"></badgeholder-vehicle>
                    </p-accordionTab>
                    <br />
                    <p-accordionTab header="Emergency/Medical">
                        <app-badgeholder-emergency [badgeHolder]="badgeHolder"></app-badgeholder-emergency>
                    </p-accordionTab>
                    <br />
                    <p-accordionTab header="Documents" *ngIf="badgeHolder.id">
                        <app-badgeholder-documents [badgeHolder]="badgeHolder"></app-badgeholder-documents>
                    </p-accordionTab>
                    <br />
                    <p-accordionTab header="Photos/Clocks" *ngIf="badgeHolder.id" ons>
                        <app-badgeholder-photos [badgeHolder]="badgeHolder"></app-badgeholder-photos>
                    </p-accordionTab>
                    <br />
                    <p-accordionTab header="Gateway" *ngIf="badgeHolder.id && isGlobalAdmin">
                        <app-badgeholder-activescans [badgeHolder]="badgeHolder"></app-badgeholder-activescans>
                    </p-accordionTab>
                    <br />
                    <p-accordionTab header="Audit" *ngIf="badgeHolder.id && isGlobalAdmin">
                        <app-badgeholder-audit [badgeHolder]="badgeHolder"></app-badgeholder-audit>
                    </p-accordionTab>
                    <br />
                </p-accordion>
            </div>
            <div *ngIf="showTabFlag">
                <p-tabView>
                    <p-tabPanel header="Required Information">
                        <app-badgeholder-required [badgeHolder]="badgeHolder"></app-badgeholder-required>
                    </p-tabPanel>
                    <p-tabPanel header="Projects">
                        <app-badgeholder-projects [badgeHolder]="badgeHolder"></app-badgeholder-projects>
                    </p-tabPanel>
                    <p-tabPanel header="Address">
                        <badgeholder-address [badgeHolder]="badgeHolder" [states]="states"></badgeholder-address>
                    </p-tabPanel>
                    <p-tabPanel header="Surveys">
                        <app-badgeholder-survey-attempts [badgeHolder]="badgeHolder"></app-badgeholder-survey-attempts>
                    </p-tabPanel>
                    <p-tabPanel header="Other Information">
                        <app-badgeholder-other [badgeHolder]="badgeHolder"></app-badgeholder-other>
                    </p-tabPanel>
                    <p-tabPanel header="Termination/Suspension">
                        <badgeholder-termination [badgeHolder]="badgeHolder"></badgeholder-termination>
                    </p-tabPanel>
                    <p-tabPanel header="Vehicle">
                        <badgeholder-vehicle [badgeHolder]="badgeHolder" [states]="states"></badgeholder-vehicle>
                    </p-tabPanel>
                    <p-tabPanel header="Emergency/Medical">
                        <app-badgeholder-emergency [badgeHolder]="badgeHolder"></app-badgeholder-emergency>
                    </p-tabPanel>
                    <p-tabPanel header="Documents">
                        <app-badgeholder-documents [badgeHolder]="badgeHolder"></app-badgeholder-documents>
                    </p-tabPanel>
                    <p-tabPanel header="Photos/Clocks">
                        <app-badgeholder-photos [badgeHolder]="badgeHolder"></app-badgeholder-photos>
                    </p-tabPanel>
                    <p-tabPanel header="Gateway" *ngIf="isGlobalAdmin">
                        <app-badgeholder-activescans [badgeHolder]="badgeHolder"></app-badgeholder-activescans>
                    </p-tabPanel>
                    <p-tabPanel header="Audit" *ngIf="isGlobalAdmin">
                        <app-badgeholder-audit [badgeHolder]="badgeHolder"></app-badgeholder-audit>
                    </p-tabPanel>
                </p-tabView>
            </div>
        </div>
    </div>
    <st-update-block [object]='badgeHolder' *ngIf="badgeHolder"></st-update-block>
</div>

<p-confirmDialog #cd header='Historical clock records' icon='pi pi-save'>
    <p-footer>
        <div class="row">
            <div class="col-7">
                <div class="row">
                    <div class="col-12 text-left">
                        <label class="control-label">Only Records After:</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-left">
                        <p-calendar class="after-date" name="afterDate" [(ngModel)]="afterDate" [showIcon]="true"></p-calendar>
                    </div>
                </div>
            </div>
            <div class="col-5 text-right">
                <button type="button" pButton icon="pi pi-check" label="Yes" (click)="cd.accept()"></button>
                <button type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button>
            </div>
        </div>
    </p-footer>
</p-confirmDialog>
