<form #thisForm='ngForm'>
    <div class="container">
        <div class="row">
            <div>
                <h3>{{visitor.name}}</h3>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="float-right">
                    <button pButton (click)="save()" [disabled]="!thisForm.valid" icon="pi pi-save" label="Save"></button>
                    <button pButton (click)="cancel()" icon="pi pi-ban" label="Cancel"></button>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-md-6">
                    <label>Badge Number</label>
                    <div class="form-group">
                        <input type="text" pInputText placeholder="####" [(ngModel)]="visitor.badgeNum" name="badgeNum" maxlength="36" class="form-control" required />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label>First Name</label>
                        <input type="text" name="firstName" placeholder="John" pInputText [(ngModel)]="visitor.firstName" maxlength="50" class="form-control" required />
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label>Last Name</label>
                        <input type="text" name="lastName" placeholder="Doe" pInputText [(ngModel)]="visitor.lastName" maxlength="50" class="form-control" required />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label>Visiting Company</label><br />
                        <p-dropdown [options]="companies" [filter]="true" placeholder="Select a Company" required [(ngModel)]="visitor.companyId" name="companyDropdown"></p-dropdown>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <label>Phone #</label>
                    <p-inputMask mask="(999)999-9999" [(ngModel)]="visitor.phone" name="phone" placeholder="Mobile #"></p-inputMask>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label>Reason for Visit</label>
                        <textarea pTextArea name="notes" [(ngModel)]="visitor.notes" maxlength="500" placeholder="Description" class="form-control"></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-6">
                    <div class="form-group">
                        <label>Access Start Date</label><br />
                        <p-calendar [(ngModel)]="visitor.startDate" name="startDate" [showIcon]="true"></p-calendar>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label>Access End Date</label><br />
                        <p-calendar [(ngModel)]="visitor.terminationDate" name="terminationDate" [showIcon]="true"></p-calendar>
                    </div>
                </div>
            </div>
        </div>
        <st-update-block [object]='visitor' *ngIf="visitor"></st-update-block>
    </div>
</form>

