import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataService } from '../services/data.service';
import { LoggingService } from '../services/logging.service';

@Component({
    selector: 'app-site-create',
    templateUrl: './site-create.component.html',
    encapsulation: ViewEncapsulation.None
})
export class SiteCreateComponent implements OnInit {

    data = { id: 'new', name: null, companyName: null, companyType: null, csiDivision: null, tradeClass: null, userName: null, weatherStationId: null, accuWeatherId: null, timeZone: 'CST', defaultLaborClassIds: null };
    companyCategories: any[];
    divisions: any[];
    trades: any[];
    defaultLaborClasses = [];

    constructor(private dataService: DataService, private loggingService: LoggingService) { }

    ngOnInit() {
        this.dataService.getById('lookups', 'defaultlaborclasses')
            .subscribe((lookup) => {
                this.defaultLaborClasses = lookup;

                this.companyCategories = [];
                this.defaultLaborClasses.forEach(m => {
                    if (this.companyCategories.filter(c => c.value === m.categoryCode).length === 0)
                        this.companyCategories.push({ value: m.categoryCode, label: m.categoryName });
                });
                this.companyCategories.sort((a, b) => ((a.label === b.label) ? 0 : ((a.label > b.label) ? 1 : -1)));

                this.setup();
            });
    }

    setup() {
        this.defaultLaborClasses.forEach(m => {
            m.selected = ((m.isDefault != null && m.isDefault == true) || m.tradeCode === this.data.tradeClass);
        });
    }

    loadCSIDivisions(): void {
        this.data.csiDivision = null;
        this.data.tradeClass = null;

        this.divisions = [];
        this.defaultLaborClasses.filter(m => m.categoryCode === this.data.companyType).forEach(m => {
            if (this.divisions.filter(c => c.value === m.divisionCode).length === 0)
                this.divisions.push({ value: m.divisionCode, label: m.divisionName });
        });
        this.divisions.sort((a, b) => ((a.label === b.label) ? 0 : ((a.label > b.label) ? 1 : -1)));
    }

    loadTrades(): void {
        this.data.tradeClass = null;

        this.trades = [];
        this.defaultLaborClasses.filter(m => m.divisionCode === this.data.csiDivision).forEach(m => {
            if (this.trades.filter(c => c.value === m.tradeCode).length === 0)
                this.trades.push({ value: m.tradeCode, label: m.tradeName });
        });
        this.trades.sort((a, b) => ((a.label === b.label) ? 0 : ((a.label > b.label) ? 1 : -1)));
    }

    save(): void {
        this.data.id = 'new';

        this.data.defaultLaborClassIds = [];
        this.defaultLaborClasses.forEach(m => {
            if (m.selected)
                this.data.defaultLaborClassIds.push(m.id);
        });

        this.dataService.save('sites', this.data)
            .subscribe((data) => {
                this.loggingService.savedMessage();

                this.data = { id: 'new', name: null, companyName: null, companyType: null, csiDivision: null, tradeClass: null, userName: null, weatherStationId: null, accuWeatherId: null, timeZone: 'CST', defaultLaborClassIds: null };
                this.setup();
            });
    }

}
