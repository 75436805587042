<div class="row">
    <div class="col-lg-12">
        <table class="table table-striped sitetraxx">
            <thead>
                <tr class="sitetraxx">
                    <th>Provider</th>
                    <th>Purchaser</th>
                    <th>Yards</th>
                </tr>
            </thead>
            <tr *ngFor="let concrete of concreteList">
                <td>{{concrete.provider}}</td>
                <td>{{concrete.name}}</td>
                <td>{{concrete.quantity}}</td>
            </tr>
        </table>
    </div>
</div>