import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BreadCrumbService } from '../services/breadcrumb.service';
import { DataService } from '../services/data.service';
import { Message } from 'primeng/api';
import { LoggingService } from '../services/logging.service';
import { Subscription } from 'rxjs';
import { UserService } from '../services/user.service';
import { DateService } from '../services/date.service';

@Component({
    selector: 'app-inspections',
    templateUrl: './inspections.component.html',
    encapsulation: ViewEncapsulation.None
})
export class InspectionsComponent implements OnInit {

    pageInfo: any;
    path: string;
    id: string;
    private sub: any;
    inspection: any;
    message: Message[] = [];
    scheduledStartDate: Date;
    scheduledEndDate: Date;
    filterInfo = '';

    constructor(
        private route: ActivatedRoute,
        private dataService: DataService,
        private dateService: DateService,
        private breadcrumbService: BreadCrumbService,
        private router: Router,
        private userService: UserService,
        private loggingService: LoggingService) { }

    ngOnInit(): void {
        this.sub = this.route.params.subscribe(params => {
            this.id = params['id'];

            if (this.id === undefined) {
                this.loadData();
            } else {
                if (this.id === 'new') {
                    this.loggingService.logPageView('Inspection - New');
                    this.inspection = {};
                } else {
                    this.loggingService.logPageView('Inspection - Profile');
                    this.dataService.getById('inspections', this.id)
                    .subscribe((data) => {
                        this.inspection = data;
                    });
                }
            }
         });
    }

    filter() {
        var startDate = this.dateService.formatDate(new Date(this.scheduledStartDate));
        var endDate = this.dateService.formatDate(new Date(this.scheduledEndDate));
        this.filterInfo = "date/range/" + startDate + "/" + endDate;

        this.loadData();
    }

    clear() {
        this.filterInfo = "";
        this.loadData();
    }


    loadData() {
        this.loggingService.logPageView('Inspections - Grid');
        this.inspection = null;
        this.dataService.getById('pages', 'inspections')
        .subscribe((data) => {
            this.pageInfo = data.result;
            this.breadcrumbService.setItemsByCategory('Controls', this.pageInfo.title, this.router.url);
        });
    }
}
