import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';

import { APP_COMPONENTS } from './app.components';
import { APP_IMPORTS } from './app.imports';
import { APP_PROVIDERS } from './app.providers';
import { APP_ROUTES } from './app.routes';


import { ServiceWorkerModule } from '@angular/service-worker';
import { AppComponent } from './components/app/app.component';

import { environment } from '../environments/environment';

import * as d3 from "d3";
import * as nvd3 from "nvd3";

@NgModule({
  bootstrap: [ AppComponent ],
  declarations: [APP_COMPONENTS],
  providers: [APP_PROVIDERS],
  imports: [APP_IMPORTS, RouterModule.forRoot(APP_ROUTES)]
})
export class AppModule { }
