import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TreeNode } from 'primeng/api';
import { DataService } from '../services/data.service';
import { LoggingService } from '../services/logging.service';
import { Subscription } from 'rxjs';
import { UserService } from '../services/user.service';

@Component({
    selector: 'app-security',
    templateUrl: './security.component.html',
    styleUrls: ['./security.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class SecurityComponent implements OnInit {

    sites: any[];
    siteId: string;

    roles: any[];
    roleId: string;

    menus = [];
    selected: any[];

    constructor(private dataService: DataService,
        private loggingService: LoggingService) { }

    ngOnInit() {
        this.dataService.getById('lookups', 'site')
            .subscribe((lookup) => {
                this.sites = lookup;
                this.sites.unshift({});
        });
        
        this.dataService.getById('menus', 'menus')
            .subscribe((menus) => {
                menus.forEach(m => {
                    if (m.parentId === '0') {
                        this.menus.push({
                            data: m.id,
                            label: m.name,
                            expandedIcon: "ui-icon-folder-open",
                            collapsedIcon: "ui-icon-folder",
                            children: []
                        });
                    }
                    else {
                        let parent = null;
                        this.menus.forEach(p => {
                            if (p.data === m.parentId)
                                parent = p;
                        });
                        if (parent)
                            parent.children.push({
                                data: m.id,
                                label: m.name
                            });
                    }
                });
        });
    }

    loadSite() {
        this.roleId = null;
        this.dataService.getById('menus', 'security-roles/' + this.siteId)
        .subscribe((roles) => {
            this.roles = roles;
            this.roles.unshift({});
        });
    }

    loadRole() {
        this.selected = [];
        this.roles.forEach(r => {
            if (r.value === this.roleId) {
                r.menuIds.forEach(id => {
                    this.menus.forEach(m => {
                        if (m.data === id) 
                            this.selected.push(m);
                        else
                            m.children.forEach(c => {
                                if (c.data === id) {
                                    this.selected.push(c);
                                }
                            });
                    });
                });
            }
        });

        this.menus.forEach(m => {
            m.expanded = false;
        });
        this.selected.forEach(m => {
            if (m.children)
                m.expanded = true;
        });
    }

    save() {
        const menuIds = [];
        this.selected.forEach(m => {
            if (m.parent && menuIds.filter(id => id === m.parent.data).length === 0)
                menuIds.push(m.parent.data);
            menuIds.push(m.data);
        });
        this.dataService.save('menus/security-roles', { roleId: this.roleId, menuAccess: menuIds.join(',') })
        .subscribe((data) => {
            this.roles.forEach(r => {
                if (r.value === this.roleId)
                    r.menuIds = menuIds;
            });
            this.loggingService.savedMessage();
        });
    }
}
