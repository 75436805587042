import { Component, OnInit, DoCheck } from '@angular/core';
import { DataService } from '../../services/data.service';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../../services/user.service';
import { DateService } from '../../services/date.service';
import { BreadCrumbService } from '../../services/breadcrumb.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-badgeholder-lastscan',
  templateUrl: './badgeholder-lastscan.component.html'
})
export class BadgeholderLastScanComponent implements OnInit {

  html: any;
  startDate: Date;
  endDate: Date;
  startDateStr: string;
  endDateStr: string;
  companies: any[];
  cols: any[];
  companyId: string = 'all';
  data: any[];
  totalRecords: number;
  section = 'Reports';
  title = 'Badge Holders - Last Scan';
  siteSubscription: Subscription;
  user: any;
  page: number = 1;
  pageSize: number = 100;
  pageSizeOptions: number[] = [100, 250,500,1000];

  searchSeconds: number;

  filtersChanged: boolean = false;

  reportData: any = {
    startDate: '20000101',
    endDate: '20000101',
    userId: '',
    companyIds: [],
    page: this.page,
    pageSize: this.pageSize,
    filters: []
  };

  constructor(private dataService: DataService,
              private userService: UserService,
              private dateService: DateService,
              private breadcrumbService: BreadCrumbService,
              private router: Router) {
                this.siteSubscription = this.userService.getSiteEvent()
                  .subscribe(siteId => {
                      this.loadData();
                  });
              }

  ngOnInit() {
    this.breadcrumbService.setItemsByCategory(this.section, this.title, this.router.url);
    this.loadData();
    this.cols = [
      { field: 'badgeNum', header: 'Badge #', canFilter: true},
      { field: 'siteSpecificId', header: 'Site ID', canFilter: true},
      { field: 'fullName', header: 'Name', canFilter: true},
      { field: 'companyName', header: 'Company', canFilter: true},
      { field: 'contractName', header: 'Contract', canFilter: true},
      { field: 'laborClass', header: 'Labor Class', canFilter: true},
      { field: 'lastScanFormatted', header: 'Last Clock', canFilter: false},
      { field: 'deletedFormatted', header: 'Deleted?', canFilter: false},
      { field: 'createdDateFormatted', header: 'Created', canFilter: false},
      { field: 'updatedDateFormatted', header: 'Last Update', canFilter: false}
    ];

    this.onSubmitForm();
  }

  ngOnDestroy() {
    this.siteSubscription.unsubscribe();
  }

  onSubmitForm() {

    this.reportData.userId = this.user.id;
    this.reportData.companyIds = [this.companyId];
    
    if (this.filtersChanged) {
      this.page = 1;
      this.filtersChanged = false;
    }
    this.reportData.page = this.page;

    if (this.reportData.pageSize === 0) {
      this.reportData.pageSize = this.pageSize;
    }

    const path = 'reports/badgeholder/lastscan/json';
    const starttime = performance.now();
    this.dataService.postForm(path, this.reportData)
        .subscribe((resp) => {
          this.totalRecords = resp.total;
          this.data = resp.results;

          const endtime = performance.now();

          if (starttime && endtime) {
            this.searchSeconds = (endtime - starttime) / 1000.0;
          }
        });
  }

  pageChanged(event: any) {
    this.pageSize = event.rows;
    this.reportData.pageSize = event.rows;

    this.page = (event.first / event.rows) + 1;
    this.reportData.page = this.page;

    this.onSubmitForm();
  }

  applyFilters(event: any) {
    this.reportData.filters = Object.keys(event.filters).map(key => {
      return { field: key, value: event.filters[key].value };
    });
    this.filtersChanged = true;
  }

  onStartDateChange() {
    this.startDateStr = this.dateService.formatDate(new Date(this.startDate));
  }

  onEndDateChange() {
    this.endDateStr = this.dateService.formatDate(new Date(this.endDate));
  }

  loadData() {
    this.loadCompanies();
    this.user = this.userService.currentUser();
  }

  loadCompanies(): void {
    this.dataService.getAll('companies')
        .subscribe((companies) => {
          this.companies = companies;
          this.companies.unshift({value: 'all', label: 'All' });
    });
  }

  createPDF(): void {
    this.reportData.userId = this.user.id;
    this.reportData.companyIds = [this.companyId];

    this.reportData.userId = this.user.id;
    this.reportData.companyIds = [this.companyId];

    const path = 'reports/badgeholder/lastscan/pdf';
    this.dataService.postForm(path, this.reportData, {responseType: 'arraybuffer'})
        .subscribe((data) => {
          const file = new Blob([data], {type: 'application/pdf'});
          const fileURL = window.URL.createObjectURL(file);

          const a = document.createElement('a');
          document.body.appendChild(a);
          a.href = fileURL;
          a.download = 'badgeholder.pdf';
          a.click();

          window.URL.revokeObjectURL(fileURL);
          a.remove();
        });

  }

  createExcel(): void {

    this.reportData.userId = this.user.id;
    this.reportData.companyIds = [this.companyId];

    const path = 'reports/badgeholder/lastscan/excel';
    this.dataService.postForm(path, this.reportData, {responseType: 'arraybuffer'})
        .subscribe((data) => {
          const file = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
          const fileURL = window.URL.createObjectURL(file);

          const a = document.createElement('a');
          document.body.appendChild(a);
          a.href = fileURL;
          a.download = 'badgeholder.xlsx';
          a.click();

          window.URL.revokeObjectURL(fileURL);
          a.remove();
        });
  }
}
