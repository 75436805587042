import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BreadCrumbService } from '../services/breadcrumb.service';
import { DataService } from '../services/data.service';
import { LoggingService } from '../services/logging.service';
import { UserService } from '../services/user.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-sms',
    templateUrl: './sms.component.html',
    encapsulation: ViewEncapsulation.None
})
export class SMSComponent implements OnInit {

    section = 'Security';
    title = 'Notify Users';
    isGlobalAdmin = false;
    companyId: string = "all";
    emergencyTeam: boolean = false;
    companies: any[];
    body = "";
    message = "";
    errorMessage = "";
    filteredRows: any[] = [];
    selectedRows: any[] = [];
    isAllChecked = false;
    hasSMSNumber = true;
    cols = [
        { field: 'photoURL', header: 'Profile', type: 'image', filter: false },
        { field: 'name', header: 'Name', type: 'text', filter: true },
        { field: 'companyName', header: 'Company', type: 'text', filter: true },
        { field: 'phone', header: 'Phone', type: 'text', filter: true },
        { field: 'emergencyTeam', header: 'Emergency Management Team', type: 'yesno2', filter: false },
        { field: 'textPhone', header: 'Allow Text', type: 'yesno', filter: true }
    ];
    data: any[];

    constructor(
        private route: ActivatedRoute,
        private dataService: DataService,
        private breadcrumbService: BreadCrumbService,
        private router: Router,
        private loggingService: LoggingService,
        private userService: UserService) {
        this.isGlobalAdmin = this.userService.currentUser().isGlobalAdmin;
    }

    ngOnInit(): void {
        this.breadcrumbService.setItemsByCategory(this.section, this.title, this.router.url);
        this.checkSite();
        this.loadCompanies();
        this.loadData();
    }

    checkSite() {
        this.dataService.getById('sites', this.userService.currentUser().siteId)
            .subscribe((data) => {
                console.log(data);
                if (data.smsNumber !== null) {
                    this.hasSMSNumber = false;
                    this.errorMessage = "";
                } else {
                    this.errorMessage = "No SMS Number has been set for this site.  Please contact your administrator.";
                }
            });
    }

    loadData() {
        this.loggingService.logPageView('Badge Holders SMS - Grid');
        this.dataService.getAll('badgeholders')
            .subscribe((data) => {
                this.data = data;
                this.filteredRows = this.data;
            });
    }

    loadCompanies(): void {
        this.dataService.getAll('companies')
            .subscribe((companies) => {
                this.companies = companies;
                this.companies.unshift({ value: 'all', label: 'All' });
            });
    }

    filter() {
        this.filteredRows = [];

        if (this.emergencyTeam === true) {
            if (this.companyId === 'all' || this.companyId === null) {
                this.data.forEach(d => {
                    if (d.emergencyTeam === 1) {
                        this.filteredRows.push(d);
                    }
                });
            } else {
                this.data.forEach(d => {
                    if (d.companyId === this.companyId && d.emergencyTeam === 1) {
                        this.filteredRows.push(d);
                    }
                });
            }
        } else {
            if (this.companyId === 'all' || this.companyId === null) {
                this.filteredRows = this.data;
            } else {
                this.data.forEach(d => {
                    if (d.companyId === this.companyId) {
                        this.filteredRows.push(d);
                    }
                });
            }
        }

    }

    onRowSelect(event) {
    }

    checkAll() {
        this.selectedRows = [];
        this.isAllChecked = !(this.isAllChecked);

        if (this.isAllChecked) {
            this.filteredRows.forEach(d => {
                if ((d.phone != null) && (d.textPhone === true)) {
                    this.selectedRows.push(d);
                }
            });
        }
    }

    send() {
        this.errorMessage = "";
        this.message = "";
        if (this.selectedRows.length === 0) {
            this.errorMessage = "Must select at least one record.";
        }

        if (this.body.length === 0) {
            this.errorMessage = "Must enter some text to send."
        }

        if (this.errorMessage === "") {

            var postData = { body: this.body, badgeHolders: [] };
            this.selectedRows.forEach(selected => {
                var record = {
                    badgeHolderId: selected.id,
                    name: selected.name,
                    companyId: selected.companyId
                };

                postData.badgeHolders.push(record);
            });

            this.dataService.save('sms/sendmessage', postData)
                .subscribe((data) => {
                    this.message = "Message Sent";
                    this.selectedRows = [];
                    this.filteredRows = [];
                    this.body = "";
                });

        }
    }
}
