<div *ngIf="pageInfo">
    <div class="container">
        <div class="row">
            <div class="col">
                <h3>Equipment</h3>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <st-table pageId="{{pageInfo.id}}" dataTable="{{pageInfo.dataName}}"></st-table>
            </div>
        </div>
    </div>
</div>

<div *ngIf="equipmentDelivery">
    <app-equipment-delivery-profile [equipmentDelivery]="equipmentDelivery"></app-equipment-delivery-profile>
</div>