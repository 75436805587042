<div class="global-admin-container">
    <div class="row">
        <div class="col-lg-12">
            <label class="control-label">Site:</label><br />
            <p-dropdown [options]="sites" [filter]="true" [(ngModel)]="siteId" (onChange)="loadData()"></p-dropdown>
            <span class="float-right" *ngIf="siteId && data">
                <button pButton icon="pi pi-plus" (click)="add()" title="Add" class="indigo-btn"></button>
            </span>
        </div>
    </div>
    <div class="row" *ngIf="siteId && data">
        <div class="col-lg-12">
            <p-table #dt [value]="data" [scrollable]="true" scrollHeight="500px" sortField="name" sortOrder="1">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 5%">Edit</th>
                        <th style="width: 5%">Delete</th>
                        <th [pSortableColumn]="'name'">Name <p-sortIcon [field]="'name'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'email'">Email <p-sortIcon [field]="'email'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'phone'">Phone <p-sortIcon [field]="'phone'"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr>
                        <td style="width: 5%"><button type="button" pButton (click)="edit(rowData)"
                                icon="pi pi-pencil"></button></td>
                        <td style="width: 5%"><button type="button" pButton (click)="delete(rowData)"
                                icon="pi pi-trash"></button></td>
                        <td>{{rowData.name}}</td>
                        <td>{{rowData.email}}</td>
                        <td>{{rowData.phone}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>


<p-dialog header="Notification" [(visible)]="showEdit" modal="modal"
    [style]="{ width: '500px', height: '460px' }">
    <div class="row">
        <div class="col-12 form-group">
            <label>Name</label>
            <input [(ngModel)]="editItem.name" pInputText type="text" class="form-control" maxlength="50" required />
        </div>
        <div class="col-12 form-group">
            <label>Email</label>
            <input [(ngModel)]="editItem.email" pInputText type="email" class="form-control" maxlength="50" required />
        </div>
        <div class="col-12 form-group">
            <label>Phone</label>
            <input [(ngModel)]="editItem.phone" pInputText type="text" class="form-control" maxlength="25" required />
        </div>
        <div>
            <br />
            <br />
        </div>
    </div>
    <p-footer>
        <button type="button" pButton icon="pi pi-save"
            [disabled]="editItem.name === undefined || editItem.name === '' || editItem.email === undefined || editItem.email === '' || editItem.phone === undefined || editItem.phone === ''"
            (click)="save()" label="Save"></button>
    </p-footer>
</p-dialog>

<p-confirmDialog header='Delete?' icon='pi pi-trash'></p-confirmDialog>

<p-toast position="top-right"></p-toast>