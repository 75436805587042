import { Component, OnInit, DoCheck } from '@angular/core';
import { DataService } from '../../services/data.service';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../../services/user.service';
import { DateService } from '../../services/date.service';
import { BreadCrumbService } from '../../services/breadcrumb.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-injury-report',
  templateUrl: './injury.component.html'
})
export class InjuryReportComponent implements OnInit {

  html: any;
  startDate: Date;
  endDate: Date;
  startDateStr: string;
  endDateStr: string;
  showStartDate: boolean = false;
  showEndDate: boolean = false;
  companies: any[];
  siteSubscription: Subscription;
  cols: any[];
  companyId: string = 'all';
  data: any[];
  section = 'Reports';
  title = 'Injury Report';
  user: any;

  constructor(private dataService: DataService,
    private userService: UserService,
    private dateService: DateService,
    private breadcrumbService: BreadCrumbService,
    private router: Router) {
    this.siteSubscription = this.userService.getSiteEvent()
      .subscribe(siteId => {
        this.loadData();
      });
  }

  ngOnInit() {
    this.breadcrumbService.setItemsByCategory(this.section, this.title, this.router.url);
    this.startDateStr = this.dateService.formatDate(new Date());
    this.endDateStr = this.dateService.formatDate(new Date());
    this.loadData();
    this.cols = [
      { field: 'companyName', header: 'Company', sortable: true },
      { field: 'badgeNum', header: 'Badge #', sortable: true },
      { field: 'siteSpecificId', header: 'Site ID', sortable: true },
      { field: 'employeeId', header: 'Employee ID', sortable: true },
      { field: 'firstName', header: 'First Name', sortable: true },
      { field: 'lastName', header: 'Last Name', sortable: true },
      { field: 'clockInFormated', header: 'Clock In', sortable: true },
      { field: 'clockOutFormated', header: 'Clock Out', sortable: true },
      { field: 'injuryFormated', header: 'Injury', sortable: true }
    ];
  }

  ngOnDestroy() {
    this.siteSubscription.unsubscribe();
  }

  onSubmitForm() {

    const reportData = {
      startDate: this.startDateStr,
      endDate: this.startDateStr,
      userId: this.user.id,
      companyIds: [this.companyId]
    };
    const path = 'reports/injury/json';
    this.dataService.postForm(path, reportData)
      .subscribe((data) => {
        this.data = data;
      });
  }

  loadData() {
    this.loadCompanies();
    this.user = this.userService.currentUser();
  }

  loadCompanies(): void {
    this.dataService.getAll('companies')
      .subscribe((companies) => {
        this.companies = companies;
        this.companies.unshift({ value: 'all', label: 'All' });
      });
  }

  onStartDateChange() {
    this.startDateStr = this.dateService.formatDate(new Date(this.startDate));
  }

  onEndDateChange() {
    this.endDateStr = this.dateService.formatDate(new Date(this.endDate));
  }


  createPDF(): void {
    const reportData = {
      startDate: this.startDateStr,
      endDate: this.endDateStr,
      userId: this.user.id,
      companyIds: [this.companyId]
    };
    const path = 'reports/injury/pdf';
    this.dataService.postForm(path, reportData, { responseType: 'arraybuffer' })
      .subscribe((data) => {
        const file = new Blob([data], { type: 'application/pdf' });
        const fileURL = window.URL.createObjectURL(file);

        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = fileURL;
        a.download = 'injury.pdf';
        a.click();

        window.URL.revokeObjectURL(fileURL);
        a.remove();
      });

  }

  createExcel(): void {

    const reportData = {
      startDate: this.startDateStr,
      endDate: this.endDateStr,
      userId: this.user.id,
      companyIds: [this.companyId]
    };
    const path = 'reports/injury/excel';
    this.dataService.postForm(path, reportData, { responseType: 'arraybuffer' })
      .subscribe((data) => {
        const file = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileURL = window.URL.createObjectURL(file);

        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = fileURL;
        a.download = 'injury.xlsx';
        a.click();

        window.URL.revokeObjectURL(fileURL);
        a.remove();
      });
  }
}
