<div class="container">
    <div class="row">
        <div class="col-lg-12">
            <table *ngIf="projects && projects.length > 0">
                <tr>
                    <th style="width: 46px; padding: .625em 0; text-align: center">Edit</th>
                    <th style="width: 52px; padding: .625em 0; text-align: center">Delete</th>
                    <th style="padding-left: 5px">Project Task</th>
                    <th style="padding-left: 5px">Code</th>
                    <th style="padding-left: 5px">Area</th>
                </tr>
                <tr *ngFor="let project of projects">
                    <td style="width: 46px; padding: .625em 0; text-align: center"><button type="button" pButton (click)="edit(project)" icon="pi pi-pencil"></button></td>
                    <td style="width: 52px; padding: .625em 0; text-align: center"><button type="button" pButton (click)="delete(project)" icon="pi pi-trash"></button></td>
                    <td style="padding-left: 5px">{{project.projectName}}</td>
                    <td style="padding-left: 5px">{{project.code}}</td>
                    <td style="padding-left: 5px">{{project.area}}</td>
                </tr>
            </table>
            <br />
            <button class="btn btn-default" (click)="edit({})" pButton icon="fa ui-icon-add" label="Add Project"></button>
        </div>
    </div>
</div>

<p-confirmDialog header='Delete?' icon='pi pi-trash'></p-confirmDialog>

<p-toast position="top-right"></p-toast>

<p-dialog header="Add Project" [(visible)]="showEditDialog" modal="modal" [style]="{ width: '600px', height: '610px', 'min-width': '400px', 'min-height': '610px' }">
    <div class="row" style="min-height: 380px; width: 500%">
        <div class="col-12 form-group">
            <label>Name</label>
            <p-dropdown [options]="projectsList" [filter]="true" [showClear]="true" placeholder="Select Project" [(ngModel)]="currentProject.projectId" [style]="{'width':'300px'}"></p-dropdown>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
    </div>
    <p-footer>
        <button type="button" pButton icon="pi pi-save" style="background-color: #012169;color: white;" [disabled]="!currentProject.projectId" (click)="save()" label="Save"></button>
    </p-footer>
</p-dialog>