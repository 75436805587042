    <p-table #dt
    [lazy]="true"
    [value]="onsiteList"
    [columns]="selectedColumns"
    [scrollable]="true"
    [exportFilename]="'onsite'"
    [paginator]="true"
    [rows]="pageSize"
    [totalRecords]="totalRecords"
    [showCurrentPageReport]="true"
    [responsive]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} total."
    [rowsPerPageOptions]="pageSizeOptions"
    (onPage)="pageChanged($event)"
    (onFilter)="applyFilters($event)"
    
> 
    <ng-template pTemplate="caption">
        <div style="text-align:left; display: flow-root">
            <button pButton (click)="onSubmitForm()" class="indigo-btn" id="btnSubmit" label="Search" icon="pi pi-search"></button>
            &nbsp;&nbsp;&nbsp;
            <input pInputText [(ngModel)]="globalFilter" placeholder="Global Filter" />
            &nbsp;&nbsp;&nbsp;
            <p-dropdown [options]="listTypes" [(ngModel)]="listType"
            [style]="{ 'margin-left': '5px', 'margin-right': '5px', 'margin-top': '5px' }"></p-dropdown>
            &nbsp;&nbsp;&nbsp;
            <span *ngIf="filterCount !== 0">&nbsp;&nbsp;&nbsp;Filter Count: {{filterCount}}</span>
            <div class="float-right">
                <button type="button" pButton title="Export" icon="pi pi-cloud-download" class="indigo-btn"
                (click)="exportToCSV()" style="float:left"></button>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th style="width: 46px; padding: .625em 0; text-align: center">View</th>
            <th style="width: 32px; padding: .625em 0; text-align: center"></th>
            <th *ngFor="let col of columns">
                {{col.header}}
            </th>
        </tr>
        <tr>
            <th style="width: 46px; padding: .625em 0; text-align: center"></th>
            <th style="width: 32px; padding: .625em 0; text-align: center"></th>
            <th style="overflow: hidden" *ngFor="let col of columns">
                <input pInputText type="text" (input)="dt.filter($event.target.value, col.field, 'contains')"
                    *ngIf="col.field !== 'profilePhotoURL' && col.field !== 'clockInPhotoURL' && col.field !== 'clockOutPhotoURL' && col.field !== 'clockIn' && col.field !== 'clockOut'">
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData" [ngClass]="{'undeletable': rowData['isSuspendedTerminated']}">
            <td style="width: 46px; padding: .625em 0; text-align: center"><button type="button" pButton
                    (click)="show(rowData)" icon="pi pi-search"></button></td>
            <td style="width: 32px; padding: .625em 0; text-align: center">
                <span *ngIf="rowData.status==='M'" class="numberCircle">M</span>
                <span *ngIf="rowData.status==='X'" class="numberCircle">A</span>
                <span *ngIf="rowData.status==='S'" class="numberCircle">SA</span>
                <span *ngIf="rowData.status==='T'" class="numberCircle">ST</span>
                <span *ngIf="rowData.status==='C' && rowData.isSalary===1" class="numberCircle">S</span>
            </td>
            <td *ngFor="let col of columns" [ngSwitch]="col.field">
                <img *ngSwitchCase="'profilePhotoURL'" id="profile-{{rowData['clockId']}}" style="width: 50px" />
                <img *ngSwitchCase="'clockInPhotoURL'" id="clockin-{{rowData['clockId']}}" style="width: 50px" />
                <img *ngSwitchCase="'clockOutPhotoURL'" id="clockout-{{rowData['exitId']}}" style="width: 50px" />
                <span *ngSwitchCase="'clockIn'">{{rowData[col.field] | date:'MM/dd/yyyy HH:mm a'}}</span>
                <span *ngSwitchCase="'terminalNameIn'">{{rowData[col.field]}}</span>
                <span *ngSwitchCase="'clockOut'">{{rowData[col.field] | date:'MM/dd/yyyy HH:mm a'}}</span>
                <span *ngSwitchCase="'terminalNameOut'">{{rowData[col.field]}}</span>
                <span *ngSwitchCase="'injury'">
                    <span *ngIf="rowData[col.field]===0">No</span>
                    <span *ngIf="rowData[col.field]===1">Yes</span>
                </span>
                <span *ngSwitchCase="'surveyResponse'">
                    <span *ngIf="rowData[col.field] && rowData[col.field]!=='PROCESSED'">Fail</span>
                    <span *ngIf="rowData[col.field] && rowData[col.field]==='PROCESSED'">Pass</span>
                </span>
                <span *ngSwitchCase="'badgeHolderId'"></span>
                <span *ngSwitchDefault>{{rowData[col.field]}}</span>
            </td>
        </tr>
    </ng-template>
</p-table>

<p-dialog
    header="Clock Details - {{onsiteInfo?.firstName}} {{onsiteInfo?.lastName}} - {{onsiteInfo?.laborClassName}} - {{onsiteInfo?.companyContract}}"
    [(visible)]="showDialog" modal="modal" [style]="{ width: '800px', height: '400px',  'min-width': '200px' }"
    [minY]="70">
    <div class="row">
        <div class="col-lg-4">
            <img width="250px" id="profile-photo" /><br />
        </div>
        <div class="col-lg-4">
            <img style="width: 250px" id="clockphoto-in" />
        </div>
        <div class="col-lg-4">
            <img style="width: 250px" id="clockphoto-out" />
        </div>
    </div>
    <!--
    <div class="row">
        <div class="col-lg-4">
            <i *ngIf="profileFace">Face Detected</i>
        </div>
        <div class="col-lg-4">
            <i *ngIf="clockInFace">Face Detected</i>
            <br />Match Profile: {{clockInCompare?.percentMatch | percent}}<br />
        </div>
        <div class="col-lg-4">
            <i *ngIf="clockOutFace">Face Detected</i>
            <br /> Match Profile: {{clockOutCompare?.percentMatch | percent}}<br />
        </div>
    </div>
    -->
    <p-footer>
        <button type="button" (click)="close()" pButton icon="pi pi-times" label="Close"></button>
    </p-footer>
</p-dialog>