import { Component, Input, OnInit, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BreadCrumbService } from '../../services/breadcrumb.service';
import { DataService } from '../../services/data.service';
import { LoggingService } from '../../services/logging.service';
import { Message } from 'primeng/api';
import { SelectItem } from 'primeng/api';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-company-profile',
    templateUrl: './company.profile.component.html',
    encapsulation: ViewEncapsulation.None
})
export class CompanyProfileComponent implements OnInit, OnChanges {
@Input() company: any;

showTabFlag: boolean;
companyCategories: SelectItem[];
companies: any[];
divisions: any[];
manpower = 'false';
hasUnion = 'false';
trades: any[];
selectedDivision: any;
user: any;
message: Message[] = [];
category = 'companies';

    constructor(
        private route: ActivatedRoute,
        private dataService: DataService,
        private loggingService: LoggingService,
        private userService: UserService,
        private breadcrumbService: BreadCrumbService,
        private router: Router) { }

    ngOnInit(): void {
        this.user = this.userService.currentUser();
        if (this.company.calculateManpower === true) {
            this.manpower = 'true';
        }

        if (this.company.hasUnion === true) {
            this.hasUnion = 'true';
        }

        this.loadCompanyCategories();
        this.loadCompanies();
    }

    ngOnChanges(simpleChanges: SimpleChanges): void {
        this.breadcrumbService.setItemsByCategoryAndName(
                                'Sitetraxx Admin',
                                'Companies',
                                '/' + this.category + '/search',
                                this.company.name,
                                this.router.url);
    }

    save(andNew: boolean) {
        if (this.manpower === 'true') {
            this.company.calculateManpower = true;
        } else {
            this.company.calculateManpower = false;
        }

        if (this.hasUnion === 'true') {
            this.company.hasUnion = true;
        } else {
            this.company.hasUnion = false;
        }

        this.company.siteId = this.user.siteId;

        this.dataService.save(this.category, this.company)
            .subscribe((data) => {
                if (!andNew)
                    this.company = data;
                else if (this.router.url.indexOf('new') > 0) {
                    this.company = { id: 'new' };
                    this.loadCompanies();
                }
                else
                    this.router.navigate(['/companies/detail/new/']);
                this.loggingService.savedMessage();
            });
    }

    cancel() {
        this.router.navigate(['/companies/search/']);
    }

    showTab(_tab: boolean) {
        this.showTabFlag = _tab;
    }

    loadCompanyCategories(): void {
        this.dataService.getById('laborclass', 'companycategories')
        .subscribe((categories) => {
            this.companyCategories = categories;
            this.loadCSIDivisions();
        });
    }

    loadCSIDivisions(): void {
        this.dataService.getById('laborclass/divisions', this.company.companyType)
        .subscribe((divisions) => {
            this.divisions = divisions;
            this.loadTrades();
        });
    }

    loadTrades(): void {
        this.dataService.getById('laborclass/tradecodes', this.company.csiDivision)
        .subscribe((trades) => {
            this.trades = trades;
        });
    }

    loadCompanies(): void {
        this.dataService.getAll(this.category)
        .subscribe((companies) => {
            this.companies = companies.filter(m => m.id !== this.company.id);
        });
    }
}
