import { Component, Input, OnInit, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BreadCrumbService } from '../../services/breadcrumb.service';
import { DataService } from '../../services/data.service';
import { LoggingService } from '../../services/logging.service';
import { DateService } from '../../services/date.service';
import { Message } from 'primeng/api';

@Component({
    selector: 'app-reward-profile',
    templateUrl: './reward.profile.component.html',
    encapsulation: ViewEncapsulation.None
})
export class RewardProfileComponent implements OnInit, OnChanges {
@Input() reward: any;

message: Message[] = [];
eventDate: Date;
rewardProgram = 'false';
badgeHolders: any[];

    constructor(
        private route: ActivatedRoute,
        private dataService: DataService,
        private loggingService: LoggingService,
        private dateService: DateService,
        private breadcrumbService: BreadCrumbService,
        private router: Router) {}

    ngOnInit(): void {
        if (this.reward.rewardProgram === true) {
            this.rewardProgram = 'true';
        }
        if (this.reward.eventDate === undefined) {
            this.eventDate = new Date();
        } else {
            this.eventDate = this.dateService.convertDateUniversalToSiteTimeDate(new Date(this.reward.eventDate));
        }
        this.loadBadgeHolders();
    }

    ngOnChanges(simpleChanges: SimpleChanges): void {
        this.breadcrumbService.setItemsByCategoryAndName(
                                'Controls',
                                'Rewards',
                                '/rewards/search',
                                this.reward.observation,
                                this.router.url);
    }

    save() {
        this.reward.eventDate = this.eventDate;
        if (this.rewardProgram === 'true') {
            this.reward.rewardProgram = true;
        }
        if (this.reward.eventDate) {
            this.reward.eventDate = this.dateService.convertDateLocalToSiteTimeDate(new Date(this.eventDate));
        }
        this.dataService.save('rewards', this.reward)
            .subscribe((data) => {
                this.reward = data;
                this.loggingService.savedMessage();
            });
    }

    cancel() {
        this.router.navigate(['/rewards/search/']);
    }

    loadBadgeHolders(): void {
        this.dataService.getById('lookups', 'badgeholders')
        .subscribe((badgeHolders) => {
            this.badgeHolders = badgeHolders;
            if(this.reward.badgeHolderId == null)
                this.badgeHolders.unshift({id: null});
        });
    }
}
