<div class="row">
    <div class="col-lg-12">
        <label class="control-label">Facility Type:</label><br />
        <p-dropdown [options]="facilityTypes" [filter]="true" [(ngModel)]="site.facilityTypeId"></p-dropdown>
    </div>
</div>
<div class="row">
    <div class="col-lg-6">
        <label class="control-label">Facility SF:</label>
        <input type="number" class="form-control" pInputText name="facilitysqf" [ngModel]="site.facilitySQF" />
    </div>
    <div class="col-lg-6">
        <label class="control-label">Facility Floors:</label>
        <input type="number" name="facilityFloors" class="form-control" pInputText [(ngModel)]="site.facilityFloors" />
    </div>
</div>
<div class="row">
    <div class="col-lg-6">
        <label class="control-label">Facility Other Unit:</label>
        <input type="text" class="form-control" pInputText ng-model="site.facility_other_unit" />
    </div>
    <div class="col-lg-6">
        <label class="control-label">Unit Type:</label><br />
        <p-dropdown [options]="types" [filter]="true" [(ngModel)]="site.unitTypeId"></p-dropdown>
    </div>
</div>