<div *ngIf="pageInfo">
    <div class="container">
        <div class="row">
            <div class="col">
                <h3>Projects</h3>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <st-table pageId="{{pageInfo.id}}" dataTable="{{pageInfo.dataName}}"></st-table>
            </div>
        </div>
    </div>
</div>

<div *ngIf="project">
    <app-project-profile [project]="project"></app-project-profile>
</div>