import { Component, OnInit, DoCheck } from '@angular/core';
import { DataService } from '../../services/data.service';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../../services/user.service';
import { DateService } from '../../services/date.service';
import { BreadCrumbService } from '../../services/breadcrumb.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-daily-onsite',
  templateUrl: './daily-onsite.component.html'
})
export class DailyOnsiteComponent implements OnInit {

  html: any;
  startDate: Date;
  endDate: Date;
  startDateStr: string;
  endDateStr: string;
  companies: any[];
  siteSubscription: Subscription;
  cols: any[];
  companyId: string = 'all';
  data: any[];
  section = 'Reports';
  title = 'Onsite';
  user: any;

  constructor(private dataService: DataService,
              private userService: UserService,
              private dateService: DateService,
              private breadcrumbService: BreadCrumbService,
              private router: Router) {
                this.siteSubscription = this.userService.getSiteEvent()
                  .subscribe(siteId => {
                      this.loadData();
                  });
              }

  ngOnInit() {
    this.breadcrumbService.setItemsByCategory(this.section, this.title, this.router.url);
    this.loadData();
    this.cols = [
      { field: 'companyName', header: 'Company' },
      { field: 'badgeNum', header: 'Badge #' },
      { field: 'siteSpecificId', header: 'Site ID' },
      { field: 'employeeId', header: 'Employee ID' },
      { field: 'firstName', header: 'First Name' },
      { field: 'lastName', header: 'Last Name' },
      { field: 'laborClass', header: 'Labor Class' },
      { field: 'projectName', header: 'Project' },
      { field: 'clockInFormated', header: 'Clock In' },
      { field: 'clockOutFormated', header: 'Clock Out' },
      { field: 'offset', header: 'Offset' },
      { field: 'totalCalc', header: 'Hours' },
      { field: 'injuryFormated', header: 'Injury' },
      { field: 'statusFormated', header: 'Status' },
      { field: 'otherInfo', header: 'Other Info.' }
    ];
  }

  ngOnDestroy() {
    this.siteSubscription.unsubscribe();
  }

  onSubmitForm() {

    const reportData = {
      startDate: this.startDateStr,
      endDate: this.endDateStr,
      userId: this.user.id,
      companyIds: [this.companyId]
    };
    const path = 'reports/onsite/json';
    this.dataService.postForm(path, reportData)
        .subscribe((data) => {
          this.data = data;
        });
  }

  onStartDateChange() {
    this.startDateStr = this.dateService.formatDate(new Date(this.startDate));
  }

  onEndDateChange() {
    this.endDateStr = this.dateService.formatDate(new Date(this.endDate));
  }

  loadData() {
    this.loadCompanies();
    this.startDate = new Date();
    this.endDate = new Date();
    this.user = this.userService.currentUser();
  }

  loadCompanies(): void {
    this.dataService.getAll('companies')
    .subscribe((companies) => {
        this.companies = companies;
        this.companies.unshift({value: 'all', label: 'All' });
    });
  }

  createPDF(): void {
    const reportData = {
      startDate: this.startDateStr,
      endDate: this.endDateStr,
      userId: this.user.id,
      companyIds: [this.companyId]
    };
    const path = 'reports/onsite/pdf';
    this.dataService.postForm(path, reportData, {responseType: 'arraybuffer'})
        .subscribe((data) => {
          const file = new Blob([data], {type: 'application/pdf'});
          const fileURL = window.URL.createObjectURL(file);

          const a = document.createElement('a');
          document.body.appendChild(a);
          a.href = fileURL;
          a.download = 'onsite.pdf';
          a.click();

          window.URL.revokeObjectURL(fileURL);
          a.remove();
        });

  }

  createExcel(): void {

    const reportData = {
      startDate: this.startDateStr,
      endDate: this.endDateStr,
      userId: this.user.id,
      companyIds: [this.companyId]
    };
    const path = 'reports/onsite/excel';
    this.dataService.postForm(path, reportData, {responseType: 'arraybuffer'})
        .subscribe((data) => {
          const file = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
          const fileURL = window.URL.createObjectURL(file);

          const a = document.createElement('a');
          document.body.appendChild(a);
          a.href = fileURL;
          a.download = 'onsite.xlsx';
          a.click();

          window.URL.revokeObjectURL(fileURL);
          a.remove();
        });
  }
}
