<div class="global-admin-container">
    <div class="row">
        <div class="col-lg-3">
            <label class="control-label">Site:</label><br />
            <p-dropdown [options]="sites" [filter]="true" [(ngModel)]="siteId" (onChange)="loadSite()"></p-dropdown>
        </div>
        <div class="col-lg-3">
            <label class="control-label">User:</label><br />
            <p-dropdown [options]="users" [filter]="true" [(ngModel)]="userId" (onChange)="loadUsers()" *ngIf="siteId">
            </p-dropdown>
        </div>
        <div class="col-lg-4">
        </div>
        <div class="col-lg-2">
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <p-panel header="User Information" *ngIf="user">
                <div class="row">
                    <div class="col-6">
                        <div><strong>Name:</strong></div>
                        <div>{{user.name}}</div>
                    </div>
                    <div class="col-6">
                        <div><strong>Username/Email:</strong></div>
                        <div>{{user.username}} / {{user.email}}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div><strong>Company:</strong></div>
                        <div>{{user.companyName}}</div>
                    </div>
                    <div class="col-6">
                        <div><strong>Site:</strong></div>
                        <div>{{user.siteName}}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div><strong>Parent User Account:</strong></div>
                        <div>
                            {{user.parentUserName}}
                            <button pButton *ngIf="user.parentUserId===null" (click)="loadAddParentDialog(user)"
                                icon="pi pi-plus" label="Add Parent"></button>
                            <button pButton *ngIf="user.parentUserId" (click)="loadDeleteParentDialog(user)"
                                icon="pi pi-trash" label="Remove Parent"></button>
                        </div>
                    </div>
                    <div class="col-6">
                        <div><strong>Site/Company:</strong></div>
                        <div>{{user.parentSiteName}} - {{user.parentCompanyName}}</div>
                    </div>
                </div>
            </p-panel>
        </div>
    </div>
    <div class="row" *ngIf="user">
        <div class="col-6">
            <button pButton (click)="loadAddChildDialog(user)" icon="pi pi-plus" label="Add Child"></button>
        </div>
    </div>
    <div class="row">
        <div class="col-12" *ngIf="childUsersCurrent">
            <br /><br />
            <h3>Child User Accounts</h3>
            <table class="table table-striped">
                <tr class="sitetraxx">
                    <th>Delete</th>
                    <th>Name</th>
                    <th>Username</th>
                    <th>Site</th>
                    <th>Company</th>
                </tr>
                <tr *ngFor="let cuser of childUsersCurrent">
                    <td style="width: 5%"><button type="button" pButton (click)="delete(cuser)"
                            icon="pi pi-trash"></button></td>
                    <td>{{cuser.name}}</td>
                    <td>{{cuser.username}}</td>
                    <td>{{cuser.siteName}}</td>
                    <td>{{cuser.companyName}}</td>
                </tr>
            </table>
        </div>
    </div>
    <div class="row" *ngIf="user">
        <div class="col-6">
            <button pButton (click)="loadAddReportDialog()" icon="pi pi-plus" label="Add Report"></button>
        </div>
    </div>
    <div class="row">
        <div class="col-12" *ngIf="reports">
            <br /><br />
            <h3>Email Reports</h3>
            <table class="table table-striped">
                <tr class="sitetraxx">
                    <th>Delete</th>
                    <th>Name</th>
                </tr>
                <tr *ngFor="let report of reports">
                    <td style="width: 5%"><button type="button" pButton (click)="deleteReport(report)"
                            icon="pi pi-trash"></button></td>
                    <td>{{report.reportName}}</td>
                </tr>
            </table>
        </div>
    </div>
</div>

<p-dialog header="Add Parent" [(visible)]="showAddParentDialog" modal="modal"
    [style]="{ width: '600px', height: '400px', 'min-width': '600px' }" [minY]="400">

    <div class="col-lg-3">
        <label class="control-label">Site:</label><br />
        <p-dropdown [options]="sites" [filter]="true" [(ngModel)]="parentSiteId" (onChange)="loadParentUsers()">
        </p-dropdown>
    </div>
    <div class="col-lg-3">
        <label class="control-label">User:</label><br />
        <p-dropdown [options]="parentUsers" [filter]="true" [(ngModel)]="parentUserId" *ngIf="parentSiteId">
        </p-dropdown>
    </div>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    <p-footer>
        <p-button (click)="addParent()" label="Add Parent"></p-button>
        <p-button (click)="cancelAddParentDialog()" label="Cancel"></p-button>
    </p-footer>
</p-dialog>

<p-dialog header="Add Child" [(visible)]="showAddChildDialog" modal="modal"
    [style]="{ width: '600px', height: '400px', 'min-width': '600px' }" [minY]="400">

    <div class="col-lg-3">
        <label class="control-label">Site:</label><br />
        <p-dropdown [options]="sites" [filter]="true" [(ngModel)]="childSiteId" (onChange)="loadChildUsers()">
        </p-dropdown>
    </div>
    <div class="col-lg-3">
        <label class="control-label">User:</label><br />
        <p-dropdown [options]="childUsers" [filter]="true" [(ngModel)]="childUserId"  (onChange)="loadUserById(childUserId)" *ngIf="childSiteId"></p-dropdown>
    </div>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    <p-footer>
        <p-button (click)="addChild()" label="Add Child"></p-button>
        <p-button (click)="cancelAddChildDialog()" label="Cancel"></p-button>
    </p-footer>
</p-dialog>

<p-dialog header="Add Report" [(visible)]="showAddReportDialog" modal="modal"
    [style]="{ width: '600px', height: '400px', 'min-width': '600px' }" [minY]="400">

    <div class="col-lg-3">
        <label class="control-label">Report:</label><br />
        <p-dropdown [options]="reportList" [filter]="true" [(ngModel)]="reportId">
        </p-dropdown>
    </div>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    <p-footer>
        <p-button (click)="addReport()" label="Add Report"></p-button>
        <p-button (click)="cancelReportDialog()" label="Cancel"></p-button>
    </p-footer>
</p-dialog>

<p-confirmDialog header='Delete?' icon='pi pi-trash'></p-confirmDialog>