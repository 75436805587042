import { Component, Input, OnInit, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';

import { DataService } from '../../services/data.service';
import { LoggingService } from '../../services/logging.service';
import { Message } from 'primeng/api';
import { environment } from '../../../../environments/environment';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-photo-list',
    templateUrl: './photolist.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [ ConfirmationService ]
})
export class PhotoListComponent implements OnInit, OnChanges {
  @Input() objectId: string;
  @Input() category: string;
  @Input() refresh: Date;

    message: Message[];
    env: any = environment;
    baseImageUrl: string;
    photos: any[];
    selectedPhoto: any;
    showPhotoDialog: boolean;
    user: any;

    constructor(
        private confirmService: ConfirmationService,
        private dataService: DataService,
        private loggingService: LoggingService,
        private router: Router,
        private userService: UserService) { }

    ngOnInit(): void {
        this.user = this.userService.currentUser();
        this.baseImageUrl = this.env.baseImageUrl;
        this.loadPhotos();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.loadPhotos();
    }

    loadPhotos(): void {
        if (this.objectId !== undefined) {
            this.dataService.getById('images', this.objectId)
                .subscribe((data) => {
                    this.photos = data;
                });
        }
    }

    delete(photo: any): void {
        this.confirmService.confirm({
            message: 'Do you want to delete ' + photo.name + '?',
            accept: () => {
                this.dataService.deleteById('images', photo.id)
                    .subscribe((data) => {
                        this.loadPhotos();
                        this.loggingService.deletedMessage();
                    });
            },
            reject: () => {
                this.loggingService.infoMessage('Cancelled', 'You have cancelled the request');
            }
        });
    }

    show(photo: any): void {
        this.selectedPhoto = photo;
        this.showPhotoDialog = true;
    }

    close(): void {
        this.selectedPhoto = undefined;
        this.showPhotoDialog = false;
    }
}
