import { Component, OnInit, DoCheck } from '@angular/core';
import { DataService } from '../../services/data.service';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../../services/user.service';
import { DateService } from '../../services/date.service';
import { BreadCrumbService } from '../../services/breadcrumb.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-weekly-report',
  templateUrl: './weekly-report.component.html'
})
export class WeeklyReportComponent implements OnInit {

  html: any;
  startDate: Date;
  endDate: Date;
  startDateStr: string;
  endDateStr: string;
  siteSubscription: Subscription;
  cols: any[];
  header: any;
  companyId: string = 'all';
  data: any[];
  section = 'Reports';
  title = 'Weekly Headcount';
  user: any;

  constructor(private dataService: DataService,
              private userService: UserService,
              private dateService: DateService,
              private breadcrumbService: BreadCrumbService,
              private router: Router) {
                this.siteSubscription = this.userService.getSiteEvent()
                  .subscribe(siteId => {
                    this.loadData();
                });
              }

  ngOnInit() {
    this.breadcrumbService.setItemsByCategory(this.section, this.title, this.router.url);
    this.user = this.userService.currentUser();
    this.startDateStr = this.dateService.formatDate(new Date());
    this.loadDateHeader();
  }

  ngOnDestroy() {
    this.siteSubscription.unsubscribe();
  }

  onSubmitForm() {
    const reportData = {
      startDate: this.startDateStr,
      endDate: this.startDateStr,
      userId: this.user.id,
      companyIds: [this.companyId]
    };
    const path = 'reports/weekly/company/json/count';
    this.dataService.postForm(path, reportData)
        .subscribe((data) => {
          this.data = data;
        });

  }

  loadData() {
    this.loadDateHeader();
  }

  loadDateHeader() {
    const path = 'reports/header/week/' + this.startDateStr;

    this.dataService.getAll(path)
        .subscribe((data) => {
          this.header = data;
          this.cols = [
            { field: 'companyName', header: 'Company' },
            { field: 'sunday', header: 'Sunday ' + this.header.day1 },
            { field: 'monday', header: 'Monday ' + this.header.day2 },
            { field: 'tuesday', header: 'Tuesday ' + this.header.day3 },
            { field: 'wednesday', header: 'Wednesday ' + this.header.day4 },
            { field: 'thursday', header: 'Thursday ' + this.header.day5 },
            { field: 'friday', header: 'Friday ' + this.header.day6 },
            { field: 'saturday', header: 'Saturday ' + this.header.day7 },
            { field: 'weekdayAverage', header: 'Weekday Avg ' + this.header.day7 },
            { field: 'fullAverage', header: 'Average ' + this.header.day7 }
            ];
      
        });
  }

  onStartDateChange() {
    this.startDateStr = this.dateService.formatDate(new Date(this.startDate));
    this.loadDateHeader();
  }

  createPDF(): void {
    const reportData = {
      startDate: this.startDateStr,
      endDate: this.endDateStr,
      userId: this.user.id,
      companyIds: [this.companyId]
    };
    const path = 'reports/onsite/pdf';
    this.dataService.postForm(path, reportData, {responseType: 'arraybuffer'})
        .subscribe((data) => {
          const file = new Blob([data], {type: 'application/pdf'});
          const fileURL = window.URL.createObjectURL(file);

          const a = document.createElement('a');
          document.body.appendChild(a);
          a.href = fileURL;
          a.download = 'onsite.pdf';
          a.click();

          window.URL.revokeObjectURL(fileURL);
          a.remove();
        });

  }

  createExcel(): void {
    const reportData = {
      startDate: this.startDateStr,
      endDate: this.startDateStr,
      userId: this.user.id,
      companyIds: [this.companyId]
    };
    const path = 'reports/weekly/company/excel/count';
    this.dataService.postForm(path, reportData, {responseType: 'arraybuffer'})
        .subscribe((data) => {
          const file = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
          const fileURL = window.URL.createObjectURL(file);

          const a = document.createElement('a');
          document.body.appendChild(a);
          a.href = fileURL;
          a.download = 'weeklycount.xlsx';
          a.click();

          window.URL.revokeObjectURL(fileURL);
          a.remove();
        });
  }
}
