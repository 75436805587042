import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { DataService } from '../services/data.service';
import { LoggingService } from '../services/logging.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

    constructor(private router: Router, private dataService: DataService, private loggingService: LoggingService) {}

    ngOnInit() {
        this.dataService.getById('menus', 'user-access')
        .subscribe((menus) => {
            this.check(menus, this.router.url);
            this.router.events.subscribe(event => {
                if (event instanceof NavigationStart) {
                    this.check(menus, event.url);
                }
            });
        });
    }

    check(menus, url) {
        const results = menus.filter(m => m.routerLink === url);
        if (results.length > 0 && !results[0].hasAccess) {
            setTimeout(() => {
                setTimeout(() => this.loggingService.errorMessage('Unauthorized', 'Access denied'));
                this.router.navigate(['/']);
            });
        }
    }
    
}
