<div *ngIf="pageInfo">
    <div class="container">
        <div class="row">
            <h3>Surveys</h3>
        </div>
        <div class="row">
            <div class="col">
                <st-table pageId="{{pageInfo.id}}" dataTable="{{pageInfo.dataName}}"></st-table>
            </div>
        </div>
    </div>
</div>

<div *ngIf="survey">
    <app-takesurvey-detail [data]="survey"></app-takesurvey-detail>
</div>