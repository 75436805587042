<div class="container">
    <div class="row">
        <div class="col-lg-12">
            <table>
                <tr>
                    <th style="width: 46px; padding: .625em 0; text-align: center">Edit</th>
                    <th style="width: 52px; padding: .625em 0; text-align: center">Delete</th>
                    <th style="width: 52px; padding: .625em 0; text-align: center">Download</th>
                    <th style="padding-left: 5px">Name</th>
                    <th style="padding-left: 5px">Category</th>
                    <th style="padding-left: 5px">Expiration Date</th>
                    <th style="width: 10%">Verified</th>
                </tr>
                <tr *ngFor="let document of documents">
                    <td style="width: 46px; padding: .625em 0; text-align: center"><button type="button" pButton
                            (click)="edit(document)" icon="pi pi-pencil"></button></td>
                    <td style="width: 52px; padding: .625em 0; text-align: center"><button type="button" pButton
                            (click)="delete(document)" icon="pi pi-trash"></button></td>
                    <td style="padding-left: 5px">
                        <a href="{{documentUrl}}/{{document.id}}" (click)="preview(document)">
                            {{document.name}}
                        </a>
                    </td>
                    <td style="padding-left: 5px">{{document.categoryName}}</td>
                    <td style="padding-left: 5px">{{document.expirationDate}}</td>
                    <td style="width: 10%"><i *ngIf="document.verified" class="fa ui-icon-check-circle"
                            style="color:green"></i></td>
                </tr>
            </table>
            <br />
            <button class="btn btn-default" (click)="edit({})" pButton icon="fa ui-icon-add"
                label="Add New Document"></button>
        </div>
    </div>
</div>

<p-dialog header="Document Preview" [(visible)]="showPreviewDialog" modal="modal"
    [style]="{ width: '99%', height: '99%', 'min-width': '300px', 'max-width': '1000px', 'max-height': '700px' }"
    [minY]="500">
    <div class="row">
        <div class="col">
            <img id="preview-image" style="max-width: 100%;" />
        </div>
    </div>
    <p-footer>
        {{currentDocument.name}}
        <button type="button" pButton icon="pi pi-download"
            [disabled]="currentDocument.name === undefined || currentDocument.name === ''" (click)="download(currentDocument)"
            label="Download"></button>
        <button type="button" pButton icon="pi pi-ban" (click)="showPreviewDialog = false" label="Close"></button>
    </p-footer>
</p-dialog>

<p-dialog header="Edit Document" [(visible)]="showEditDialog" modal="modal"
    [style]="{ width: '700px', height: '660px', 'min-width': '500px' }" [minY]="500">
    <div class="row">
        <div class="col-8 form-group">
            <label>Name</label>
            <input [(ngModel)]="currentDocument.name" pInputText type="text" class="form-control" maxlength="100"
                required />
        </div>
        <div class="col-4 form-group">
            <p-checkbox name="verified" binary="true" label="Verified" [(ngModel)]="currentDocument.verified">
            </p-checkbox>
        </div>
    </div>
    <div class="row">
        <div class="col-6 form-group">
            <label>Category</label><br />
            <p-dropdown [options]="documentCategories" placeholder="Select Category" [filter]="true"
                [(ngModel)]="currentDocument.categoryId" name="categoryDropdown"></p-dropdown>
        </div>
        <div class="col-6 form-group">
            <label>Expiration Date</label>
            <p-calendar [(ngModel)]="expirationDate" [showIcon]="true"></p-calendar>
        </div>
    </div>
    <div class="row">
        <p-fileUpload name="files" url="{{uploadImageUrl}}" (onUpload)="onUpload($event)" accept=".pdf,image/*"
            maxFileSize="50000000" showCancelButton="false">
            <ng-template pTemplate="content">
                <ul *ngIf="uploadedFiles.length">
                    <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
                </ul>
                <br />
                <br />
                <br />
                <br />
                <span>Drag and Drop a Photo into this area or click the "Choose" button to select a file.</span>
                <br />
                <br />
                <br />
                <br />
            </ng-template>
        </p-fileUpload>
    </div>
    <p-footer>
        <button type="button" pButton icon="pi pi-save" style="background-color: #012169;color: white;"
            [disabled]="currentDocument.name === undefined || currentDocument.name === ''" (click)="save()"
            label="Save"></button>
    </p-footer>
</p-dialog>

<p-confirmDialog header='Delete?' icon='pi pi-trash'></p-confirmDialog>

<p-toast position="top-right"></p-toast>