import { Component, OnInit, DoCheck } from '@angular/core';

import { BreadCrumbService } from '../services/breadcrumb.service';
import { DataService } from '../services/data.service';
import { ActivatedRoute } from '@angular/router';
import { LoggingService } from '../services/logging.service';
import { Subscription } from 'rxjs';
import { UserService } from '../services/user.service';

@Component({
    selector: 'app-projects',
    templateUrl: './project.component.html'
})
export class ProjectComponent implements OnInit, DoCheck {
    pageInfo: any;
    path: string;
    sub: any;
    id: string;
    project: any;
    title = 'Projects';
    name = '';

    constructor(
        private route: ActivatedRoute,
        private dataService: DataService,
        private breadcrumbService: BreadCrumbService,
        private userService: UserService,
        private loggingService: LoggingService) { }

    ngOnInit(): void {

        this.sub = this.route.params.subscribe(params => {
            this.id = params['id'];

            if (this.id === undefined) {
                this.loadData();
            } else {
                if (this.id === 'new') {
                    this.loggingService.logPageView('Project - New');
                    this.project = {};
                } else {
                    this.loggingService.logPageView('Project - Profile');
                    this.dataService.getById('projects', this.id)
                    .subscribe((data) => {
                        this.project = data;
                        this.name = this.project.name;
                        this.id = this.project.id;
                    });
                }
            }
         });
    }

    ngDoCheck(): void {
        if (this.id === undefined) {
            this.breadcrumbService.setItems([
                {label: 'Sitetraxx Admin'},
                {label: this.title, routerLink: ['/projects/search']}
            ]);
        } else {
            this.breadcrumbService.setItems([
                {label: 'Sitetraxx Admin'},
                {label: this.title, routerLink: ['/projects/search']},
                {label: this.name, routerLink: ['/projects/detail/' + this.id ]}
            ]);
        }
    }

    loadData() {
        this.loggingService.logPageView('Projects - Grid');
        this.project = null;
        this.dataService.getById('pages', 'projects')
        .subscribe((data) => {
            this.pageInfo = data.result;
            this.title = this.pageInfo.title;
        });
    }
}
