<form #thisForm='ngForm'>
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <h3>{{terminal.name}}</h3>
            </div>
            <div class="col-lg-6">
                <div class="float-right">
                    <button pButton (click)="save()" [disabled]="!thisForm.valid" icon="pi pi-save" label="Save"></button>
                    <button pButton (click)="cancel()" icon="pi pi-ban" label="Cancel"></button>
                </div>
            </div>
        </div>
        <div class="row>">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <label>Terminal #:</label>
                        <div class="form-inline">
                            <input type="text" placeholder="####" [(ngModel)]="terminal.terminalNumber" name="terminalNumber" maxlength="20" class="form-control" required />
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <label>Last Sync:</label>
                        <div class="form-inline">
                            {{terminal.lastSyncDate | date:"MM/dd/yyyy h:mm a"}}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-12">
                        <div class="form-group">
                            <label>Terminal Name:</label>
                            <input type="text" [(ngModel)]="terminal.name" name="name" maxlength="50" class="form-control" required />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label>Screen Delay Duration:</label>
                            <input type="number" [(ngModel)]="terminal.relayDuration" name="relayDuration" class="form-control" required />
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label>Security Relay Duration:</label>
                            <input type="number" [(ngModel)]="terminal.securityRelayDuration" name="securityRelayDuration" class="form-control" required />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label>Maximum Photo Width:</label>
                            <input type="number" [(ngModel)]="terminal.photoWidth" name="photoWidth" class="form-control" />
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="ui-g-12">
                            <p-checkbox name="hasInOutScreen" binary="true" label="Enable In/Out Screen" [(ngModel)]="terminal.hasInOutScreen"></p-checkbox>
                        </div>
                        <div class="ui-g-12">
                            <p-checkbox name="hasInjuryScreen" binary="true" label="Enable Injury Screen" [(ngModel)]="terminal.hasInjuryScreen"></p-checkbox>
                        </div>
                        <div class="ui-g-12">
                            <p-checkbox name="hasLights" binary="true" label="Enable Lights" [(ngModel)]="terminal.hasLights"></p-checkbox>
                        </div>
                        <div class="ui-g-12">
                            <p-checkbox name="hasProjectScreen" binary="true" label="Enable Project Screen" [(ngModel)]="terminal.hasProjectScreen"></p-checkbox>
                        </div>
                        <div class="ui-g-12">
                            <p-checkbox name="hasPhotos" binary="true" label="Enable Photos" [(ngModel)]="terminal.hasPhotos"></p-checkbox>
                        </div>
                        <div class="ui-g-12">
                            <p-checkbox name="hasSurveyIn" binary="true" label="Enable Entry Survey" [(ngModel)]="terminal.hasSurveyIn"></p-checkbox>
                        </div>
                        <div class="ui-g-12">
                            <p-checkbox name="hasSurveyOut" binary="true" label="Enable Exit Survey" [(ngModel)]="terminal.hasSurveyOut"></p-checkbox>
                        </div>
                    </div>
                </div>
                <p-panel header="Terminal Surveys">
                    <div class="row">
                        <div class="col-md-6">
                            <label>Survey In:</label><br />
                            <p-dropdown [options]="surveys" [showClear]="true" placeholder="Select a Survey" [filter]="true" name="surveyIn" [(ngModel)]="terminal.surveyIn"></p-dropdown>
                        </div>
                        <div class="col-md-6">
                            <label>Survey In Frequency (0 - 100):</label>
                            <input type="number" [(ngModel)]="terminal.surveyInPercent" name="surveyInPercent" class="form-control" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label>Survey Out:</label><br />
                            <p-dropdown [options]="surveys" [showClear]="true" placeholder="Select a Survey" [filter]="true" name="surveyOut" [(ngModel)]="terminal.surveyOut"></p-dropdown>
                        </div>
                        <div class="col-md-6">
                            <label>Survey In Frequency (0 - 100):</label>
                            <input type="number" [(ngModel)]="terminal.surveyOutPercent" name="surveyOutPercent" maxlength="50" class="form-control" />
                        </div>
                    </div>
                </p-panel>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label class="control-label">Status:</label>
                            <select class="form-control" [(ngModel)]="terminal.status" name="status">
                                <option value="A">Active</option>
                                <option value="D">Disabled</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-12 col-md-12">
                        <div class="form-group">
                            <label>Status Reason:</label>
                            <textarea class="form-control" [(ngModel)]="terminal.statusReason" name="statusReason" maxlength="100"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <st-update-block [object]='terminal'></st-update-block>
    </div>
</form>

