import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BreadCrumbService } from '../services/breadcrumb.service';
import { DataService } from '../services/data.service';
import { LoggingService } from '../services/logging.service';
import { UserService } from '../services/user.service';
import { Subscription } from 'rxjs';
import { DateService } from '../services/date.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { Table } from 'primeng/table';

@Component({
    selector: 'app-badgeholders',
    templateUrl: './badgeholders.component.html',
    providers: [ConfirmationService],
    encapsulation: ViewEncapsulation.None
})
export class BadgeHoldersComponent implements OnInit {
    @ViewChild('dt', { static: true }) private _table: Table;
    private datePipe = new DatePipe('en-US');
    
    pageInfo: any;
    id: string;
    private sub: any;
    badgeHolder: any;
    showTabFlag: boolean;
    years: any[];
    section = 'Sitetraxx Admin';
    title = 'Badge Holders';
    isGlobalAdmin = false;
    page: number = 1;
    pageSize: number = 100;
    pageSizeOptions: number[] = [100,250,500,1000];
    totalRecords: number;  
    filtersChanged: boolean = false;
    badgeHolders: any[] = [];
    user: any;
    searchSeconds: number;
    filterCount: number = 0;
    reportData: any = {
        startDate: '20000101',
        endDate: '20000101',
        userId: '',
        companyIds: [],
        page: this.page,
        pageSize: this.pageSize,
        filters: []
      };

    globalFilter: string;
    listType = '0';
    listTypes: any = [
        { label: 'Show All workers', value: '0' },
        { label: 'Workers without a Badge #', value: '1' },
        { label: 'Terminated or Suspended workers', value: '2' },
    ];
  

    cols = [
        { field: 'profilePhotoURL', header: 'Profile' },
        { field: 'badgeNum', header: 'Badge Num' },
        { field: 'siteSpecificId', header: 'Site Id' },
        { field: 'employeeId', header: 'Employee Id' },
        { field: 'name', header: 'Name' },
        { field: 'companyName', header: 'Company' },
        { field: 'contractName', header: 'Contract' },
        { field: 'laborClassDesc', header: 'Labor Class' }
    ];

    constructor(
        private route: ActivatedRoute,
        private dataService: DataService,
        private dateService: DateService,
        private breadcrumbService: BreadCrumbService,
        private confirmService: ConfirmationService,
        private messageService: MessageService,
        private router: Router,
        private loggingService: LoggingService,
        private userService: UserService) {
            this.isGlobalAdmin = this.userService.currentUser().isGlobalAdmin;
    }

    ngOnInit(): void {
        this.user = this.userService.currentUser();
        this.breadcrumbService.setItemsByCategory(this.section, this.title, this.router.url);
        this.showTabFlag = false;

        this.sub = this.route.params.subscribe(params => {
            this.id = params['id'];

            if (this.id === undefined) {
                this.onSubmitForm();
//                this.loadData();
            } else {
                if (this.id === 'new') {
                    this.loggingService.logPageView('Badge Holders - New');
                    this.badgeHolder = {};
                } else {
                    this.loggingService.logPageView('Badge Holders - Profile');
                    this.dataService.getById('badgeholders', this.id)
                    .subscribe((data) => {
                        this.badgeHolder = data;
                    });
                }
            }
         });
    }

    onSubmitForm() {
        this.badgeHolders = [];
        this.reportData.userId = this.user.id;
        this.reportData.companyIds = [];
        this.reportData.startDate = this.dateService.formatDate(new Date(localStorage.getItem('currentDate')));
        
        if (this.filtersChanged) {
          this.page = 1;
          this.filtersChanged = false;
        }
        this.reportData.page = this.page;
    
        if (this.reportData.pageSize === 0) {
          this.reportData.pageSize = this.pageSize;
        }

        if (this.globalFilter !== undefined && this.globalFilter !== '') {
            var foundIndex = this.reportData.filters.findIndex(x => x.field == "GlobalFilter");
            if (foundIndex !== -1) {
                this.reportData.filters[foundIndex] = { field: 'GlobalFilter', value: this.globalFilter };
            } else {
                var globalFilter = { field: 'GlobalFilter', value: this.globalFilter };
                this.reportData.filters.push(globalFilter);    
            }
        }

        var foundListIndex = this.reportData.filters.findIndex(x => x.field == "ListFilter");
        if (foundListIndex !== -1) {
            this.reportData.filters[foundListIndex] = { field: 'ListFilter', value: this.listType };
        } else {
            var listFilter = { field: 'ListFilter', value: this.listType };
            this.reportData.filters.push(listFilter);    
        }

        const path = 'badgeholders/v2';
        const starttime = performance.now();
        this.dataService.postForm(path, this.reportData)
            .subscribe((resp) => {
              this.totalRecords = resp.totalRecords;
              this.badgeHolders = resp.data;
              if ((this.totalRecords != this.badgeHolders.length) &&
                  (this.pageSize != this.badgeHolders.length)) {
                    this.filterCount = this.badgeHolders.length;
                    this.totalRecords = this.filterCount;
                    this.pageSize = this.filterCount;
              } else {
                this.filterCount = 0;
              }
    
              const endtime = performance.now();
    
              if (starttime && endtime) {
                this.searchSeconds = (endtime - starttime) / 1000.0;
              }

              setTimeout(() => {
                this.loadPhotos();
              }, 3000);
            });
      }

      edit(record: any) {
        const path = '/badgeholders/detail/' + record.id;
        this.router.navigate([path]);
    }

    loadData() {
        this.loggingService.logPageView('Badge Holders - Grid');
        this.badgeHolder = null;
        this.dataService.getById('pages', 'badgeholders')
            .subscribe((data) => {
                this.pageInfo = data.result;
                this.title = this.pageInfo.title;
            });
    }

    pageChanged(event: any) {
        this.pageSize = event.rows;
        this.reportData.pageSize = event.rows;
    
        this.page = (event.first / event.rows) + 1;
        this.reportData.page = this.page;
    
        this.onSubmitForm();
      }

      applyFilters(event: any) {
        this.reportData.filters = Object.keys(event.filters).map(key => {
          return { field: key, value: event.filters[key].value };
        });
        this.filtersChanged = true;
    }

    public onFilter(event): void {
        const filter = ((event.filters.global !== undefined) ? event.filters.global.value : '');
        const filtered = [];
        for (const row of this.badgeHolders) {
            let globalFilterFound = (filter === '');
            let allFiltersOK = true;
            for (const column of this.cols) {
                if (row[column.field] != undefined && row[column.field] != null) {
                    let format = null;

                    let value = row[column.field];
                    value = this.datePipe.transform(value, format);

                    if (filter !== '' && value !== undefined && value !== null && String(value).toLowerCase().includes(filter.toLowerCase())) {
                        globalFilterFound = true;
                    }
                    if (this._table.filters[column.field] !== undefined && (value === undefined && value === null || !String(value).toLowerCase().includes(this._table.filters[column.field].value.toLowerCase()))) {
                        allFiltersOK = false;
                    }
                }
            }
        }
        this._table.filteredValue = filtered;
    }

    loadPhotos() {
        for (const row of this.badgeHolders) {
            this.loadPhoto(row['id'], row['id'], 'profile', 'profile');
        }    
    }

    loadPhoto(id: string, imageId: string, type: string, path: string) {
        if (imageId !== null) {
            const imgId = type + '-' + imageId;
            const img = window.document.getElementById(imgId);
            img.style.display = 'none';

            this.dataService.getById('files/download/' + path, id, { responseType: 'arraybuffer' })
            .subscribe((photo) => {
                const file = new Blob([photo], { type: 'image/jpeg' });
                const fileURL = window.URL.createObjectURL(file);

                img.setAttribute('src', fileURL);
                img.style.display = '';
//              setTimeout(() => window.URL.revokeObjectURL(fileURL), 1000);
            });
        }
    }

    addNew(): void {
        const path = '/badgeholders/detail/new';
        this.router.navigate([path]);
    }

    createExcel(): void {
        this.dataService.postForm('badgeholders/excel', {}, {responseType: 'arraybuffer'})
            .subscribe((data) => {
                const file = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                const fileURL = window.URL.createObjectURL(file);

                const a = document.createElement('a');
                document.body.appendChild(a);
                a.href = fileURL;
                a.download = 'badgeholders.xlsx';
                a.click();

                window.URL.revokeObjectURL(fileURL);
                a.remove();
            });
    }

    exportToCSV(): void {
        let csv = '';
        for (const column of this.cols) {
            if (column.field !== 'profilePhotoURL') {
                if (csv !== '') {
                    csv += ',';
                }
                csv += column.header;
            }
        }
        csv = csv + '\n';


        const rows = this.badgeHolders;
        for (const row of rows) {
            let csvRow = '';
            for (const column of this.cols) {
                if (column.field !== 'profilePhotoURL') {
                    if (csvRow !== '') {
                        csvRow += ',';
                    }
                    csvRow += '"' + row[column.field] + '"';
                }
            }
            csv += csvRow + '\n';
        }

        const blob = new Blob(['\uFEFF', csv], { type: 'text/csv' });
        const link = document.createElement('a');
        link.setAttribute('href', window.URL.createObjectURL(blob));
        link.setAttribute('download', 'badgeholders.csv');
        document.body.appendChild(link); // Required for FF
        link.click();
    }

    delete(rData: any) {
        this.confirmService.confirm({
            message: 'Do you want to delete ' + rData.name + '?',
            accept: () => {
                this.dataService.deleteById('badgeholders', rData.id)
                    .subscribe((data) => {
                        this.onSubmitForm();
                        this.messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'Record deleted' });
                    });
            },
            reject: () => {
                this.messageService.add({ severity: 'info', summary: 'Cancelled', detail: 'You have cancelled the request' });
            }
        });
    }

    undelete(rData: any) {
        this.confirmService.confirm({
            message: 'Do you want to undelete ' + rData.name + '?',
            accept: () => {
                this.dataService.getById('badgeholders/' + rData.id, 'undelete')
                    .subscribe((data) => {
                        this.onSubmitForm();
                        this.messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'Record undeleted' });
                    });
            },
            reject: () => {
                this.messageService.add({ severity: 'info', summary: 'Cancelled', detail: 'You have cancelled the request' });
            }
        });
    }
}
