import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Message } from 'primeng/api';
import { DataService } from '../../services/data.service';
import { Subscription } from 'rxjs';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-site-location',
    templateUrl: './site.location.component.html',
    encapsulation: ViewEncapsulation.None
})
export class SiteLocationComponent implements OnInit {
    @Input() site: any;

    states: any[];
    siteSubscription: Subscription;
    timeZones: any[];

    constructor(private dataService: DataService,
        private userService: UserService) {
        this.siteSubscription = this.userService.getSiteEvent()
            .subscribe(siteId => {
                this.loadStates();
            });
    }

    ngOnInit() {
        this.loadStates();
        this.timeZones = this.dataService.timeZones;
    }

    loadStates(): void {
        this.dataService.getById('lookups', 'states')
            .subscribe((states) => {
                this.states = states;
            });
    }
}
