import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BreadCrumbService } from '../../services/breadcrumb.service';
import { DataService } from '../../services/data.service';
import { LoggingService } from '../../services/logging.service';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-terminal-profile',
  templateUrl: './terminal.profile.component.html',
  styleUrls: ['./terminal.profile.component.css']
})
export class TerminalProfileComponent implements OnInit, OnChanges {
@Input() terminal: any;

message: Message[] = [];
surveys: any[];
showDialogFlag: boolean;

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private loggingService: LoggingService,
    private breadcrumbService: BreadCrumbService,
    private router: Router) { }

  ngOnInit() {
    this.loadSurveys();
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    this.breadcrumbService.setItemsByCategoryAndName(
                            'Sitetraxx Admin',
                            'Terminals',
                            '/terminals/search',
                            this.terminal.name,
                            this.router.url);
    }

  save() {
    this.dataService.save('terminals', this.terminal)
        .subscribe((data) => {
            this.loggingService.savedMessage();
        });
  }

  showDialog(status: boolean) {
      this.showDialogFlag = status;
  }

  loadSurveys() {
    this.loggingService.logPageView('Surveys - Dropdown');
    this.dataService.getById('lookups', 'surveys')
    .subscribe((data) => {
        this.surveys = data;
      });
  }

  cancel() {
      this.router.navigate(['/terminals/search/']);
  }

}
