<div id="printArea">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <label class="control-label">Company:</label>
                <p-dropdown [options]="companies" [filter]="true" [(ngModel)]="companyId"></p-dropdown>
            </div>
            <div class="col-lg-3" *ngIf="showStartDate">
                <label class="control-label">Start Date:</label>
                <p-calendar [(ngModel)]="startDate" (onSelect)="onStartDateChange()" [showIcon]="true"></p-calendar>
            </div>
            <div class="col-lg-3" *ngIf="showEndDate">
                <label class="control-label">End Date:</label>
                <p-calendar [(ngModel)]="endDate" (onSelect)="onEndDateChange()" [showIcon]="true"></p-calendar>
            </div>
            <div class="col-lg-3">
                <button pButton (click)="onSubmitForm()" id="btnSubmit" label="Submit" icon="pi pi-search"></button>
                <!-- <button pButton (click)="createPDF()" id="btnPDF" label="PDF" icon="pi pi-file-pdf"></button> -->
                <button pButton (click)="createExcel()" label="Excel" icon="pi pi-file-excel"></button>
            </div>
        </div>
        <div class="row">
            <p-table #dt [value]="data" [columns]="cols" [scrollable]="true" scrollHeight="500px"
                [exportFilename]="'badgeholders'">
                <ng-template pTemplate="colgroup">
                    <colgroup>
                        <col style="width:220px">
                        <col style="width:120px">
                        <col style="width:110px">
                        <col style="width:110px">
                        <col style="width:150px">
                        <col style="width:105px">
                        <col style="width:110px">
                    </colgroup>
                </ng-template>
                <ng-template pTemplate="caption">
                    <div style="text-align: left">
                        <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                        <input type="text" pInputText size="50" placeholder="Global Filter" [(ngModel)]="globalFilter"
                            (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto" />
                        <span *ngIf="data && !dt.filteredValue">{{dt.totalRecords}} - {{data.length}} Records</span>
                        <span *ngIf="data && dt.filteredValue">{{dt.filteredValue.length}} - {{data.length}}
                            Records</span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="(col.sortable) ? col.field : null">
                            {{col.header}}
                            <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
                        </th>
                    </tr>
                    <tr>
                        <th style="overflow: hidden" *ngFor="let col of columns">
                            <input pInputText type="text"
                                (input)="dt.filter($event.target.value, col.field, 'contains')"
                                *ngIf="col.field !== 'profilePhotoURL' && col.field !== 'clockInPhotoURL' && col.field !== 'clockOutPhotoURL'">
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pSelectableRow]="rowData">
                        <td *ngFor="let col of columns" [ngSwitch]="col.field">
                            <span [ngClass]="dateClass(rowData[col.field])"
                                *ngSwitchCase="'expirationDate'">{{rowData[col.field] | date:'MM/dd/yyyy'}}</span>
                            <span *ngSwitchDefault>{{rowData[col.field]}}</span>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div [innerHtml]="html"></div>
    </div>
</div>