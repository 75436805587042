<div class="row">
    <div class="col-lg-12">
        <table class="table table-striped sitetraxx">
            <thead>
                <tr class="sitetraxx">
                    <th>Product</th>
                    <th>Enter</th>
                    <th>Exit</th>
                </tr>
            </thead>
            <tr *ngFor="let buildingmaterial of buildingmaterials">
                <td>{{buildingmaterial.name}}</td>
                <td>{{buildingmaterial.enterTime | date:"MM/dd/yyyy h:mma"}}</td>
                <td>{{buildingmaterial.exitTime | date:"MM/dd/yyyy h:mma"}}</td>
            </tr>
        </table>
    </div>
</div>