import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { BreadCrumbService } from '../services/breadcrumb.service';
import { DataService } from '../services/data.service';
import { ActivatedRoute } from '@angular/router';
import { LoggingService } from '../services/logging.service';
import { UserService } from '../services/user.service';
import { Subscription } from 'rxjs';


@Component({
    selector: 'app-resources',
    templateUrl: './resource.component.html'
})
export class ResourceComponent implements OnInit {
    pageInfo: any;
    path: string;
    sub: any;
    id: string;
    resource: any;
    isGlobalAdmin = false;

    constructor(
        private route: ActivatedRoute,
        private dataService: DataService,
        private breadcrumbService: BreadCrumbService,
        private loggingService: LoggingService,
        private router: Router,
        private userService: UserService) {
            this.isGlobalAdmin = this.userService.currentUser().isGlobalAdmin;
        }

    ngOnInit(): void {

        this.sub = this.route.params.subscribe(params => {
            this.id = params['id'];

            if (this.id === undefined) {
                this.loadData();
            } else {
                if (this.id === 'new') {
                    this.loggingService.logPageView('Resource - New');
                    this.resource = {};
                    this.resource.id = 'new';
                } else {
                    this.loggingService.logPageView('Resource - Profile');
                    this.dataService.getById('resources', this.id)
                    .subscribe((data) => {
                        this.resource = data;
                    });
                }
            }
         });
    }

    loadData() {
        this.loggingService.logPageView('Resources - Grid');
        this.resource = null;
        this.dataService.getById('pages', 'resources')
            .subscribe((data) => {
                this.pageInfo = data.result;
                this.breadcrumbService.setItemsByCategory('Sitetraxx Admin', this.pageInfo.title, this.router.url);
            });
    }
}
