import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../../services/data.service';
import { LoggingService } from '../../services/logging.service';

@Component({
  selector: 'app-survey-attempts',
  templateUrl: './attempt.component.html',
  styleUrls: ['./attempt.component.css']
})
export class SurveyAttemptComponent implements OnInit {
  @Input() survey: any;

  pageInfo: any;
  path: string;
  sub: any;
  id: string;
  attempts: any[];
  responses: any[];
  badgeHolderName: string;
  surveyName: string;
  badgeHolders: any[];
  badgeHolderId: string;
  startDate: Date;
  endDate: Date;
  filterInfo = '';
  showAddDialog = false;
  cols = [
    { field: 'firstName', header: 'First Name', type: 'text', filter: true, sortable: true },
    { field: 'lastName', header: 'Last Name', type: 'text', filter: true, sortable: true },
    { field: 'surveyAttemptDate', header: 'Attempt Date', type: 'datetime', filter: true, sortable: true }
  ];

  constructor(
    private dataService: DataService,
    private loggingService: LoggingService) { }

  ngOnInit() {
    this.filterInfo = this.survey.id;
    this.loadBadgeHolders();
  }

  private getData(): any {
    return { surveyId: this.survey.id, badgeHolderId: this.badgeHolderId, startDate: this.startDate, endDate: this.endDate };
  }
  loadData() {
    this.loggingService.logPageView('Attempts - Grid');
    this.attempts = [];

    this.dataService.postForm('surveys/attempts', this.getData())
      .subscribe((data) => {
        this.attempts = data;
      });
  }

  loadBadgeHolders(): void {
    this.dataService.getById('lookups', 'badgeholders')
      .subscribe((badgeHolders) => {
        this.badgeHolders = badgeHolders;
        this.badgeHolders.unshift({ id: null });
      });
  }

  createExcel(): void {

    this.dataService.postForm('surveys/attempts/excel', this.getData(), { responseType: 'arraybuffer' })
      .subscribe((data) => {
        const file = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileURL = window.URL.createObjectURL(file);

        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = fileURL;
        a.download = 'surveysAttempts.xlsx';
        a.click();

        window.URL.revokeObjectURL(fileURL);
        a.remove();
      });
  }

  view(attempt: any) {
    this.responses = [];
    this.badgeHolderName = attempt.firstName + ' ' + attempt.lastName;
    this.surveyName = attempt.surveyName;
    this.dataService.getById('surveys/attempts/details', attempt.id)
      .subscribe((data) => {
        this.responses = data;
      });
    this.showAddDialog = true;
  }

  cancel() {
    this.showAddDialog = false;
  }
}