import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { LoggingService } from '../services/logging.service';
import { Router } from '@angular/router';
import { share, map } from 'rxjs/operators';

import { ProgressService } from '../services/progress.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    env: any = environment;

    constructor(
        private userService: UserService,
        private loggingService: LoggingService,
        private router: Router,
        private progressService: ProgressService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        this.progressService.display(true);
        this.loggingService.logEvent(req.url);

        if (req.url !== this.env.baseAPIUrl + 'users/authenticate') {
            const user = this.userService.currentUser();

            req = req.clone({
                headers: req.headers.append('Authorization', 'Bearer ' + user.token)
                    .append('X-SiteTraxx-UserId', user.id)
                    .append('X-SiteTraxx-SiteId', user.siteId)
            });
        }
        return next.handle(req).pipe(share())
            .pipe((event) => {
                event.subscribe(null, error => {
                    this.loggingService.logError(error);
                    this.progressService.display(false);
                });
                return event;
            })
            .pipe(map<HttpEvent<any>, any>((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse)
                    this.progressService.display(false);
                return event;
            }));
    }
}
