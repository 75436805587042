import { Component, Input, OnInit, AfterViewInit, OnDestroy, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import {trigger, state, style, transition, animate} from '@angular/animations';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {MenuItem} from 'primeng/api';
import {SecureLayoutComponent} from '../../layouts/secure-layout.component';
import { LoggingService } from '../services/logging.service';
import { DataService } from '../services/data.service';
import { DateService } from '../services/date.service';
import { Subscription } from 'rxjs';
import { UserService } from '../services/user.service';

declare var jQuery: any;

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() reset: boolean;

    model: any[];
    siteSubscription: Subscription;

    layoutMenuScroller: HTMLDivElement;

    @ViewChild('layoutMenuScroller', { static: true }) layoutMenuScrollerViewChild: ElementRef;

    constructor(public app: SecureLayoutComponent, 
        private dataService: DataService, 
        private userService: UserService,
        private loggingService: LoggingService) {
            this.siteSubscription = this.userService.getSiteEvent()
                .subscribe(siteId => {
                    this.loadData();
                });
        }

    ngOnInit() {
        this.app.darkMenu = true;

        this.loadData();
    }

    ngOnDestroy() {
        this.siteSubscription.unsubscribe();
    }

    ngAfterViewInit() {
        this.layoutMenuScroller = <HTMLDivElement> this.layoutMenuScrollerViewChild.nativeElement;

        setTimeout(() => {
//            jQuery(this.layoutMenuScroller).nanoScroller({flash: true});
        }, 10);
    }

    loadData() {
        this.dataService.getAll('menus')
            .subscribe((data) => {
                this.model = data;
            });
    }

    changeTheme(theme: string) {
        const themeLink: HTMLLinkElement = <HTMLLinkElement> document.getElementById('theme-css');
        themeLink.href = 'assets/theme/theme-' + theme + '.css';
    }

    changeLayout(theme: string) {
        const layoutLink: HTMLLinkElement = <HTMLLinkElement> document.getElementById('layout-css');
        layoutLink.href = 'assets/layout/css/layout-' + theme + '.css';
    }

    updateNanoScroll() {
        setTimeout(() => {
//            jQuery(this.layoutMenuScroller).nanoScroller();
        }, 500);
    }
}


@Component({
    /* tslint:disable:component-selector */
    selector: '[app-submenu]',
    /* tslint:enable:component-selector */
    templateUrl: './submenu.component.html',
    animations: [
        trigger('children', [
            state('visible', style({
                height: '*'
            })),
            state('hidden', style({
                height: '0px'
            })),
            transition('visible => hidden', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hidden => visible', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class AppSubMenuComponent {
    @Input() item: MenuItem;
    @Input() root: boolean;
    @Input() visible: boolean;
    _reset: boolean;
    activeIndex: number;
    hover: boolean;
    constructor(public app: SecureLayoutComponent, public router: Router, public location: Location) {}
    itemClick(event: Event, item: MenuItem, index: number) {
        // avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();
            return true;
        }
        // activate current item and deactivate active sibling if any
        if (item.routerLink || item.items || item.command || item.url) {
            this.activeIndex = (this.activeIndex === index) ? null : index;
        }
        // execute command
        if (item.command) {
            item.command({originalEvent: event, item: item});
        }
        // prevent hash change
        if (item.items || (!item.url && !item.routerLink)) {
            event.preventDefault();
        }
        // hide menu
        if (!item.items) {
            if (this.app.isMobile()) {
                this.app.sidebarActive = false;
                this.app.mobileMenuActive = false;
            }
        }
    }

    isActive(index: number): boolean {
        return this.activeIndex === index;
    }

    @Input() get reset(): boolean {
        return this._reset;
    }

    set reset(val: boolean) {
        this._reset = val;
    }
}
