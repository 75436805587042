<form #thisForm='ngForm' style="min-height: 400px;">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <h3>{{resource.name}}</h3>
            </div>
            <div class="col-lg-6">
                <div class="float-right">
                    <button pButton (click)="save(true)" [disabled]="!thisForm.valid || !canSave()" icon="pi pi-save"
                        label="New"></button>
                    <button pButton (click)="save(false)" [disabled]="!thisForm.valid || !canSave()" icon="pi pi-save"
                        label="Save"></button>
                    <button pButton (click)="cancel()" icon="pi pi-ban" label="Cancel"></button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="row">
                    <div class="col">
                        <div class="row">
                            <div class="col">
                                <label class="control-label" for="resourceType">Resource Type:</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <p-dropdown [options]="resourceTypes" name="resourceType" [(ngModel)]="resource.resourceType" required></p-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col">
                                <label class="control-label" for="colorType">Color Type:</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <p-dropdown [options]="colorTypes" name="colorType" [(ngModel)]="resource.colorType" required (onChange)="setupEvents()"></p-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label class="control-label" for="resourceName">Resource Name:</label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon" *ngIf="resource.resourceType === 1">Crane</span>  
                            <span class="p-inputgroup-addon" *ngIf="resource.resourceType === 2">Man Hoist</span>  
                            <span class="p-inputgroup-addon" *ngIf="resource.resourceType === 3">Loading Dock</span>  
                            <span class="p-inputgroup-addon" *ngIf="resource.resourceType === 4">Gate</span>  
                            <input class="form-control" pInputText name="resourceName" type="text" [(ngModel)]="resource.name" maxlength="50" required />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="row">
                    <div class="col">
                        <label class="control-label" style="margin-bottom: 17px;">Start Date</label><br />
                        <p-calendar required name="startDate" [(ngModel)]="startDate" [showIcon]="true"></p-calendar>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label class="control-label" style="margin-bottom: 15px;">End Date</label><br />
                        <p-calendar required name="endDate" [(ngModel)]="endDate" [showIcon]="true"></p-calendar>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="row">
                    <div class="col">
                        <label class="control-label" style="margin-bottom: 17px;">Start Time</label><br />
                        <p-calendar required name="startTime" [(ngModel)]="startTime" [timeOnly]="true" [stepMinute]="15" [showIcon]="true"></p-calendar>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label class="control-label" style="margin-bottom: 15px;">End Time</label><br />
                        <p-calendar required name="endTime" [(ngModel)]="endTime" [timeOnly]="true" [stepMinute]="15" [showIcon]="true"></p-calendar>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="resource.id !== 'new'">
            <div class="col-lg-12 text-right">
                <button pButton (click)="showDialogFlag = true" label="Add Unavailable time"></button>
            </div>
        </div>
        <div class="row" *ngIf="resource.id !== 'new'">
            <div class="col-lg-12">
                <p-fullCalendar #fc [events]="events" [options]="options"></p-fullCalendar>
            </div>
        </div>
        <st-update-block [object]='resource'></st-update-block>
    </div>
</form>

<p-dialog header="Unavailable" [(visible)]="showDialogFlag" modal="modal" [style]="{ width: '520px', height: '670px', 'min-width': '200px' }" [minY]="70">
    <div style="width: 480px; height: 530px;">
        <form #dialogForm="ngForm">
            <div class="row">
                <div class="col-12">
                    <label>Title:</label>
                    <input [(ngModel)]="resourceSchedule.name" name="name" pInputText type="text" class="form-control" required />
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <label class="control-label" *ngIf="!(resourceSchedule.end || resourceSchedule.showOnSunday || resourceSchedule.showOnMonday || resourceSchedule.showOnTuesday || resourceSchedule.showOnWednesday || resourceSchedule.showOnThursday || resourceSchedule.showOnFriday || resourceSchedule.showOnSaturday)"> Date:</label>
                    <label class="control-label" *ngIf="resourceSchedule.end || resourceSchedule.showOnSunday || resourceSchedule.showOnMonday || resourceSchedule.showOnTuesday || resourceSchedule.showOnWednesday || resourceSchedule.showOnThursday || resourceSchedule.showOnFriday || resourceSchedule.showOnSaturday">Start Date:</label>
                    <br />
                    <p-calendar [(ngModel)]="resourceScheduleStartDate" name="resourceScheduleStartDate" [showIcon]="true" required></p-calendar>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <label>End Date:</label><br />
                    <p-calendar [(ngModel)]="resourceScheduleEndDate" name="resourceScheduleEndDate" [showIcon]="true" [required]="resourceSchedule.showOnSunday || resourceSchedule.showOnMonday || resourceSchedule.showOnTuesday || resourceSchedule.showOnWednesday || resourceSchedule.showOnThursday || resourceSchedule.showOnFriday || resourceSchedule.showOnSaturday"></p-calendar>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <label class="control-label">Days of the Week:</label><br />
                    <p-checkbox [(ngModel)]="resourceSchedule.showOnSunday" name="showOnSunday" label="Sunday" binary="true"></p-checkbox>&nbsp;
                    <p-checkbox [(ngModel)]="resourceSchedule.showOnMonday" name="showOnMonday" label="Monday" binary="true"></p-checkbox>&nbsp;
                    <p-checkbox [(ngModel)]="resourceSchedule.showOnTuesday" name="showOnTuesday" label="Tuesday" binary="true"></p-checkbox>&nbsp;
                    <p-checkbox [(ngModel)]="resourceSchedule.showOnWednesday" name="showOnWednesday" label="Wednesday" binary="true"></p-checkbox><br />
                    <p-checkbox [(ngModel)]="resourceSchedule.showOnThursday" name="showOnThursday" label="Thursday" binary="true"></p-checkbox>&nbsp;
                    <p-checkbox [(ngModel)]="resourceSchedule.showOnFriday" name="showOnFriday" label="Friday" binary="true"></p-checkbox>&nbsp;
                    <p-checkbox [(ngModel)]="resourceSchedule.showOnSaturday" name="showOnSaturday" label="Saturday" binary="true"></p-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <label class="control-label" style="margin-bottom: 17px;">Time</label><br />
                    <p-calendar required [(ngModel)]="resourceScheduleStartTime" name="resourceScheduleStartTime" [timeOnly]="true" [stepMinute]="15" [showIcon]="true"></p-calendar>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <label>Duration (minutes):</label>
                    <input [(ngModel)]="resourceSchedule.duration" name="duration" pInputText type="number" class="form-control" required />
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <p-button (click)="deleteUnavailble()" label="Delete" *ngIf="resourceSchedule.id && resourceSchedule.id != 'new'"></p-button>
        <p-button (click)="saveUnavailble()" [disabled]="!dialogForm.valid" label="Save"></p-button>
        <p-button (click)="cancelUnavailble()" label="Cancel"></p-button>
    </p-footer>
</p-dialog>