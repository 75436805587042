<div *ngIf="companies">
    <div class="row">
        <div class="col-lg-12">
            <span class="grid-header">{{companies.length}} Companies</span>
        </div>
    </div>
    <div class="row">
        <table class="table table-striped">
            <thead>
                <tr class="sitetraxx">
                    <th>Details</th>
                    <th>Name</th>
                    <th>Headcount</th>
                    <th width="50"><button type="button" pButton title="Export" icon="pi pi-cloud-download" class="indigo-btn" (click)="exportToCSV()"></button></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let company of companies">
                    <td><button pButton icon="pi pi-list" (click)="onsiteDetail(company)" title="Add" class="indigo-btn"></button></td>
                    <td>{{company.companyName}}</td>
                    <td>{{company.counter}}</td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td><b>Total:</b></td>
                    <td><b>{{headCountTotal}}</b></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<p-dialog header="Clock In/Outs" [(visible)]="showDialog" modal="modal" [style]="{ width: '800px', height: '400px', 'min-width': '200px' }" [minY]="100">
    <div class="panel-body" style="padding: 0;">
        <h3>{{currentCompany.name}}</h3>

        <p-table [value]="onsite" [scrollable]="true" scrollHeight="200px">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th>Badge #</th>
                    <th>Name</th>
                    <th>Labor Class</th>
                    <th>In</th>
                    <th>Out</th>
                    <th>Total Hrs</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-badgeholder>
                <tr>
                    <td>{{badgeholder.badgeNum}}</td>
                    <td>{{badgeholder.firstName}} {{badgeholder.lastName}}</td>
                    <td>{{badgeholder.laborClassName}}</td>
                    <td>{{badgeholder.clockIn | date:"MM/dd/yyyy HH:mm:ss"}}</td>
                    <td>{{badgeholder.clockOut | date:"MM/dd/yyyy HH:mm:ss"}}</td>
                    <td>{{badgeholder.total}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <p-footer>
        <button class="btn btn-secondary" (click)="close()"> Close</button>
    </p-footer>
</p-dialog>