import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { BreadCrumbService } from '../../services/breadcrumb.service';
import { DataService } from '../../services/data.service';
import { LoggingService } from '../../services/logging.service';
import { DateService } from '../../services/date.service';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-delay-detail',
  templateUrl: './delay.detail.component.html',
  styleUrls: ['./delay.detail.component.css']
})
export class DelayDetailComponent implements OnInit, OnChanges {
  @Input() delay: any;

  message: Message[];
  delayCauses: any[];
  companies: any[];

  category = 'delays';

  constructor(
      private route: ActivatedRoute,
      private dataService: DataService,
      private loggingService: LoggingService,
      private dateService: DateService,
      private breadcrumbService: BreadCrumbService,
      private router: Router) { }

  ngOnInit() {
    this.delay.delayStart = this.dateService.convertDateUniversalToSiteTimeDate(new Date(this.delay.delayStart));
    if (this.delay.delayEnd != null) {
      this.delay.delayEnd = this.dateService.convertDateUniversalToSiteTimeDate(new Date(this.delay.delayEnd));
    }
    this.loadDelayCauses();
    this.loadCompanies();
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    this.breadcrumbService.setItemsByCategoryAndName(
                            'Controls',
                            'Delays',
                            '/' + this.category + '/search',
                            this.delay.subject,
                            this.router.url);
  }

  save() {
    if (this.delay.potentialDuration)
      this.delay.potentialDuration = this.delay.potentialDuration.toString();
    if (this.delay.totalTimeImpact)
      this.delay.totalTimeImpact = this.delay.totalTimeImpact.toString();
    if (this.delay.delayStart != null) {
      this.delay.delayStart = this.dateService.convertDateLocalToSiteTimeDate(new Date(this.delay.delayStart));
      if (this.delay.delayEnd != null) {
        this.delay.delayEnd = this.dateService.convertDateLocalToSiteTimeDate(new Date(this.delay.delayEnd));
      }
      this.dataService.save('delays', this.delay)
          .subscribe((data) => {
              this.loggingService.savedMessage();
          });
    }
  }

  cancel() {
    this.router.navigate(['/' + this.category + '/search/']);
  }

  loadDelayCauses() {
    const ddData = [];
    ddData.push({ label: 'Weather Delay', value: 'Weather Delay' });
    ddData.push({ label: 'Material Delay', value: 'Material Delay' });
    ddData.push({ label: 'Equipment Delay', value: 'Equipment Delay' });
    ddData.push({ label: 'Owner Delay', value: 'Owner Delay' });
    ddData.push({ label: 'Contractor Delay', value: 'Contractor Delay' });
    ddData.push({ label: 'Subcontractor Delay', value: 'Subcontractor Delay' });
    ddData.push({ label: 'Drawing Delay', value: 'Drawing Delay' });
    ddData.push({ label: 'Coordination Delay', value: 'Coordination Delay' });
    ddData.push({ label: 'Other', value: 'Other' });

    this.delayCauses = ddData;
  }

  loadCompanies(): void {
    this.dataService.getAll('companies')
    .subscribe((companies) => {
        this.companies = companies;
    });
}

}
