import { Component, Input, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm, FormGroup } from '@angular/forms';

import { DataService } from '../../services/data.service';
import { DateService } from '../../services/date.service';
import { ConfirmationService } from 'primeng/api';
import { environment } from '../../../../environments/environment';
import { LoggingService } from '../../services/logging.service';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-badgeholder-activescans',
    templateUrl: './badgeholder.activescans.component.html',
    providers: [ ConfirmationService ],
    encapsulation: ViewEncapsulation.None
})
export class BadgeHolderActiveScansComponent implements OnInit {
@Input() badgeHolder: any;
@Input() date: string;

gatewayLogs: any[];
env: any = environment;
user: any;
dt: string;

    constructor(
        private dataService: DataService,
        private dateService: DateService,
        private userService: UserService,
        private loggingService: LoggingService) {
        }

    ngOnInit(): void {
        this.dt = this.dateService.formatDate(new Date());
        this.user = this.userService.currentUser();
        this.loadGatewayLogs();
    }

    loadGatewayLogs(): void {
        this.dataService.getById('gateways/badgeholder', this.badgeHolder.beaconId + '/' + this.dt)
        .subscribe((data) => {
            this.gatewayLogs = data;
        });
    }
}
