<div class="panel-body" id="photosPanel">
  <div *ngIf="loadingClocks || loadingSyncs" class="row">
    <div class="col-lg-12">
      Loading...
    </div>
  </div>
  <div *ngIf="false && syncTargets && syncTargets.length > 0" class="row">
    <div class="mx-5 alert alert-info">
      <p>This badge holder has synced records that may have additional clocks and photos:</p>
      <ul class="mt-2">
        <li *ngFor="let target of syncTargets">
          <a class="text-primary" href="/badgeholders/detail/{{target.badgeHolderId}}">{{target.siteName}}</a>
        </li>
      </ul>
    </div>
  </div>
    <div class="row">
        <div class="col-lg-3" *ngFor="let photo of photos">
            <p-panel>
                <p-header>
                    <span *ngIf="photo.status==='M'" class="numberCircle">M</span>
                    <span *ngIf="photo.status==='X'" class="numberCircle">A</span>
                    <span><strong>{{photo.clockIn | date:'MM/dd/yyyy'}}</strong></span>
                </p-header>
                <div class="row">
                    <div class="col-lg-6">
                        {{photo.clockIn | date:'HH:mm a'}}<br />
                        <img width="50px" id="clockIn-{{photo.clockId}}" />
                    </div>
                    <div class="col-lg-6">
                        {{photo.clockOut | date:'HH:mm a'}}
                        <br />
                        <img *ngIf="photo.status==='C'" width="50px" id="clockOut-{{photo.exitId}}" />
                    </div>
                </div>
                <p-footer>
                    <button (click)="show(photo)" type="button" pButton icon="pi pi-search"></button>
                    <strong>{{photo.totalCalc}}</strong>
                </p-footer>
            </p-panel>
            <br />
        </div>
    </div>
</div>

<p-dialog
    header="Clock Details - {{onsiteInfo?.firstName}} {{onsiteInfo?.lastName}} - {{onsiteInfo?.laborClassName}} - {{onsiteInfo?.companyContract}}"
    [(visible)]="showDialog" modal="modal"
    [style]="{ width: '800px', height: '560px', 'min-width': '200px' }" [minY]="70">
    <div class="row">
        <!--
        <div class="col-lg-4">
            <img *ngIf="onsiteInfo?.photoId" width="250px" src="{{onsiteInfo?.profilePhotoURL}}" /><br />
        </div>
        -->
        <div class="col-lg-4" *ngIf="onsiteInfo">
            {{onsiteInfo.clockIn | date:'HH:mm a'}}<br />
            <img style="width: 250px" id="clockIn-photo" />
        </div>
        <div class="col-lg-4" *ngIf="onsiteInfo">
            {{onsiteInfo.clockOut | date:'HH:mm a'}}<br />
            <img style="width: 250px" id="clockOut-photo" />
        </div>
    </div>
    <p-footer>
        <button type="button" (click)="close()" pButton icon="pi pi-times" label="Close"></button>
    </p-footer>
</p-dialog>
