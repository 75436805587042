<div class="container">
    <div class="row">
        <span class="grid-header">Questions</span>
        <span class="pull-right">
            <button pButton icon="pi pi-plus" (click)="add()" title="Add" class="indigo-btn"></button>
        </span>
    </div>
    <div class="row">
        <div class="col-md-12">
            <table class="table table-striped">
                <thead>
                    <tr class="sitetraxx">
                        <th>Edit</th>
                        <th>Delete</th>
                        <th>Name</th>
                        <th>Type</th>
                    </tr>
                </thead>
                <tr *ngFor="let question of questions">
                    <td>
                        <button type="button" pButton (click)="edit(question)" icon="pi pi-pencil" style="max-width: 90%"></button>
                    </td>
                    <td>
                        <button type="button" pButton (click)="delete(question)" icon="pi pi-trash" style="max-width: 90%"></button>
                    </td>
                    <td>{{question.name}}</td>
                    <td>{{question.questionType}}</td>
                </tr>
            </table>
        </div>
    </div>
</div>

<p-dialog header="Add Question" [(visible)]="showAddDialog" [responsive]="true" [style]="{ width: '600px', height: '600px' }">
    <label>Question Type</label><br />
    <p-dropdown [options]="questionTypes" (change)="typeChange()" name="questionTypes" [(ngModel)]="questionTypeString"></p-dropdown><br/><br/>  
    <label>Question</label>
    <textarea pTextArea [(ngModel)]="questionName" rows="5" class="form-control"></textarea>
    <label>Desired Response:</label>
    <p-dropdown [options]="desiredResponseOptions" name="desiredResponse" [(ngModel)]="desiredResponseString"></p-dropdown>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <p-footer>
        <button type="button" pButton icon="pi pi-save" (click)="saveQuestion()" label="Save"></button>
        <button type="button" pButton icon="pi pi-ban" (click)="cancelQuestion()" label="Cancel"></button>
    </p-footer>
</p-dialog>

<p-confirmDialog header='Delete?' icon='pi pi-trash'></p-confirmDialog>

