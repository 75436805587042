<div *ngIf="pageInfo">
    <div class="container">
        <div class="row">
            <h3>Concrete</h3>
        </div>
        <div class="col">
            <st-table pageId="{{pageInfo.id}}" dataTable="{{pageInfo.dataName}}"></st-table>
        </div>
    </div>
</div>

<div *ngIf="concrete">
    <app-concrete-profile [concrete]="concrete"></app-concrete-profile>
</div>