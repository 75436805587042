import { Component, OnInit } from '@angular/core';
import {SelectItem} from 'primeng/api';
import {MenuItem} from 'primeng/api';

import { SecureLayoutComponent } from '../../layouts/secure-layout.component';
import { DateService } from '../services/date.service';
import { DataService } from '../services/data.service';
import { Subscription } from 'rxjs';
import { BreadCrumbService } from '../services/breadcrumb.service';
import { LoggingService } from '../services/logging.service';
import { UserService } from '../services/user.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {

onSiteCount: any = { total: 0 };
companyTotal = '0';
weather: any;

safetyCount: any = { 'incidents': 0, 'rewards': 0, 'violations': 0 };
terminalCount: any[];
subscription: Subscription;
siteSubscription: Subscription;
dt: string;
weatherIcon = '';
tempF: string;
windSpeed: string;
rainRate: string;
maxIcon: string;
minIcon: string;
isToday: boolean;

    constructor(public app: SecureLayoutComponent,
                private dateService: DateService,
                private dataService: DataService,
                private userService: UserService,
                private breadcrumbService: BreadCrumbService,
                private loggingService: LoggingService) {
        this.subscription = this.dateService.getCurrentDate()
            .subscribe(date => {
                this.dt = this.dateService.formatDate(date);
                this.loadData();
            });

        this.siteSubscription = this.userService.getSiteEvent()
            .subscribe(siteId => {
                this.loadData();
            });
    }

    loadData() {
        this.loadTerminals();
        this.loadOnsiteCount();
        this.loadOnsiteCompanyCount();
        this.loadWeather();
    }

    ngOnInit(): void {
        this.breadcrumbService.setItems([
            {label: 'Dashboard', routerLink: ['/']}
        ]);
        this.loggingService.logPageView('Dashboard');
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.siteSubscription.unsubscribe();
    }

    loadTerminals(): void {
        this.dataService.getById('terminals/count', this.dt)
            .subscribe((data) => {
                this.terminalCount = data;
            });
    }

    loadOnsiteCount(): void {
        this.dataService.getById('onsite/count', this.dt)
            .subscribe((data) => {
                this.onSiteCount = data;
            });
    }

    loadWeather(): void {
        let today = this.dateService.formatDate(new Date);

        if (today === this.dt) {
            this.isToday = true;
            this.dataService.getById('weather', this.dt)
                .subscribe((data) => {
                    this.weather = data;
                    this.weatherIcon = this.weather.iconURL;
                });
        } else {
            this.isToday = false;
            this.dataService.getById('weather/date/minmax', this.dt)
                .subscribe((data) => {
                    this.weather = data;
                    this.tempF = this.weather.minTempF + 'F / ' + this.weather.maxTempF + ' F';
                    this.windSpeed = this.weather.minWindSpeed + ' / ' + this.weather.maxWindSpeed;
                    this.rainRate = this.weather.minRainRate + ' / ' + this.weather.maxRainRate;
                    this.maxIcon = this.weather.maxIconURL;
                    this.minIcon = this.weather.minIconURL;
                });
        }
    }

    loadOnsiteCompanyCount(): void {
        this.dataService.getById('onsite/company/count', this.dt)
            .subscribe((data) => {
                this.companyTotal = data;
            });
    }
}
