import { Component, Input, OnInit, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DataService } from '../../services/data.service';
import { LoggingService } from '../../services/logging.service';
import { DateService } from '../../services/date.service';
import { Message } from 'primeng/api';
import { environment } from '../../../../environments/environment';
import { UserService } from '../../services/user.service';
import { ConfirmationService } from 'primeng/api';
import { BreadCrumbService } from '../../services/breadcrumb.service';

@Component({
    selector: 'app-hauloff-profile',
    templateUrl: './hauloff.profile.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [ ConfirmationService ]
})
export class HaulOffProfileComponent implements OnInit, OnChanges {
@Input() hauloff: any;

env: any = environment;
showTabFlag: boolean;
showUploadDialog: boolean;
showPhotoDialog: boolean;
companyContracts: any[];
materialTypes: any[];
materialCategories: any[];
unitTypes: any[];
materialCategory: any;
message: Message[] = [];
user: any;
photos: any[];
selectedPhoto: any;
isNew = false;
isReady = false;
baseImageUrl: string;
category = 'hauloff';
refresh = new Date();

    constructor(
        private dataService: DataService,
        private loggingService: LoggingService,
        private dateService: DateService,
        private userService: UserService,
        private breadcrumbService: BreadCrumbService,
        private confirmService: ConfirmationService,
        private router: Router) { }

    ngOnInit(): void {
        this.baseImageUrl = this.env.baseImageUrl;
        this.user = this.userService.currentUser();
        if (this.hauloff.id === undefined) {
            this.isNew = true;
            this.hauloff.enterTime = new Date();
            this.hauloff.exitTime = new Date();
            this.isReady = true;
        } else {
            this.dataService.getById('hauloffs', this.hauloff.id)
                .subscribe((data) => {
                    this.hauloff = data;
                    if (this.hauloff.enterTime === undefined) {
                        this.hauloff.enterTime = new Date();
                    } else {
                        this.hauloff.enterTime = this.dateService.convertDateUniversalToSiteTimeDate(new Date(this.hauloff.enterTime));
                    }
            
                    if (this.hauloff.exitTime === undefined) {
                        this.hauloff.exitTime = new Date();
                    } else {
                        this.hauloff.exitTime = this.dateService.convertDateUniversalToSiteTimeDate(new Date(this.hauloff.exitTime));
                    }
            
                    if (this.hauloff.materialType != null) {
                        this.dataService.getById('lookups/material', this.hauloff.materialType)
                            .subscribe((dataMC) => {
                                this.materialCategory = dataMC.categoryCode;
                                this.loadMaterialTypes();
                            });
                    }
                    this.isReady = true;
        });
        }

        this.loadCompanyContracts();
        this.loadMaterialCategories();
        this.loadUnitTypes();
    }

    showDialogChangedHandler(showDialog: boolean): void {
        this.refresh = new Date();
    }

    ngOnChanges(simpleChanges: SimpleChanges): void {
        this.breadcrumbService.setItemsByCategoryAndName(
                                'Controls',
                                'Haul-Offs',
                                '/hauloffs/search',
                                this.hauloff.name,
                                this.router.url);
    }

    save() {
        if (this.hauloff.enterTime) {
            this.hauloff.enterTime = this.dateService.convertDateLocalToSiteTimeDate(new Date(this.hauloff.enterTime));
        }
        if (this.hauloff.exitTime) {
            this.hauloff.exitTime = this.dateService.convertDateLocalToSiteTimeDate(new Date(this.hauloff.exitTime));
        }
        this.dataService.save('hauloffs', this.hauloff)
            .subscribe((data) => {
                this.loggingService.savedMessage();
            });
    }

    cancel() {
        this.router.navigate(['/hauloffs/search/']);
    }

    show(photo: any): void {
        this.refresh = new Date();
        this.selectedPhoto = photo;
        this.showPhotoDialog = true;
    }

    close(): void {
        this.selectedPhoto = {};
        this.showPhotoDialog = false;
    }

    showTab(_tab: boolean) {
        this.showTabFlag = _tab;
    }

    openUploadDialog(): void {
        this.showUploadDialog = true;
    }

    closeUploadDialog(): void {
        this.showUploadDialog = false;
    }

    loadCompanyContracts(): void {
        this.dataService.getById('lookups', 'companycontracts')
            .subscribe((data) => {
                this.companyContracts = data;
            });
    }

    loadMaterialTypes(): void {
        this.dataService.getById('lookups/materialtypes', this.materialCategory)
            .subscribe((data) => {
                this.materialTypes = data;
            });
    }

    loadMaterialCategories(): void {
        this.dataService.getById('lookups', 'materialcategories')
            .subscribe((data) => {
                this.materialCategories = data;
            });
    }

    loadUnitTypes(): void {
        this.dataService.getById('lookups', 'unittypes')
            .subscribe((data) => {
                this.unitTypes = data;
            });
    }
}
