import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-badgeholder-other',
  templateUrl: './badgeholder.other.component.html',
  encapsulation: ViewEncapsulation.None
})
export class BadgeHolderOtherComponent implements OnInit {
  @Input() badgeHolder: any;

  years: any[];

  constructor() {
  }

  ngOnInit(): void {
    this.badgeHolder.emergencyTeam = (this.badgeHolder.emergencyTeam === 1) ? '1' : '0';
    this.badgeHolder.isSalary = (this.badgeHolder.isSalary === 1) ? '1' : '0';
    this.loadYears();
  }

  loadYears() {
    const year = new Date().getFullYear();
    const range = [];
    for (let i = 0; i < 100; i++) {
      range.push({label: year - i, value: (year - i).toString()});
    }
    this.years = range;
  }

  onPhoneChanged() {
    if (this.shouldAutoCheckText()) {
      this.badgeHolder.textPhone = true;
    }
  }

  private shouldAutoCheckText() {
    // if it's already checked, don't autocheck it
    if (this.badgeHolder.textPhone) {
      return false;
    }

    // must have a badge number
    if (!this.badgeHolder.badgeNum || this.badgeHolder.badgeNum.length !== 10) {
      return false;
    }

    // must have a phone number
    if (!this.badgeHolder.phone || this.badgeHolder.phone.length === 0) {
      return false;
    }

    // must not have refused texts
    if (this.badgeHolder.hasRefusedTexts) {
      return false;
    }

    return true;
  }
}
