<div class="container">
    <div class="row">
        <div>
            <h3>{{site?.name}}</h3>
        </div>
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col-lg-6">
            <strong>View:</strong>
            <button type="button" pButton (click)="showTab(false)" icon="pi pi-list"></button>
            <button type="button" pButton (click)="showTab(true)" icon="pi pi-folder"></button>
        </div>
        <div class="col-lg-6">
            <div class="float-right">
                <button pButton (click)="save()" icon="pi pi-save" label="Save"></button>
                <button pButton (click)="cancel()" icon="pi pi-ban" label="Cancel"></button>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="site">
        <div class="col-lg-12">
            <div class="row">
                <div class="col">
                    <div *ngIf="!showTabFlag">
                        <p-accordion>
                            <p-accordionTab header="Required Information" [selected]="true">
                                <app-site-required [site]="site"></app-site-required>
                            </p-accordionTab>
                            <br />
                            <p-accordionTab header="Location">
                                <app-site-location [site]="site"></app-site-location>
                            </p-accordionTab>
                            <br />
                            <p-accordionTab header="Facility Information">
                                <app-site-facility [site]="site"></app-site-facility>
                            </p-accordionTab>
                            <br />
                            <p-accordionTab header="Wage Scale">
                                <app-site-wagescale [site]="site"></app-site-wagescale>
                            </p-accordionTab>
                            <br />
                            <p-accordionTab header="Badge Setup">
                                <app-site-badges [site]="site"></app-site-badges>
                            </p-accordionTab>
                            <br />
                            <p-accordionTab header="Document Categories">
                                <app-site-document-categories [site]="site"></app-site-document-categories>
                            </p-accordionTab>
                        </p-accordion>
                    </div>
                    <div *ngIf="showTabFlag">
                        <p-tabView>
                            <p-tabPanel header="Required Information">
                                <app-site-required [site]="site"></app-site-required>
                            </p-tabPanel>
                            <p-tabPanel header="Location">
                                <app-site-location [site]="site"></app-site-location>
                            </p-tabPanel>
                            <p-tabPanel header="Facility Information">
                                <app-site-facility [site]="site"></app-site-facility>
                            </p-tabPanel>
                            <p-tabPanel header="Wage Scale">
                                <app-site-wagescale [site]="site"></app-site-wagescale>
                            </p-tabPanel>
                            <p-tabPanel header="Badge Setup">
                                <app-site-badges [site]="site"></app-site-badges>
                            </p-tabPanel>
                        </p-tabView>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <st-update-block [object]='site' *ngIf="site"></st-update-block>
</div>