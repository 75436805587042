import { Component, Input, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm, FormGroup } from '@angular/forms';

import { LoggingService } from '../../services/logging.service';
import { DataService } from '../../services/data.service';

import { STTableComponent } from '../../st-table/table.component';

@Component({
    selector: 'app-badgeholder-audit',
    templateUrl: './badgeholder.audit.component.html',
    encapsulation: ViewEncapsulation.None
})
export class BadgeHolderAuditComponent implements OnInit {

    @Input() badgeHolder: any;

    pageInfo: any;
    title = 'Badge Holder Audit';

    @ViewChild(STTableComponent) stTable: STTableComponent;

    constructor(
        private route: ActivatedRoute,
        private dataService: DataService,
        private loggingService: LoggingService) {

        }

    ngOnInit(): void {
        this.dataService.getById('pages', 'badgeholderaudit')
        .subscribe((data) => {
            this.pageInfo = data.result;
            this.title = this.pageInfo.title;
//            this.loadData();
        });
    }

    loadData(): void {
        this.stTable.loadData();
    }
}
