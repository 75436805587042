<ngx-widget-grid [rows]="10" [columns]="8" [highlightNextPosition]="false"
                 [showGrid]="true" (widgetPositionChange)="onWidgetChange($event)">
                 <ngx-widget *ngFor="let widget of widgets;let $index = index;"
                 [position]="widget"
                 [movable]="true"
                 [resizable]="true"
                 [swapOnMove]="swapWidgets">
       <div style="height:100%;width:100%; display:flex;">
           <div>
            <div class="task-box task-box-3">
                <div class="task-box-header">
                    <span style="color: white">Current People On-Site</span>
    <!--                <i (click)="loadOnsiteCount()" class="material-icons">refresh</i> -->
                </div>
                <div class="task-box-content" style="text-align: center">
                    <h1><a href="/onsite">{{onSiteCount.total}}</a></h1>
                    <p></p>
                </div>
                <div class="task-box-footer">
                    <span class="task-status">{{onSiteCount.visitors}}</span> Visitors
                    <span class="task-status">{{onSiteCount.inspectors}}</span> Inspectors
                </div>
            </div>
           </div>
         </div>
     </ngx-widget>
</ngx-widget-grid>
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />