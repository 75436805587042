<div class="row" *ngIf="object && createdBy && updatedBy && (createdBy.name || object.createdDate || updatedBy.name || object.updatedDate)">
    <div class="col-12 text-center">
        <br/><br/>
        <span *ngIf="createdBy.name || object.createdDate">Created: </span> 
        <span *ngIf="object.createdDate">{{dateService.convertDateUniversalToSiteTimeDate(object.createdDate) | date:'MM/dd/yyyy @ HH:mm a'}}</span>
        <span *ngIf="createdBy.name"> by {{createdBy.name}}</span>
        <br *ngIf="(createdBy.name || object.createdDate) && (updatedBy.name || object.updatedDate)"/>
        <span *ngIf="updatedBy.name || object.updatedDate">Updated: </span> 
        <span *ngIf="object.updatedDate">{{dateService.convertDateUniversalToSiteTimeDate(object.updatedDate) | date:'MM/dd/yyyy @ HH:mm a'}}</span>
        <span *ngIf="updatedBy.name"> by {{updatedBy.name}}</span>
    </div>
</div>