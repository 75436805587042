import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { DateService } from '../services/date.service';
import { Subscription } from 'rxjs';
import { BreadCrumbService } from '../services/breadcrumb.service';
import { LoggingService } from '../services/logging.service';

@Component({
  selector: 'app-activitylog',
  templateUrl: './activitylog.component.html',
  styleUrls: ['./activitylog.component.css']
})
export class ActivityLogComponent implements OnInit {

  dt: string;
  subscription: Subscription;

  constructor(
    private dataService: DataService,
    private dateService: DateService,
    private breadcrumbService: BreadCrumbService,
    private loggingService: LoggingService,
    private router: Router) {
    this.subscription = this.dateService.getCurrentDate()
        .subscribe(date => {
          this.dt = this.dateService.formatDate(date);
        });
  }

  ngOnInit() {
    this.loggingService.logPageView('Activity Log');
    this.breadcrumbService.setItemsByCategory('Controls', 'Activity Log', this.router.url);
  }
}
