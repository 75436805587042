import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { BreadCrumbService } from '../services/breadcrumb.service';
import { DataService } from '../services/data.service';
import { ActivatedRoute } from '@angular/router';
import { LoggingService } from '../services/logging.service';
import { UserService } from '../services/user.service';
import { Subscription } from 'rxjs';


@Component({
    selector: 'app-companies',
    templateUrl: './company.component.html'
})
export class CompanyComponent implements OnInit {
    pageInfo: any;
    path: string;
    sub: any;
    id: string;
    company: any;
    isGlobalAdmin = false;

    constructor(
        private route: ActivatedRoute,
        private dataService: DataService,
        private breadcrumbService: BreadCrumbService,
        private loggingService: LoggingService,
        private router: Router,
        private userService: UserService) {
            this.isGlobalAdmin = this.userService.currentUser().isGlobalAdmin;
        }

    ngOnInit(): void {

        this.sub = this.route.params.subscribe(params => {
            this.id = params['id'];

            if (this.id === undefined) {
                this.loadData();
            } else {
                if (this.id === 'new') {
                    this.loggingService.logPageView('Company - New');
                    this.company = {};
                    this.company.id = 'new';
                } else {
                    this.loggingService.logPageView('Company - Profile');
                    this.dataService.getById('companies', this.id)
                    .subscribe((data) => {
                        this.company = data;
                    });
                }
            }
         });
    }

    loadData() {
        this.loggingService.logPageView('Companies - Grid');
        this.company = null;
        this.dataService.getById('pages', 'companies')
            .subscribe((data) => {
                this.pageInfo = data.result;
                this.breadcrumbService.setItemsByCategory('Sitetraxx Admin', this.pageInfo.title, this.router.url);
            });
    }

    createExcel(): void {
        this.dataService.postForm('companies/excel', {}, {responseType: 'arraybuffer'})
            .subscribe((data) => {
                const file = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                const fileURL = window.URL.createObjectURL(file);

                const a = document.createElement('a');
                document.body.appendChild(a);
                a.href = fileURL;
                a.download = 'companies.xlsx';
                a.click();

                window.URL.revokeObjectURL(fileURL);
                a.remove();
            });
    }
}
