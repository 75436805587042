import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AppInsights } from 'applicationinsights-js';
import { environment } from '../../../environments/environment';
import { Message } from 'primeng/api';

@Injectable()
export class LoggingService {

    notify: EventEmitter<Message> = new EventEmitter();

    private config: Microsoft.ApplicationInsights.IConfig = {
        instrumentationKey: environment.appInsights.instrumentationKey
    };

    constructor(private router: Router) {
        if (!AppInsights.config) {
            AppInsights.downloadAndSetup(this.config);
        }
    }

    logPageView(name?: string, url?: string, properties?: any,
        measurements?: any, duration?: number) {
        AppInsights.trackPageView(name, url, properties, measurements, duration);
    }

    logEvent(name: string, properties?: any, measurements?: any) {
        AppInsights.trackEvent(name, properties, measurements);
    }

    logError(error: any): void {
        AppInsights.trackException(error, this.router.url);
        if (error && error.status === 401) {
            this.router.navigate(['/login']);
        } else {
            if (error.error)
                this.errorMessage('Error', error.error);

            console.error(error, this.router.url);
        }
    }

    getErrorMessage(error: any): Message {
        return { severity: 'error', summary: 'Error', detail: error.statusText };
    }

    message(severity: string, summary: string, detail: string): void {
        this.notify.emit({ severity: severity, summary: summary, detail: detail });
    }
    successMessage(summary: string, detail: string): void {
        this.message('success', summary, detail);
    }
    infoMessage(summary: string, detail: string): void {
        this.message('info', summary, detail);
    }
    errorMessage(summary: string, detail: string): void {
        this.message('error', summary, detail);
    }

    savedMessage(detail: string = 'Record saved'): void {
        this.successMessage('Saved', detail);
    }
    deletedMessage(detail: string = 'Record deleted'): void {
        this.successMessage('Confirmed', detail);
    }
}
