<div class="task-box task-box-3">
    <div class="task-box-header">
        <span style="color: white">{{title}}</span>
<!--        <i (click)="loadCount()" class="material-icons">refresh</i> -->
    </div>
    <div class="task-box-content" style="text-align: center">
        <h1><a [href]="pageUrl">{{countItem?.dayCount}}</a></h1>
        <p>To Date: {{countItem?.totalCount}}</p>
    </div>
    <div class="task-box-footer">
        <span> </span>
    </div>
</div>