import { Component, OnInit, DoCheck } from '@angular/core';
import { DataService } from '../../services/data.service';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../../services/user.service';
import { DateService } from '../../services/date.service';
import { BreadCrumbService } from '../../services/breadcrumb.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-executive',
  templateUrl: './executive.component.html'
})
export class ExecutiveComponent implements OnInit {

  html: any;
  siteSubscription: Subscription;
  companies: any[];
  cols: any[];
  companyId: string = 'all';
  data: any[];
  section = 'Reports';
  title = 'Executive';
  user: any;
  totals: any = {
    enrolledWorkers: 0,
    activeWorkers: 0,
    averageWorkersOnsite: 0,
    workdays: 0,
    totalManHours: 0,
    adjustedHours: 0,
    plannedHours: 0,
    manDays: 0,
    hoursUnder: 0,
    hoursOver: 0,
    plannedVsAdjustedPct: 0,
    totalClockEvents: 0,
    totalAutoClocks: 0,
    numberWorkersAutoClock: 0,
    autoClockPct: 0
  };

  constructor(private dataService: DataService,
              private userService: UserService,
              private dateService: DateService,
              private breadcrumbService: BreadCrumbService,
              private router: Router) {
                this.siteSubscription = this.userService.getSiteEvent()
                  .subscribe(siteId => {
                    this.loadData();
                });
              }

  ngOnInit() {
    this.breadcrumbService.setItemsByCategory(this.section, this.title, this.router.url);
    this.loadData();
    this.cols = [
      { field: 'companyName', header: 'Company' },
      { field: 'enrolledWorkers', header: 'Enrolled Workers' },
      { field: 'activeWorkers', header: 'Active Workers' },
      { field: 'averageWorkersOnsite', header: 'Average On-Site' },
      { field: 'workdays', header: 'Work Days On-Site Per ST' },
      { field: 'totalManHours', header: 'Total Man Hours Per ST' },
      { field: 'adjustedHours', header: 'Adjusted Hours Per ST Auto Clocks' },
      { field: 'plannedHours', header: 'Planned Hours' },
      { field: 'manDays', header: 'Man Days Per ST (headcount)' },
      { field: 'hoursUnder', header: 'Hours Under' },
      { field: 'hoursOver', header: 'Hours Over' },
      { field: 'plannedVsAdjustedPct', header: 'Planned VS Adjusted Hrs' },
      { field: 'totalClockEvents', header: 'Total Clock Events' },
      { field: 'totalAutoClocks', header: 'Total Of Auto Clocks' },
      { field: 'numberWorkersAutoClock', header: 'Number Of Workers WIth An Auto Clock' },
      { field: 'autoClockPct', header: '% Of Auto Clock Events (0% Goal)' }
    ];
  }

  ngOnDestroy() {
    this.siteSubscription.unsubscribe();
  }

  onSubmitForm() {
    const reportData = {
      startDate: '20000101',
      endDate: '20000101',
      userId: this.user.id,
      companyIds: [this.companyId]
    };
    const path = 'reports/executive/json';
    this.dataService.postForm(path, reportData)
        .subscribe((data) => {
          this.data = data;
        });
  }

  loadData() {
    this.loadCompanies();
    this.user = this.userService.currentUser();
  }

  loadCompanies(): void {
    this.dataService.getAll('companies')
    .subscribe((companies) => {
        this.companies = companies;
        this.companies.unshift({value: 'all', label: 'All' });
    });
  }

  loadTotal(): void {
    this.data.forEach(item => {
      this.totals.enrolledWorkers += item.enrolledWorkers;
      this.totals.activeWorkers += item.activeWorkers;
      this.totals.averageWorkersOnsite += item.averageWorkersOnsite;
      this.totals.workdays += item.workdays;
      this.totals.totalManHours += item.totalManHours;
      this.totals.adjustedHours += item.adjustedHours;
      this.totals.plannedHours += item.plannedHours;
      this.totals.manDays += item.manDays;
      this.totals.hoursUnder += item.hoursUnder;
      this.totals.hoursOver += item.hoursOver;
      //this.totals.plannedVsAdjustedPct += item.plannedVsAdjustedPct;
      this.totals.totalClockEvents += item.totalClockEvents;
      this.totals.totalAutoClocks += item.totalAutoClocks;
      this.totals.numberWorkersAutoClock += item.numberWorkersAutoClock;
      //this.totals.autoClockPct += item.autoClockPct;
    });
    this.totals.plannedVsAdjustedPct = (this.totals.adjustedHours / this.totals.plannedHours) * 100;
    this.totals.autoClockPct = (this.totals.totalAutoClocks / this.totals.totalClockEvents) * 100;
  }

  createPDF(): void {
    const reportData = {
      startDate: '20190101',
      endDate: '20190101',
      userId: this.user.id,
      companyIds: [this.companyId]
    };
    const path = 'reports/executive/pdf';
    this.dataService.postForm(path, reportData, {responseType: 'arraybuffer'})
      .subscribe((data) => {
        const file = new Blob([data], {type: 'application/pdf'});
        const fileURL = window.URL.createObjectURL(file);

        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = fileURL;
        a.download = 'executive.pdf';
        a.click();

        window.URL.revokeObjectURL(fileURL);
        a.remove();
      });

  }
  createExcel(): void {

      const reportData = {
        startDate: '20190101',
        endDate: '20190101',
        userId: this.user.id,
        companyIds: [this.companyId]
      };
      const path = 'reports/executive/excel';
      this.dataService.postForm(path, reportData, {responseType: 'arraybuffer'})
        .subscribe((data) => {
          const file = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
          const fileURL = window.URL.createObjectURL(file);

          const a = document.createElement('a');
          document.body.appendChild(a);
          a.href = fileURL;
          a.download = 'executive.xlsx';
          a.click();

          window.URL.revokeObjectURL(fileURL);
          a.remove();
      });
  }
}
