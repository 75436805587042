import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '../services/auth.service';
import { Subject } from 'rxjs';
import { Message } from 'primeng/api';
import { LoggingService } from '../services/logging.service';
import { DataService } from '../services/data.service';

@Component({
    moduleId: 'app-login',
    templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit {
    model: any = {};
    loading = false;
    returnUrl: string;
    msgs: Message[];
    private subject = new Subject<any>();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private dataService: DataService,
        private loggingService: LoggingService
    ) { }

    ngOnInit() {
        // reset login status
        this.authService.logout();

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    login() {
        this.msgs = [];
        this.loading = true;
        this.loggingService.logPageView('Login - Attempt');
        this.authService.login(this.model)
            .subscribe(
                user => {
                    if (user && user.token) {
                        this.loggingService.logPageView('Login - Success - ' + user.username);
                        this.subject.next(user);
                        localStorage.setItem('currentUser', JSON.stringify(user));
                        this.dataService.getAll('users/features')
                            .subscribe((data) => {
                                var features = data;
                                localStorage.setItem('features', JSON.stringify(features));
                                this.router.navigate([this.returnUrl]);
                        });
                    } else {
                        this.msgs = [{severity: 'error', summary: 'Error', detail: user.message}];
                    }
                },
                error => this.msgs = [this.loggingService.getErrorMessage(error)]);
    }
}
