<div class="row">
    <div class="col-lg-8">
        <label class="control-label">Site:</label>
        <p-dropdown [options]="sites" [filter]="true" [(ngModel)]="siteId" (onChange)="siteChanged()"></p-dropdown>
        <label class="control-label">Badge Template:</label>
        <p-dropdown [options]="badgeTemplates" [filter]="true" [(ngModel)]="badgeTemplateId" [disabled]="!siteId"
            (onChange)="badgeTemplateChanged()"></p-dropdown>
        <button pButton (click)="badgeTemplateId = undefined" [disabled]="!badgeTemplateId" icon="pi pi-print"
            label="Clone Badge Template"></button>
    </div>
    <div class="col-lg-4">
        <button pButton (click)="showBadge()" [disabled]="!siteId" icon="pi pi-print" label="Print Badge"></button>
    </div>
</div>
<div class="row">
    <div class="col-lg-8">
        <div class="row">
            <div class="col-lg-10">
                <textarea pTextArea [(ngModel)]="badgeTemplateHTML" [disabled]="!siteId" [rows]="28"
                    class="form-control" (ngModelChange)="setupDisplayHTML()"></textarea>
            </div>
            <div class="col-lg-2">
                <strong>Fields:</strong><br />
                <i><strong>NOTE: These are case sensitive</strong></i><br />
                siteLogo<br />
                siteName<br />
                badgeHolderPhoto<br />
                qrcodedata<br />
                stLogo<br />
                stLogoLarge<br />
                FirstName<br />
                LastName<br />
                CompanyName<br />
                BadgeNum<br />
                badgeExpireOneYear<br />
                EmergencyContact<br />
                EmergencyPhone<br />
                gcLogo<br />
                contact<br />
                securityPhone<br />
                securityEmail<br />
                LaborClassDesc<br />
                SiteSpecificId<br />
                EmployeeId<br />
                Certifications<br />
                Vaccinated<br />
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8">
                <input type="text" [(ngModel)]="badgeTemplateTitle" name="badgeTemplateTitle"
                    [disabled]="!siteId || badgeTemplateTitle === '>>THIS SITE ONLY'" maxlength="25"
                    class="form-control" required />
            </div>
            <div class="col-lg-4">
                <button pButton (click)="save()"
                    [disabled]="(badgeTemplateHTML === orginalBadgeTemplateHTML && badgeTemplateTitle === orginalBadgeTemplateTitle) || (!badgeTemplateId && badgeTemplateTitle === orginalBadgeTemplateTitle)"
                    icon="pi pi-print" label="Save Badge Template"></button>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8">
                <label class="control-label">Profile Photo Width:</label>
                <input type="number" [(ngModel)]="badgeTemplateWidth" name="badgeTemplateWidth" [disabled]="!siteId"
                    required />
                <label class="control-label">Height:</label>
                <input type="number" [(ngModel)]="badgeTemplateHeight" name="badgeTemplateHeight" [disabled]="!siteId"
                    required />
            </div>
        </div>
    </div>
    <div class="col-lg-4">
        <div [innerHTML]="displayHTML"></div>
    </div>
</div>

<p-toast position="top-right"></p-toast>