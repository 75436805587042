<div id="printArea">
    <div class="container">
        <div class="row">
            <div class="col-lg-11">
                <h3>Daily Report</h3>
                <h6>Report Date: {{ reportDate }}</h6>
            </div>
            <div class="col-lg-1">
                <img alt="logo float-right" src="../../../assets/images/sitetraxx-icon.png" class="mobile-logo" />
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="ui-g dashboard">
                    <div class="ui-g-12 ui-lg-4">
                        <st-card *ngIf="onSiteCount" [data]="onSiteCount"></st-card>
                    </div>
                    <div class="ui-g-12 ui-lg-4">
                        <st-card *ngIf="companyCount" [data]="companyCount"></st-card>
                    </div>
                    <div class="ui-g-12 ui-lg-4">
                        <st-card *ngIf="weatherMinMax" [data]="weatherMinMax"></st-card>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <st-company-matrix [filter]="filter"></st-company-matrix>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <app-st-areachart *ngIf="manpowerChartData" [chartData]="manpowerChartData"></app-st-areachart>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                Run Date: {{ datetime }}
            </div>
            <div class="col-lg-6">
                Copyright <i class="pi pi-copyright"></i> SiteTraxx Inc.
            </div>
        </div>
    </div>
</div>