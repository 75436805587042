import { Component, OnInit, DoCheck } from '@angular/core';
import { DataService } from '../../services/data.service';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../../services/user.service';
import { DateService } from '../../services/date.service';
import { BreadCrumbService } from '../../services/breadcrumb.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-expiration-report',
  templateUrl: './expiration.component.html',
  styleUrls: ['./expiration.component.css']
})
export class DocumentExpirationReportComponent implements OnInit {

  html: any;
  startDate: Date;
  endDate: Date;
  startDateStr: string;
  endDateStr: string;
  showStartDate: boolean = false;
  showEndDate: boolean = false;
  companies: any[];
  siteSubscription: Subscription;
  cols: any[];
  companyId: string = 'all';
  data: any[];
  section = 'Reports';
  title = 'Document Expiration Report';
  user: any;
  dangerDate: Date;
  warnDate: Date;
  closeDate: Date;


  constructor(private dataService: DataService,
    private userService: UserService,
    private dateService: DateService,
    private breadcrumbService: BreadCrumbService,
    private router: Router) {
    this.siteSubscription = this.userService.getSiteEvent()
      .subscribe(siteId => {
        this.loadData();
      });
  }

  ngOnInit() {
    this.breadcrumbService.setItemsByCategory(this.section, this.title, this.router.url);
    this.startDateStr = this.dateService.formatDate(new Date());
    this.endDateStr = this.dateService.formatDate(new Date());
    this.loadData();
    this.cols = [
      { field: 'companyName', header: 'Company', sortable: true },
      { field: 'badgeNum', header: 'Badge #', sortable: true },
      { field: 'firstName', header: 'First Name', sortable: true },
      { field: 'lastName', header: 'Last Name', sortable: true },
      { field: 'documentName', header: 'Document Name', sortable: true },
      { field: 'documentCategory', header: 'Document Type', sortable: true },
      { field: 'expirationDate', header: 'Expiration Date', sortable: true }
    ];
  }

  ngOnDestroy() {
    this.siteSubscription.unsubscribe();
  }

  onSubmitForm() {

    this.dangerDate = new Date();
    this.dangerDate.setDate(this.dangerDate.getDate() + 30);
    this.warnDate = new Date();
    this.warnDate.setDate(this.warnDate.getDate() + 60);
    this.closeDate = new Date();
    this.closeDate.setDate(this.closeDate.getDate() + 90);

    const reportData = {
      startDate: this.startDateStr,
      endDate: this.startDateStr,
      userId: this.user.id,
      companyIds: [this.companyId]
    };
    const path = 'reports/expiration/json';
    this.dataService.postForm(path, reportData)
      .subscribe((data) => {
        this.data = data;
      });
  }
  dateClass(dt: string) {
    if (new Date(dt) <= this.dangerDate)
      return 'text-danger';
    if (new Date(dt) <= this.warnDate)
      return 'text-warn';
    if (new Date(dt) <= this.closeDate)
      return 'text-close';
    return undefined;
  }

  loadData() {
    this.loadCompanies();
    this.user = this.userService.currentUser();
  }

  loadCompanies(): void {
    this.dataService.getAll('companies')
      .subscribe((companies) => {
        this.companies = companies;
        this.companies.unshift({ value: 'all', label: 'All' });
      });
  }

  onStartDateChange() {
    this.startDateStr = this.dateService.formatDate(new Date(this.startDate));
  }

  onEndDateChange() {
    this.endDateStr = this.dateService.formatDate(new Date(this.endDate));
  }


  createPDF(): void {
    const reportData = {
      startDate: this.startDateStr,
      endDate: this.endDateStr,
      userId: this.user.id,
      companyIds: [this.companyId]
    };
    const path = 'reports/expiration/pdf';
    this.dataService.postForm(path, reportData, { responseType: 'arraybuffer' })
      .subscribe((data) => {
        const file = new Blob([data], { type: 'application/pdf' });
        const fileURL = window.URL.createObjectURL(file);

        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = fileURL;
        a.download = 'expiration.pdf';
        a.click();

        window.URL.revokeObjectURL(fileURL);
        a.remove();
      });

  }

  createExcel(): void {

    const reportData = {
      startDate: this.startDateStr,
      endDate: this.endDateStr,
      userId: this.user.id,
      companyIds: [this.companyId]
    };
    const path = 'reports/expiration/excel';
    this.dataService.postForm(path, reportData, { responseType: 'arraybuffer' })
      .subscribe((data) => {
        const file = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileURL = window.URL.createObjectURL(file);

        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = fileURL;
        a.download = 'expiration.xlsx';
        a.click();

        window.URL.revokeObjectURL(fileURL);
        a.remove();
      });
  }
}
