

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import { UserService } from './user.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { UUID } from 'angular2-uuid';
//import { ResponseContentType } from '@angular/http';

@Injectable()
export class DataService {
    env: any = environment;

    public timeZones = [
        { label: "Central Standard Time", value: "CST" },
        { label: "Eastern Standard Time", value: "EST" },
        { label: "Arizona Time", value: "AZT" },
        { label: "Mountain Standard Time", value: "MST" },
        { label: "Pacific Standard Time", value: "PST" },
    ];


    constructor(private http: HttpClient, private userService: UserService) {
    }

    public getAll<T>(_table: string) {
        const _url = this.env.baseAPIUrl + _table;
        return this.http.get<any[]>(_url).pipe(share());
    }

    public getText(_path: string) {
        const _url = this.env.baseAPIUrl + _path;
        return this.http.get(_url, { responseType: 'text' }).pipe(share());
    }

    public getPDF(_path: string) {
        const _url = this.env.baseAPIUrl + _path;
        //        return this.http.get(_url, { responseType: ResponseContentType.ArrayBuffer}).pipe(share());

        return this.http.get(_url, { responseType: 'arraybuffer' }).pipe(share());
    }

    public getById<T>(_table: string, _id: string, options: any = null) {
        const _url = this.env.baseAPIUrl + _table + '/' + _id;
        if (options) {
            return this.http.get<any>(_url, options).pipe(share());
        }
        return this.http.get<any>(_url).pipe(share());
    }

    public getCompare<T>(_table: string, _id: string, options: any = null) {
        const _url = "https://kvaly3k1bl.execute-api.us-east-2.amazonaws.com/test/worker?id=" + _id;
        if (options) {
            return this.http.get<any>(_url, options).pipe(share());
        }
        return this.http.get<any>(_url).pipe(share());
    }

    public deleteById<T>(_table: string, _id: string) {
        const _url = this.env.baseAPIUrl + _table + '/' + _id;
        return this.http.delete<any>(_url).pipe(share());
    }

    public addAll<T>(_table: string, data: any) {
        const _url = this.env.baseAPIUrl + _table;
        return this.http.post<any>(_url, data).pipe(share());
    }

    public save<T>(_table: string, data: any, createId: boolean = true) {
        if (data.id === 'new' || data.id === undefined) {
            if(createId) {
              data.id = UUID.UUID();
            }
            const _url = this.env.baseAPIUrl + _table;
            return this.http.post<any>(_url, data).pipe(share());
        } else {
            const _url = this.env.baseAPIUrl + _table + '/' + data.id;
            return this.http.put<any>(_url, data).pipe(share());
        }
    }

    //'Content-Type,Authorization,X-Amz-Date,X-Api-Key,X-Amz-Security-Token,X-Sitetraxx-Userid,X-Sitetraxx-Siteid'

    public postForm<T>(_path: string, data: any, options: any = null) {
        const _url = this.env.baseAPIUrl + _path;
        if (options) {
            return this.http.post<any>(_url, data, options).pipe(share());
        }
        return this.http.post<any>(_url, data).pipe(share());
    }
}
