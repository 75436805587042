<div class="row">
    <div class="col-lg-6">
        <label class="control-label">Site Name:</label>
        <input name="name" type="text" class="form-control" pInputText [(ngModel)]="site.name" [ngModelOptions]="{standalone: true}"  maxlength="100"/>
    </div>
    <div class="col-lg-6">
        <label class="control-label">Prime Delivery Method:</label><br />
        <p-dropdown name="primeDeliveryMethodId" [options]="deliveryMethods" [filter]="true" [(ngModel)]="site.primeDeliveryMethodId"></p-dropdown>
    </div>
</div>
<div class="row">
    <div class="col-lg-6">
        <label class="control-label">Type of Construction:</label>
        <div class="form-check-inline">
            <p-radioButton name="typeOfContruction" value="R" [(ngModel)]="site.typeOfConstruction" label="Renovation"></p-radioButton>
        </div>
        <div class="form-check-inline">
            <p-radioButton name="typeOfContruction" value="N" [(ngModel)]="site.typeOfConstruction" label="New Construction"></p-radioButton>
        </div>
    </div>
    <div class="col-lg-6">
        <label class="control-label">Estimated Contract Value ($):</label>
        <input type="number" class="form-control" pInputText name="estimatedValue" [(ngModel)]="site.estimatedValue" />
    </div>
</div>
<div class="row">
    <div class="col-lg-6">
        <label class="control-label">Demolition Required:</label>
        <div class="form-check-inline">
            <p-radioButton name="demolitionRequired" value="1" [(ngModel)]="site.demolitionRequired" label="Yes"></p-radioButton>
        </div>
        <div class="form-check-inline">
            <p-radioButton name="demolitionRequired" value="0" [(ngModel)]="site.demolitionRequired" label="No"></p-radioButton>
        </div>
    </div>
    <div class="col-lg-6">
        <label class="control-label">Final Contract Value ($):</label>
        <input type="number" class="form-control" pInputText name="finalValue" [(ngModel)]="site.finalValue" />
    </div>
</div>
<div class="row">
    <div class="col-lg-6">
        <label class="control-label">P&amp;P Bond Required:</label>
        <div class="form-check-inline">
            <p-radioButton name="bondRequired" value="1" [(ngModel)]="site.bondRequired" label="Yes"></p-radioButton>
        </div>
        <div class="form-check-inline">
            <p-radioButton name="bondRequired" value="0" [(ngModel)]="site.bondRequired" label="No"></p-radioButton>
        </div>
    </div>
    <div class="col-lg-6">
        <label class="control-label">Forecast Day of Week:</label><br />
        <p-dropdown name="forecastDayOfWeek" [options]="daysOfWeek" [filter]="true" [(ngModel)]="site.forecastDayOfWeek"></p-dropdown>
    </div>
</div>
<div class="row">
    <div class="col-lg-6">
        <label class="control-label">Site SMS Number:</label>
        <input name="smsNumber" type="text" class="form-control" pInputText [(ngModel)]="site.smsNumber" [ngModelOptions]="{standalone: true}"  maxlength="100"/>
    </div>
    <div class="col-lg-6">
        <label class="control-label">Site Job Id:</label>
        <input name="siteJobId" type="text" class="form-control" pInputText [(ngModel)]="site.siteJobId" maxlength="50" />
    </div>
</div>
<div class="row">
    <div class="col-lg-6">
        <label class="control-label">Start Date:</label>
        <p-calendar [(ngModel)]="startDate" (onSelect)="onStartDateChange()" (onInput)="onStartDateChange()" [showIcon]="true"></p-calendar>
    </div>
    <div class="col-lg-6">
        <label class="control-label">End Date:</label>
        <p-calendar [(ngModel)]="endDate" (onSelect)="onEndDateChange()" (onInput)="onEndDateChange()" [showIcon]="true"></p-calendar>
    </div>
</div>
