import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { BreadCrumbService } from '../../services/breadcrumb.service';
import { LoggingService } from '../../services/logging.service';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-questions',
  templateUrl: './question.component.html',
  providers: [ ConfirmationService ],
  styleUrls: ['./question.component.css']
})
export class QuestionComponent implements OnInit {
  @Input() survey: any;

  pageInfo: any;
  path: string;
  sub: any;
  id: string;
  questions: any[];
  questionId: string;
  questionName: string;
  desiredResponse = 0;
  desiredResponseString: string;
  questionTypeString: string;
  filterInfo = '';
  showAddDialog = false;
  desiredResponseOptionsYesNo = [
    {"label":"Yes","value":"1","data":"1"},
    {"label":"No","value":"0","data":"0"}];
  desiredResponseOptionsMultiple = [
      {"label":"1","value":"1","data":"1"},
      {"label":"2","value":"2","data":"2"},
      {"label":"3","value":"3","data":"3"},
      {"label":"4","value":"4","data":"4"},
      {"label":"5","value":"5","data":"5"}];
  desiredResponseOptions = [];

  questionTypes = [
    {"label":"Yes/No","value":"yeano"},
    {"label":"Multiple","value":"multiple"}];

  constructor(
    private dataService: DataService,
    private confirmService: ConfirmationService,
    private loggingService: LoggingService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.questions = [];
    this.filterInfo = this.survey.id;
    this.loadData();
  }

  loadData() {
    this.loggingService.logPageView('Question - Grid');
    this.questions = [];

    this.dataService.getById('surveys/questions', this.survey.id)
      .subscribe((data) => {
        this.questions = data;
      });
  }

  add() {
    this.questionId = 'new';
    this.questionName = '';
    this.showAddDialog = true;
    this.desiredResponse = 0;
    this.desiredResponseString = "0";
    this.questionTypeString = "yesno";
    this.typeChange();
  }

  edit(question: any) {
    this.desiredResponseOptions = this.desiredResponseOptionsYesNo;
    this.questionId = question.id;
    this.questionName = question.name;
    this.showAddDialog = true;
    this.desiredResponseString = question.desiredResponse.toString();
    this.questionTypeString = question.questionType;
    this.typeChange();
  }

  delete(question: any) {
    this.confirmService.confirm({
        message: 'Do you want to delete ' + question.name + '?',
        accept: () => {
            this.dataService.deleteById('surveys/questions', question.id)
                .subscribe((data) => {
                    this.loadData();
                    this.messageService.add({severity: 'success', summary: 'Confirmed', detail: 'Record deleted'});
                  });
        },
        reject: () => {
          this.messageService.add({severity: 'info', summary: 'Cancelled', detail: 'You have cancelled the request'});
        }
    });
  }

  saveQuestion() {
    this.desiredResponse = parseInt(this.desiredResponseString);

    var question = { 
        id: this.questionId, 
        name: this.questionName, 
        surveyId: this.survey.id, 
        questionType: this.questionTypeString,
        desiredResponse: this.desiredResponse 
    };

    this.dataService.save('surveys/questions', question)
        .subscribe((data) => {
            this.loggingService.savedMessage();
            this.showAddDialog = false;
            this.loadData();
        });
  }

  typeChange() {
    if (this.questionTypeString === "yesno") {
      this.desiredResponseOptions = this.desiredResponseOptionsYesNo;
    } else {
      this.desiredResponseOptions = this.desiredResponseOptionsMultiple;
    }
  }

  cancelQuestion() {
    this.showAddDialog = false;
  }
}