import { Component, OnInit, Input } from '@angular/core';
import { DateService } from '../../services/date.service';
import { DataService } from '../../services/data.service';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-st-areachart',
    templateUrl: './areachart.component.html'
})
export class STAreaChartComponent implements OnInit {
    @Input() chartData: any[];

    chartOptions: any;

    constructor(private dateService: DateService,
                private dataService: DataService) {
        this.loadChartOptions();
    }

    ngOnInit(): void {

    }

    loadChartOptions(): void {
        this.chartOptions = {
          view: [1000, 500],
          showXAxis: true,
          showYAxis: true,
          gradient: true,
          showLegend: true,
          showXAxisLabel: true,
          xAxisLabel: 'Hours',
          showYAxisLabel: true,
          yAxisLabel: 'FTE',
          autoScale: true,
          colorScheme: {
            domain: ['#f98504', '#15216a', '#C7B42C', '#AAAAAA']
          }
        };
      }
}
