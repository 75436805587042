import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Message } from 'primeng/api';
import { LoggingService } from '../../services/logging.service';
import { DataService } from '../../services/data.service';
import { UserService } from '../../services/user.service';
import { SharedModule, ConfirmationService} from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { UUID } from 'angular2-uuid';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-site-wagescale',
    templateUrl: './site.wagescale.component.html',
    providers: [ ConfirmationService ],
    encapsulation: ViewEncapsulation.None
})
export class SiteWageScaleComponent implements OnInit {
@Input() site: any;

env: any = environment;

laborClasses: any[];
cols: any[];
selectedLaborClass: any = {};
laborClassDefaults: any[];
defaultCompanyCategories: any[];
defaultCSIDivisions: any[];
defaultTrades: any[];
pageInfo: any;
showAddDialog: boolean;
showEditDialog: boolean;
selectedLabor = [];
filter: any = { companyCode: '', divisionCode: '', tradeCode: '', laborName: '' };
laborTypes = [];
siteSubscription: Subscription;

    constructor(private dataService: DataService,
                private userService: UserService,
                private confirmService: ConfirmationService,
                private http: HttpClient,
                private loggingService: LoggingService) {
                    this.siteSubscription = this.userService.getSiteEvent()
                        .subscribe(siteId => {
                            this.loadLaborClasses();
                    });
                }

    ngOnInit() {
        this.showAddDialog = false;
        this.loadLaborClasses();

        this.cols = [
            { field: 'divisionName', header: 'Division' },
            { field: 'tradeName', header: 'Trade' },
            { field: 'name', header: 'Description' },
            { field: 'laborType', header: 'Type' },
            { field: 'baseRate', header: 'Labor Wage' }
        ];

        this.laborTypes.push({label: 'Consultant', value: 'Consultant' });
        this.laborTypes.push({label: 'Delivery', value: 'Delivery' });
        this.laborTypes.push({label: 'Inspector', value: 'Inspector' });
        this.laborTypes.push({label: 'Laborer', value: 'Laborer' });
        this.laborTypes.push({label: 'Other', value: 'Other' });
        this.laborTypes.push({label: 'Safety', value: 'Safety' });
        this.laborTypes.push({label: 'Skilled', value: 'Skilled' });
        this.laborTypes.push({label: 'Supervisor', value: 'Supervisor' });
     }

     loadLaborClasses(): void {
        this.dataService.getAll('laborclass')
            .subscribe((data) => {
                this.laborClasses = data;
            });
     }

     edit(laborClass: any): void {
        this.selectedLaborClass = laborClass;
        this.showEditDialog = true;
     }

     cancelLabor(): void {
        this.showEditDialog = false;
     }

     delete(laborclass: any): void {
        this.confirmService.confirm({
            message: 'Do you want to delete ' + laborclass.name + '?',
            accept: () => {
                this.dataService.deleteById('laborclass', laborclass.id)
                    .subscribe((data) => {
                        this.loadLaborClasses();
                    });
            },
            reject: () => {
            }
        });
     }

     addNew(): void {
        this.showAddDialog = true;
        this.loadDefaultLaborClasses();
        this.loadCompanyCategories();
     }

    saveLabor(): void {
        this.showEditDialog = false;
        this.dataService.save('laborclass', this.selectedLaborClass)
            .subscribe((data) => {
            });
    }

    loadDefaultLaborClasses(): void {
        this.postData('laborclass/default', this.filter)
            .subscribe((data) => {
                this.laborClassDefaults = data;
            });
    }

    postData<T>(_table: string, data: any): any {
        const _url = this.env.baseAPIUrl + _table;
        return this.http.post<any>(_url, data);
    }

    loadCompanyCategories(): void {
        this.dataService.getAll('lookups/defaultcompanycategories')
            .subscribe((data) => {
                this.defaultCompanyCategories = data;
            });
    }

    loadCSIDivisions(): void {
        this.loadDefaultLaborClasses();
        this.dataService.getById('lookups/defaultcsidivisions', this.filter.companyCode)
            .subscribe((data) => {
                this.defaultCSIDivisions = data;
            });
    }

    loadTrades(): void {
        this.loadDefaultLaborClasses();
        this.dataService.getById('lookups/defaulttrades', this.filter.divisionCode)
            .subscribe((data) => {
                this.defaultTrades = data;
            });
    }

    filterLabor(): void {
        this.loadDefaultLaborClasses();
    }

    addLabor(): void {
        this.showAddDialog = false;
        this.postData('laborclass/list', this.selectedLabor)
            .subscribe((data) => {
                this.loadLaborClasses();
            });
    }

     cancel(): void {
         this.showAddDialog = false;
         this.selectedLabor = [];
         this.filter = { companyCode: '', divisionCode: '', tradeCode: '', laborName: '' };
     }

    selected(labor: any): void {
        const user = this.userService.currentUser();
        labor.siteId = user.siteId;
        labor.laborClassDefaultId = labor.id;
        labor.id = UUID.UUID();
        this.selectedLabor.push(labor);
    }
}
