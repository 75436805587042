import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Message } from 'primeng/api';
import { LoggingService } from '../../services/logging.service';
import { DataService } from '../../services/data.service';
import { UserService } from '../../services/user.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-site-facility',
    templateUrl: './site.facility.component.html',
    encapsulation: ViewEncapsulation.None
})
export class SiteFacilityComponent implements OnInit {
@Input() site: any;

types: any[];
facilityTypes: any[];
siteSubscription: Subscription;

    constructor(private dataService: DataService, 
        private userService: UserService,
        private loggingService: LoggingService) {
            this.siteSubscription = this.userService.getSiteEvent()
                .subscribe(siteId => {
                    this.site.id = siteId;
                    this.loadTypes();
                    this.loadFacilityTypes();
                });
        }

    ngOnInit() {
        this.loadTypes();
        this.loadFacilityTypes();
    }

    ngOnDestroy() {
        this.siteSubscription.unsubscribe();
    }

    loadTypes(): void {
        this.types = [
            { label: 'Acre', value: 'Acre' },
            { label: 'Beds', value: 'Beds' },
            { label: 'Calendar Days', value: 'Calendar Days' },
            { label: 'Cubic Yards', value: 'Cubic Yards' },
            { label: 'Linear Feet', value: 'Linear Feet' },
            { label: 'Man-Hours', value: 'Man-Hours' },
            { label: 'Parking Stalls', value: 'Parking Stalls' },
            { label: 'Rooms', value: 'Rooms' },
            { label: 'Seats', value: 'Seats' },
            { label: 'Units', value: 'Units' }
        ];
    }

    loadFacilityTypes(): void {
        this.dataService.getById('lookups', 'facilitytypes')
        .subscribe((data) => {
            this.facilityTypes = data;
        });
    }

}
