import { Component, OnInit, ViewEncapsulation, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Table } from 'primeng/table';
// import { FilterUtils } from "primeng/utils";

import { BreadCrumbService } from '../services/breadcrumb.service';
import { DataService } from '../services/data.service';
import { Subscription } from 'rxjs';
import { DateService } from '../services/date.service';
import { environment } from '../../../environments/environment';
import { LoggingService } from '../services/logging.service';
import { UserService } from '../services/user.service';

@Component({
    selector: 'app-onsite',
    templateUrl: './onsite.component.html',
    encapsulation: ViewEncapsulation.None
})
export class OnsiteComponent implements OnInit {

    @ViewChild('dt', { static: true }) private _table: Table;
    private datePipe = new DatePipe('en-US');

    pageInfo: any;
    path: string;
    id: string;
    private sub: any;
    onsiteList: any[];
    onsiteInfo: any;
    showTabFlag: boolean;
    years: any[];
    subscription: Subscription;
    siteSubscription: Subscription;
    showDialog = false;
    clockInFace: boolean;
    clockOutFace: boolean;
    profileFace: boolean;
    clockInCompare: any;
    clockOutCompare: any;
    env: any = environment;
    cols: any[];
    _selectedColumns: any[];
    profilePhoto: any;
    filterCount: number = 0;
    page: number = 1;
    pageSize: number = 100;
    pageSizeOptions: number[] = [100,250,500,1000];
    totalRecords: number;  
    startDate: Date;
    endDate: Date;
    startDateStr: string;
    endDateStr: string;
    companies: any[];
    user: any;
    filtersChanged: boolean = false;
    companyId: string = 'all';
    searchSeconds: number;

    globalFilter: string;
    listType = '0';
    listTypes: any = [
        { label: 'Show All workers', value: '0' },
        { label: 'Only workers still clocked in and onsite', value: '1' },
        { label: 'Only workers with an auto clock or manual edit', value: '2' },
        { label: 'Only workers that have clocked out for the day', value: '3' },
        { label: 'Terminated or Suspended workers that clocked in', value: '4' },
    ];

    reportData: any = {
        startDate: '20000101',
        endDate: '20000101',
        userId: '',
        companyIds: [],
        page: this.page,
        pageSize: this.pageSize,
        filters: []
      };

    constructor(
        private route: ActivatedRoute,
        private dataService: DataService,
        private breadcrumbService: BreadCrumbService,
        private dateService: DateService,
        private userService: UserService,
        private loggingService: LoggingService,
        private router: Router) {
        this.subscription = this.dateService.getCurrentDate()
            .subscribe(date => {
//                this.startDateStr = this.dateService.formatDate(date);
                this.loadOnsiteData();
            });

        this.siteSubscription = this.userService.getSiteEvent()
            .subscribe(siteId => {
                this.loadOnsiteData();
            });
    }

    ngOnInit(): void {
        this.user = this.userService.currentUser();
        this.breadcrumbService.setItems([
            { label: 'Controls' },
            { label: 'On-Site', routerLink: [this.router.url] }
        ]);
        this.cols = [
            { field: 'badgeNum', header: 'B#' },
            { field: 'profilePhotoURL', header: 'Profile' },
            { field: 'badgeHolderName', header: 'Name' },
            { field: 'companyName', header: 'Company' },
            { field: 'projectName', header: 'Project' },
            { field: 'siteSpecificId', header: 'Site Specific Id' },
            { field: 'contractName', header: 'Contract' },
            { field: 'laborClassName', header: 'Classification' },
            { field: 'clockInPhotoURL', header: 'Photo In' },
            { field: 'clockIn', header: 'In' },
            { field: 'terminalNameIn', header: 'Terminal In' },
            { field: 'clockOutPhotoURL', header: 'Photo Out' },
            { field: 'clockOut', header: 'Out' },
            { field: 'terminalNameOut', header: 'Terminal Out' },
            { field: 'totalCalc', header: 'Total Hours' },
            { field: 'injury', header: 'Injury' },
            { field: 'surveyResponse', header: 'Survey Response' }
        ];

        this._selectedColumns = this.cols;

        this.showTabFlag = false;
//        if (this.startDateStr === undefined) {
//            this.startDateStr = this.dateService.formatDate(new Date);
//        }

        this.sub = this.route.params.subscribe(params => {
            this.id = params['id'];

            if (this.id === undefined) {

            } else {
                this.loggingService.logPageView('Onsite - Profile');
                this.dataService.getById('onsite', this.id)
                    .subscribe((data) => {
                        this.onsiteList = data;
                    },
                    (error) => { console.error(error) },
                    () => {
                        setTimeout(() => {
                            this.loadPhotos();
                          }, 3000);
                    });
            }
        });
    }

    loadCompanies(): void {
        this.dataService.getAll('companies')
            .subscribe((companies) => {
              this.companies = companies;
              this.companies.unshift({value: 'all', label: 'All' });
        });
      }

    loadPhotos() {
        for (const row of this.onsiteList) {
            this.loadPhoto(row['badgeHolderId'], row['clockId'], 'profile', 'profile');
            this.loadPhoto(row['clockId'], row['clockId'], 'clockin', 'clockin/' + row['clockInPhotoURL']);
            this.loadPhoto(row['exitId'], row['exitId'], 'clockout', 'clockout/' + row['clockOutPhotoURL']);
        }    
    }

    loadPhoto(id: string, imageId: string, type: string, path: string) {
        if (imageId !== null) {
            const imgId = type + '-' + imageId;
            const img = window.document.getElementById(imgId);
            img.style.display = 'none';

            this.dataService.getById('files/download/' + path, id, { responseType: 'arraybuffer' })
            .subscribe((photo) => {
                const file = new Blob([photo], { type: 'image/jpeg' });
                const fileURL = window.URL.createObjectURL(file);

                img.setAttribute('src', fileURL);
                img.style.display = '';
//              setTimeout(() => window.URL.revokeObjectURL(fileURL), 1000);
            });
        }
    }

    @Input() get selectedColumns(): any[] {
        return this._selectedColumns;
    }

    set selectedColumns(val: any[]) {
        this._selectedColumns = this.cols.filter(col => val.includes(col));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.siteSubscription.unsubscribe();
    }

    pageChanged(event: any) {
        this.pageSize = event.rows;
        this.reportData.pageSize = event.rows;
    
        this.page = (event.first / event.rows) + 1;
        this.reportData.page = this.page;
    
        this.onSubmitForm();
      }

      onSubmitForm() {
        this.onsiteList = [];
        this.user = this.userService.currentUser();
        this.reportData.userId = this.user.id;
        this.reportData.companyIds = [this.companyId];
        this.reportData.startDate = this.dateService.formatDate(new Date(localStorage.getItem('currentDate')));
        
        if (this.filtersChanged) {
          this.page = 1;
          this.filtersChanged = false;
        }
        this.reportData.page = this.page;
    
        if (this.reportData.pageSize === 0) {
          this.reportData.pageSize = this.pageSize;
        }

        if (this.globalFilter !== undefined && this.globalFilter !== '') {
            var foundIndex = this.reportData.filters.findIndex(x => x.field == "GlobalFilter");
            if (foundIndex !== -1) {
                this.reportData.filters[foundIndex] = { field: 'GlobalFilter', value: this.globalFilter };
            } else {
                var globalFilter = { field: 'GlobalFilter', value: this.globalFilter };
                this.reportData.filters.push(globalFilter);    
            }
        }

        var foundListIndex = this.reportData.filters.findIndex(x => x.field == "ListFilter");
        if (foundListIndex !== -1) {
            this.reportData.filters[foundListIndex] = { field: 'ListFilter', value: this.listType };
        } else {
            var listFilter = { field: 'ListFilter', value: this.listType };
            this.reportData.filters.push(listFilter);    
        }

        const path = 'onsite/v2';
        const starttime = performance.now();
        this.dataService.postForm(path, this.reportData)
            .subscribe((resp) => {
              this.totalRecords = resp.totalRecords;
              this.onsiteList = resp.data;

              if ((this.totalRecords != this.onsiteList.length) &&
                  (this.pageSize != this.onsiteList.length)) {
                    this.filterCount = this.onsiteList.length;
                    this.totalRecords = this.filterCount;
                    this.pageSize = this.filterCount;
              } else {
                this.filterCount = 0;
              }
    
              const endtime = performance.now();
    
              if (starttime && endtime) {
                this.searchSeconds = (endtime - starttime) / 1000.0;
              }

              setTimeout(() => {
                this.loadPhotos();
              }, 3000);
            });
      }

    public onFilter(event): void {
        const filter = ((event.filters.global !== undefined) ? event.filters.global.value : '');
        const filtered = [];
        for (const row of this.onsiteList) {
            let globalFilterFound = (filter === '');
            let allFiltersOK = true;
            for (const column of this.cols) {
                if (column.type !== 'image' && row[column.field] != undefined && row[column.field] != null) {
                    let format = null;
                    if (column.field === 'clockIn' || column.field === 'clockOut') {
                        format = 'MM/dd/yyyy HH:mm a';
                    }

                    let value = row[column.field];
                    if (column.field === 'surveyResponse')
                        value = (value ? ((value === 'PROCESSED') ? 'Pass' : 'Fail') : '');
                    else if (format !== null) {
                        value = this.datePipe.transform(value, format);
                    }

                    if (filter !== '' && value !== undefined && value !== null && String(value).toLowerCase().includes(filter.toLowerCase())) {
                        globalFilterFound = true;
                    }
                    if (this._table.filters[column.field] !== undefined && (value === undefined && value === null || !String(value).toLowerCase().includes(this._table.filters[column.field].value.toLowerCase()))) {
                        allFiltersOK = false;
                    }
                }
            }
            if (globalFilterFound && allFiltersOK &&
                (this.listType === '0' ||
                    (this.listType === '1' && row['clockOut'] == null) ||
                    (this.listType === '2' && row['status'] != null && (row['status'] === 'M' || row['status'] === 'X')) ||
                    (this.listType === '3' && row['clockOut'] != null))) {
                filtered.push(row);
            }
        }
        this._table.filteredValue = filtered;
    }

    exportToCSV(): void {
        let csv = '';
        for (const column of this.cols) {
            if (column.field !== 'profilePhotoURL' && column.field !== 'clockInPhotoURL' && column.field !== 'clockOutPhotoURL') {
                if (csv !== '') {
                    csv += ',';
                }
                csv += column.header;
            }
        }
        csv = csv + '\n';


        const rows = ((this._table.filteredValue !== undefined && this._table.filteredValue !== null) ? this._table.filteredValue : this.onsiteList);
        for (const row of rows) {
            let csvRow = '';
            for (const column of this.cols) {
                if (column.field !== 'profilePhotoURL' && column.field !== 'clockInPhotoURL' && column.field !== 'clockOutPhotoURL') {
                    if (csvRow !== '') {
                        csvRow += ',';
                    }
                    if (column.field === 'surveyResponse')
                        csvRow += (row[column.field] ? ((row[column.field] === 'PROCESSED') ? 'Pass' : 'Fail') : '');
                    else if (column.field === 'clockIn' || column.field === 'clockOut') {
                        csvRow += this.datePipe.transform(row[column.field], 'MM/dd/yyyy HH:mm a');
                    } else {
                        csvRow += '"' + row[column.field] + '"';
                    }
                }
            }
            csv += csvRow + '\n';
        }

        const blob = new Blob(['\uFEFF', csv], { type: 'text/csv' });
        const link = document.createElement('a');
        link.setAttribute('href', window.URL.createObjectURL(blob));
        link.setAttribute('download', this._table.exportFilename + '.csv');
        document.body.appendChild(link); // Required for FF
        link.click();
    }

    applyFilters(event: any) {
        this.reportData.filters = Object.keys(event.filters).map(key => {
          return { field: key, value: event.filters[key].value };
        });
        this.filtersChanged = true;
    }

    loadOnsiteData(): void {
        this.reportData.startDate = this.startDateStr;
//        this.loadCompanies();
        this.onsiteList = [];
        
        this.onSubmitForm();
    }

    loadTableData(): void {
        this.onsiteList = null;
        this.dataService.getById('pages', 'onsite')
            .subscribe((data) => {
                this.pageInfo = data.result;
            });
    }

    show(onsite: any): void {
        this.profileFace = false;
        this.clockInFace = false;
        this.clockOutFace = false;
        this.clockInCompare = {};
        this.clockOutCompare = {};
        this.onsiteInfo = onsite;
        this.showDialog = true;

        setTimeout(() => {
            this.loadPhoto(onsite.badgeHolderId, 'photo', 'profile','profile');
            this.loadPhoto(onsite.enterId, 'in', 'clockphoto','clockin/' + onsite.clockInPhotoURL);
            this.loadPhoto(onsite.exitId, 'out', 'clockphoto','clockin/' + onsite.clockOutPhotoURL);
          }, 1000);
/*
        if (this.onsiteInfo.photoId !== null) {
            this.dataService.getById('face/detect', this.onsiteInfo.photoId)
                .subscribe((data) => {
                    this.profileFace = data.result;
                    onsite.profileFace = this.profileFace;
                });
        }
        if (this.onsiteInfo.enterId !== null) {
            this.dataService.getById('face/detect', this.onsiteInfo.enterId)
                .subscribe((data) => {
                    this.clockInFace = data.result;
                    onsite.clockInFace = this.clockInFace;
                });
        }
        if (this.onsiteInfo.exitId !== null) {
            this.dataService.getById('face/detect', this.onsiteInfo.exitId)
                .subscribe((data) => {
                    this.clockOutFace = data.result;
                    onsite.clockOutFace = this.clockOutFace;
                });
        }

        if (onsite.profileFace === true && onsite.clockInFace === true) {
            this.verifyFaces(onsite.photoId, onsite.enterId, 'Enter');
        }

        if (onsite.profileFace === true && onsite.clockOutFace === true) {
            this.verifyFaces(onsite.photoId, onsite.exitId, 'Exit');
        }
*/
        
    }

    verifyFaces(faceid1: string, faceid2: string, type: string): any {
        const faceInput = { photoid1: faceid1, photoid2: faceid2 };
        this.dataService.save('face/verify', faceInput)
            .subscribe((data) => {
                if (type === 'Enter') {
                    this.clockInCompare = data.result;
                } else if (type === 'Exit') {
                    this.clockOutCompare = data.result;
                }
            });
    }

    close(): void {
        this.onsiteInfo = null;
        this.showDialog = false;
    }

    setAsProfile(id: string, type: string): void {
        const payload = { badgeHolderId: this.onsiteInfo.badgeHolderId, photoId: id };

        this.onsiteInfo.profilePhotoURL = this.env.baseImageUrl + this.onsiteInfo.profilePhotoURL + '.jpg';

        if (type === 'Enter') {
            this.onsiteInfo.photoId = this.onsiteInfo.enterId;
        } else if (type === 'Exit') {
            this.onsiteInfo.photoId = this.onsiteInfo.exitId;
        }

        this.dataService.save('face/profile', payload)
            .subscribe((data) => {
                this.onsiteInfo.profileFace = true;
            });
    }
}
