import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataService } from '../services/data.service';
import { DateService } from '../services/date.service';
import { BreadCrumbService } from '../services/breadcrumb.service';
import { LoggingService } from '../services/logging.service';
import { Message } from 'primeng/api';
import { UserService } from '../services/user.service';
import { colorSets } from '@swimlane/ngx-charts/release/utils';

@Component({
  selector: 'app-approve-labor',
  templateUrl: './approve-labor.component.html',
  styleUrls: ['./approve-labor.component.css']
})
export class ApproveLaborComponent implements OnInit {

  isGlobalAdmin = false;
  laborSummary: any[];
  fullLaborSummary: any[];
  dt: string;
  user: any;
  subscription: Subscription;
  siteSubscription: Subscription;
  showDialog: boolean;
  viewNoteDialog: boolean;
  showNoteDialog: boolean;
  laborDetails: any[];
  currentBadgeHolder: string;
  totalHours: number;
  isChecked: boolean;
  dayOfTheWeek: number;
  subtractDays: number;
  adjustedDate: Date;
  site: any;
  firstDay: Date;
  secondDay: Date;
  thridDay: Date;
  fourthDay: Date;
  fifthDay: Date;
  sixthDay: Date;
  seventhDay: Date;
  showSearchDialog: boolean;
  badgeHolder: any;
  notes: string;
  note: string;
  message: Message[] = [];
  companies: any[];
  companyId: string;
  projects: any[];
  projectId: string;

  constructor(
    private dataService: DataService,
    private dateService: DateService,
    private breadcrumbService: BreadCrumbService,
    private loggingService: LoggingService,
    private userService: UserService,
    private router: Router) {
    this.isGlobalAdmin = this.userService.currentUser().isGlobalAdmin;
    this.subscription = this.dateService.getCurrentDate()
      .subscribe(date => {
        this.adjustedDate = new Date(date);
        this.dayOfTheWeek = new Date(date).getDay();
        this.subtractDays = this.dayOfTheWeek - 1;

        this.firstDay = new Date(date);
        this.firstDay.setDate(date.getDate() - this.subtractDays);
        this.firstDay.setHours(6, 0, 0);

        this.secondDay = this.addDays(1, this.firstDay);
        this.thridDay = this.addDays(2, this.firstDay);
        this.fourthDay = this.addDays(3, this.firstDay);
        this.fifthDay = this.addDays(4, this.firstDay);
        this.sixthDay = this.addDays(5, this.firstDay);
        this.seventhDay = this.addDays(6, this.firstDay);

        this.dt = this.dateService.formatDate(this.firstDay);
        this.loadApprovalSummary();
      });

    this.siteSubscription = this.userService.getSiteEvent()
      .subscribe(siteId => {
        this.loadApprovalSummary();
      });
  }

  addDays(num, date) {
    const dateVal = new Date(date);
    dateVal.setDate(dateVal.getDate() + num);
    dateVal.setHours(6, 0, 0);
    return dateVal;
  }

  ngOnInit() {
    this.user = this.userService.currentUser();
    this.dataService.getById('sites', this.user.siteId)
      .subscribe((data) => {
        this.site = data;
      });
    this.breadcrumbService.setItemsByCategory('Controls', 'Approve Labor', this.router.url);
    this.loggingService.logPageView('Approve Labor');
    this.showDialog = false;
    this.showNoteDialog = false;
    this.isChecked = false;
    this.loadApprovalSummary();
    this.loadCompanies();
    this.loadProjects();
  }

  ngOnDestroy() {
    this.siteSubscription.unsubscribe();
  }

  badgeHolderChangedHandler(badgeHolder: any) {
    badgeHolder.badgeHolderId = badgeHolder.id;
    this.edit(badgeHolder);
  }

  checkFilters() {
    if (this.companyId == null)
      this.companyId = 'all';
    if (this.projectId == null)
      this.projectId = 'all';
    if (this.companyId === 'all' && this.projectId === 'all') {
      this.laborSummary = this.fullLaborSummary;
    } else {
      this.laborSummary = [];
      this.fullLaborSummary.forEach(summary => {
        if ((this.companyId === 'all' || summary.companyId === this.companyId) &&
          (this.projectId === 'all' || (summary.projectIds && summary.projectIds.indexOf(this.projectId) >= 0)))
          this.laborSummary.push(summary);
      });
    }
  }

  loadCompanies(): void {
    this.dataService.getAll('companies')
      .subscribe((companies) => {
        this.companies = companies;
        this.companies.unshift({ value: 'all', label: 'All' });
      });
  }

  loadProjects(): void {
    this.dataService.getAll('projects')
      .subscribe((projects) => {
        this.projects = projects;
        this.projects.forEach(m => {
          m.value = m.id;
          if (m.code !== null) {
            m.label = m.name + ' (' + m.code + ')';
          } else {
            m.label = m.name;
          }
        });
        this.projects.unshift({ value: 'all', label: 'All' });
      });
  }

  loadApprovalSummary() {
    this.laborSummary = [];
    this.fullLaborSummary = [];
    this.dataService.getById('labor', this.dt)
      .subscribe((data) => {
        this.laborSummary = data;
        for (let x = 0; x < this.laborSummary.length; x++) {
          if (this.laborSummary[x].sunday === null) {
            this.laborSummary[x].sunday = 0;
          }
          if (this.laborSummary[x].monday === null) {
            this.laborSummary[x].monday = 0;
          }
          if (this.laborSummary[x].tuesday === null) {
            this.laborSummary[x].tuesday = 0;
          }
          if (this.laborSummary[x].wednesday === null) {
            this.laborSummary[x].wednesday = 0;
          }
          if (this.laborSummary[x].thursday === null) {
            this.laborSummary[x].thursday = 0;
          }
          if (this.laborSummary[x].friday === null) {
            this.laborSummary[x].friday = 0;
          }
          if (this.laborSummary[x].saturday === null) {
            this.laborSummary[x].saturday = 0;
          }
          this.laborSummary[x].originalSunday = this.laborSummary[x].sunday;
          this.laborSummary[x].originalMonday = this.laborSummary[x].monday;
          this.laborSummary[x].originalTuesday = this.laborSummary[x].tuesday;
          this.laborSummary[x].originalWednesday = this.laborSummary[x].wednesday;
          this.laborSummary[x].originalThursday = this.laborSummary[x].thursday;
          this.laborSummary[x].originalFriday = this.laborSummary[x].friday;
          this.laborSummary[x].originalSaturday = this.laborSummary[x].saturday;
          if (this.laborSummary[x].sunday > 4) {
            this.laborSummary[x].sunday = this.laborSummary[x].sunday + this.laborSummary[x].offsetTime;
            this.laborSummary[x].sundayHasNotes = this.laborSummary[x].sundayHasNotes;
          }
          if (this.laborSummary[x].monday > 4) {
            this.laborSummary[x].monday = this.laborSummary[x].monday + this.laborSummary[x].offsetTime;
            this.laborSummary[x].mondayHasNotes = this.laborSummary[x].mondayHasNotes;
          }
          if (this.laborSummary[x].tuesday > 4) {
            this.laborSummary[x].tuesday = this.laborSummary[x].tuesday + this.laborSummary[x].offsetTime;
            this.laborSummary[x].tuesdayHasNotes = this.laborSummary[x].tuesdayHasNotes;
          }
          if (this.laborSummary[x].wednesday > 4) {
            this.laborSummary[x].wednesday = this.laborSummary[x].wednesday + this.laborSummary[x].offsetTime;
            this.laborSummary[x].wednesdayHasNotes = this.laborSummary[x].wednesdayHasNotes;
          }
          if (this.laborSummary[x].thursday > 4) {
            this.laborSummary[x].thursday = this.laborSummary[x].thursday + this.laborSummary[x].offsetTime;
            this.laborSummary[x].thursdayHasNotes = this.laborSummary[x].thursdayHasNotes;
          }
          if (this.laborSummary[x].friday > 4) {
            this.laborSummary[x].friday = this.laborSummary[x].friday + this.laborSummary[x].offsetTime;
            this.laborSummary[x].fridayHasNotes = this.laborSummary[x].fridayHasNotes;
          }
          if (this.laborSummary[x].saturday > 4) {
            this.laborSummary[x].saturday = this.laborSummary[x].saturday + this.laborSummary[x].offsetTime;
            this.laborSummary[x].saturdayHasNotes = this.laborSummary[x].saturdayHasNotes;
          }
          this.laborSummary[x].totalHours = this.calcTotalHours(this.laborSummary[x]);
        }
        this.fullLaborSummary = this.laborSummary;
        this.checkFilters();
      });
  }

  //  changeHours(summary: any): void {
  //    summary.totalHours = this.calcTotalHours(summary);
  // }

  changeOffset(summary: any): void {
    if (summary.originalSunday > 4) {
      summary.sunday = summary.originalSunday + summary.offsetTime;
    }
    if (summary.originalMonday > 4) {
      summary.monday = summary.originalMonday + summary.offsetTime;
    }
    if (summary.originalTuesday > 4) {
      summary.tuesday = summary.originalTuesday + summary.offsetTime;
    }
    if (summary.orginalWednesday > 4) {
      summary.wednesday = summary.originalWednesday + summary.offsetTime;
    }
    if (summary.originalThursday > 4) {
      summary.thursday = summary.originalThursday + summary.offsetTime;
    }
    if (summary.originalFriday > 4) {
      summary.friday = summary.originalFriday + summary.offsetTime;
    }
    if (summary.originalSaturday > 4) {
      summary.saturday = summary.originalSaturday + summary.offsetTime;
    }
  }

  calcTotalHours(summary: any): number {
    return summary.sunday
      + summary.monday
      + summary.tuesday
      + summary.wednesday
      + summary.thursday
      + summary.friday
      + summary.saturday;
  }

  notePopup(record: any): void {
    this.resetNoteModified();
    this.notes = record.approvalNote;
    this.note = null;
    record.modifiedNote = true;
    this.viewNoteDialog = false;
    this.showNoteDialog = true;
  }

  projectSelected(detail: any) {
    detail.modified = true;
  }

  saveNote(): void {
    this.showNoteDialog = false;
    if (this.note)
      for (let x = 0; x < this.laborDetails.length; x++) {
        if (this.laborDetails[x].modifiedNote === true) {
          this.laborDetails[x].injuryNoteMissing = false;
          this.laborDetails[x].modified = true;
          if (this.laborDetails[x].approvalNote && this.laborDetails[x].approvalNote.length > 0)
            this.laborDetails[x].approvalNote += '\n\n';
          else
            this.laborDetails[x].approvalNote = '';
          this.laborDetails[x].approvalNote += `${this.user.name} on  ${this.dateService.formatDateDash(new Date())} ${this.dateService.formatTime(new Date())}`;
          this.laborDetails[x].approvalNote += '\n';
          this.laborDetails[x].approvalNote += this.note;
        }
      }
  }

  resetLaborModified(): void {
    for (let x = 0; x < this.laborDetails.length; x++) {
      this.laborDetails[x].modified = false;
    }
  }

  resetNoteModified(): void {
    for (let x = 0; x < this.laborDetails.length; x++) {
      this.laborDetails[x].modifiedNote = false;
    }
  }

  edit(record: any): void {
    this.showDialog = true;
    if (record.firstName !== undefined) {
      this.currentBadgeHolder = record.firstName + ' ' + record.lastName;
    } else {
      this.currentBadgeHolder = record.badgeHolderFirstName + ' ' + record.badgeHolderLastName;
    }
    this.laborDetails = [];
    this.totalHours = 0;
    this.dataService.getById('labor/detail/' + record.badgeHolderId, this.dt)
      .subscribe((data) => {
        this.laborDetails = data;
        let firstName = '';
        let lastName = '';

        if (this.user.canApproveLabor)
          for (let x = 0; x < this.laborDetails.length; x++) {
            if (x > 0)
              if (!this.laborDetails[x - 1].combineAbove && !this.laborDetails[x - 1].combineBelow &&
                (!this.laborDetails[x - 1].clockOut || this.laborDetails[x - 1].clockIn == this.laborDetails[x - 1].clockOut) &&
                (!this.laborDetails[x].clockOut || this.laborDetails[x].clockIn == this.laborDetails[x].clockOut)) {
                const lastDate = new Date(this.laborDetails[x - 1].clockIn);
                lastDate.setHours(0);
                lastDate.setMinutes(0);
                lastDate.setSeconds(0);
                lastDate.setMilliseconds(0);
                const thisDate = new Date(this.laborDetails[x].clockIn);
                thisDate.setHours(0);
                thisDate.setMinutes(0);
                thisDate.setSeconds(0);
                thisDate.setMilliseconds(0);
                if (lastDate.getTime() === thisDate.getTime())
                  this.laborDetails[x].combineAbove = this.laborDetails[x - 1];
              }
              else if (!this.laborDetails[x - 1].combineAbove && !this.laborDetails[x - 1].combineBelow &&
                this.laborDetails[x - 1].clockOut && this.laborDetails[x - 1].clockIn !== this.laborDetails[x - 1].clockOut &&
                (!this.laborDetails[x].clockOut || this.laborDetails[x].clockIn === this.laborDetails[x].clockOut))
                this.laborDetails[x - 1].combineBelow = this.laborDetails[x];

            if (this.laborDetails[x].clockId && this.laborDetails[x].clockId !== 'new' && this.laborDetails[x].clockOut && this.laborDetails[x].clockIn === this.laborDetails[x].clockOut)
              this.laborDetails[x].canMoveOver = true;
          }

        for (let x = 0; x < this.laborDetails.length; x++) {
          if (this.laborDetails[x].offset === null) {
            this.laborDetails[x].offset = this.site.defaultOffset;
          }
          this.laborDetails[x].clockIn = new Date(this.laborDetails[x].clockIn);
          this.laborDetails[x].clockInTime = new Date(this.laborDetails[x].clockIn);
          this.laborDetails[x].clockOut = new Date(this.laborDetails[x].clockOut);
          this.laborDetails[x].clockOutTime = new Date(this.laborDetails[x].clockOut);
          this.laborDetails[x].injuryNoteMissing = true;
          if (this.laborDetails[x].injury === 1)
            this.laborDetails[x].injury = true;
          if (this.laborDetails[x].injury === 0)
            this.laborDetails[x].injury = false;
          this.laborDetails[x].injuryOriginal = this.laborDetails[x].injury;
          if (this.laborDetails[x].firstName != '') {
            firstName = this.laborDetails[x].firstName;
            lastName = this.laborDetails[x].lastName;
          }
        }

        this.calcDetailTotalHours();

        //        this.currentBadgeHolder = firstName + ' ' + lastName;
      });
  }

  showNotes(record: any, day: number): void {
    let date = new Date(this.firstDay);
    switch (day) {
      case 1:
        date = new Date(this.secondDay);
        break;
      case 2:
        date = new Date(this.thridDay);
        break;
      case 3:
        date = new Date(this.fourthDay);
        break;
      case 4:
        date = new Date(this.fifthDay);
        break;
      case 5:
        date = new Date(this.sixthDay);
        break;
      case 6:
        date = new Date(this.seventhDay);
        break;
    }
    date.setHours(0, 0, 0, 0);
    const nextDate = new Date(date);
    nextDate.setDate(date.getDate() + 1);
    this.dataService.getById('labor/detail/' + record.badgeHolderId, this.dt)
      .subscribe((data) => {
        this.laborDetails = data;
        this.notes = '';
        data.forEach(l => {
          const clockIn = new Date(l.clockIn);
          if (l.approvalNote && date <= clockIn && nextDate > clockIn) {
            if (this.notes != '')
              this.notes += '\n';
            this.notes += l.approvalNote;
          }
        });
        this.viewNoteDialog = true;
        this.showNoteDialog = false;
      });
  }

  changeDetailTime(detail: any, checkNull: boolean, index: number, forCheckIn: boolean): void {
    detail.modified = true;

    if (detail.clockInTime == null)
      detail.clockInTime = new Date(detail.clockIn);
    else {
      detail.clockIn.setHours(detail.clockInTime.getHours());
      detail.clockIn.setMinutes(detail.clockInTime.getMinutes());
    }

    if (checkNull && detail.clockOut == null)
      detail.clockOut = new Date(detail.clockIn);
    if (detail.clockOutTime == null)
      detail.clockOutTime = new Date(detail.clockOut);
    else {
      detail.clockOut.setHours(detail.clockOutTime.getHours());
      detail.clockOut.setMinutes(detail.clockOutTime.getMinutes());
    }
    detail.hours = Math.round((detail.clockOut - detail.clockIn) / 36e5 * 100) / 100;

    detail.totalCalc = this.dateService.formatTimeFromHours(detail.hours);


    if (forCheckIn && detail.isChild && index > 0) {
      this.laborDetails[index - 1].clockOutTime = detail.clockInTime;
      this.laborDetails[index - 1].clockOut = detail.clockIn;

      this.laborDetails[index - 1].hours = Math.round((this.laborDetails[index - 1].clockOut - this.laborDetails[index - 1].clockIn) / 36e5 * 100) / 100;
    }
    else if (!forCheckIn && (detail.hasChildren || detail.isChild) && (index + 1) < this.laborDetails.length && this.laborDetails[index + 1].isChild) {
      this.laborDetails[index + 1].clockInTime = detail.clockOutTime;
      this.laborDetails[index + 1].clockIn = detail.clockOut;
      this.laborDetails[index + 1].hours = Math.round((this.laborDetails[index + 1].clockOut - this.laborDetails[index + 1].clockIn) / 36e5 * 100) / 100;
    }

    this.calcDetailTotalHours();
  }
  splitTime(i: number): void {
    const detail = this.laborDetails[i];
    detail.modified = true;
    detail.hours = Number(detail.hours);

    const detailNew = Object.assign({}, this.laborDetails[i]);
    detailNew.isChild = true;
    detailNew.id = 0;
    detailNew.clockId = 'new';
    detailNew.splitClockId = detail.clockId;
    detailNew.hasChildren = null;
    detailNew.hours = Math.round(Math.floor(detailNew.hours / 2) * 100) / 100;
    detail.hours -= detailNew.hours;
    detail.hours = Math.round(detail.hours * 100) / 100;
    detailNew.clockIn = new Date(detail.clockIn);
    detailNew.clockIn.setTime(detail.clockIn.getTime() + (detail.hours * 3600000));
    detailNew.clockInTime = new Date(detailNew.clockIn);

    detail.isChild = null;
    detail.splitClockId = null;
    detail.hasChildren = true;

    this.laborDetails.splice((i + 1), 0, detailNew);

    this.changeDetailHours(detail);
    this.changeDetailHours(detailNew);
  }
  splitTimeEvenly(i: number): void {
    const detail = this.laborDetails[i];
    detail.hours = Number(detail.hours);
    let nextClockIn = new Date(detail.clockIn);

    let hours = detail.hours;
    let rows = 1;

    for (; i + rows < this.laborDetails.length; rows++)
      if (this.laborDetails[i + rows].splitClockId === detail.clockId)
        hours += Number(this.laborDetails[i + rows].hours);
      else
        break;

    const floorHours = Math.floor(hours / rows);
    let unallocated = Math.round((hours - (floorHours * rows)) * 100) / 100;
    for (let r = 0; r < rows; r++) {
      const detailNew = this.laborDetails[i + r];
      detailNew.hours = floorHours;
      if (unallocated > 0) {
        if (unallocated >= 1)
          detailNew.hours += 1;
        else
          detailNew.hours += unallocated;
        unallocated -= 1;
      }
      detailNew.hours = Math.round(detailNew.hours * 100) / 100;

      detailNew.clockIn = nextClockIn;
      detailNew.clockInTime = new Date(detailNew.clockIn);

      this.changeDetailHours(detailNew);

      nextClockIn = detailNew.clockOutTime;
    }
  }
  changeDetailHours(detail: any, index?: number): void {
    detail.modified = true;

    detail.hours = Number(detail.hours);
    detail.clockOut = new Date(detail.clockIn);
    detail.clockOut.setTime(detail.clockIn.getTime() + (detail.hours * 3600000));
    detail.clockOutTime = new Date(detail.clockOut);

    detail.totalCalc = this.dateService.formatTimeFromHours(detail.hours);

    if (index != null && (detail.hasChildren || detail.isChild) && (index + 1) < this.laborDetails.length && this.laborDetails[index + 1].isChild) {
      this.laborDetails[index + 1].clockInTime = detail.clockOutTime;
      this.laborDetails[index + 1].clockIn = detail.clockOut;
      this.laborDetails[index + 1].hours = Math.round((this.laborDetails[index + 1].clockOut - this.laborDetails[index + 1].clockIn) / 36e5 * 100) / 100;
    }

    this.calcDetailTotalHours();
  }
  combineAbove(detailClockOut: any, detailClockIn: any): void {
    detailClockIn.modified = true;
    detailClockIn.clockOut = detailClockOut.clockOut;
    detailClockIn.clockOutTime = detailClockOut.clockOutTime;
    detailClockIn.combineAboveWithClockId = detailClockOut.clockId;

    detailClockIn.hours = Math.round((detailClockIn.clockOut - detailClockIn.clockIn) / 36e5 * 100) / 100;
    detailClockIn.totalCalc = this.dateService.formatTimeFromHours(detailClockIn.hours);

    detailClockOut.combineAbove = null;
    detailClockOut.modified = true;
    detailClockOut.deleted = true;

    this.calcDetailTotalHours();
  }
  combineBelow(detailClockOut: any, detailClockIn: any): void {
    detailClockIn.modified = true;
    detailClockIn.canMoveOver = false;
    detailClockIn.clockOut = detailClockIn.clockIn;
    detailClockIn.clockOutTime = detailClockIn.clockInTime;
    detailClockIn.clockIn = detailClockOut.clockOut;
    detailClockIn.clockInTime = detailClockOut.clockOutTime;
    detailClockIn.combineBelowWithClockId = detailClockOut.clockId;

    detailClockIn.hours = Math.round((detailClockIn.clockOut - detailClockIn.clockIn) / 36e5 * 100) / 100;
    detailClockIn.totalCalc = this.dateService.formatTimeFromHours(detailClockIn.hours);

    detailClockOut.combineBelow = null;
    detailClockOut.modified = true;
    detailClockOut.canMoveOver = false;
    detailClockOut.clockOut = detailClockOut.clockIn;
    detailClockOut.clockOutTime = detailClockOut.clockInTime;

    detailClockOut.hours = Math.round((detailClockOut.clockOut - detailClockOut.clockIn) / 36e5 * 100) / 100;
    detailClockOut.totalCalc = this.dateService.formatTimeFromHours(detailClockOut.hours);

    this.calcDetailTotalHours();
  }
  moveOver(detailClock: any): void {
    detailClock.modified = true;
    detailClock.canMoveOver = false;
    detailClock.moveOver = true;
    detailClock.combineAbove = null;
    detailClock.combineBelow = null;
  }
  delete(detail: any): void {
    detail.modified = true;
    detail.deleted = true;
  }
  undelete(detail: any): void {
    detail.modified = true;
    detail.deleted = null;
  }

  calcDetailTotalHours(): void {
    this.totalHours = 0;
    for (let x = 0; x < this.laborDetails.length; x++) {
      this.totalHours += this.laborDetails[x].hours;
    }

    this.totalHours = Number(this.totalHours.toFixed(2));
  }

  feature(featureItem: string): boolean {
    return this.userService.hasFeature(featureItem);
  }

  save(): void {
    let saveData = [];
    for (let x = 0; x < this.laborDetails.length; x++) {
      if (this.laborDetails[x].injury != this.laborDetails[x].injuryOriginal && this.laborDetails[x].injuryNoteMissing) {
        this.notePopup(this.laborDetails[x]);
        return;
      }
      if (this.laborDetails[x].modified === true) {
        this.laborDetails[x].clockIn = this.dateService.convertDateToLocalTimeDate(this.laborDetails[x].clockIn);
        this.laborDetails[x].clockOut = this.dateService.convertDateToLocalTimeDate(this.laborDetails[x].clockOut);
        saveData.push(this.laborDetails[x]);
      }
    }

    this.dataService.save('labor', saveData)
      .subscribe((data) => {
        this.loggingService.savedMessage();
        this.loadApprovalSummary();
        this.showDialog = false;
      });
  }

  cancel(): void {
    this.showDialog = false;
  }

  checkAll(): void {
    for (let x = 0; x < this.laborSummary.length; x++) {
      if (this.isChecked === true) {
        this.laborSummary[x].approved = false;
      } else {
        this.laborSummary[x].approved = true;
      }
    }
    if (this.isChecked === true) {
      this.isChecked = false;
    } else {
      this.isChecked = true;
    }
    this.dataService.getAll('labor/approve-all/' + this.dt + '/' + this.isChecked)
      .subscribe((data) => { });
  }
  appoved(detail) {
    this.dataService.getById('labor/approve/' + detail.badgeHolderId + '/' + this.dt, detail.approved)
      .subscribe((data) => { });
  }
  onHasOffsetsChanged(detail) {
    this.dataService.getById('labor/offsetsChanged/' + detail.badgeHolderId + '/' + this.dt + '/' + detail.hasOffset + '/0', '0')
      .subscribe((data) => {
        detail.offset = data.offset;

        detail.monday = data.monday;
        detail.tuesday = data.tuesday;
        detail.wednesday = data.wednesday;
        detail.thursday = data.thursday;
        detail.friday = data.friday;
        detail.saturday = data.saturday;
        detail.sunday = data.sunday;

        detail.totalHours = this.calcTotalHours(detail);
      });
  }

  openSearchDialog(): void {
    this.showSearchDialog = true;
  }

  exportToCSV(): void {
    let csv = 'Badge #,First Name,Last Name,Company Name,Labor Class,Offset';
    csv += `,Monday (${this.firstDay.getMonth() + 1}/${this.firstDay.getDate()})`;
    csv += `,Tuesday (${this.secondDay.getMonth() + 1}/${this.secondDay.getDate()})`;
    csv += `,Wednesday (${this.thridDay.getMonth() + 1}/${this.thridDay.getDate()})`;
    csv += `,Thursday (${this.fourthDay.getMonth() + 1}/${this.fourthDay.getDate()})`;
    csv += `,Friday (${this.fifthDay.getMonth() + 1}/${this.fifthDay.getDate()})`;
    csv += `,Saturday (${this.sixthDay.getMonth() + 1}/${this.sixthDay.getDate()})`;
    csv += `,Sunday (${this.seventhDay.getMonth() + 1}/${this.seventhDay.getDate()})`;
    csv += ',Total';
    csv += '\n';


    for (const row of this.laborSummary) {
      let csvRow = row.badgeNum;
      csvRow += ',';
      csvRow += '"';
      csvRow += row.badgeHolderLastName;
      csvRow += '"';
      csvRow += ',';
      csvRow += '"';
      csvRow += row.badgeHolderFirstName;
      csvRow += '"';
      csvRow += ',';
      csvRow += '"';
      csvRow += row.companyName;
      csvRow += '"';
      csvRow += ',';
      csvRow += '"';
      csvRow += row.laborClassName;
      csvRow += '"';
      csvRow += ',';
      csvRow += row.offsetTime;
      csvRow += ',';
      csvRow += Math.round(row.monday * 100) / 100;
      csvRow += ',';
      csvRow += Math.round(row.tuesday * 100) / 100;
      csvRow += ',';
      csvRow += Math.round(row.wednesday * 100) / 100;
      csvRow += ',';
      csvRow += Math.round(row.thursday * 100) / 100;
      csvRow += ',';
      csvRow += Math.round(row.friday * 100) / 100;
      csvRow += ',';
      csvRow += Math.round(row.saturday * 100) / 100;
      csvRow += ',';
      csvRow += Math.round(row.sunday * 100) / 100;
      csvRow += ',';
      csvRow += Math.round(row.totalHours * 100) / 100;

      csv += csvRow + '\n';
    }

    const blob = new Blob(['\uFEFF', csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.setAttribute('href', window.URL.createObjectURL(blob));
    link.setAttribute('download', 'ApproveLabor.csv');
    document.body.appendChild(link); // Required for FF
    link.click();
  }

}
