<form #thisForm='ngForm'>
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <h3>{{company.name}}</h3>
            </div>
            <div class="col-lg-6">
                <div class="float-right">
                    <button pButton (click)="save(true)" [disabled]="!thisForm.valid" icon="pi pi-save"
                        label="New"></button>
                    <button pButton (click)="save(false)" [disabled]="!thisForm.valid" icon="pi pi-save"
                        label="Save"></button>
                    <button pButton (click)="cancel()" icon="pi pi-ban" label="Cancel"></button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="row">
                    <div class="col">
                        <label class="control-label" for="companyName">Company Name:</label>
                        <input class="form-control" pInputText name="companyName" type="text" [(ngModel)]="company.name"
                            maxlength="100" required />
                    </div>
                </div>
                <div class="row"
                    *ngIf="(company.parentCompanyId != null && company.parentCompanyId !== '0') || company.id == 'new'">
                    <div class="col">
                        <label class="control-label">Parent Company:</label><br />
                        <p-dropdown [options]="companies" placeholder="Select a Parent Company" [filter]="true" required
                            [(ngModel)]="company.parentCompanyId" name="companyDropdown"></p-dropdown>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label class="control-label">Company Category:</label><br />
                        <p-dropdown [options]="companyCategories" placeholder="Select a Category" [filter]="true"
                            required [(ngModel)]="company.companyType" name="categoryDropdown"
                            (onChange)="loadCSIDivisions()"></p-dropdown>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label class="control-label" for="trade">CSI Division:</label><br />
                        <p-dropdown [options]="divisions" placeholder="Select a Division" [filter]="true" required
                            [(ngModel)]="company.csiDivision" name="divisionDropdown" (onChange)="loadTrades()">
                        </p-dropdown>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label class="control-label" for="trade">Trade Classification:</label><br />
                        <p-dropdown [options]="trades" placeholder="Select a Trade" [filter]="true" required
                            [(ngModel)]="company.tradeClass" name="tradeDropdown"></p-dropdown>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label class="control-label" for="trade">Emergency Refuge Station:</label>
                        <input type="text" class="form-control" pInputText name="refugeStation"
                            [(ngModel)]="company.refugeStation" maxlength="50" />
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="row">
                    <div class="col">
                        <div><b>Company to be included within manpower calculator:</b></div>
                        <div class="ui-g" style="width:250px;margin-bottom:10px">
                            <div class="ui-g-6">
                                <p-radioButton name="includeManpower" value="true" label="Yes" [(ngModel)]="manpower">
                                </p-radioButton>
                            </div>
                            <div class="ui-g-6">
                                <p-radioButton name="includeManpower" value="false" label="No" [(ngModel)]="manpower">
                                </p-radioButton>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div><b>Union Labor</b></div>
                </div>
                <div class="row">
                    <div class="ui-g">
                        <div class="ui-g-3">
                            <p-radioButton name="unionLabor" value="true" label="Yes" [(ngModel)]="hasUnion">
                            </p-radioButton>
                        </div>
                        <div class="ui-g-3">
                            <p-radioButton name="unionLabor" value="false" label="No" [(ngModel)]="hasUnion">
                            </p-radioButton>
                        </div>
                        <div class="ui-g-6">
                            <label class="control-label">Union:</label>
                            <input type="text" class="form-control" pInputText name="unionName"
                                [(ngModel)]="company.unionName" maxlength="50" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <label>Company is Certified in the following:</label>
                </div>
                <div class="row">
                    <div class="ui-g" style="width:250px;margin-bottom:10px">
                        <div class="ui-g-12">
                            <div class="ui-g-12">
                                <p-checkbox name="mbe" binary="true" label="MBE - Minority" [(ngModel)]="company.mbe">
                                </p-checkbox>
                            </div>
                            <div class="ui-g-12">
                                <p-checkbox name="wbe" binary="true" label="WBE - Women" [(ngModel)]="company.wbe">
                                </p-checkbox>
                            </div>
                            <div class="ui-g-12">
                                <p-checkbox name="dbe" binary="true" label="DBE - Disadvantaged"
                                    [(ngModel)]="company.dbe"></p-checkbox>
                            </div>
                            <div class="ui-g-12">
                                <p-checkbox name="sbe" binary="true" label="SBE - Small Business"
                                    [(ngModel)]="company.sbe"></p-checkbox>
                            </div>
                            <div class="ui-g-12">
                                <p-checkbox name="lbe" binary="true" label="LBE - Local Business"
                                    [(ngModel)]="company.lbe"></p-checkbox>
                            </div>
                            <div class="ui-g-12">
                                <p-checkbox name="vob" binary="true" label="VOB - Veteran Owned"
                                    [(ngModel)]="company.vob"></p-checkbox>
                            </div>
                            <div class="ui-g-12">
                                <p-checkbox name="sdvob" binary="true" label="SDVOB - Service Disabled"
                                    [(ngModel)]="company.sdvob"></p-checkbox>
                            </div>
                            <div class="ui-g-12">
                                <p-checkbox name="pwd" binary="true" label="PWD - Persons with Disabilites"
                                    [(ngModel)]="company.pwd"></p-checkbox>
                            </div>
                            <div class="ui-g-12">
                                <p-checkbox name="lgbt" binary="true"
                                    label="LGBT - Lesbian, Gay, Bisexual &amp; Transgender" [(ngModel)]="company.lgbt">
                                </p-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <st-update-block [object]='company'></st-update-block>
    </div>
</form>