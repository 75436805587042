import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { DataService } from '../services/data.service';

@Component({
    selector: 'app-weather-status',
    templateUrl: './weather-status.component.html',
    encapsulation: ViewEncapsulation.None
})
export class WeatherStatusComponent implements OnInit {

    data: any[];

    constructor(private dataService: DataService) {}

    ngOnInit() {
        this.dataService.getById('weather', 'check-status')
            .subscribe((response) => {
                this.data = response;
        });
    }
}
