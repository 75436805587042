<form #thisForm='ngForm'>
    <div class="container">
        <div class="row">
            <div>
                <h3>{{inspector.name}}</h3>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="float-right">
                    <button pButton (click)="save()" [disabled]="!thisForm.valid" icon="pi pi-save" label="Save"></button>
                    <button pButton (click)="cancel()" [hidden]="dialog" icon="pi pi-ban" label="Cancel"></button>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <label>Badge Number</label>
                            <div class="form-group">
                                <input type="text" pInputText placeholder="####" [(ngModel)]="inspector.badgeNum" name="badgeNum" maxlength="36" class="form-control" required />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 col-md-6">
                            <div class="form-group">
                                <label>First Name</label>
                                <input type="text" pInputText placeholder="John" [(ngModel)]="inspector.firstName" name="firstName" maxlength="50" class="form-control" required />
                            </div>
                        </div>
                        <div class="col-6 col-md-6">
                            <div class="form-group">
                                <label>Last Name</label>
                                <input type="text" pInputText placeholder="Doe" [(ngModel)]="inspector.lastName" name="lastName" maxlength="50" class="form-control" required />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label>Company</label><br />
                                <p-dropdown [options]="companies" [filter]="true" placeholder="Select a Company" required [(ngModel)]="inspector.companyId" name="companyDropdown"></p-dropdown>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label>Phone</label>
                                <p-inputMask mask="(999)999-9999" [(ngModel)]="inspector.phone" name="phone" required placeholder="(999)999-9999"></p-inputMask>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label>Access Start Date</label><br/>
                                <p-calendar [(ngModel)]="inspector.startDate" name="startDate" [showIcon]="true"></p-calendar>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label>Access End Date</label><br/>
                                <p-calendar [(ngModel)]="inspector.terminationDate" name="terminationDate" [showIcon]="true"></p-calendar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
