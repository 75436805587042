<div class="layout-sidebar" [ngClass]="{'layout-sidebar-active': app.sidebarActive, 'layout-sidebar-dark': app.darkMenu}" (click)="app.onSidebarClick($event)" (mouseover)="app.sidebarActive=true" (mouseleave)="app.sidebarActive=false">
    <div class="sidebar-logo">
        <a routerLink="/">
            <span class="app-name">&nbsp;&nbsp;SITETRAXX&nbsp;&nbsp;&nbsp;</span>
            <img alt="logo" src="../assets/images/sitetraxx-icon.png" />
        </a>
        <a href="#" class="sidebar-anchor" title="Toggle Menu" (click)="app.onToggleMenuClick($event)"><i class="pi pi-pin"></i></a>
    </div>

    <div #layoutMenuScroller class="nano">
        <div class="nano-content sidebar-scroll-content">
            <div class="layout-menu-container" (click)="updateNanoScroll()">
                <ul app-submenu [item]="model" root="true" class="layout-menu" visible="true" [reset]="reset"></ul>
            </div>
        </div>
    </div>
</div>