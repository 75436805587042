import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { BreadCrumbService } from '../services/breadcrumb.service';
import { LoggingService } from '../services/logging.service';
import { Subscription } from 'rxjs/Subscription';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.css']
})
export class SurveyComponent implements OnInit {

  pageInfo: any;
  path: string;
  sub: any;
  id: string;
  survey: any;

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private breadcrumbService: BreadCrumbService,
    private loggingService: LoggingService,
    private userService: UserService,
    private router: Router) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];

      if (this.id === undefined) {
        this.loadData();
      } else {
          if (this.id === 'new') {
            this.loggingService.logPageView('Survey - New');
              this.survey = {};
              this.survey.id = 'new';
          } else {
            this.loggingService.logPageView('Survey - Profile');
              this.dataService.getById('surveys/detail', this.id)
              .subscribe((data) => {
                  this.survey = data;
              });
          }
      }
   });
  }

  loadData() {
    this.loggingService.logPageView('Surveys - Grid');
    this.survey = null;
    this.dataService.getById('pages', 'surveys')
    .subscribe((data) => {
        this.pageInfo = data.result;
        this.breadcrumbService.setItemsByCategory('SiteTraxx Admin', this.pageInfo.title, this.router.url);
      });
  }
}
