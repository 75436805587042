<form #thisForm='ngForm'>
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <h3>{{survey.name}}</h3>
            </div>
            <div class="md-col-6">
                <div class="pull-right">
                    <button pButton (click)="save()" [disabled]="!thisForm.valid" icon="pi pi-save" label="Save"></button>
                    <button pButton (click)="cancel()" icon="pi pi-ban" label="Cancel"></button>
                </div>
            </div>
        </div>
        <div class="row>">
            <div class="col-md-12">
                <label>Survey Name:</label>
                <div class="form-inline">
                    <input pInputText type="text" [(ngModel)]="survey.name" name="name" maxlength="50" class="form-control" required />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label>Description:</label>
                <div class="form-inline">
                    <textarea pTextArea [rows]="5" [cols]="50" name="description" maxlength="250" [(ngModel)]="survey.description" class="form-control" required></textarea>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <app-questions [survey]="survey" *ngIf="showQuestions"></app-questions>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <app-survey-attempts [survey]="survey"></app-survey-attempts>
            </div>
        </div>
        <st-update-block [object]='survey'></st-update-block>
    </div>
</form>

