import { TreeDragDropService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { AlertService } from './components/services/alert.service';
import { AuthService } from './components/services/auth.service';
import { AuthGuard } from './components/services/authguard.service';
import { BreadCrumbService } from './components/services/breadcrumb.service';
import { DataService } from './components/services/data.service';
import { GridService } from './components/services/grid.service';
import { UserService } from './components/services/user.service';
import { UserNameService } from './components/services/user-name.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './components/shared/auth.interceptor';
import { DateService } from './components/services/date.service';
import { WeatherService } from './components/services/weather.service';
import { LoggingService } from './components/services/logging.service';
import { ProgressService } from './components/services/progress.service';

export const APP_PROVIDERS = [
    TreeDragDropService,
    MessageService,
    AlertService,
    AuthGuard,
    AuthService,
    BreadCrumbService,
    DataService,
    DateService,
    GridService,
    LoggingService,
    ProgressService,
    UserService,
    UserNameService,
    WeatherService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];
