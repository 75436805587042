import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DateService } from '../../services/date.service';

@Component({
    selector: 'badgeholder-termination',
    templateUrl: './badgeholder.termination.component.html',
    encapsulation: ViewEncapsulation.None
})
export class BadgeHolderTerminationComponent implements OnInit {
@Input() badgeHolder: any;

suspendedDate: Date;
terminationDate: Date;


    constructor(private dateService: DateService) { }

    ngOnInit(): void {
        if (this.badgeHolder.suspendedDate === undefined) {
            this.suspendedDate = new Date('2030-01-01');
        } else {
            this.suspendedDate = this.dateService.convertDateUniversalToSiteTimeDate(new Date(this.badgeHolder.suspendedDate));
        }

        if (this.badgeHolder.terminationDate === undefined) {
            this.terminationDate = new Date('2030-01-01');
        } else {
            this.terminationDate = this.dateService.convertDateUniversalToSiteTimeDate(new Date(this.badgeHolder.terminationDate));
        }
    }

    onSuspendedDateChange() {
        this.badgeHolder.suspendedDate = this.dateService.convertDateLocalToSiteTimeDate(new Date(this.suspendedDate));
    }

    onTerminationDateChange() {
        this.badgeHolder.terminationDate = this.dateService.convertDateLocalToSiteTimeDate(new Date(this.terminationDate));
    }
}
