<div *ngIf="pageInfo">
    <div class="container">
        <div class="row">
            <div class="col">
                <h3>Resources</h3>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <st-table pageId="{{pageInfo.id}}" dataTable="{{pageInfo.dataName}}"></st-table>
            </div>
        </div>
    </div>
</div>

<div *ngIf="resource">
    <app-resource-profile [resource]="resource"></app-resource-profile>
</div>