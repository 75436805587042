import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ProgressService {

  public show: Subject<boolean> = new BehaviorSubject<boolean>(false);

  public isDisplayed = false;

  constructor() {
  }

  public display(display) {
    this.isDisplayed = display;
    this.show.next(display);
  }
}
