<div class="row">
    <div class="col-lg-4">
        <div>
            Filter by Company:
            <p-dropdown [options]="companies" (onChange)="checkFilters()" [showClear]="true"
                placeholder="Select a Company" [filter]="true" [(ngModel)]="companyId"></p-dropdown>
            <br />
        </div>
        <div>
            Filter by Project:
            <p-dropdown [options]="projects" (onChange)="checkFilters()" [showClear]="true"
                placeholder="Select a Project" [filter]="true" [(ngModel)]="projectId"></p-dropdown>
        </div>
    </div>
    <div class="col-lg-4">
        <h3>Date Range: {{firstDay | date:"MM/dd/yyyy"}} - {{seventhDay | date:"MM/dd/yyyy"}}</h3>
    </div>
    <div class="col-lg-4">
        <button pButton (click)="openSearchDialog()">Search</button>
    </div>
</div>
<table class="table table-striped">
    <thead>
        <tr class="sitetraxx">
            <th>
                <p-checkbox name="checkAll" binary="true" title="Approve/Unapprove All" (onChange)="checkAll()">
                </p-checkbox>
            </th>
            <th></th>
            <th>Badge #</th>
            <th>Name</th>
            <th>Monday<br />({{firstDay | date:"MM/dd"}})</th>
            <th>Tuesday<br />({{secondDay | date:"MM/dd"}})</th>
            <th>Wednesday<br />({{thridDay | date:"MM/dd"}})</th>
            <th>Thursday<br />({{fourthDay | date:"MM/dd"}})</th>
            <th>Friday<br />({{fifthDay | date:"MM/dd"}})</th>
            <th>Saturday<br />({{sixthDay | date:"MM/dd"}})</th>
            <th>Sunday<br />({{seventhDay | date:"MM/dd"}})</th>
            <th>
                <button type="button" pButton title="Export" icon="pi pi-cloud-download" class="indigo-btn float-right"
                    (click)="exportToCSV()"></button>
                <br clear="all" />
                Total
            </th>
        </tr>
    </thead>
    <tr *ngFor="let labor of laborSummary">
        <td>
            <p-checkbox [(ngModel)]="labor.approved" binary="true" title="Approve/Unapprove" (click)="appoved(labor)">
            </p-checkbox>
        </td>
        <td><button type="button" pButton (click)="edit(labor)" icon="pi pi-search"></button></td>
        <td>{{labor.badgeNum}}</td>
        <td>
            {{labor.badgeHolderName}}
            <span *ngIf="labor.isSalary==1" class="numberCircle">S</span>
            <br /> {{labor.companyName}}
            <br /> {{labor.laborClassName}}
        </td>
        <td>
            <!--
            <input type="text" (change)="changeHours(labor)" class="form-control" [(ngModel)]="labor.monday" /><br/>
            -->
            <span *ngIf="labor.mondayStatus === 1" class="numberCircle">A</span>
            <span *ngIf="labor.mondayStatus === 2" class="numberCircle">M</span>
            <span class="hours">{{labor.monday | number:'1.2-2'}}</span>
            <span class="pi pi-info-circle" (click)="showNotes(labor, 0)" *ngIf="labor.mondayHasNotes"></span>
        </td>
        <td>
            <!--
            <input type="text" (change)="changeHours(labor)" class="form-control" [(ngModel)]="labor.tuesday" /><br/>
            -->
            <span *ngIf="labor.tuesdayStatus === 1" class="numberCircle">A</span>
            <span *ngIf="labor.tuesdayStatus === 2" class="numberCircle">M</span>
            <span class="hours">{{labor.tuesday | number:'1.2-2'}}</span>
            <span class="pi pi-info-circle" (click)="showNotes(labor, 1)" *ngIf="labor.tuesdayHasNotes"></span>
        </td>
        <td>
            <!--
            <input type="text" (change)="changeHours(labor)" class="form-control" [(ngModel)]="labor.wednesday" /><br/>
            -->
            <span *ngIf="labor.wednesdayStatus === 1" class="numberCircle">A</span>
            <span *ngIf="labor.wednesdayStatus === 2" class="numberCircle">M</span>
            <span class="hours">{{labor.wednesday | number:'1.2-2'}}</span>
            <span class="pi pi-info-circle" (click)="showNotes(labor, 2)" *ngIf="labor.wednesdayHasNotes"></span>
        </td>
        <td>
            <!--
            <input type="text" (change)="changeHours(labor)" class="form-control" [(ngModel)]="labor.thursday" /><br/>
            -->
            <span *ngIf="labor.thursdayStatus === 1" class="numberCircle">A</span>
            <span *ngIf="labor.thursdayStatus === 2" class="numberCircle">M</span>
            <span class="hours">{{labor.thursday | number:'1.2-2'}}</span>
            <span class="pi pi-info-circle" (click)="showNotes(labor, 3)" *ngIf="labor.thursdayHasNotes"></span>
        </td>
        <td>
            <!--
            <input type="text" (change)="changeHours(labor)" class="form-control" [(ngModel)]="labor.friday" /><br/>
            -->
            <span *ngIf="labor.fridayStatus === 1" class="numberCircle">A</span>
            <span *ngIf="labor.fridayStatus === 2" class="numberCircle">M</span>
            <span class="hours">{{labor.friday | number:'1.2-2'}}</span>
            <span class="pi pi-info-circle" (click)="showNotes(labor, 4)" *ngIf="labor.fridayHasNotes"></span>
        </td>
        <td>
            <!--
            <input type="text" (change)="changeHours(labor)" class="form-control" [(ngModel)]="labor.saturday" /><br/>
            -->
            <span *ngIf="labor.saturdayStatus === 1" class="numberCircle">A</span>
            <span *ngIf="labor.saturdayStatus === 2" class="numberCircle">M</span>
            <span class="hours">{{labor.saturday | number:'1.2-2'}}</span>
            <span class="pi pi-info-circle" (click)="showNotes(labor, 5)" *ngIf="labor.saturdayHasNotes"></span>
        </td>
        <td>
            <!--
            <input type="text" (change)="changeHours(labor)" class="form-control" [(ngModel)]="labor.sunday" /><br/>
            -->
            <span *ngIf="labor.sundayStatus === 1" class="numberCircle">A</span>
            <span *ngIf="labor.sundayStatus === 2" class="numberCircle">M</span>
            <span class="hours">{{labor.sunday | number:'1.2-2'}}</span>
            <span class="pi pi-info-circle" (click)="showNotes(labor, 6)" *ngIf="labor.sundayHasNotes"></span>
        </td>
        <td>
            <!--
            <input type="text" (change)="changeHours(labor)" class="form-control" [(ngModel)]="labor.totalHours" />
            -->
            <span>{{labor.totalHours | number:'1.2-2'}}</span>
        </td>
    </tr>
</table>



<p-dialog header="Add Note" [(visible)]="showNoteDialog" [style]="{ width: '500px' }">
    <textarea pTextArea [(ngModel)]="notes" rows="10" readonly class="form-control"
        *ngIf="notes && notes.length > 0"></textarea>
    <textarea pTextArea [(ngModel)]="note" rows="5" class="form-control" *ngIf="user.canApproveLabor"></textarea>
    <p-footer>
        <button type="button" pButton icon="pi pi-save" (click)="saveNote()" label="Save"
            *ngIf="user.canApproveLabor"></button>
    </p-footer>
</p-dialog>

<p-dialog header="View Note" [(visible)]="viewNoteDialog" [style]="{ width: '500px' }">
    <textarea pTextArea [(ngModel)]="notes" rows="10" readonly class="form-control"
        *ngIf="notes && notes.length > 0"></textarea>
</p-dialog>

<p-dialog header="Labor Details {{currentBadgeHolder}}" [(visible)]="showDialog" modal="modal"
    [style]="{ width: (isGlobalAdmin ? '1300px' : '1200px') }" class="approve-labor-dialog">
    <div>
        <div class="row">
            <table class="table table-striped" style="width: 1000px" *ngIf="site">
                <tbody>
                    <tr>
                        <th></th>
                        <th></th>
                        <th *ngIf="site.hasOffset"></th>
                        <th colspan="2">Clock In</th>
                        <th colspan="2">Clock Out</th>
                        <th colspan="4"></th>
                        <th *ngIf="isGlobalAdmin"></th>
                    </tr>
                    <tr class="sitetraxx">
                        <th>Notes</th>
                        <th>Project</th>
                        <th *ngIf="site.hasOffset">Offset</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Hours</th>
                        <th>Time</th>
                        <th *ngIf="site.hasOffset">Adjusted Hours</th>
                        <th>Injury</th>
                        <th *ngIf="isGlobalAdmin"></th>
                    </tr>
                    <tr [ngClass]="{'bg-is-child': detail.isChild}" *ngFor="let detail of laborDetails; let i = index">
                        <td
                            [ngClass]="{ 'note-required': (detail.injury != detail.injuryOriginal && detail.injuryNoteMissing) }">
                            <button
                                *ngIf="user.canApproveLabor && !(detail.approvalNote && detail.approvalNote.length > 0)"
                                type="button" pButton (click)="notePopup(detail)" icon="pi pi-plus"></button>
                            <button
                                *ngIf="user.canApproveLabor && detail.approvalNote && detail.approvalNote.length > 0"
                                type="button" pButton (click)="notePopup(detail)" icon="pi pi-edit"></button>
                            <button
                                *ngIf="!user.canApproveLabor && detail.approvalNote && detail.approvalNote.length > 0"
                                type="button" pButton (click)="notePopup(detail)" icon="pi pi-search"></button>
                        </td>
                        <td class="text-center">
                            <p-dropdown [options]="projects" (onChange)="projectSelected(detail)" [showClear]="true"
                                class="narrow-projects" placeholder="Select a Project" [filter]="true"
                                [(ngModel)]="detail.projectId" [style]="{'width':'320px'}">
                            </p-dropdown>
                            <a (click)="splitTime(i)" title="Split Time between multiple Projects" class="split-time"
                                *ngIf="user.canApproveLabor && !detail.isChild">Split Time</a>
                            <span class="split-time" *ngIf="user.canApproveLabor && detail.hasChildren">-</span>
                            <a (click)="splitTimeEvenly(i)" title="Split Time Evenly between multiple Projects"
                                class="split-time" *ngIf="user.canApproveLabor && detail.hasChildren">Split Time
                                Evenly</a>
                        </td>
                        <td *ngIf="site.hasOffset">
                            <input name="offset" (change)="changeDetailHours(detail)" type="number" style="width: 75px"
                                class="form-control" [(ngModel)]="detail.offset" />
                        </td>
                        <td>
                            {{detail.clockIn | date:"MM/dd/yyyy"}}
                        </td>
                        <td>
                            <div class="narrow-control narrow-time" [ngClass]="{ 'with-icon': detail.canMoveOver }">
                                <p-calendar *ngIf="user.canApproveLabor" name="clockInTime" class="narrow-control"
                                    [(ngModel)]="detail.clockInTime" [timeOnly]="true" [disabled]="detail.deleted"
                                    (onSelect)="changeDetailTime(detail, false, i, true)"
                                    (change)="changeDetailTime(detail, true, i, true)">
                                </p-calendar>
                                <button type="button" pButton (click)="moveOver(detail)" icon="pi pi-arrow-right"
                                    title="Move Clock In to Clock Out" *ngIf="detail.canMoveOver"></button>
                            </div>
                            <span *ngIf="!user.canApproveLabor">{{ detail.clockIn | date:"HH:mm:ss" }}</span>
                        </td>
                        <td>
                            <p-calendar *ngIf="user.canApproveLabor" name="clockOutDate" class="narrow-control"
                                [(ngModel)]="detail.clockOut" [showIcon]="true" [disabled]="detail.deleted"
                                (onSelect)="changeDetailTime(detail, false, i, false)"
                                (change)="changeDetailTime(detail, true, i, false)">
                            </p-calendar>
                            <span *ngIf="!user.canApproveLabor">{{ detail.clockOut | date:"MM/dd/yyyy" }}</span>
                        </td>
                        <td>
                            <div class="narrow-control narrow-time" [ngClass]="{ 'with-icon': detail.combineAbove }">
                                <p-calendar *ngIf="user.canApproveLabor" name="clockOutTime"
                                    [(ngModel)]="detail.clockOutTime" [timeOnly]="true" [disabled]="detail.deleted"
                                    (onSelect)="changeDetailTime(detail, false, i, false)"
                                    (change)="changeDetailTime(detail, true, i, false)">
                                </p-calendar>
                                <button type="button" pButton (click)="combineAbove(detail, detail.combineAbove)"
                                    icon="pi pi-arrow-up" title="Move to Clock Out for previous row"
                                    *ngIf="detail.combineAbove"></button>
                                <button type="button" pButton (click)="combineBelow(detail, detail.combineBelow)"
                                    icon="pi pi-arrow-down" title="Move to Clock In for next row"
                                    *ngIf="detail.combineBelow"></button>
                            </div>
                            <span *ngIf="!user.canApproveLabor">{{ detail.clockOut | date:"HH:mm:ss" }}</span>
                        </td>
                        <td>
                            <input *ngIf="user.canApproveLabor" name="hours" (change)="changeDetailHours(detail, i)"
                                style="width: 100px" class="form-control" [(ngModel)]="detail.hours"
                                [disabled]="detail.deleted" type="number" />
                            <span *ngIf="!user.canApproveLabor">{{ detail.hours }}</span>
                        </td>
                        <td>{{detail.totalCalc}}</td>
                        <td *ngIf="site.hasOffset">{{ (detail.offset + detail.hours) }}</td>
                        <td>
                            <p-checkbox binary="true" [(ngModel)]="detail.injury" name="injury"
                                (onChange)="detail.modified = true"
                                [disabled]="!user.canApproveLabor || detail.deleted"></p-checkbox>
                        </td>
                        <td *ngIf="isGlobalAdmin">
                            <button type="button" pButton (click)="delete(detail)" icon="pi pi-trash"
                                *ngIf="!detail.deleted"></button>
                            <button type="button" pButton (click)="undelete(detail)" icon="pi pi-undo"
                                *ngIf="detail.deleted"></button>
                        </td>
                    </tr>
                </tbody>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><strong>Total:</strong></td>
                    <td><strong>{{totalHours}}</strong></td>
                    <td></td>
                    <td></td>
                    <td *ngIf="isGlobalAdmin"></td>
                </tr>
            </table>
        </div>
    </div>
    <p-footer>
        <button *ngIf="user.canApproveLabor" type="button" pButton icon="pi pi-save" (click)="save()"
            label="Save"></button>
        <button type="button" pButton icon="pi pi-ban" (click)="cancel()" label="Cancel"></button>
    </p-footer>
</p-dialog>
<app-badgeholder-search [(showDialog)]="showSearchDialog" (badgeHolderChange)="badgeHolderChangedHandler($event)">
</app-badgeholder-search>