<div *ngIf="pageInfo">
    <div class="container">
        <div class="row">
            <div class="col">
                <h3>Companies</h3>
            </div>
            <div class="col">
                <div class="float-right" *ngIf="isGlobalAdmin">
                    <button pButton (click)="createExcel()" label="Excel" icon="pi pi-file-excel"
                        class="indigo-btn"></button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <st-table pageId="{{pageInfo.id}}" dataTable="{{pageInfo.dataName}}" filter="?fullList=true"></st-table>
            </div>
        </div>
    </div>
</div>

<div *ngIf="company">
    <app-company-profile [company]="company"></app-company-profile>
</div>