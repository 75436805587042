import { Component, Input, OnInit, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {SelectItem} from 'primeng/api';

import { DataService } from '../../services/data.service';
import { MessageService } from 'primeng/api';
import { environment } from '../../../../environments/environment';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-upload-photo',
    templateUrl: './uploadphoto.component.html',
    encapsulation: ViewEncapsulation.None
})
export class UploadPhotoComponent implements OnInit {
  showDialogValue: boolean;

  @Output()
  showDialogChange = new EventEmitter<boolean>();

  @Input() category: string;

  @Input()
  get showDialog() {
    return this.showDialogValue;
  }

  @Input() objectId: string;

  set showDialog(val) {
    this.showDialogValue = val;
    this.showDialogChange.emit(this.showDialogValue);
  }

    uploadedFiles: any[] = [];
    uploadImageUrl: string;
    env: any = environment;
    photo: string;
    user: any;

    constructor(
        private dataService: DataService,
        private messageService: MessageService,
        private userService: UserService) { }

    ngOnInit(): void {
        this.user = this.userService.currentUser();

        this.uploadImageUrl = this.env.baseAPIUrl
            + 'images/upload/'
            + this.category
            + '/'
            + this.user.siteId
            + '/'
            + this.objectId;
    }

    showUploadDialog() {
        this.uploadedFiles = [];
        this.showDialog = true;
    }

    closeUploadDialog() {
        this.showDialog = false;
    }

    updatePhoto() {

    }

    onUpload(event) {
        for (const file of event.files) {
            this.uploadedFiles.push(file);
        }

        this.updatePhoto();

        this.messageService.clear();
        this.messageService.add({severity: 'info', summary: 'File Uploaded', detail: ''});
    }
}
