import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BreadCrumbService } from '../../services/breadcrumb.service';
import { DataService } from '../../services/data.service';
import { LoggingService } from '../../services/logging.service';

@Component({
  selector: 'app-takesurvey-detail',
  templateUrl: './takesurvey.detail.component.html',
  styleUrls: ['./takesurvey.detail.component.css']
})
export class TakeSurveyDetailComponent implements OnInit, OnChanges {
@Input() data: any;

showDialogFlag: boolean;
survey: any;
badgeHolder: any;
questions: any[];
responses: any[];
showSearchDialog = false;
enableSurveyButton = true;
viewSurveyDialog = false;

  constructor(
    private dataService: DataService,
    private loggingService: LoggingService,
    private breadcrumbService: BreadCrumbService,
    private router: Router) { }

  ngOnInit() {
    this.survey = this.data;
    this.loadQuestions();
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    this.breadcrumbService.setItemsByCategoryAndName('Surveys', 'Take Survey', '/takesurveys/search', '', this.router.url);
  }

  save() {
    this.dataService.save('surveys', this.survey)
        .subscribe((data) => {
            this.loggingService.savedMessage();
        });
  }

  cancel() {
    this.router.navigate(['/surveys/search/']);
  }

  openSearchDialog(): void {
    this.showSearchDialog = true;
  }

  badgeHolderChangedHandler(badgeHolderSelected: any) {
    this.badgeHolder = badgeHolderSelected;
    this.enableSurveyButton = false;
    this.initalizeResponses();
  }

  loadQuestions() {
    this.dataService.getById('surveys/questions', this.survey.id)
      .subscribe((data) => {
          this.questions = data;
      });
  }

  initalizeResponses() {
    this.responses = [];

    this.questions.forEach(question => {
      this.responses.push({ 
        name: question.name,
        surveyId: this.survey.id,
        questionId: question.id,
        questionType: question.questionType,
        badgeHolderId: this.badgeHolder.id,
        value: ''
       });
    });
  }

  startSurvey() {
    this.viewSurveyDialog = true;
  }

  submitSurvey() {
    this.dataService.save('surveys/responses', this.responses)
      .subscribe((data) => {
        this.viewSurveyDialog = false;
        this.loggingService.successMessage('Survey','Survey Submitted');
      });    
      
  }

}
