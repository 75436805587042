import { Injectable } from '@angular/core';
import { Subject ,  Observable } from 'rxjs';
import { MenuItem } from 'primeng/api';

@Injectable()
export class BreadCrumbService {

    private itemsSource = new Subject<MenuItem[]>();

    itemsHandler = this.itemsSource.asObservable();

    setItems(items: MenuItem[]) {
        this.itemsSource.next(items);
    }

    setItemsByCategory(section: string, title: string, path: string) {
        if (section !== undefined) {
            const items = [
                {label: section},
                {label: title, routerLink: [path]}
            ];

            this.itemsSource.next(items);
        }
    }

    setItemsByCategoryAndName(section: string, title: string, path: string, name: string, detailPath: string) {
        const items = [
            {label: section},
            {label: title, routerLink: [path]}
        ];

        if (name !== undefined && name !== null && name !== '') {
            if (name.length > 10) {
                name = name.substring(0, 50);
            }
            if (detailPath !== null && detailPath !== '') {
                items.push({label: name, routerLink: [detailPath]});
            } else {
                items.push({label: name});
            }
        }

        this.itemsSource.next(items);
    }
}
