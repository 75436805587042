import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { BreadCrumbService } from '../services/breadcrumb.service';
import { DataService } from '../services/data.service';
import { ActivatedRoute } from '@angular/router';
import { LoggingService } from '../services/logging.service';
import { Subscription } from 'rxjs';
import { UserService } from '../services/user.service';
import { DateService } from '../services/date.service';
import { STTableComponent } from '../st-table/table.component';

@Component({
    selector: 'app-buildingmaterials',
    templateUrl: './buildingmaterial.component.html'
})
export class BuildingMaterialComponent implements OnInit {

    @ViewChild(STTableComponent) stTable: STTableComponent;

    pageInfo: any;
    path: string;
    sub: any;
    id: string;
    buildingmaterial: any;
    category = 'buildingmaterial';
    scheduledStartDate: Date;
    scheduledEndDate: Date;
    filterInfo = '';
    gridData = [];

    constructor(
        private route: ActivatedRoute,
        private dataService: DataService,
        private breadcrumbService: BreadCrumbService,
        private loggingService: LoggingService,
        private userService: UserService,
        private dateService: DateService,
        private router: Router) { }

    ngOnInit(): void {

        this.sub = this.route.params.subscribe(params => {
            this.id = params['id'];

            if (this.id === undefined) {
                this.loadData();
            } else {
                if (this.id === 'new') {
                    this.loggingService.logPageView('Building Material - New');
                    this.buildingmaterial = {};
                } else {
                    this.loggingService.logPageView('Building Material - Profile');
                    this.dataService.getById('buildingmaterials', this.id)
                        .subscribe((data) => {
                            this.buildingmaterial = data;
                        });
                }
            }
        });
    }

    filter() {
        if (this.scheduledStartDate || this.scheduledEndDate) {
            var startDate = (this.scheduledStartDate ? this.dateService.formatDate(new Date(this.scheduledStartDate)) : 'undefined');
            var endDate = (this.scheduledEndDate ? this.dateService.formatDate(new Date(this.scheduledEndDate)) : 'undefined');
            this.filterInfo = "date/range/" + startDate + "/" + endDate;
        }
        else
            this.filterInfo = "";

        this.loadData();
    }

    clear() {
        this.scheduledStartDate = null;
        this.scheduledEndDate = null;
        this.filterInfo = "";
        this.loadData();
    }

    loadData() {
        this.loggingService.logPageView('Building Material - Grid');
        this.buildingmaterial = null;
        if (this.pageInfo) {
            this.stTable.filter = this.filterInfo;
            this.stTable.loadData();
        }
        else
            this.dataService.getById('pages', 'buildingmaterials')
                .subscribe((data) => {
                    this.pageInfo = data.result;
                    this.breadcrumbService.setItemsByCategory('Controls', this.pageInfo.title, this.router.url);
                });
    }
}
