<div class="panel-body" id="otherPanel">
    <div class="row">
        <div class="col-lg-6">
            <div><b>Sex:</b></div>
            <p-radioButton name="gender" value="M" [(ngModel)]="badgeHolder.gender" label="Male (M)"></p-radioButton>
            <p-radioButton name="gender" value="F" [(ngModel)]="badgeHolder.gender" label="Female (F)"></p-radioButton>
            <p-radioButton name="gender" value="N" [(ngModel)]="badgeHolder.gender" label="N/A"></p-radioButton>
        </div>
        <div class="col-lg-6">
            <label class="control-label">Ethnicity:</label>
            <select class="form-control" name="race" [(ngModel)]="badgeHolder.race">
                <option value="NA">N/A</option>
                <option value="AA">African American</option>
                <option value="A">Asian</option>
                <option value="W">Caucasian</option>
                <option value="H">Hispanic</option>
                <option value="O">Other</option>
            </select>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6">
            <div><b>Preferred Language:</b></div>
            <div class="form-check-inline">
                <p-radioButton name="preferredLanguage" value="E" [(ngModel)]="badgeHolder.preferredLanguage" label="English"></p-radioButton>
            </div>
            <div class="form-check-inline">
                <p-radioButton name="preferredLanguage" value="S" [(ngModel)]="badgeHolder.preferredLanguage" label="Spanish"></p-radioButton>
            </div>
            <div class="form-check-inline">
                <p-radioButton name="preferredLanguage" value="O" [(ngModel)]="badgeHolder.preferredLanguage" label="Other"></p-radioButton>
            </div>
        </div>
        <div class="col-lg-6">
            <label class="control-label">Year of Birth:</label>
            <p-dropdown [options]="years" placeholder="Select a Year" [filter]="true" name="dob" [(ngModel)]="badgeHolder.dob"></p-dropdown>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <label class="control-label">Drivers License #:</label>
            <input type="text" class="form-control" *ngIf="badgeHolder" pInputText name="driversLicenseNumber" [(ngModel)]="badgeHolder.driversLicenseNumber" maxlength="25" />
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6">
            <label class="control-label">Phone Number:</label>
            <p-inputMask mask="(999)999-9999" [(ngModel)]="badgeHolder.phone" name="phone" (change)="onPhoneChanged()" placeholder="(999)999-9999" *ngIf="badgeHolder"></p-inputMask>
        </div>
        <div class="col-lg-6">
            <br />
            <p-checkbox name="textReceived" binary="true" label="Text can be received" [(ngModel)]="badgeHolder.textPhone" [disabled]="badgeHolder.hasRefusedTexts"></p-checkbox>
            <div class="alert alert-warning" *ngIf="badgeHolder.hasRefusedTexts">
              This badge holder has opted out of text messages.
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <label class="control-label">Email:</label>
            <input type="text" class="form-control" *ngIf="badgeHolder" pInputText name="email" [(ngModel)]="badgeHolder.email" maxlength="100" />
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6">
            <label class="control-label">Emergency Management Team:</label>
            <div class="form-check-inline">
                <p-radioButton name="optEmergencyTeam" value="1" [(ngModel)]="badgeHolder.emergencyTeam" label="Yes"></p-radioButton>
            </div>
            <div class="form-check-inline">
                <p-radioButton name="optEmergencyTeam" value="0" [(ngModel)]="badgeHolder.emergencyTeam" label="No"></p-radioButton>
            </div>
        </div>
        <div class="col-lg-6">
            <label class="control-label">Emergency Refuge Station:</label>
            <input type="text" class="form-control" *ngIf="badgeHolder" pInputText name="refugeStation" [(ngModel)]="badgeHolder.refugeStation" maxlength="50" />
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <label class="control-label">Salary Worker:</label>
            <div class="form-check-inline">
                <p-radioButton name="isSalary" value="1" [(ngModel)]="badgeHolder.isSalary" label="Yes"></p-radioButton>
            </div>
            <div class="form-check-inline">
                <p-radioButton name="isSalary" value="0" [(ngModel)]="badgeHolder.isSalary" label="No"></p-radioButton>
            </div>
        </div>
    </div>
</div>
