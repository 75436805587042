<div class="row">
    <div class="col-lg-12">
        <h3 class="float-left" style="margin-top: 0;">Date Range:&nbsp;&nbsp;</h3>
        <p-calendar class="float-left" [(ngModel)]="fromDate" (onSelect)="loadData()" [showIcon]="true"></p-calendar>
        <h3 class="float-left" style="margin-top: 0;">&nbsp;&nbsp;-&nbsp;&nbsp;</h3>
        <p-calendar class="float-left" [(ngModel)]="toDate" (onSelect)="loadData()" [showIcon]="true"></p-calendar>
    </div>
</div>
<table class="table table-striped" *ngIf="data">
    <thead>
        <tr class="sitetraxx">
            <th></th>
            <th>Badge #</th>
            <th>Name</th>
            <th>Company</th>
            <th>Labor Class</th>
            <th>Date</th>
            <th>Injury</th>
        </tr>
    </thead>
    <tr *ngFor="let m of data">
        <td><button type="button" pButton (click)="addNote(m)" icon="pi pi-plus"></button></td>
        <td>{{m.badgeNum}}</td>
        <td>{{m.badgeHolderName}}</td>
        <td>{{m.companyName}}</td>
        <td>{{m.laborClass}}</td>
        <td>{{m.clockIn | date:"MM/dd/yyyy"}}</td>
        <td>
            <p-checkbox binary="true" [(ngModel)]="m.injury" name="injury" (onChange)="saveInjury(m)"></p-checkbox>
        </td>
    </tr>
</table>



<p-dialog header="Add Note" [(visible)]="noteItem" [style]="{ width: '500px' }">
    <textarea pTextArea [(ngModel)]="noteItem.approvalNote" rows="5" class="form-control" *ngIf="noteItem"></textarea>
    <p-footer>
        <button type="button" pButton icon="pi pi-save" (click)="saveNote()" label="Save"></button>
    </p-footer>
</p-dialog>
