<form #thisForm='ngForm'>
    <div class="container" *ngIf="data">
        <div class="row">
            <div class="col">
                <span class="grid-header">{{data.items.length}} Companies - Week {{data.currentWeek}}/{{data.currentYear}}</span>
                <div class="float-right">
                    <button pButton (click)="save()" [disabled]="!thisForm.valid" icon="pi pi-save" label="Save"></button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="search-col">
                    <input pInputText type="text" (input)="dt.filter($event.target.value, 'name', 'contains'); dtAll.filter($event.target.value, 'name', 'contains')" />
                    <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                </div>
                <div class="type-col">
                    <div class="form-check-inline">
                        <p-radioButton name="optType" value="1" [(ngModel)]="optType" label="Headcount"></p-radioButton>
                    </div>
                    <div class="form-check-inline">
                        <p-radioButton name="optType" value="0" [(ngModel)]="optType" label="Man-hours"></p-radioButton>
                    </div>
                </div>
                <div class="legend-col">
                    <span class="float-left color-coded green">&nbsp;</span>
                    <span class="float-left legend-text">0-9.9% variance +/-</span>
                </div>
                <div class="legend-col">
                    <span class="float-left color-coded yellow">&nbsp;</span>
                    <span class="float-left legend-text">10-19.99% variance +/-</span>
                </div>
                <div class="legend-col">
                    <span class="float-left color-coded red">&nbsp;</span>
                    <span class="float-left legend-text">20% or higher</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p-table #dt [value]="data.items" [scrollable]="true" scrollHeight="700px" sortField="forecastVariance" sortOrder="-1">
                    <ng-template pTemplate="colgroup">
                        <colgroup>
                            <col style="width:170px">
                            <col style="width:70px">
                            <col style="width:70px">
                            <col style="width:70px">
                            <col style="width:70px">
                            <col style="width:95px">
                            <col style="width:95px">
                            <col style="width:100px">
                            <col style="width:90px">
                            <col *ngFor="let i of [0,1,2,3,4,5]" style="width:50px">
                        </colgroup>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th [pSortableColumn]="'name'">Company Name
                                <p-sortIcon [field]="'name'"></p-sortIcon>
                            </th>
                            <th [pSortableColumn]="'enrolled'" class="locked">Total Enrolled<br/>Workers
                                <p-sortIcon [field]="'enrolled'"></p-sortIcon>
                            </th>
                            <th [pSortableColumn]="'activeLastWeek'" class="locked">Previous week<br/>Active Workers
                                <p-sortIcon [field]="'activeLastWeek'"></p-sortIcon>
                            </th>
                            <th [pSortableColumn]="'lastWeekTotal'" class="locked">Previous Week {{(optType === '0') ? "Adjusted " : ""}}Total<br/>Man-{{(optType === '0') ? "Hours" : "Days"}}<br *ngIf="optType === '0'"/>{{(optType === '0') ? "per ST" : ""}}<br/>({{(optType === '0') ? "Hours" : "Headcount"}})<br/>
                                <p-sortIcon [field]="'lastWeekTotal'"></p-sortIcon>
                            </th>
                            <th [pSortableColumn]="'days'" class="locked">Days Onsite<br/>Selected Week
                                <p-sortIcon [field]="'days'"></p-sortIcon>
                            </th>
                            <th [pSortableColumn]="'active'" class="locked">Selected week<br/>Active Workers
                                <p-sortIcon [field]="'active'"></p-sortIcon>
                            </th>
                            <th [pSortableColumn]="'actualTotal'">{{(optType === '0') ? "Adjusted " : ""}}Total<br/>Man-{{(optType === '0') ? "Hours" : "Days"}}<br *ngIf="optType === '0'"/>{{(optType === '0') ? "per ST" : ""}}<br/>({{(optType === '0') ? "Hours" : "Headcount"}})<br/>
                                <p-sortIcon [field]="'actualTotal'"></p-sortIcon>
                            </th>
                            <th [pSortableColumn]="'forecastVariance'">Weekly Variance on Forecast
                                <p-sortIcon [field]="'forecastVariance'"></p-sortIcon>
                            </th>
                            <th [pSortableColumn]="'forecasted'">Selected week Forecasted<br/>({{(optType === '0') ? "Hours" : "Headcount"}})<br/>
                                <p-sortIcon [field]="'forecasted'"></p-sortIcon>
                            </th>
                            <th *ngFor="let m of data.weeks" [pSortableColumn]="m.name">
                                w{{m.week}}<br />
                                {{m.year}}
                                <p-sortIcon [field]="m.name"></p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                        <tr>
                            <td>{{rowData.name}}</td>
                            <td>{{rowData.enrolled | number: '.0-0'}}</td>
                            <td>{{rowData.activeLastWeek | number: '.0-0'}}</td>
                            <td>{{((optType === '0') ? rowData.lastWeekTotal : rowData.lastWeekTotalHeadCount) | number: '.0-1'}}</td>
                            <td>{{rowData.days | number: '.0-0'}}</td>
                            <td>{{rowData.active | number: '.0-0'}}</td>
                            <td>{{((optType === '0') ? rowData.actualTotal : rowData.actualTotalHeadCount) | number: '.0-0'}}</td>
                            <td [ngClass]="className((optType === '0') ? rowData.forecastVarianceTrend : rowData.forecastVarianceTrendHeadCount)"><span>{{((optType === '0') ? rowData.forecastVariance : rowData.forecastVarianceHeadCount) | number: '.0-1'}}%</span></td>
                            <td>
                                <input pInputText type="number" [(ngModel)]="rowData.forecasted" name="forecasted{{rowData.id}}" required (change)="forecastedChanged(rowData)" *ngIf="optType === '0'" />
                                <input pInputText type="number" [(ngModel)]="rowData.forecastedHeadCount" name="forecastedHeadCount{{rowData.id}}" required (change)="forecastedHeadCountChanged(rowData)" *ngIf="optType !== '0'" />
                            </td>
                            <td *ngFor="let m of data.weeks">
                                <span *ngFor="let cw of rowData.weeks">
                                    <span *ngIf="cw.year === m.year && cw.week === m.week" class="color-coded" [ngClass]="className((optType === '0') ? cw.trend : cw.trendHeadCount)">
                                        <i class="fa ui-icon-trending-up" *ngIf="((optType === '0') ? cw.trend : cw.trendHeadCount) > 0"></i>
                                        <i class="fa ui-icon-trending-down" *ngIf="((optType === '0') ? cw.trend : cw.trendHeadCount) < 0"></i>
                                    </span>
                                </span>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
        <br />
        <div class="row" *ngIf="allItems">
            <div class="col">
                <p-table #dtAll [columns]="dataColumns" [frozenColumns]="frozenColumns" [value]="allItems" [scrollable]="true" scrollHeight="300px" frozenWidth="250px" sortField="name" sortOrder="1">
                    <ng-template pTemplate="colgroup" let-columns>
                        <colgroup>
                            <col *ngFor="let col of columns" [style.width.px]="(col.header === 'Name') ? 250: 50">
                        </colgroup>
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th style="height: 80px" *ngFor="let col of columns" [pSortableColumn]="col.field">
                                <span *ngIf="col.header === 'Name'">{{col.header}}</span>
                                <br *ngIf="col.header === 'Name'" />
                                <br *ngIf="col.header === 'Name'" />
                                <span *ngIf="col.header !== 'Name'">w{{col.week}}<br />{{col.year}}</span>
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr style="height: 47px;">
                            <td style="overflow: hidden" *ngFor="let col of columns">
                                <span style="white-space: nowrap;" *ngIf="col.field === 'name'">{{rowData[col.field]}}</span>
                                <span *ngIf="col.field !== 'name'">
                                    <span *ngFor="let cw of rowData.weeks">
                                        <span *ngIf="cw.year === col.year && cw.week === col.week" class="color-coded" [ngClass]="className(((optType === '0') ? cw.trend : cw.trendHeadCount) )">
                                            <i class="fa ui-icon-trending-up" *ngIf="((optType === '0') ? cw.trend : cw.trendHeadCount)  > 0"></i>
                                            <i class="fa ui-icon-trending-down" *ngIf="((optType === '0') ? cw.trend : cw.trendHeadCount)  < 0"></i>
                                        </span>
                                </span>
                                </span>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</form>