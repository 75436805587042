<div class="container">
    <div class="row">
        <div class="col-lg-12">
            <button type="button" label="Refresh" pButton (click)="loadGatewayLogs()" icon="pi pi-refresh"></button>
            <table>
                <tr>
                    <th style="padding-left: 15px">Gateway</th>
                    <th style="padding-left: 15px">Time</th>
                    <th style="padding-left: 15px">Signal Strength</th>
                </tr>
                <tr *ngFor="let gateway of gatewayLogs">
                    <td style="padding-left: 15px">{{gateway.gatewayName}} ({{gateway.gatewayId}})</td>
                    <td style="padding-left: 15px">{{dateService.convertDateToLocalTimeDate(gateway.clockTime) | date:'MM/dd/yyyy HH:mm:ss a'}}</td>
                    <td style="padding-left: 15px">{{gateway.rssi}}</td>
                </tr>
            </table>
        </div>
    </div>
</div>