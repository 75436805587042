import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Message } from 'primeng/api';
import {SelectItem} from 'primeng/api';
import { LoggingService } from '../../services/logging.service';
import { DataService } from '../../services/data.service';
import { Subscription } from 'rxjs';
import { UserService } from '../../services/user.service';
import { DateService } from '../../services/date.service';

@Component({
    selector: 'app-site-required',
    templateUrl: './site.required.component.html',
    encapsulation: ViewEncapsulation.None
})
export class SiteRequiredComponent implements OnInit {
    @Input() site: any;

    deliveryMethods: any[];
    daysOfWeek: SelectItem[];
    siteSubscription: Subscription;
    startDate: Date;
    endDate: Date;


    constructor(private dataService: DataService,
         private loggingService: LoggingService,
         private dateService: DateService,
         private userService: UserService) {
        this.daysOfWeek = [
            {label: 'Monday', value: '1'},
            {label: 'Tuesday', value: '2'},
            {label: 'Wednesday', value: '3'},
            {label: 'Thursday', value: '4'},
            {label: 'Friday', value: '5'},
            {label: 'Saturday', value: '6'},
            {label: 'Sunday', value: '0'},
        ];
        this.siteSubscription = this.userService.getSiteEvent()
            .subscribe(siteId => {
                this.loadDeliveryMethods();
            });
    }

    ngOnInit() {
        this.site.demolitionRequired = (this.site.demolitionRequired === 1) ? '1' : '0';
        this.site.typeOfContruction = (this.site.typeOfContruction === 1) ? '1' : '0';
        this.site.bondRequired = (this.site.bondRequired === 1) ? '1' : '0';

        if (this.site.startDate === undefined || this.site.startDate === null) {
            this.startDate = new Date();
        } else {
            this.startDate = this.dateService.convertDateUniversalToSiteTimeDate(new Date(this.site.startDate));
        }

        if (this.site.endDate === undefined || this.site.endDate === null) {
            this.endDate = new Date();
        } else {
            this.endDate = this.dateService.convertDateUniversalToSiteTimeDate(new Date(this.site.endDate));
        }

        this.loadDeliveryMethods();
    }

    ngOnDestroy() {
        this.siteSubscription.unsubscribe();
    }

    loadDeliveryMethods(): void {
        this.dataService.getById('lookups', 'deliverymethod')
            .subscribe((lookup) => {
                this.deliveryMethods = lookup;
        });
    }

    onStartDateChange() {
        this.site.startDate = this.dateService.convertDateLocalToSiteTimeDate(new Date(this.startDate));
    }

    onEndDateChange() {
        this.site.endDate = this.dateService.convertDateLocalToSiteTimeDate(new Date(this.endDate));
    }

}
