import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoggingService } from '../../services/logging.service';
import { DataService } from '../../services/data.service';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-delay-grid',
  templateUrl: './delay.grid.component.html',
  styleUrls: ['./delay.grid.component.css']
})
export class DelayGridComponent implements OnInit, OnChanges {
  @Input() dateFilter: string;

  delays: any[];

  constructor(
    private dataService: DataService,
    private router: Router,
    private loggingService: LoggingService) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.loadDelays();
  }

  loadDelays(): void {
    this.dataService.getById('delays', this.dateFilter)
      .subscribe((data) => {
        this.delays = data;
      });
  }

  add(): void {
    this.router.navigate(['/delays/detail/new']);
  }

  edit(currentDelay: any): void {
    this.router.navigate(['/delays/detail/' + currentDelay.id]);
  }


  private exportToCSV(): void {
      let csv = 'Subject,Location,Type,Details';
      csv = csv + '\n';


      for (const row of this.delays) {
          let csvRow = row.subject;
          csvRow += ',';
          csvRow += row.location;
          csvRow += ',';
          csvRow += row.delayType;
          csvRow += ',';
          csvRow += row.description;

          csv += csvRow + '\n';
      }

      const blob = new Blob(['\uFEFF', csv], {type: 'text/csv'});
      const link = document.createElement('a');
      link.setAttribute('href', window.URL.createObjectURL(blob));
      link.setAttribute('download', 'ActivityLogDelays.csv');
      document.body.appendChild(link); // Required for FF
      link.click();
  }
}
