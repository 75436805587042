import { Component, OnInit, DoCheck } from '@angular/core';
import { DataService } from '../../services/data.service';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../../services/user.service';
import { DateService } from '../../services/date.service';
import { BreadCrumbService } from '../../services/breadcrumb.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-daily-attendance',
  templateUrl: './daily-attendance.component.html'
})
export class DailyAttendanceComponent implements OnInit {

  html: any;
  startDate: Date;
  endDate: Date;
  startDateStr: string;
  endDateStr: string;
  siteSubscription: Subscription;
  cols: any[];
  header: any;
  companyId: string = 'all';
  data: any[];
  section = 'Reports';
  title = 'Daily Attendance';
  user: any;

  constructor(private dataService: DataService,
              private userService: UserService,
              private dateService: DateService,
              private breadcrumbService: BreadCrumbService,
              private router: Router) {
                this.siteSubscription = this.userService.getSiteEvent()
                  .subscribe(siteId => {
                    this.loadHeader();
                });
              }

  ngOnInit() {
    this.breadcrumbService.setItemsByCategory(this.section, this.title, this.router.url);
    this.user = this.userService.currentUser();
    this.startDateStr = this.dateService.formatDate(new Date());
    this.loadHeader();
  }

  ngOnDestroy() {
    this.siteSubscription.unsubscribe();
  }

  onSubmitForm() {

    const reportData = {
      startDate: this.startDateStr,
      endDate: this.startDateStr,
      userId: this.user.id,
      companyIds: [this.companyId]
    };
    const path = 'reports/daily/attendance/json';
    this.dataService.postForm(path, reportData)
        .subscribe((data) => {
          this.data = data;
        });

  }

  onStartDateChange() {
    this.startDateStr = this.dateService.formatDate(new Date(this.startDate));
  }

  loadHeader() {
    this.cols = [
      { field: 'companyName', header: 'Company', type: 'text', width: '50px' },
      { field: 'badgeHolderName', header: 'Name', type: 'text', width: '50px' },
      { field: 'address', header: 'Address', type: 'text', width: '50px' },
      { field: 'city', header: 'City', type: 'text', width: '50px' },
      { field: 'state', header: 'State', type: 'text', width: '10px' },
      { field: 'zipCode', header: 'Zip', type: 'text', width: '10px' },
      { field: 'phone', header: 'Phone', type: 'text', width: '50px' },
      { field: 'email', header: 'Email', type: 'text', width: '50px' },
      { field: 'siteSpecificId', header: 'Site Specific Id', type: 'text', width: '50px' },
      { field: 'clockIn', header: 'Clock In', type: 'datetime', width: '50px' },
      { field: 'clockOut', header: 'Clock Out', type: 'datetime', width: '50px' }
      ];
  }

  createPDF(): void {
    const reportData = {
      startDate: this.startDateStr,
      endDate: this.endDateStr,
      userId: this.user.id,
      companyIds: [this.companyId]
    };
    const path = 'reports/daily/attendance/pdf';
    this.dataService.postForm(path, reportData, {responseType: 'arraybuffer'})
        .subscribe((data) => {
          const file = new Blob([data], {type: 'application/pdf'});
          const fileURL = window.URL.createObjectURL(file);

          const a = document.createElement('a');
          document.body.appendChild(a);
          a.href = fileURL;
          a.download = 'attendance.pdf';
          a.click();

          window.URL.revokeObjectURL(fileURL);
          a.remove();
        });

  }

  createExcel(): void {

    const reportData = {
      startDate: this.startDateStr,
      endDate: this.startDateStr,
      userId: this.user.id,
      companyIds: [this.companyId]
    };
    const path = 'reports/daily/attendance/excel';
    this.dataService.postForm(path, reportData, {responseType: 'arraybuffer'})
        .subscribe((data) => {
          const file = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
          const fileURL = window.URL.createObjectURL(file);

          const a = document.createElement('a');
          document.body.appendChild(a);
          a.href = fileURL;
          a.download = 'attendance.xlsx';
          a.click();

          window.URL.revokeObjectURL(fileURL);
          a.remove();
        });
  }
}
