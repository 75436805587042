<div class="container">
    <div class="row">
        <div class="col">
            <span class="grid-header">Completed Surveys</span>
        </div>
        <div class="col text-right">
            <label class="control-label">Filter Date:</label>
            <p-calendar class="narrow-control" [(ngModel)]="dateFilter" name="dateFilter" [showIcon]="true"
                (onSelect)="dateFilterChaned()" (change)="dateFilterChaned()">
            </p-calendar>
        </div>
    </div>
    <div class="row">
        <table class="table table-striped">
            <thead>
                <tr class="sitetraxx">
                    <th>View</th>
                    <th>Name</th>
                    <th>Date</th>
                </tr>
            </thead>
            <tr *ngFor="let attempt of attempts">
                <td>
                    <button type="button" pButton (click)="view(attempt)" icon="pi pi-search"
                        style="max-width: 90%"></button>
                </td>
                <td>{{attempt.firstName}} {{attempt.lastName}}</td>
                <td>{{ dateService.convertDateUniversalToSiteTimeDate(attempt.surveyAttemptDate) | date:'MM/dd/yyyy hh:mm a'}}</td>
            </tr>
        </table>
    </div>
</div>

<p-dialog header="Survey Response" [(visible)]="showAddDialog" [responsive]="true"
    [style]="{ width: '600px', height: '500px' }">
    <div>{{badgeHolderName}}</div>
    <div>Survey: {{surveyName}}</div>
    <label>Questions</label>
    <table class="table table-striped" *ngIf="attemptData">
        <tr *ngFor="let response of attemptData.surveyResponses" style="padding: 2px;">
            <td>{{response.name}}</td>
            <td width="100">
                <span *ngIf="response.value">Yes</span>
                <span *ngIf="!response.value">No</span>
            </td>
        </tr>
    </table>
    <label>Followup</label>
    <textarea pTextArea [(ngModel)]="attemptData.surveyAttempt.notes" rows="5" class="form-control"
        *ngIf="attemptData && attemptData.surveyAttempt"></textarea>
    <div *ngIf="attemptData && attemptData.surveyAttempt && attemptData.notesByUser">
        by: {{attemptData.notesByUser.name}} on {{attemptData.surveyAttempt.notesDate | date:'MM/dd/yyyy h:mm a'}}
    </div>
    <p-footer>
        <button type="button" pButton icon="pi pi-save" (click)="save()" label="Save"></button>
        <button type="button" pButton icon="pi pi-ban" (click)="cancel()" label="Cancel"></button>
    </p-footer>
</p-dialog>