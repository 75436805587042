import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LoggingService } from '../../services/logging.service';
import { DataService } from '../../services/data.service';
import { Message } from 'primeng/api';

@Component({
    selector: 'app-project-profile',
    templateUrl: './project.profile.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ProjectProfileComponent implements OnInit {
@Input() project: any;

showTabFlag: boolean;
message: Message[] = [];
uoms: any[];

    constructor(
        private route: ActivatedRoute,
        private dataService: DataService,
        private router: Router,
        private loggingService: LoggingService) {}

    ngOnInit(): void {
        this.loadUOMs();
    }

    save() {
        this.dataService.save('projects', this.project)
            .subscribe((data) => {
                this.project = data;
                this.loggingService.savedMessage();
            });
    }

    cancel() {
        this.router.navigate(['/projects/search/']);
    }

    loadUOMs() {
        this.dataService.getById('lookups', 'projectuom')
            .subscribe((data) => {
                this.uoms = data;
            });
    }
}
