import { Component, OnInit, DoCheck } from '@angular/core';
import { DataService } from '../services/data.service';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../services/user.service';
import { DateService } from '../services/date.service';
import { BreadCrumbService } from '../services/breadcrumb.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-phone-list',
  templateUrl: './phone-list.component.html'
})
export class PhoneListComponent implements OnInit {

  html: any;
  startDate: Date;
  endDate: Date;
  startDateStr: string;
  endDateStr: string;
  siteSubscription: Subscription;
  cols: any[];
  header: any;
  companyId: string = 'all';
  data: any[];
  section = 'Reports';
  title = 'Phone List';
  user: any;

  constructor(private dataService: DataService,
    private userService: UserService,
    private dateService: DateService,
    private breadcrumbService: BreadCrumbService,
    private router: Router) {
    this.siteSubscription = this.userService.getSiteEvent()
      .subscribe(siteId => {
        this.loadData();
      });
  }

  ngOnInit() {
    this.breadcrumbService.setItemsByCategory(this.section, this.title, this.router.url);
    this.user = this.userService.currentUser();
    this.startDateStr = this.dateService.formatDate(new Date());
    this.loadData();
  }

  ngOnDestroy() {
    this.siteSubscription.unsubscribe();
  }

  loadData() {
    this.cols = [
      { field: 'companyName', header: 'Company' },
      { field: 'name', header: 'Name' },
      { field: 'badgeNum', header: 'Badge #' },
      { field: 'phone', header: 'Phone' }
    ];
    const path = 'badgeholders/phonelist';
    this.dataService.getAll(path)
      .subscribe((data) => {
        this.data = data;
      });
  }

  createPDF(): void {
    const reportData = {
      startDate: this.startDateStr,
      endDate: this.endDateStr,
      userId: this.user.id,
      companyIds: [this.companyId]
    };
    const path = 'reports/onsite/pdf';
    this.dataService.postForm(path, reportData, { responseType: 'arraybuffer' })
      .subscribe((data) => {
        const file = new Blob([data], { type: 'application/pdf' });
        const fileURL = window.URL.createObjectURL(file);

        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = fileURL;
        a.download = 'onsite.pdf';
        a.click();

        window.URL.revokeObjectURL(fileURL);
        a.remove();
      });

  }

  createExcel(): void {
    const reportData = {
      startDate: this.startDateStr,
      endDate: this.startDateStr,
      userId: this.user.id,
      companyIds: [this.companyId]
    };
    const path = 'badgeholders/phonelist/excel';
    this.dataService.postForm(path, reportData, { responseType: 'arraybuffer' })
      .subscribe((data) => {
        const file = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileURL = window.URL.createObjectURL(file);

        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = fileURL;
        a.download = 'phonelist.xlsx';
        a.click();

        window.URL.revokeObjectURL(fileURL);
        a.remove();
      });
  }
}
