import { Component, Input, OnInit, ViewEncapsulation, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { FullCalendar } from 'primeng';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import { DataService } from '../../services/data.service';
import { LoggingService } from '../../services/logging.service';
import { DateService } from '../../services/date.service';
import { Message } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { BreadCrumbService } from '../../services/breadcrumb.service';

@Component({
    selector: 'app-buildingmaterial-profile',
    templateUrl: './buildingmaterial.profile.component.html',
    styleUrls: ['./buildingmaterial.profile.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [ConfirmationService]
})
export class BuildingMaterialProfileComponent implements OnInit, OnChanges {
    @Input() buildingmaterial: any;

    showTabFlag: boolean;
    showUploadDialog: boolean;
    companyContracts: any[];
    materialTypes: any[];
    materialCategories: any[];
    unitTypes: any[];
    materialCategory: any;
    craneNeeded = 'false';
    hoistNeeded = 'false';
    dockNeeded = 'false';
    scheduledDate: Date;
    scheduledTime: Date;
    actualDate: Date;
    message: Message[] = [];
    isNew = false;
    category = 'buildingmaterial';
    refresh = new Date();
    cranes;
    hoists;
    docks;
    gates;
    duration = [];

    @ViewChild('fc') fc: FullCalendar;
    events = [];
    options;
    showDialogFlag = true;
    dialogResourceType: number;
    dialogResourceName: string;

    constructor(
        private dataService: DataService,
        private loggingService: LoggingService,
        private dateService: DateService,
        private breadcrumbService: BreadCrumbService,
        private router: Router) { }

    ngOnInit(): void {
        if (this.buildingmaterial.id === undefined) {
            this.isNew = true;
            this.scheduledDate = new Date();
            this.scheduledDate = new Date(this.scheduledDate.getFullYear(), this.scheduledDate.getMonth(), this.scheduledDate.getDate(), this.scheduledDate.getHours(), Math.floor(this.scheduledDate.getMinutes() / 15) * 15);
            this.scheduledTime = this.scheduledDate;
            this.loadResourceTypes();
        } else {
            this.dataService.getById('buildingmaterials', this.buildingmaterial.id)
                .subscribe((data) => {
                    this.buildingmaterial = data;
                    this.scheduledDate = new Date(this.buildingmaterial.scheduledDate);
                    this.scheduledTime = this.scheduledDate;
                    if (this.buildingmaterial.actualDate != undefined) {
                        this.actualDate = new Date(this.buildingmaterial.actualDate);
                    }

                    //                    this.scheduledDate = this.dateService.convertDateUniversalToSiteTimeDate(this.buildingmaterial.scheduledDate);
                    //                    this.actualDate = this.dateService.convertDateUniversalToSiteTimeDate(this.buildingmaterial.actualDate);
                    if (this.buildingmaterial.craneNeeded === true) {
                        this.craneNeeded = 'true';
                    }

                    if (this.buildingmaterial.hoistNeeded === true) {
                        this.hoistNeeded = 'true';
                    }

                    if (this.buildingmaterial.dockNeeded === true) {
                        this.dockNeeded = 'true';
                    }
                    this.loadResourceTypes();
                });
        }

        if (this.buildingmaterial.materialTypeId != null) {
            this.dataService.getById('lookups/material', this.buildingmaterial.materialTypeId)
                .subscribe((dataMC) => {
                    this.materialCategory = dataMC.categoryCode;
                    this.loadMaterialTypes();
                });
        }

        this.loadDuration();
        this.loadCompanyContracts();
        this.loadMaterialCategories();
        this.loadUnitTypes();
        const instance = this;
        this.options = {
            plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
            header: {
                left: 'prev,next',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay'
            },
            allDaySlot: false,
            dateClick: function (info) {
                info.jsEvent.preventDefault(); // don't let the browser navigate
                if (!instance.dialogResourceType)
                    if (instance.fc.getCalendar().view.type === 'dayGridMonth') {
                        instance.fc.getCalendar().gotoDate(info.date);
                        instance.fc.getCalendar().changeView('timeGridDay');
                    }
                    else if (!instance.actualDate) {
                        instance.scheduledDate = info.date;
                        instance.showDialogFlag = false;
                        instance.loadResourceTypes();
                    }
            },
            eventClick: function (info) {
                info.jsEvent.preventDefault(); // don't let the browser navigate

                if (instance.dialogResourceType) {
                    if (info.event.classNames && info.event.classNames.length > 1 && info.event.classNames[1] === 'add-event')
                        switch (instance.dialogResourceType) {
                            case 1:
                                instance.craneNeeded = 'true';
                                instance.buildingmaterial.craneId = info.event.id;
                                instance.showDialogFlag = false;
                                break;
                            case 2:
                                instance.hoistNeeded = 'true';
                                instance.buildingmaterial.hoistId = info.event.id;
                                instance.showDialogFlag = false;
                                break;
                            case 3:
                                instance.dockNeeded = 'true';
                                instance.buildingmaterial.dockId = info.event.id;
                                instance.showDialogFlag = false;
                                break;
                            case 4:
                                instance.buildingmaterial.gateId = info.event.id;
                                instance.showDialogFlag = false;
                                break;
                        }
                }
            }
        };
        setTimeout(() => this.showDialogFlag = false);
    }

    ngOnChanges(simpleChanges: SimpleChanges): void {
        this.breadcrumbService.setItemsByCategoryAndName('Controls', 'Building Materials', '/buildingmaterials/search', this.buildingmaterial.name, this.router.url);
    }

    showDialogChangedHandler(showDialog: boolean): void {
        this.refresh = new Date();
    }

    private schedulingConflict(d: any, start: Date, end: Date): boolean {
        if (!d.startRecur)
            return (new Date(d.start) < end && new Date(d.end) >= start);

        let result = false;
        if (d.daysOfWeek)
            d.daysOfWeek.forEach(m => {
                if (m === start.getDay())
                    result = (new Date(d.startRecur) < end && new Date(d.endRecur) >= start);
            });
        return result;
    }
    schedule(resourceType: number): void {
        this.scheduledDate.setHours(this.scheduledTime.getHours());
        this.scheduledDate.setMinutes(this.scheduledTime.getMinutes());
        this.dataService.getAll(`resources/all-events/${this.buildingmaterial.id}/${resourceType}/${this.dateService.formatDate(this.scheduledDate)}`)
            .subscribe((data) => {

                if (!this.buildingmaterial.duration)
                    this.buildingmaterial.duration = '30';
                data.forEach(m => {
                    m.start = new Date(m.start);
                    m.end = new Date(m.end);
                });

                this.dialogResourceType = resourceType;
                this.dialogResourceName = 'Scheduled';
                const start = this.scheduledDate;
                const end = new Date(start.getTime() + Number(this.buildingmaterial.duration) * 60000);

                if (resourceType) {
                    switch (resourceType) {
                        case 1:
                            {
                                this.dialogResourceName = 'Crane';
                                this.cranes.forEach(m => {
                                    let exists = false;
                                    data.filter(d => d.resourceId === m.value).forEach(d => {
                                        if (this.schedulingConflict(d, start, end))
                                            exists = true;
                                    });
                                    if (!exists)
                                        data.push({
                                            id: m.value,
                                            title: `${this.dialogResourceName} ${m.label}`,
                                            classNames: [m.data.classNames, `${((m.value === this.buildingmaterial.craneId) ? '' : 'add-event')}`],
                                            start: start,
                                            end: end
                                        });
                                });
                            }
                            break;
                        case 2:
                            {
                                this.dialogResourceName = 'Man Hoist';
                                this.hoists.forEach(m => {
                                    if (!m.disabled) {
                                        let exists = false;
                                        data.filter(d => d.resourceId === m.value).forEach(d => {
                                            if (this.schedulingConflict(d, start, end))
                                                exists = true;
                                        });
                                        if (!exists)
                                            data.push({
                                                id: m.value,
                                                title: `${this.dialogResourceName} ${m.label}`,
                                                classNames: [m.data.classNames, `${((m.value === this.buildingmaterial.hoistId) ? '' : 'add-event')}`],
                                                start: start,
                                                end: end
                                            });
                                    }
                                });
                            }
                            break;
                        case 3:
                            {
                                this.dialogResourceName = 'Loading Dock';
                                this.docks.forEach(m => {
                                    let exists = false;
                                    data.filter(d => d.resourceId === m.value).forEach(d => {
                                        if (this.schedulingConflict(d, start, end))
                                            exists = true;
                                    });
                                    if (!exists)
                                        data.push({
                                            id: m.value,
                                            title: `${this.dialogResourceName} ${m.label}`,
                                            classNames: [m.data.classNames, `${((m.value === this.buildingmaterial.dockId) ? '' : 'add-event')}`],
                                            start: start,
                                            end: end
                                        });
                                });
                            }
                            break;
                        case 4:
                            {
                                this.dialogResourceName = 'Gate';
                                this.gates.forEach(m => {
                                    let exists = false;
                                    data.filter(d => d.resourceId === m.value).forEach(d => {
                                        if (this.schedulingConflict(d, start, end))
                                            exists = true;
                                    });
                                    if (!exists)
                                        data.push({
                                            id: m.value,
                                            title: `${this.dialogResourceName} ${m.label}`,
                                            classNames: [m.data.classNames, `${((m.value === this.buildingmaterial.gateId) ? '' : 'add-event')}`],
                                            start: start,
                                            end: end
                                        });
                                });
                            }
                            break;
                    }
                    this.fc.getCalendar().changeView('dayGridMonth');
                    setTimeout(() => this.fc.getCalendar().changeView('timeGridDay'), 250);
                }
                else {
                    const currentEvents = data.filter(m => m.id === this.buildingmaterial.id);
                    if (currentEvents.length > 0)
                        currentEvents.forEach(m => {
                            m.start = start;
                            m.end = end;
                        });
                    else {
                        if (this.buildingmaterial.craneId) {
                            const resources = this.cranes.filter(m => m.value === this.buildingmaterial.craneId);
                            if (resources.length > 0)
                                data.push(
                                    {
                                        id: this.buildingmaterial.id,
                                        title: "Crane " + resources[0].label,
                                        classNames: resources[0].data.classNames,
                                        start: start,
                                        end: end
                                    });
                        }
                        if (this.buildingmaterial.hoistId) {
                            const resources = this.hoists.filter(m => m.value === this.buildingmaterial.hoistId);
                            if (resources.length > 0)
                                data.push(
                                    {
                                        id: this.buildingmaterial.id,
                                        title: "Man Hoist " + resources[0].label,
                                        classNames: resources[0].data.classNames,
                                        start: start,
                                        end: end
                                    });
                        }
                        if (this.buildingmaterial.dockId) {
                            const resources = this.docks.filter(m => m.value === this.buildingmaterial.dockId);
                            if (resources.length > 0)
                                data.push(
                                    {
                                        id: this.buildingmaterial.id,
                                        title: "Loading Dock " + resources[0].label,
                                        classNames: resources[0].data.classNames,
                                        start: start,
                                        end: end
                                    });
                        }
                        if (this.buildingmaterial.gateId) {
                            const resources = this.gates.filter(m => m.value === this.buildingmaterial.gateId);
                            if (resources.length > 0)
                                data.push(
                                    {
                                        id: this.buildingmaterial.id,
                                        title: "Gate " + resources[0].label,
                                        classNames: resources[0].data.classNames,
                                        start: start,
                                        end: end
                                    });
                        }
                        if (data.filter(m => m.id === this.buildingmaterial.id).length < 1)
                            data.push(
                                {
                                    id: this.buildingmaterial.id,
                                    title: 'THIS SHIPMENT',
                                    classNames: ['current-event'],
                                    start: start,
                                    end: end
                                });
                    }

                    this.fc.getCalendar().changeView('dayGridMonth');
                }

                data.forEach(m => {
                    let overlaps = 1;
                    let lastEnd = m.end;
                    data.forEach(m2 => {
                        if (m2.id !== m.id && m2.start < lastEnd && ((m2.start >= m.start && m2.start <= m.end) || (m2.end >= m.start && m2.end <= m.end))) {
                            overlaps++;
                            lastEnd = m2.end;
                        }
                    });

                    const widthClassName = `event-width-${Math.min(overlaps, 5)}`;
                    if (m.classNames) {
                        if (typeof (m.classNames) === 'string')
                            m.classNames = m.classNames.split(',');
                        m.classNames.push(widthClassName);
                    }
                    else
                        m.classNames = [widthClassName];
                });

                this.events = data;
                this.fc.getCalendar().gotoDate(new Date(2020, 1, 1));
                setTimeout(() => this.fc.getCalendar().gotoDate(this.scheduledDate), 100);

                this.showDialogFlag = true;
            });
    }

    save() {
        this.scheduledDate.setHours(this.scheduledTime.getHours());
        this.scheduledDate.setMinutes(this.scheduledTime.getMinutes());
        this.buildingmaterial.scheduledDate = this.dateService.convertDateLocalToSiteTimeDate(this.scheduledDate);
        this.buildingmaterial.actualDate = this.dateService.convertDateLocalToSiteTimeDate(this.actualDate);
        //        this.buildingmaterial.scheduledDate = this.scheduledDate;
        //        this.buildingmaterial.actualDate = this.actualDate;
        this.buildingmaterial.enterTime = this.buildingmaterial.scheduledDate;
        this.buildingmaterial.exitTime = this.buildingmaterial.actualDate;
        if (this.craneNeeded === 'true') {
            if (!this.buildingmaterial.craneId) {
                alert('Please select a Crane Number.');
                return;
            }
            this.buildingmaterial.craneNeeded = true;
        } else {
            this.buildingmaterial.craneNeeded = false;
        }

        if (this.hoistNeeded === 'true') {
            if (!this.buildingmaterial.hoistId) {
                alert('Please select a Hoist Number.');
                return;
            }
            this.buildingmaterial.hoistNeeded = true;
        } else {
            this.buildingmaterial.hoistNeeded = false;
        }

        if (this.dockNeeded === 'true') {
            if (!this.buildingmaterial.dockId) {
                alert('Please select a Loading Dock Number.');
                return;
            }
            this.buildingmaterial.dockNeeded = true;
        } else {
            this.buildingmaterial.dockNeeded = false;
        }

        this.dataService.save('buildingmaterials', this.buildingmaterial)
            .subscribe((data) => {
                this.loggingService.savedMessage();
            });
    }

    cancel() {
        this.router.navigate(['/buildingmaterials/search/']);
    }

    showTab(_tab: boolean) {
        this.showTabFlag = _tab;
    }

    openUploadDialog(): void {
        this.showUploadDialog = true;
    }

    closeUploadDialog(): void {
        this.showUploadDialog = false;
    }

    loadCompanyContracts(): void {
        this.dataService.getById('lookups', 'companycontracts')
            .subscribe((data) => {
                this.companyContracts = data;
            });
    }

    loadDuration(): void {
        this.dataService.getById('lookups', 'duration')
            .subscribe((data) => {
                this.duration = data;
            });
    }

    loadMaterialTypes(): void {
        this.dataService.getById('lookups/materialtypes', this.materialCategory)
            .subscribe((data) => {
                this.materialTypes = data;
            });
    }

    loadMaterialCategories(): void {
        this.dataService.getById('lookups', 'materialcategories')
            .subscribe((data) => {
                this.materialCategories = data;
            });
    }

    loadUnitTypes(): void {
        this.dataService.getById('lookups', 'unittypes')
            .subscribe((data) => {
                this.unitTypes = data;
            });
    }

    loadResourceTypes(): void {
        console.log(this.actualDate ,this.cranes)
        if (this.actualDate && this.cranes)
            return;

        if (!this.buildingmaterial.duration)
            this.buildingmaterial.duration = '30';
        this.scheduledDate.setHours(this.scheduledTime.getHours());
        this.scheduledDate.setMinutes(this.scheduledTime.getMinutes());
        console.log('resourcetypes' + ((this.actualDate || !this.scheduledDate) ? '' : `/${this.buildingmaterial.id}/${this.dateService.formatDate(this.scheduledDate)}/${this.dateService.formatTime(this.scheduledDate)}/${this.buildingmaterial.duration}`))
        this.dataService.getById('lookups', 'resourcetypes' + ((this.actualDate || !this.scheduledDate) ? '' : `/${this.buildingmaterial.id}/${this.dateService.formatDate(this.scheduledDate)}/${this.dateService.formatTime(this.scheduledDate)}/${this.buildingmaterial.duration}`))
            .subscribe((data) => {
                this.cranes = [];
                this.hoists = [];
                this.docks = [];
                this.gates = [];
                data.forEach(m => {
                    m.data = JSON.parse(m.data);
                    if (m.data.resourceType === 1) {
                        if (m.disabled && m.value === this.buildingmaterial.craneId)
                            this.buildingmaterial.craneId = null;
                        this.cranes.push(m);
                    }
                    else if (m.data.resourceType === 2) {
                        if (m.disabled && m.value === this.buildingmaterial.hoistId)
                            this.buildingmaterial.hoistId = null;
                        this.hoists.push(m);
                    }
                    else if (m.data.resourceType === 3) {
                        if (m.disabled && m.value === this.buildingmaterial.dockId)
                            this.buildingmaterial.dockId = null;
                        this.docks.push(m);
                    }
                    else if (m.data.resourceType === 4)
                        this.gates.push(m);
                });;
            });
    }
}
