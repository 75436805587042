<div class="row">
    <div class="col-lg-12">
        <label class="control-label">Address:</label>
        <input type="text" class="form-control" pInputText name="address" [(ngModel)]="site.address" maxlength="45" />
    </div>
</div>
<div class="row">
    <div class="col-lg-6">
        <label class="control-label">City:</label>
        <input type="text" class="form-control" pInputText name="city" [(ngModel)]="site.city" maxlength="45" />
    </div>
    <div class="col-lg-3">
        <label class="control-label">State:</label><br />
        <p-dropdown [options]="states" [filter]="true" [(ngModel)]="site.state"></p-dropdown>
    </div>
    <div class="col-lg-3">
        <label class="control-label">Zip:</label>
        <input type="text" class="form-control" pInputText name="zip" [(ngModel)]="site.zip" maxlength="15" />
    </div>
</div>
<div class="row">
    <div class="col-lg-3">
        <label class="control-label">Time Zone:</label><br />
        <p-dropdown [options]="timeZones" [filter]="false" [(ngModel)]="site.timeZone"></p-dropdown>
    </div>
</div>
<div class="row">
    <div class="col-lg-6">
        <p-checkbox name="hasPerDiem" binary="true" label="Per Diem" [(ngModel)]="site.hasPerDiem"></p-checkbox>
    </div>
    <div class="col-lg-6">
        <label class="control-label">Default Per Diem Amount:</label>
        <input type="number" class="form-control" *ngIf="site" pInputText name="defaultPerDiemAmount" [(ngModel)]="site.defaultPerDiemAmount" maxlength="15" />
    </div>
</div>