import { PhotoListComponent } from './../../shared/photolist/photolist.component';
import { Component, Input, OnInit, ViewEncapsulation, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { DataService } from '../../services/data.service';
import { LoggingService } from '../../services/logging.service';
import { BreadCrumbService } from '../../services/breadcrumb.service';
import { UserService } from '../../services/user.service';
import { DateService } from '../../services/date.service';
import { BadgeHolderRequiredComponent } from '../required/badgeholder.required.component';
import { BadgeHolderPhotosComponent } from '../photos/badgeholder.photos.component';
import { BadgeHolderAuditComponent } from '../audit/badgeholder.audit.component';

@Component({
    selector: 'app-badgeholder-profile',
    templateUrl: './badgeholder.profile.component.html',
    styleUrls: ['./badgeholder.profile.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [ ConfirmationService ]
})
export class BadgeHolderProfileComponent implements OnInit, OnChanges {
@Input() badgeHolder: any;
// @Input() showTabFlag: boolean;

showTabFlag: boolean;
years: any[];
states: any[];
selectedState: any;
contractId: string;
laborClassId: string;
isGlobalAdmin = false;
afterDate: Date;

@ViewChild(BadgeHolderRequiredComponent) required: BadgeHolderRequiredComponent;
@ViewChild(BadgeHolderPhotosComponent) photos: BadgeHolderPhotosComponent;
@ViewChild(BadgeHolderAuditComponent) audit: BadgeHolderAuditComponent;

    constructor(
        private route: ActivatedRoute,
        private confirmService: ConfirmationService,
        private dataService: DataService,
        private dateService: DateService,
        private loggingService: LoggingService,
        private userService: UserService,
        private breadcrumbService: BreadCrumbService,
        private router: Router) {
            this.isGlobalAdmin = this.userService.currentUser().isGlobalAdmin;
        }

    ngOnInit(): void {
        this.loadStates();
    }

    ngOnChanges(simpleChanges: SimpleChanges): void {
        this.contractId = this.badgeHolder.contractId;
        this.laborClassId = this.badgeHolder.laborClassId;

        this.breadcrumbService.setItemsByCategoryAndName(
            'Sitetraxx Admin',
            'Badge Holders',
            '/badgeholders/search',
            this.badgeHolder.name,
            this.router.url
        );
    }

    save(andNew) {
        this.afterDate = null;
        const isNew = (this.badgeHolder.id == null);
        if (this.badgeHolder.companyId != null && this.badgeHolder.companyId !== '' &&
        this.badgeHolder.contractId != null && this.badgeHolder.contractId !== '') {
            this.badgeHolder.badgeHolderType = 'W';
            this.setRadioValues();

            // we do not want `save` to create a new id because if we get an error back,
            // it prevents the user from saving again without refreshing the page
            this.dataService.save('badgeholders', this.badgeHolder, false)
                .subscribe((data) => {
                    this.badgeHolder = data;
                    this.loggingService.savedMessage();

                    if (!isNew && (this.contractId !== this.badgeHolder.contractId || this.laborClassId !== this.badgeHolder.laborClassId)) {
                        this.confirmService.confirm({
                            message: 'Do you want to change the historical clock records to match the current company, contract and labor class?',
                            accept: () => {
                                this.dataService.getById('badgeholders/' + this.badgeHolder.id, 'update-clocks/' + (this.afterDate ? this.dateService.formatDate(this.afterDate) : 'null'))
                                    .subscribe((success) => {
                                        if (andNew)
                                            this.router.navigate(['/badgeholders/detail/new/']);
                                        else if (success) {
                                            this.loggingService.successMessage('Changed', 'Historical clock records changed');

                                            this.photos.loadPhotos();
                                        }
                                    });
                            },
                            reject: () => {
                                this.loggingService.infoMessage('Unchanged', 'Historical clock records unchanged');
                            }
                        });
                    }
                    else if (andNew)
                        if (this.router.url.indexOf('new') > 0)
                            this.badgeHolder = {};
                        else
                            this.router.navigate(['/badgeholders/detail/new/']);

                    this.contractId = this.badgeHolder.contractId;
                    this.laborClassId = this.badgeHolder.laborClassId;
                    this.audit.loadData();
                });
        }
    }

    setRadioValues() {
        this.badgeHolder.parkingPass = (this.badgeHolder.parkingPass === '1') ? 1 : 0;
        this.badgeHolder.commercialVehicle = (this.badgeHolder.commercialVehicle === '1') ? 1 : 0;
        this.badgeHolder.driving = (this.badgeHolder.driving === '1') ? 1 : 0;
        this.badgeHolder.emergencyTeam = (this.badgeHolder.emergencyTeam === '1') ? 1 : 0;
        this.badgeHolder.isSalary = (this.badgeHolder.isSalary === '1') ? 1 : 0;
        this.badgeHolder.isVaccinated = (this.badgeHolder.isVaccinated === '1') ? 1 : 0;
        this.badgeHolder.vaccineCardVerified = (this.badgeHolder.vaccineCardVerified === '1') ? 1 : 0;
    }

    cancel() {
        const path = '/badgeholders/search/';
        this.router.navigate([path]);
    }

    showTab(_tab: boolean) {
        this.showTabFlag = _tab;
    }

    loadStates(): void {
        this.dataService.getById('lookups', 'states')
            .subscribe((states) => {
                this.states = states;
        });
    }

    badgeHolderChangedHandler(badgeHolder: any) {
        this.badgeHolder = badgeHolder;
    }

    feature(name: string): boolean {
        return this.userService.hasFeature(name);
    }
}
