<div class="panel-body" id="locationPanel">
    <div class="row">
        <div class="col-lg-12">
            <label class="control-label">Address:</label>
            <input type="text" class="form-control" *ngIf="badgeHolder" pInputText name="address" [(ngModel)]="badgeHolder.address" maxlength="100" />
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6">
            <label class="control-label">City:</label>
            <input type="text" class="form-control" *ngIf="badgeHolder" pInputText name="city" [(ngModel)]="badgeHolder.city" maxlength="50" />
        </div>
        <div class="col-lg-3">
            <label class="control-label">State:</label><br />
            <p-dropdown [options]="states" [filter]="true" [(ngModel)]="badgeHolder.state"></p-dropdown>
        </div>
        <div class="col-lg-3">
            <label class="control-label">Zip:</label>
            <input type="text" placeholder="12345" class="form-control" *ngIf="badgeHolder" pInputText name="zipCode" [(ngModel)]="badgeHolder.zipCode" maxlength="15" />
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6">
            <p-checkbox name="hasPerDiem" binary="true" label="Per Diem" [(ngModel)]="badgeHolder.hasPerDiem"></p-checkbox>
        </div>
        <div class="col-lg-6">
            <label class="control-label">Per Diem Amount:</label>
            <input type="number" class="form-control" *ngIf="badgeHolder" pInputText name="perDiemAmount" [(ngModel)]="badgeHolder.perDiemAmount" maxlength="15" />
        </div>
    </div>
</div>