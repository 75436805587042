<div *ngIf="pageInfo">
    <div class="container">
        <div class="row">
            <h3>Badge Holders</h3>
        </div>
        <div class="row" style="overflow-x: auto; margin-right: 10px">
            <st-table pageId="{{pageInfo.id}}" dataTable="{{pageInfo.dataName}}" filter="{{badgeHolder.id}}"></st-table>
        </div>
    </div>
</div>
