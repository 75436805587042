<form #thisForm='ngForm'>
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <h3>Equipment Delivery: {{equipmentDelivery?.name}}</h3>
            </div>
            <div class="col-lg-6">
                <div class="float-right">
                    <button pButton (click)="save()" [disabled]="!thisForm.valid" icon="pi pi-save"
                        label="Save"></button>
                    <button pButton (click)="cancel()" icon="pi pi-ban" label="Cancel"></button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="equipmentDelivery" class="container">
        <div class="row">
            <div class="col-8">
                <div class="row">
                    <div class="col-lg-6 col-12">
                        <div class="form-group">
                            <label>Equipment Company</label><br />
                            <input pInputText name="ticketNum" [(ngModel)]="equipmentDelivery.name" maxlength="100"
                                class="form-control" type="text" placeholder="" required />
                        </div>
                    </div>
                    <div class="col-lg-6 col-12">
                        <div class="form-group">
                            <label>Shipping Ticket #</label><br />
                            <input pInputText name="ticketNum" [(ngModel)]="equipmentDelivery.ticketNum" maxlength="50"
                                class="form-control" type="text" placeholder="" required />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-12">
                        <label>Enter Date</label><br />
                        <p-calendar name="enterTime" [(ngModel)]="equipmentDelivery.enterTime" [showIcon]="true">
                        </p-calendar>
                    </div>
                    <div class="col-lg-6 col-12">
                        <label>Exit Date</label><br />
                        <p-calendar name="exitTime" [(ngModel)]="equipmentDelivery.exitTime" [showIcon]="true">
                        </p-calendar>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <div class="form-group">
                            <label>Renting Company</label>
                            <input pInputText name="rentalCompany" [(ngModel)]="equipmentDelivery.rentalCompany"
                                maxlength="50" class="form-control" type="text" placeholder="" required />
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="form-group">
                            <label>Company / Contract</label><br />
                            <p-dropdown name="companyContracts" [filter]="true" [options]="companyContracts"
                                placeholder="Select a Company/Contract" [(ngModel)]="equipmentDelivery.contractId">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="row">
                    <div class="col-12">
                        <label>Photo</label><br />
                        <button pButton icon="pi pi-plus" [disabled]="equipmentDelivery.id===undefined"
                            (click)="openUploadDialog()" label="Add Photo" class="indigo-btn"></button>
                        <app-photo-list [objectId]="equipmentDelivery.id" [(refresh)]="refresh" [category]="category"
                            *ngIf="equipmentDelivery.id !== undefined"></app-photo-list>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <app-upload-photo [(showDialog)]="showUploadDialog" [category]="category"
        (showDialogChange)="showDialogChangedHandler($event)" [objectId]="equipmentDelivery.id"
        *ngIf="equipmentDelivery.id !== undefined"></app-upload-photo>

    <div class="container" *ngIf="equipmentDelivery.id">
        <div class="row">
            <div class="col-12">
                <div *ngIf="equipmentDelivery">
                    <div class="row">
                        <div class="col-lg-12">
                            <span class="grid-header">Equipment</span>
                            <span class="float-right"><button pButton icon="pi pi-plus" (click)="openEquipmentDialog()"
                                    title="Add" class="indigo-btn"></button></span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <table class="table table-striped">
                                <thead>
                                    <tr class="sitetraxx">
                                        <th>Edit</th>
                                        <th>Delete</th>
                                        <th>Description</th>
                                        <th>Make</th>
                                        <th>Model</th>
                                        <th>Quantity</th>
                                    </tr>
                                </thead>
                                <tr *ngFor="let equipment of equipmentList">
                                    <td style="width: 5%"><button type="button" pButton
                                            (click)="editEquipmentDialog(equipment)" icon="pi pi-pencil"></button></td>
                                    <td style="width: 5%"><button type="button" pButton
                                            (click)="deleteEquipmentDialog(equipment)" icon="pi pi-trash"></button></td>
                                    <td>{{equipment.name}}</td>
                                    <td>{{equipment.make}}</td>
                                    <td>{{equipment.model}}</td>
                                    <td>{{equipment.quantity}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <st-update-block [object]='equipmentDelivery' *ngIf="equipmentDelivery"></st-update-block>
    </div>
</form>

<p-confirmDialog header='Delete?' icon='pi pi-trash'></p-confirmDialog>

<p-dialog header="Equipment" [(visible)]="showEquipmentDialog" modal="equipment">
    <div class="col-12" *ngIf="equipment">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label>Description</label>
                    <input type="text" pInputText placeholder="" [(ngModel)]="equipment.name" maxlength="100"
                        class="form-control" required />
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label>Quantity</label>
                    <input type="number" pInputText placeholder="" [(ngModel)]="equipment.quantity" class="form-control"
                        required />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label>Make</label>
                    <input type="text" pInputText placeholder="" [(ngModel)]="equipment.make" maxlength="50"
                        class="form-control" required />
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label>Model</label>
                    <input type="text" pInputText placeholder="" [(ngModel)]="equipment.model" maxlength="50"
                        class="form-control" required />
                </div>
            </div>
        </div>
    </div>
    <p-footer *ngIf="equipment">
        <button type="button" (click)="saveEquipment()" pButton icon="pi pi-save" label="Save"
            [disabled]="equipment.name === undefined || equipment.name === '' || equipment.quantity === undefined || equipment.quantity === '' || equipment.make === undefined || equipment.make === '' || equipment.model === undefined || equipment.model === ''"></button>
        <button type="button" (click)="closeEquipmentDialog()" pButton icon="pi pi-times" label="Close"></button>
    </p-footer>
</p-dialog>