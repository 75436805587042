import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { DataService } from '../services/data.service';
import { DateService } from '../services/date.service';

@Component({
    selector: 'app-clock-logs',
    templateUrl: './clock-logs.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ClockLogsComponent implements OnInit {

    env: any = environment;

    sites: any[];

    siteId: string;
    fromDate: Date;
    toDate: Date;

    clocks: any[];
    cols: any[];

    constructor(private dataService: DataService, private dateService: DateService) { }

    ngOnInit() {
        this.cols = [
            { field: 'terminalNumber', header: 'Terminal Number' },
            { field: 'terminalNumberRaw', header: 'Terminal Number (Raw)' },
            { field: 'badgeNumber', header: 'Badge Number' },
            { field: 'badgeNumberRaw', header: 'Badge Number (Raw)' },
            { field: 'name', header: 'Name' },
            { field: 'nameRaw', header: 'Name (Raw)' },
            { field: 'action', header: 'Action' },
            { field: 'actionRaw', header: 'Action (Raw)' },
            { field: 'clockTime', header: 'Clock Time' },
            { field: 'clockTimeRaw', header: 'Clock Time (Raw)' },
            { field: 'createdDate', header: 'Created Date' },
            { field: 'createdDateRaw', header: 'Created Date (Raw)' },
        ];

        this.dataService.getById('lookups', 'site')
            .subscribe((lookup) => {
                this.sites = lookup;
                this.sites.unshift({});
            });
    }
    loadData(): void {
        if (this.siteId && this.fromDate && this.toDate && (this.fromDate <= this.toDate))
            this.dataService.getById('clock', `${this.siteId}/raw/${this.dateService.formatDate(this.fromDate)}/${this.dateService.formatDate(this.toDate)}`)
                .subscribe((response) => {
                    this.clocks = response;
                });
    }
    style(clock, raw) {
        if (clock !== raw)
            return { color: 'red', 'font-weight': '900' };
        return null;
    }
    styleDateTime(clock, raw) {
        const clockDT = new Date(clock);
        clockDT.setSeconds(0, 0);
        const rawDT = new Date(raw);
        rawDT.setSeconds(0, 0);
        if (clockDT.toISOString() !== rawDT.toISOString())
            return { color: 'red', 'font-weight': '900' };
        return null;
    }



    private exportToCSV(): void {
        let csv = 'Terminal Number,Terminal Number (Raw),Badge Number, Badge Number (Raw),Name,Name (Raw),Action,Action (Raw),Clock Time,Clock Time (Raw),Created Date,Created Date (Raw)';
        csv = csv + '\n';


        for (const row of this.clocks) {
            let csvRow = '';

            if (row.terminalNumber)
                csvRow += `"${row.terminalNumber}"`;
            csvRow += ',';
            if (row.terminalNumberRaw)
                csvRow += `"${row.terminalNumberRaw}"`;
            csvRow += ',';

            if (row.badgeNumber)
                csvRow += `="${row.badgeNumber}"`;
            csvRow += ',';
            if (row.badgeNumberRaw)
                csvRow += `"${row.badgeNumberRaw}"`;
            csvRow += ',';

            if (row.name)
                csvRow += `"${row.name}"`;
            csvRow += ',';
            if (row.nameRaw)
                csvRow += `"${row.nameRaw}"`;
            csvRow += ',';

            if (row.action)
                csvRow += `"${row.action}"`;
            csvRow += ',';
            if (row.actionRaw)
                csvRow += `"${row.actionRaw}"`;
            csvRow += ',';

            if (row.clockTime)
                csvRow += `"${this.dateService.formatDateTime(new Date(row.clockTime))}"`;
            csvRow += ',';
            if (row.clockTimeRaw)
                csvRow += `"${this.dateService.formatDateTime(new Date(row.clockTimeRaw))}"`;
            csvRow += ',';

            if (row.createdDate)
                csvRow += `"${this.dateService.formatDateTime(new Date(row.createdDate))}"`;
            csvRow += ',';
            if (row.createdDateRaw)
                csvRow += `"${this.dateService.formatDateTime(new Date(row.createdDateRaw))}"`;

            csv += csvRow + '\n';
        }

        const blob = new Blob(['\uFEFF', csv], { type: 'text/csv' });
        const link = document.createElement('a');
        link.setAttribute('href', window.URL.createObjectURL(blob));
        link.setAttribute('download', 'ClockLogs.csv');
        document.body.appendChild(link); // Required for FF
        link.click();
    }
}
