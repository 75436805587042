import { Component, OnInit, DoCheck } from '@angular/core';
import { DataService } from '../../services/data.service';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../../services/user.service';
import { DateService } from '../../services/date.service';
import { BreadCrumbService } from '../../services/breadcrumb.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-contract-status',
    templateUrl: './contract-status.component.html'
})
export class ContractStatusComponent implements OnInit {

    html: any;
    startDate: Date;
    endDate: Date;
    startDateStr: string;
    endDateStr: string;
    companies: any[];
    siteSubscription: Subscription;
    cols: any[];
    companyId: string = 'all';
    data: any[];
    section = 'Reports';
    title = 'Contract Status';
    user: any;

    constructor(private dataService: DataService,
        private userService: UserService,
        private dateService: DateService,
        private breadcrumbService: BreadCrumbService,
        private router: Router) {
        this.siteSubscription = this.userService.getSiteEvent()
            .subscribe(siteId => {
                this.loadData();
            });
    }

    ngOnInit() {
        this.breadcrumbService.setItemsByCategory(this.section, this.title, this.router.url);
        this.loadData();
        this.cols = [
            { field: 'companyName', header: 'Company' },
            { field: 'finalValue', header: 'Contract Value' },
            { field: 'estimatedTotalManHours', header: 'Estm Total Man Hours' },
            { field: 'totalManHours', header: 'Adjusted Hours Per ST Auto Clocks' },
            { field: 'totalManDays', header: 'Man Days Per ST (Headcount)' },
            { field: 'percentageHours', header: '% of Manhours' },
            { field: 'totalWages', header: 'Total Labor Wages' },
            { field: 'percentageValue', header: '% of Contract Value' },
        ];
    }

    ngOnDestroy() {
        this.siteSubscription.unsubscribe();
    }

    onSubmitForm() {
        const reportData = {
            startDate: this.startDateStr,
            endDate: this.endDateStr,
            userId: this.user.id,
            companyIds: [this.companyId]
        };
        this.dataService.postForm('contracts/status/json', reportData)
            .subscribe((data) => {
                this.data = data;
            });
    }

    onStartDateChange() {
        this.startDateStr = this.dateService.formatDate(new Date(this.startDate));
    }

    onEndDateChange() {
        this.endDateStr = this.dateService.formatDate(new Date(this.endDate));
    }

    loadData() {
        this.loadCompanies();
        this.user = this.userService.currentUser();
    }

    loadCompanies(): void {
        this.dataService.getAll('companies')
            .subscribe((companies) => {
                this.companies = companies;
                this.companies.unshift({ value: 'all', label: 'All' });
            });
    }

    createPDF(): void {
        const reportData = {
            startDate: this.startDateStr,
            endDate: this.endDateStr,
            userId: this.user.id,
            companyIds: [this.companyId]
        };
        this.dataService.postForm('contracts/status/pdf', reportData, { responseType: 'arraybuffer' })
            .subscribe((data) => {
                const file = new Blob([data], { type: 'application/pdf' });
                const fileURL = window.URL.createObjectURL(file);

                const a = document.createElement('a');
                document.body.appendChild(a);
                a.href = fileURL;
                a.download = 'ContractStatus.pdf';
                a.click();

                window.URL.revokeObjectURL(fileURL);
                a.remove();
            });

    }

    createExcel(): void {

        const reportData = {
            startDate: this.startDateStr,
            endDate: this.endDateStr,
            userId: this.user.id,
            companyIds: [this.companyId]
        };
        this.dataService.postForm('contracts/status/excel', reportData, { responseType: 'arraybuffer' })
            .subscribe((data) => {
                const file = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const fileURL = window.URL.createObjectURL(file);

                const a = document.createElement('a');
                document.body.appendChild(a);
                a.href = fileURL;
                a.download = 'ContractStatus.xlsx';
                a.click();

                window.URL.revokeObjectURL(fileURL);
                a.remove();
            });
    }
}
