<div id="printArea">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <label class="control-label">Company:</label>
                <p-dropdown [options]="companies" [filter]="true" [(ngModel)]="companyId"></p-dropdown>
            </div>
            <div class="col-lg-6">
                <button pButton (click)="onSubmitForm()" id="btnSubmit" label="Submit" icon="pi pi-search"></button>
                <button pButton (click)="createExcel()" label="Excel" icon="pi pi-file-excel"></button>
            </div>
        </div>
        <div class="row">
            <p-table #dt
                     [lazy]="true"
                     [value]="data"
                     [columns]="cols"
                     [scrollable]="true"
                     [exportFilename]="'badgeholders'"
                     [paginator]="true"
                     [rows]="pageSize"
                     [totalRecords]="totalRecords"
                     [showCurrentPageReport]="true"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords} total."
                     [rowsPerPageOptions]="pageSizeOptions"
                     (onPage)="pageChanged($event)"
                     (onFilter)="applyFilters($event)"
            >
                <ng-template pTemplate="caption">
                    <div style="text-align: left">
                        <span *ngIf="searchSeconds">Search took {{searchSeconds}} seconds to complete.</span>
                        &nbsp;
                    </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns">
                            {{col.header}}
                        </th>
                    </tr>
                    <tr>
                        <th style="overflow: hidden" *ngFor="let col of columns">
                            <ng-container *ngIf="col.canFilter; then filterInput else emptySpace"></ng-container>
                            <ng-template #filterInput>
                                <input pInputText type="text" (input)="dt.filter($event.target.value, col.field, 'contains')" *ngIf="col.field !== 'profilePhotoURL' && col.field !== 'clockInPhotoURL' && col.field !== 'clockOutPhotoURL'">
                            </ng-template>
                            <ng-template #emptySpace>
                                &nbsp;
                            </ng-template>
                        </th>                         
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pSelectableRow]="rowData">
                        <td *ngFor="let col of columns" [ngSwitch]="col.field">
                            <span>{{rowData[col.field]}}</span>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div [innerHtml]="html"></div>
    </div>
</div>