<div id="printArea">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h3>Daily Attendance</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3">
                <label class="control-label">Select Day:</label>
                <p-calendar [(ngModel)]="startDate" (onSelect)="onStartDateChange()" [showIcon]="true"></p-calendar>
            </div>
            <div class="col-lg-3">
                <button pButton (click)="onSubmitForm()" id="btnSubmit" label="Submit" icon="pi pi-search"></button>
                <!--<button pButton (click)="createPDF()" id="btnPDF" label="PDF" icon="pi pi-file-pdf"></button>-->
                <button pButton (click)="createExcel()" label="Excel" icon="pi pi-file-excel"></button>
            </div>
        </div>
        <div class="row">
            <p-table #dt [value]="data" [columns]="cols" [exportFilename]="'attendance'" columnResizeMode="expand">
                <ng-template pTemplate="caption">
                    <div style="text-align: left">
                        <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                        <input type="text" pInputText size="50" placeholder="Global Filter" [(ngModel)]="globalFilter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto" />
                        <span *ngIf="onsiteList && !dt.filteredValue">{{dt.totalRecords}} - {{onsiteList.length}} Records</span>
                        <span *ngIf="onsiteList && dt.filteredValue">{{dt.filteredValue.length}} - {{onsiteList.length}} Records</span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns">
                            {{col.header}}
                        </th>
                    </tr>
                    <tr>
                        <th style="overflow: hidden" *ngFor="let col of columns">
                            <input pInputText type="text" (input)="dt.filter($event.target.value, col.field, 'contains')" *ngIf="col.field !== 'profilePhotoURL' && col.field !== 'clockInPhotoURL' && col.field !== 'clockOutPhotoURL'">
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pSelectableRow]="rowData">
                        <td *ngFor="let col of columns" [ngSwitch]="col.field" width="{{col.width}}">
                            <span *ngIf="col.type==='datetime'">{{rowData[col.field] | date:'MM/dd/yyyy HH:mm a'}}</span>
                            <span *ngIf="col.type==='text'">{{rowData[col.field]}}</span>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <!--<div [innerHtml]="html"></div>-->
    </div>
</div>