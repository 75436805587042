<table class="table table-striped sitetraxx">
    <thead>
        <tr class="sitetraxx">
            <th>Name</th>
            <th>Skilled</th>
            <th>Supervisor</th>
            <th>Laborer</th>
            <th>Safety</th>
            <th>Consultant</th>
            <th>Other</th>
            <th>Total</th>
        </tr>
    </thead>
    <tr *ngFor="let man of manpowerMatrix">
        <td>{{man.companyName}}</td>
        <td>{{man.skilled}}</td>
        <td>{{man.supervisor}}</td>
        <td>{{man.laborer}}</td>
        <td>{{man.safety}}</td>
        <td>{{man.consultant}}</td>
        <td>{{man.other}}</td>
        <td><b>{{man.total}}</b></td>
    </tr>
    <tr>
        <td><b>Total:</b></td>
        <td><b>{{totalSkilled}}</b></td>
        <td><b>{{totalSupervisor}}</b></td>
        <td><b>{{totalLaborer}}</b></td>
        <td><b>{{totalSafety}}</b></td>
        <td><b>{{totalConsultant}}</b></td>
        <td><b>{{totalOther}}</b></td>
        <td><b>{{overalllTotal}}</b></td>
    </tr>
</table>