<div>
    <div class="container">
        <div class="row">
            <div class="col">
                <h3>Crews</h3>
            </div>
        </div>
        <div class="row" *ngIf="false">
            <div class="col-lg-4">
                <label class="control-label">Select Day:</label>
                <p-calendar [(ngModel)]="startDate" (onSelect)="onStartDateChange()" [showIcon]="true"></p-calendar>
            </div>
            <div class="col-lg-8">
                <h3>Date: {{firstDay | date:"MM/dd/yyyy"}}</h3>
            </div> 
        </div>

        <p-table [columns]="cols" [value]="data" dataKey="crewId">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th style="width: 3em"></th>
                    <th>Crew</th>
                    <th>Foreman</th>
                    <th>Phase Code</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
                <tr>
                    <td>
                        <a href="#" [pRowToggler]="rowData">
                            <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
                        </a>
                    </td>
                    <td>{{rowData.crewName}}</td>
                    <td>{{rowData.foremanName}}</td>
                    <td>
                        <span>
                            <p-dropdown appendTo="body" [options]="projects" [showClear]="true"
                            placeholder="Select a Project" [filter]="true" [(ngModel)]="rowData.currentProjectId"></p-dropdown>
                    <p-button (click)="applyProject(rowData)" label="Apply"></p-button></span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
                <tr>
                    <td [attr.colspan]="columns.length + 1">
                        <div class="p-grid -fluid" style="font-size:16px;padding:20px">

                            <table class="table table-striped sitetraxx">
                                <tr class="sitetraxx">
                                    <th>Name</th>
                                    <th>Trade</th>
                                    <th>Phase Code</th>
                                </tr>
                                <tr *ngFor="let record of rowData.workers">
                                    <td>{{record.workerName}}</td>
                                    <td>{{record.laborClass}}</td>
                                    <td>
                                        <p-dropdown appendTo="body" [options]="projects" [showClear]="true"
                                        placeholder="Select a Project" [filter]="true" [(ngModel)]="record.projectId"></p-dropdown>
                                    </td>
                                </tr>
                            </table>
                            <p-button (click)="saveCrew(rowData)" label="Save"></p-button>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>