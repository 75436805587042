import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'st-page',
    templateUrl: './page.component.html'
})
export class PageComponent implements OnInit {
    page: string;
    pageInfo: any;
    
        constructor(
//            private breadcrumbService: BreadCrumbService,
//            private dataService: DataService,
//            private route: ActivatedRoute
        ) {
//            this.page = route.snapshot.params['page'];
        }
    ngOnInit(): void {
        
//        this.dataService.getById("pages",this.page)
//        .subscribe((data) => {
//            this.pageInfo = data;
//
//            this.breadcrumbService.setItems([
//                {label: 'Sitetraxx Admin'},
//                {label: this.pageInfo.title, routerLink: ['/' + this.page + '/all']}
//            ]);
//        });
    }
}