<form #thisForm='ngForm'>
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <h3>{{violation?.badgeHolderName}}</h3>
            </div>
            <div class="col-lg-6">
                <div class="float-right">
                    <button pButton (click)="save()" [disabled]="!thisForm.valid" icon="pi pi-save" label="Save"></button>
                    <button pButton (click)="cancel()" icon="pi pi-ban" label="Cancel"></button>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-6 col-md-6">
                    <div class="form-group">
                        <label class="control-label">Badge Holder</label><br />
                        <p-dropdown [options]="badgeHolders" [filter]="true" [(ngModel)]="violation.badgeHolderId" name="badgeHolderId" required></p-dropdown>
                    </div>
                </div>
                <div class="col-6 col-md-6">
                    <div class="form-group">
                        <label class="control-label">Location</label>
                        <input type="text" pInputText name="location" [(ngModel)]="violation.location" maxlength="100" class="form-control" required />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label>Violation Description</label>
                        <textarea pInputText name="observation" [(ngModel)]="violation.observation" maxlength="500" class="form-control"></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label>Corrective Action</label>
                        <textarea pInputText name="correctiveAction" [(ngModel)]="violation.correctiveAction" maxlength="500" class="form-control"></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label>Suggested Training</label>
                        <textarea pInputText name="suggestedTraining" [(ngModel)]="violation.suggestedTraining" maxlength="500" class="form-control"></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-12">
                    <label class="control-label">Track Corrective Action:</label>
                    <p-radioButton name="trackAction" value="true" [(ngModel)]="trackAction" label="Yes"></p-radioButton>
                    <p-radioButton name="trackAction" value="false" [(ngModel)]="trackAction" label="No"></p-radioButton>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-12">
                    <label class="control-label">Date of Corrective Action:</label>
                    <p-calendar name="correctiveActionDate" [(ngModel)]="correctiveActionDate" required [showIcon]="true"></p-calendar>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-12">
                    <div class="form-group">
                        <label>Corrective Action Notes:</label>
                        <textarea pInputText name="notes" [(ngModel)]="violation.notes" maxlength="500" class="form-control"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <st-update-block [object]='violation' *ngIf="violation"></st-update-block>
    </div>
</form>

