<ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
    <li [ngClass]="{'active-menuitem': isActive(i)}" [class]="child.badgeStyleClass">
        <a [href]="child.url||'#'" (click)="itemClick($event,child,i)" *ngIf="!child.routerLink" [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target" (mouseenter)="hover=true" (mouseleave)="hover=false" class="ripplelink">
            <i class="material-icons">{{child.icon}}</i>
            <span class="menuitem-text">{{child.label}}</span>
            <i class="material-icons layout-submenu-toggler" *ngIf="child.items">keyboard_arrow_down</i>
            <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
        </a>
        <a (click)="itemClick($event,child,i)" *ngIf="child.routerLink" [routerLink]="child.routerLink" routerLinkActive="active-menuitem-routerlink" [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target"
            (mouseenter)="hover=true" (mouseleave)="hover=false" class="ripplelink">
            <i class="material-icons">{{child.icon}}</i>
            <span class="menuitem-text">{{child.label}}</span>
            <i class="material-icons layout-submenu-toggler" *ngIf="child.items">>keyboard_arrow_down</i>
            <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
        </a>
        <ul app-submenu [item]="child" *ngIf="child.items" [visible]="isActive(i)" [@children]="isActive(i) ? 'visible' : 'hidden'"></ul>
    </li>
</ng-template>