import { Component, Input, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm, FormGroup } from '@angular/forms';

import { DataService } from '../../services/data.service';

@Component({
    selector: 'badgeholder-address',
    templateUrl: './badgeholder.address.component.html',
    encapsulation: ViewEncapsulation.None
})
export class BadgeHolderAddressComponent implements OnInit {
@Input() badgeHolder: any;
@Input() states: any[];

    constructor(
        private route: ActivatedRoute,
        private dataService: DataService) {

        }

    ngOnInit(): void {

    }
}