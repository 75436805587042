<div class="container">
    <div class="row">
        <div class="col-6">
            <h4>Report Delay</h4>
        </div>
        <div class="col-6">
            <div class="float-right">
                <button type="button" id="save" pButton (click)="save()" label="Save" icon="pi pi-save"></button>
                <button type="button" id="cancel" pButton (click)="cancel()" label="Cancel" icon="pi pi-ban"></button>
            </div>
        </div>
    </div>
    <div class="row">
    </div>
    <div class="row">
        <div class="col-lg-6">
            <div class="form-group">
                <label class="control-form">Type of Delay:</label><br/>
                <p-radioButton name="optType" id="optType1" value="Potential Delay" label="Potential Delay" [(ngModel)]="delay.delayType"></p-radioButton><br/>
                <p-radioButton name="optType" id="optType2" value="Actual Delay" label="Actual Delay" [(ngModel)]="delay.delayType"></p-radioButton>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="form-group">
                <label class="control-form">Cause of Delay:</label>
                <p-dropdown [options]="delayCauses" [filter]="true" placeholder="Select a Cause" name="dob" [(ngModel)]="delay.cause"></p-dropdown>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6">
            <div class="form-group">
                <label class="control-form">Delay Subject:</label>
                <input class="form-control" pInputText id="subject" type="text" [(ngModel)]="delay.subject" maxlength="50" />
            </div>
        </div>
        <div class="col-lg-6">
            <div class="form-group">
                <label class="control-form">Location of Delay</label>
                <input class="form-control" pInputText type="text" [(ngModel)]="delay.location"  maxlength="100"/>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="form-group">
                <label class="control-form">Delay Description:</label>
                <textarea pInputTextarea class="form-control" [(ngModel)]="delay.description" maxlength="250"></textarea>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-4">
            <div class="form-group">
                <label class="control-label">Company Responsible:</label><br />
                <p-dropdown [options]="companies" [filter]="true" placeholder="Select a Responsible Company" [(ngModel)]="delay.companyResponsible" name="companyResponsibleDropdown"></p-dropdown>
            </div>
        </div>
        <div class="col-lg-8">
            <div class="form-group">
                <label class="control-form">Company Responsible Description:</label>
                <textarea pTextArea class="form-control" [(ngModel)]="delay.companyResponsibleDescription" maxlength="250"></textarea>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-4">
            <div class="form-group">
                <label class="control-form">Company Impacted:</label><br />
                <p-dropdown [options]="companies" [filter]="true" placeholder="Select a Responsible Company" [(ngModel)]="delay.companyImpacted" name="companyImpactedDropdown"></p-dropdown>
            </div>
        </div>
        <div class="col-lg-8">
            <div class="form-group">
                <label class="control-form">Company Impacted Description:</label>
                <textarea pInputTextarea class="form-control" [(ngModel)]="delay.companyImpactedDescription" maxlength="250"></textarea>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6">
            <div class="form-group">
                <label class="control-form">Actual Delay:</label><br/>
                <p-checkbox name="approved" binary="true" label="Delay Approved" [(ngModel)]="delay.approved"></p-checkbox><br/>
                <p-checkbox name="contractDelay" binary="true" label="Critical Path Delay" [(ngModel)]="delay.contractDelay"></p-checkbox><br/>
                <p-checkbox name="companyDelay" binary="true" label="Concurrent Delay" [(ngModel)]="delay.companyDelay"></p-checkbox><br/>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="form-group">
                <label class="control-form">Delay Start Date:</label><br/>
                <p-calendar [(ngModel)]="delay.delayStart" [showIcon]="true"></p-calendar><br />
                <label class="control-form">Delay End Date:</label><br/>
                <p-calendar [(ngModel)]="delay.delayEnd" [showIcon]="true"></p-calendar>
            </div>
            <div class="form-group">
                <label class="control-form">Potential Delay Duration:</label>
                <input type="number" pInputText class="form-control" [(ngModel)]="delay.potentialDuration" />
            </div>
            <div class="form-group">
                <label class="control-form">Total Time Impact:</label>
                <input type="number" pInputText class="form-control" [(ngModel)]="delay.totalTimeImpact" />
            </div>
        </div>
    </div>
    <st-update-block [object]='delay' *ngIf="delay"></st-update-block>
</div>

