import { Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import {
    ConfirmationService,
    Message,
    MessageService,
} from 'primeng/api';
import { Table } from 'primeng/table';
// import { FilterUtils } from "primeng/utils";

import { LoggingService } from '../services/logging.service';
import { DataService } from '../services/data.service';
import { GridService } from '../services/grid.service';
import { DateService } from '../services/date.service';
//import { Route } from '@angular/router/src/config';
import { Subscription } from 'rxjs';
import { UserService } from '../services/user.service';

@Component({
    selector: 'st-table',
    templateUrl: './table.component.html',
    providers: [ConfirmationService]
})
export class STTableComponent implements OnInit, OnChanges {
    @Input() pageId: string;
    @Input() dataTable: string;
    @Input() filter: string;
    //    @Input() gridData: any[];
    //    @Output() selectedRows = new EventEmitter();

    @ViewChild('dt', { static: true }) private _table: Table;

    tableData: string;
    gridOptions: any = {};
    loading: boolean;
    private datePipe = new DatePipe('en-US');
    siteSubscription: Subscription;
    isDataLoaded: boolean = false;
    gridData: any[];
    hasImages: boolean = false;

    constructor(
        private confirmService: ConfirmationService,
        private dataService: DataService,
        private gridService: GridService,
        private messageService: MessageService,
        private dateService: DateService,
        private userService: UserService,
        private router: Router,
        private loggingService: LoggingService) {
        this.siteSubscription = this.userService.getSiteEvent()
            .subscribe(siteId => {
                this.isDataLoaded = true;
                this.loadData();
            });
    }

    ngOnInit(): void {
        this.loading = true;

        // FilterUtils['date'] = (value, filter): boolean => {
        //     return this.datePipe.transform(this.dateService.convertDateUniversalToSiteTimeDate(value), 'shortDate').includes(filter);
        // };
        // FilterUtils['datetime'] = (value, filter): boolean => {
        //     return this.datePipe.transform(this.dateService.convertDateUniversalToSiteTimeDate(value), 'MM/dd/yyyy HH:mm a').includes(filter);
        // };
        // FilterUtils['time'] = (value, filter): boolean => {
        //     return this.datePipe.transform(this.dateService.convertDateUniversalToSiteTimeDate(value), 'shortTime').includes(filter);
        // };

        this.dataService.getById('gridoptions', this.pageId)
            .subscribe((options) => {
                this.gridOptions = options;
                this.tableData = this.gridOptions.dataName;
                this.gridOptions.scrollable = false;// doesn't work right now
                for (const column of this.gridOptions.fields) {
                    if (column.type === 'image') {
                        this.hasImages = true;
                    }
                }
                this.loadData();
            });
    }

    ngOnDestroy() {
        this.siteSubscription.unsubscribe();
    }

    public onFilter(event): void {
        const filter = ((event.filters.global !== undefined) ? event.filters.global.value : '');
        const filtered = [];
        for (const row of this.gridData) {
            let globalFilterFound = (filter === '');
            let allFiltersOK = true;
            for (const column of this.gridOptions.fields) {
                if (column.type !== 'image') {
                    let format = null;
                    if (column.type === 'date') {
                        format = 'shortDate';
                    } else if (column.type === 'datetime') {
                        format = 'MM/dd/yyyy HH:mm a';
                    } else if (column.type === 'time') {
                        format = 'shortTime';
                    } 

                    let value = row[column.field];
                    if (column.type === 'yesno')
                        value = (value ? 'Yes' : 'No');
                    else if (column.type === 'passfail')
                        value = (value ? 'Pass' : 'Fail');

                    if (format !== null) {
                        value = this.datePipe.transform(this.dateService.convertDateUniversalToSiteTimeDate(value), format);
                    }

                    if (filter !== '' && value !== undefined && value !== null && String(value).toLowerCase().includes(filter.toLowerCase())) {
                        globalFilterFound = true;
                    }
                    if (this._table.filters[column.field] !== undefined && (value === undefined && value === null || !String(value).toLowerCase().includes(this._table.filters[column.field].value.toLowerCase()))) {
                        allFiltersOK = false;
                    }
                } 
            }
            if (globalFilterFound && allFiltersOK) {
                filtered.push(row);
            }
        }
        this._table.filteredValue = filtered;
    }
    private exportToCSV(): void {
        let csv = '';
        for (const column of this.gridOptions.fields) {
            if (column.type !== 'image') {
                if (csv !== '') {
                    csv += ',';
                }
                csv += column.header;
            }
        }
        csv = csv + '\n';


        const rows = ((this._table.filteredValue !== undefined && this._table.filteredValue !== null) ? this._table.filteredValue : this.gridData);
        for (const row of rows) {
            let csvRow = '';
            for (const column of this.gridOptions.fields) {
                if (column.type !== 'image') {
                    if (csvRow !== '') {
                        csvRow += ',';
                    }
                    if (!row[column.field])
                        csvRow += ' ';
                    else
                        if (column.type === 'date') {
                            csvRow += this.datePipe.transform(this.dateService.convertDateUniversalToSiteTimeDate(row[column.field]), 'shortDate');
                        } else if (column.type === 'datetime') {
                            csvRow += this.datePipe.transform(this.dateService.convertDateUniversalToSiteTimeDate(row[column.field]), 'MM/dd/yyyy HH:mm a');
                        } else if (column.type === 'time') {
                            csvRow += this.datePipe.transform(this.dateService.convertDateUniversalToSiteTimeDate(row[column.field]), 'shortTime');
                        } else if (column.type === 'yesno') {
                            csvRow += '"' + (row[column.field] ? 'Yes' : 'No') + '"';
                        } else if (column.type === 'passfail') {
                            csvRow += '"' + (row[column.field] ? 'Pass' : 'Fail') + '"';
                        } else if (column.type === 'number') {
                            csvRow += '"' + row[column.field] + '"';
                        } else {
                            if (isNaN(row[column.field]))
                                if (row[column.field].indexOf(',') < 0)
                                    csvRow += '=';
                            csvRow += '"' + row[column.field].replace(/"/g, '""') + '"';
                        }
                }
            }
            csv += csvRow + '\n';
        }

        const blob = new Blob(['\uFEFF', csv], { type: 'text/csv' });
        const link = document.createElement('a');
        link.setAttribute('href', window.URL.createObjectURL(blob));
        link.setAttribute('download', this._table.exportFilename + '.csv');
        document.body.appendChild(link); // Required for FF
        link.click();
    }

    ngOnChanges(changes: SimpleChanges) {
        //this.loadData();
        // for (const propName in changes) {
        //     this.loadData();
        // }
    }

    loadData() {
        this.gridData = [];
        if (this.filter === undefined) {
            this.dataService.getAll(this.tableData)
                .subscribe((data) => {
                    this.gridData = data;
                    this.loading = false;
                    setTimeout(() => this.autoFit());
                },
                (error) => { console.log(error); },
                ()=> {
                    setTimeout(() => {
                        this.loadPhotos()
                      }, 3000);
                });
        } else {
            this.dataService.getById(this.tableData, this.filter)
                .subscribe((data) => {
                    this.gridData = data;
                    this.loading = false;
                    setTimeout(() => this.autoFit());
                },
                (error) => { console.log(error); },
                ()=> {
//                    setTimeout(() => {
//                        this.loadPhotos()
//                      }, 3000);
                }); 
        }
    }

    loadPhotos() {
        if (this.hasImages) {
            for (const row of this.gridData) {
                let photoPath = row['photoURL'];
                let value = row['id'];
                const img = window.document.getElementById('profile-' + value);
    //          img.style.display = 'none';
    
                this.dataService.getById('files/download/profile', value, { responseType: 'arraybuffer' })
                .subscribe((photo) => {
                    const file = new Blob([photo], { type: 'image/jpeg' });
                    const fileURL = window.URL.createObjectURL(file);
    
                    img.setAttribute('src', fileURL);
    //              img.style.display = '';
    //              setTimeout(() => window.URL.revokeObjectURL(fileURL), 1000);
                });
            }    
        }
    }

    private context: CanvasRenderingContext2D;
    autoFit() {
        if (!this.context)
            this.context = document.createElement("canvas").getContext("2d");

        if (this.gridOptions && this.gridOptions.fields)
            this.gridOptions.fields.forEach(col => {
                if (col.type === 'image')
                    col.width = '76px';
                else {
                    const elements = document.getElementsByClassName('p-table-column-' + col.field);
                    if (elements.length > 0) {
                        this.context.font = '400 14px Roboto, "Helvetica Neue", sans-serif';
                        let width = 50;
                        let values = col.header.replace(/-/g, '_').split(' ');
                        values.forEach(m => {
                            width = Math.max(this.context.measureText(m + 'XX').width, width);
                        });

                        this.context.font = '14px Roboto, "Helvetica Neue", sans-serif';
                        for (let i = 2; i < elements.length; i++) {
                            values = elements[i].getElementsByClassName('ng-star-inserted')[0].innerHTML.replace(/-/g, '_').split(' ');
                            values.forEach(m => {
                                width = Math.max(this.context.measureText(m + 'XX').width, width);
                            });
                        }

                        width = Math.ceil(width);
                        width = Math.max(width, 50);
                        width = Math.min(width, 200);
                        col.width = `${String(width)}px`;
                    }
                }
            });
    }

    delete(rData: any) {
        this.confirmService.confirm({
            message: 'Do you want to delete ' + rData.name + '?',
            accept: () => {
                this.dataService.deleteById(this.tableData, rData.id)
                    .subscribe((data) => {
                        this.loadData();
                        this.messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'Record deleted' });
                    });
            },
            reject: () => {
                this.messageService.add({ severity: 'info', summary: 'Cancelled', detail: 'You have cancelled the request' });
            }
        });
    }
    undelete(rData: any) {
        this.confirmService.confirm({
            message: 'Do you want to undelete ' + rData.name + '?',
            accept: () => {
                this.dataService.getById(this.tableData + '/' + rData.id, 'undelete')
                    .subscribe((data) => {
                        this.loadData();
                        this.messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'Record undeleted' });
                    });
            },
            reject: () => {
                this.messageService.add({ severity: 'info', summary: 'Cancelled', detail: 'You have cancelled the request' });
            }
        });
    }

    edit(record: any) {
        const path = '/' + this.dataTable + '/detail/' + record.id;
        this.router.navigate([path]);
    }

    addNew(): void {
        const path = '/' + this.dataTable + '/detail/new';
        this.router.navigate([path]);
    }

    toggle() {
        this.gridOptions.stacked = !this.gridOptions.stacked;
    }
}
