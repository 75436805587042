import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Message, MessageService } from 'primeng/api';
import { environment } from '../../../environments/environment';
import { DataService } from '../services/data.service';

@Component({
    selector: 'app-badge-templates',
    templateUrl: './badge-templates.component.html',
    encapsulation: ViewEncapsulation.None
})
export class BadgeTemplatesComponent implements OnInit {

    env: any = environment;

    sites: any[];
    siteId: string;

    badgeTemplates: any[];
    badgeTemplateId: string;
    badgeTemplateWidth: string;
    badgeTemplateHeight: string;
    badgeTemplateTitle: string;
    badgeTemplateHTML: string;

    orginalBadgeTemplateTitle: string;
    orginalBadgeTemplateWidth: string;
    orginalBadgeTemplateHeight: string;
    orginalBadgeTemplateHTML: string;

    displayHTML: SafeHtml;

    constructor(private dataService: DataService, private sanitized: DomSanitizer, private messageService: MessageService) { }

    ngOnInit() {
        this.loadData();
    }
    loadData() {
        this.dataService.getById('lookups', 'site')
            .subscribe((lookup) => {
                this.sites = lookup;
                this.sites.unshift({});
            });
    }
    siteChanged(): void {
        this.dataService.getById('lookups', `badgetemplate/${this.siteId}`)
            .subscribe((lookup) => {
                this.badgeTemplates = lookup;
                this.sites.some(m => {
                    if (m.value === this.siteId) {
                        this.badgeTemplateId = m.data;
                        this.setBadgeTemplateHTML();
                        return m;
                    }
                })
            });
    }
    setBadgeTemplateHTML(): void {
        this.badgeTemplates.some(m => {
            if (m.value === this.badgeTemplateId) {
                this.badgeTemplateTitle = m.label;
                this.orginalBadgeTemplateTitle = m.label;

                const data = JSON.parse(m.data);
                this.badgeTemplateWidth = data.width;
                this.orginalBadgeTemplateWidth = data.width;

                this.badgeTemplateHeight = data.height;
                this.orginalBadgeTemplateHeight = data.height;

                this.badgeTemplateHTML = data.html;
                this.orginalBadgeTemplateHTML = data.html;

                this.setupDisplayHTML();
                return m;
            }
        })
    }
    setupDisplayHTML(): void {
        this.displayHTML = this.sanitized.bypassSecurityTrustHtml(this.badgeTemplateHTML
            .replace(/{{siteLogo}}/g, `https://tcnstphotos.blob.core.windows.net/site/site_${this.siteId}/sitelogo.png`)
            .replace(/{{gcLogo}}/g, `https://tcnstphotos.blob.core.windows.net/site/site_${this.siteId}/gclogo.jpg`)
            .replace(/{{stLogo}}/g, `https://webapp.sitetraxx.com/assets/images/sitetraxx-icon.png`)
            .replace(/{{stLogoLarge}}/g, `https://webapp.sitetraxx.com/assets/images/sitetraxx-icon-large.png`)
            .replace(/{{FirstName}}/g, 'First')
            .replace(/{{LastName}}/g, 'Last')
            .replace(/{{siteName}}/g, 'Site Name')
            .replace(/{{CompanyName}}/g, 'Company Name')
            .replace(/{{BadgeNum}}/g, 'Badge #')
            .replace(/{{badgeExpireOneYear}}/g, 'Badge Expire One Year')
            .replace(/{{EmergencyContact}}/g, 'Emergency Contact')
            .replace(/{{EmergencyPhone}}/g, 'Emergency Phone')
            .replace(/{{contact}}/g, `<span style='font-size: 8pt'>and contact</span><br /><span style='font-size: 8pt'>Emergency Contact</span><br /><span style='font-size: 8pt'>Emergency Phone</span><br />`)
            .replace(/{{badgeHolderPhoto}}/g, '../assets/images/user_profile.jpg')
            .replace(/{{qrcodedata}}/g, '../assets/images/user_profile.jpg')
            .replace(/{{securityPhone}}/g, 'Security Phone')
            .replace(/{{securityEmail}}/g, 'Security Email')
            .replace(/{{LaborClassDesc}}/g, 'Labor Class')
            .replace(/{{SiteSpecificId}}/g, 'Site ID')
            .replace(/{{EmployeeId}}/g, 'Employee ID')
            .replace(/{{Vaccinated}}/g, 'Vaccinated')
            .replace(/{{Certifications}}/g, 'Certification #1<br />Certification #2')
        );
    }
    badgeTemplateChanged(): void {
        this.setBadgeTemplateHTML();
        this.dataService.save('sites/badgetemplate', { id: this.siteId, badgeTemplateId: this.badgeTemplateId })
            .subscribe((data) => {
                this.sites.some(m => {
                    if (m.value === this.siteId) {
                        m.data = this.badgeTemplateId;
                        return m;
                    }
                })
                this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Record saved' });
            });
    }
    showBadge(): void {
        window.open(this.env.baseAPIUrl + 'pdf/sample/' + this.siteId);
    }

    save(): void {
        this.dataService.save('lookups', {
            id: this.badgeTemplateId,
            Title: this.badgeTemplateTitle,
            ProfilePhotoWidth: this.badgeTemplateWidth,
            ProfilePhotoHeight: this.badgeTemplateHeight,
            HTML: this.badgeTemplateHTML,
            Hidden: (this.badgeTemplateTitle === '>>THIS SITE ONLY'),
        })
            .subscribe((data) => {
                this.loadData();
                this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Record saved' });
            });
    }
}
