<p-dialog header="Upload" [(visible)]="showDialog" modal="modal" [style]="{ width: '500px' }">
    <p-fileUpload name="files" multiple="multiple" url="{{uploadImageUrl}}" (onUpload)="onUpload($event)" accept="image/*" maxFileSize="10000000" showCancelButton="false">
        <ng-template pTemplate="content">
            <ul *ngIf="uploadedFiles.length">
                <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
            </ul>
            <span>Drag and Drop a Photo into this area or click the "Choose" button to select a file.</span>
        </ng-template>
    </p-fileUpload>
    <p-footer>
        <button type="button" pButton icon="pi pi-times" (click)="closeUploadDialog()" label="Close"></button>
    </p-footer>
</p-dialog>