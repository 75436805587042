import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Tree } from 'primeng/tree';
import { DataService } from '../services/data.service';
import { LoggingService } from '../services/logging.service';

@Component({
    selector: 'app-menus',
    templateUrl: './menus.component.html',
    styleUrls: ['./menus.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class MenusComponent implements OnInit {

    menus = [];

    nextId = -1;

    @ViewChild(Tree, { static: true }) tree: Tree;

    constructor(private dataService: DataService, private loggingService: LoggingService) {}

    ngOnInit() {
        this.dataService.getById('menus', 'all-menus')
            .subscribe((menus) => {
                this.nextId = -1;
                menus.forEach(m => {
                    if (this.nextId < m.id)
                        this.nextId = m.id;

                    if (m.parentId === '0') {
                        this.menus.push({
                            data: m,
                            expandedIcon: "ui-icon-folder-open",
                            collapsedIcon: "ui-icon-folder",
                            children: []
                        });
                    }
                    else {
                        let parent = null;
                        this.menus.forEach(p => {
                            if (p.data.id === m.parentId)
                                parent = p;
                        });
                        if (parent)
                            parent.children.push({
                                data: m,
                                droppable: false
                            });
                    }
                });
                this.nextId += 10;
        });
    }


    add(parent) {
        parent.children.push({
            data: {id: this.nextId},
            droppable: false
        });
        parent.expanded = true;
        this.nextId += 10;
    }


    onDrop(e) {
        console.log(e);
    }


    save() {
        let isMissingName = false;
        let isMissingRoute = false;
        const menus = [];
        let rootSortOrder = 1;
        this.tree.value.forEach(m => {
            if (!m.data.name)
                isMissingName = true;
            if (m.data.id === '1' && !m.data.route)
                isMissingRoute = true;
            m.data.sortOrder = rootSortOrder;
            rootSortOrder += 10;
            menus.push(m.data);
           
            let itemSortOrder = 1;
            m.children.forEach(c => {
                if (!c.data.name)
                    isMissingName = true;
                if (!c.data.route)
                    isMissingRoute = true;
                c.data.parentId = m.data.id;
                c.data.sortOrder = itemSortOrder;
                itemSortOrder += 10;
                menus.push(c.data);
            });
        });
        
        if (isMissingName)
            this.loggingService.errorMessage('Validation Error', 'Name is required');
        else if (isMissingRoute)
            this.loggingService.errorMessage('Validation Error', 'Route is required');
        else
            this.dataService.save('menus', menus)
                .subscribe((data) => {
                    this.loggingService.savedMessage();
                });
    }
}
