import { Component, Input, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm, FormGroup } from '@angular/forms';

import { DataService } from '../../services/data.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { environment } from '../../../../environments/environment';
import { UserService } from '../../services/user.service';
import { LoggingService } from '../../services/logging.service';

@Component({
    selector: 'app-badgeholder-projects',
    templateUrl: './badgeholder.projects.component.html',
    providers: [ ConfirmationService ],
    encapsulation: ViewEncapsulation.None
})
export class BadgeHolderProjectsComponent implements OnInit {
@Input() badgeHolder: any;

projects: any[];
currentProject: any = {};
showEditDialog = false;
showDeleteDialog = false;
projectsList: any[];
user: any;

    constructor(
        private route: ActivatedRoute,
        private dataService: DataService,
        private confirmService: ConfirmationService,
        private messageService: MessageService,
        private loggingService: LoggingService,
        private userService: UserService) { }

    ngOnInit(): void {
        this.user = this.userService.currentUser();

        this.loadProjects();
    }

    loadProjects(): void {
        this.dataService.getById('badgeholders/' + this.badgeHolder.id, 'projects')
        .subscribe((data) => {
            this.projects = data;
        });
    }

    loadProjectList(): void {
        this.dataService.getAll('lookups/projects')
        .subscribe((data) => {
            this.projectsList = data;
        });
    }

    loadProject(projectId: string): void {
        if (projectId !== undefined) {
            this.dataService.getById('badgeholders/projects', projectId)
                .subscribe((data) => {
                    this.currentProject = data;
                });
        } else {
            this.currentProject.id = 'new';
            this.currentProject.badgeHolderId = this.badgeHolder.id;
        }
    }

    edit(project): void {
        this.currentProject = project;
        if (this.currentProject === undefined) {
            this.currentProject.id = 'new';
        }
        this.showEditDialog = true;

        this.loadProjectList();
    }

    delete(project): void {
        this.showDeleteDialog = true;
        this.currentProject = project;

            this.confirmService.confirm({
                message: 'Do you want to delete ' + project.projectName + '?',
                accept: () => {
                    this.dataService.deleteById('badgeholders/projects', project.id)
                        .subscribe((data) => {
                            this.loadProjects();
                            this.messageService.add({severity: 'success', summary: 'Confirmed', detail: 'Record deleted'});
                        });
                },
                reject: () => {
                    this.messageService.add({severity: 'info', summary: 'Cancelled', detail: 'You have cancelled the request'});
                }
            });
    }

    save(): void {
        if (this.currentProject.badgeHolderId === undefined) {
            this.currentProject.badgeHolderId = this.badgeHolder.id;
        }
        this.dataService.save('badgeholders/projects', this.currentProject)
            .subscribe((data) => {
                this.loadProjects();
            });
        this.showEditDialog = false;
    }

}
