import { Component, Input, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm, FormGroup } from '@angular/forms';

import { LoggingService } from '../../services/logging.service';
import { DataService } from '../../services/data.service';

@Component({
    selector: 'app-badgeholder-photos',
    templateUrl: './badgeholder.photos.component.html',
    encapsulation: ViewEncapsulation.None
})
export class BadgeHolderPhotosComponent implements OnInit {
@Input() badgeHolder: any;

photos: any[];
onsiteInfo: any;
showDialog = false;
clockInFace: boolean;
clockOutFace: boolean;
profileFace: boolean;
clockInCompare: any;
clockOutCompare: any;
syncTargets: any[];
loadingClocks = false;
loadingSyncs = false;

    constructor(
        private route: ActivatedRoute,
        private dataService: DataService,
        private loggingService: LoggingService) {

        }

    ngOnInit(): void {
        this.loadPhotos();
    }

    loadPhotos(): void {
      this.loadingClocks = true;
        this.dataService.getById('badgeholders/' + this.badgeHolder.id, 'clocks')
            .subscribe((data) => {
                this.photos = data;
                this.loadingClocks = false;
                setTimeout(() => {
                    for (const row of this.photos) {
                        this.loadPhoto(row['clockId'], row['clockId'], 'clockIn', 'clockin/' + row['clockInPhotoURL']);
                        this.loadPhoto(row['exitId'], row['exitId'], 'clockOut', 'clockout/' + row['clockOutPhotoURL']);
                    }
                }, 2000);
            });

        // not going to do this yet because of all the duplicates
        // this.loadingSyncs = true;
        // this.dataService.getById('badgeholders/' + this.badgeHolder.id, 'syncs')
        //   .subscribe((data) => {
        //     this.loadingSyncs = false;
        //     this.syncTargets = data;
        //   });
    }

    loadPhoto(id: string, imageId: string, type: string, path: string) {
        if (imageId !== null) {
            const imgId = type + '-' + imageId;
            const img = window.document.getElementById(imgId);
            img.style.display = 'none';

            this.dataService.getById('files/download/' + path, id, { responseType: 'arraybuffer' })
            .subscribe((photo) => {
                const file = new Blob([photo], { type: 'image/jpeg' });
                const fileURL = window.URL.createObjectURL(file);

                img.setAttribute('src', fileURL);
                img.style.display = '';
//              setTimeout(() => window.URL.revokeObjectURL(fileURL), 1000);
            });
        }
    }

    show(onsite: any): void {
        this.onsiteInfo = null;
//        this.profileFace = false;
//        this.clockInFace = false;
//        this.clockOutFace = false;
//        this.clockInCompare = {};
//        this.clockOutCompare = {};
        this.onsiteInfo = onsite;
    setTimeout(() => {
        this.loadPhoto(onsite.clockId, 'photo', 'clockIn', 'clockin/' + onsite.clockInPhotoURL);
        this.loadPhoto(onsite.exitId, 'photo', 'clockOut', 'clockout/' + onsite.clockOutPhotoURL);
    }, 2000);

/*
        if (this.onsiteInfo.photoId != null) {
            this.dataService.getById('face/detect', this.onsiteInfo.photoId)
                .subscribe((data) => {
                this.profileFace = data.result;
                onsite.profileFace = this.profileFace;
            });
        }
        if (this.onsiteInfo.enterId != null) {
            this.dataService.getById('face/detect', this.onsiteInfo.enterId)
                .subscribe((data) => {
                this.clockInFace = data.result;
                onsite.clockInFace = this.clockInFace;
            });
        }
        if (this.onsiteInfo.exitId != null) {
            this.dataService.getById('face/detect', this.onsiteInfo.exitId)
                .subscribe((data) => {
                this.clockOutFace = data.result;
                onsite.clockOutFace = this.clockOutFace;
            });
        }

        if (onsite.profileFace === true && onsite.clockInFace === true) {
            this.verifyFaces(onsite.photoId, onsite.enterId, 'Enter');
        }

        if (onsite.profileFace === true && onsite.clockOutFace === true) {
            this.verifyFaces(onsite.photoId, onsite.exitId, 'Exit');
        }
*/

        this.showDialog = true;
    }

    verifyFaces(faceid1: string, faceid2: string, type: string): any {
        const faceInput = { photoid1: faceid1, photoid2: faceid2 };
        this.dataService.save('face/verify', faceInput)
            .subscribe((data) => {
                if (type === 'Enter') {
                    this.clockInCompare = data.result;
                } else if (type === 'Exit') {
                    this.clockOutCompare = data.result;
                }
            });
    }

    close(): void {
        this.onsiteInfo = null;
        this.showDialog = false;
    }

    setAsProfile(id: string, type: string): void {
        const payload = { badgeHolderId: this.onsiteInfo.badgeHolderId, photoId: id };

        if (type === 'Enter') {
            this.onsiteInfo.profilePhotoURL = this.onsiteInfo.clockInPhotoURL;
            this.onsiteInfo.photoId = this.onsiteInfo.enterId;
        } else if (type === 'Exit') {
            this.onsiteInfo.profilePhotoURL = this.onsiteInfo.clockOutPhotoURL;
            this.onsiteInfo.photoId = this.onsiteInfo.exitId;
        }

        this.dataService.save('face/profile', payload)
            .subscribe((data) => {
                this.onsiteInfo.profileFace = true;
            });
    }
}
