
<div *ngIf="badgeHolder===undefined">
<div class="container">
    <div class="row">
        <div class="col">
            <div class="float-left">
                <h3>Badge Holders</h3>
            </div>    
        </div>
    </div>
    <div class="row">
        <div class="col">
            <p-table #dt 
                [lazy]="true" 
                [value]="badgeHolders" 
                [columns]="cols" 
                [scrollable]="true"
                [exportFilename]="'onsite'" 
                [paginator]="true" 
                [rows]="pageSize" 
                [totalRecords]="totalRecords"
                [showCurrentPageReport]="true" 
                [responsive]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} total."
                [rowsPerPageOptions]="pageSizeOptions" 
                (onPage)="pageChanged($event)" 
                (onFilter)="applyFilters($event)">
                <ng-template pTemplate="caption">
                    <div style="text-align:left; display: flow-root">
                            <button pButton (click)="onSubmitForm()" class="indigo-btn" id="btnSubmit" label="Search" icon="pi pi-search"></button>
                            &nbsp;&nbsp;&nbsp;
                            <input pInputText [(ngModel)]="globalFilter" placeholder="Global Filter" />
                            &nbsp;&nbsp;&nbsp;
                            <p-dropdown [options]="listTypes" [(ngModel)]="listType"
                            [style]="{ 'margin-left': '5px', 'margin-right': '5px', 'margin-top': '5px' }"></p-dropdown>
                            &nbsp;&nbsp;&nbsp;
                            <span *ngIf="filterCount !== 0">&nbsp;&nbsp;&nbsp;Filter Count: {{filterCount}}</span>
                        <div class="float-right">
                            <button type="button" pButton title="Export" icon="pi pi-cloud-download" class="indigo-btn"
                                (click)="exportToCSV()" style="float:left"></button>
                                <button pButton icon="pi pi-plus" title="Add" (click)="addNew()" class="indigo-btn" style="float: right;"></button>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th style="width: 46px; padding: .625em 0; text-align: center">View</th>
                        <th style="width: 46px; padding: .625em 0; text-align: center">Delete</th>
                        <th *ngIf="isGlobalAdmin" style="width: 55px; padding: .625em 0; text-align: center">UnDelete</th>
                        <th style="width: 32px; padding: .625em 0; text-align: center"></th>
                        <th *ngFor="let col of columns">
                            {{col.header}}
                        </th>
                    </tr>
                    <tr>
                        <th style="width: 46px; padding: .625em 0; text-align: center"></th>
                        <th style="width: 46px; padding: .625em 0; text-align: center"></th>
                        <th *ngIf="isGlobalAdmin" style="width: 55px; padding: .625em 0; text-align: center"></th>
                        <th style="width: 32px; padding: .625em 0; text-align: center"></th>
                        <th style="overflow: hidden" *ngFor="let col of columns">
                            <input pInputText type="text"
                                (input)="dt.filter($event.target.value, col.field, 'contains')"
                                *ngIf="col.field !== 'profilePhotoURL'">
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pSelectableRow]="rowData" [ngClass]="{'undeletable': rowData['deleted']}">
                        <td style="width: 46px; padding: .625em 0; text-align: center"><button type="button" pButton
                            (click)="edit(rowData)" icon="pi pi-pencil"></button></td>
                        <td style="width: 46px; padding: .625em 0; text-align: center"><button type="button" pButton
                            (click)="delete(rowData)" icon="pi pi-trash"></button></td>
                        <td *ngIf="isGlobalAdmin" style="width: 55px; padding: .625em 0; text-align: center">
                            <button type="button" pButton (click)="undelete(rowData)" icon="pi pi-undo" style="width: 35px; height: 35px;" *ngIf="rowData['deleted']"></button>
                        </td>
                        <td style="width: 32px; padding: .625em 0; text-align: center">
                            <span *ngIf="rowData.status==='S'" class="numberCircle">SA</span>
                            <span *ngIf="rowData.status==='T'" class="numberCircle">ST</span>
                        </td>                
                        <td *ngFor="let col of columns" [ngSwitch]="col.field">
                            <img *ngSwitchCase="'profilePhotoURL'" id="profile-{{rowData['id']}}"
                                style="width: 50px" />
                            <span *ngSwitchCase="'badgeHolderId'"></span>
                            <span *ngSwitchDefault>{{rowData[col.field]}}</span>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
<p-toast position="top-right"></p-toast>
<p-confirmDialog header='Delete?' icon='pi pi-trash'></p-confirmDialog>
</div>
<div *ngIf="badgeHolder">
    <app-badgeholder-profile [badgeHolder]="badgeHolder"></app-badgeholder-profile>
</div>