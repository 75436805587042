import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { DataService } from '../services/data.service';

@Component({
    selector: 'app-terminal-status',
    templateUrl: './terminal-status.component.html',
    encapsulation: ViewEncapsulation.None
})
export class TerminalStatusComponent implements OnInit {

    data: any[];
    cols = [
        { field: 'siteName', header: 'Site' },
        { field: 'name', header: 'Name' },
        { field: 'lastSyncDate', header: 'Last Sync Date' },
        { field: 'versionInfo', header: 'Version Info' },
        { field: 'deleted', header: 'Deleted' },
        { field: 'status', header: 'Status' },
        { field: 'badgeHolderCount', header: 'Badge Holder Count' },
    ];

    constructor(private dataService: DataService) { }

    ngOnInit() {
        this.dataService.getById('terminals', 'global/all')
            .subscribe((response) => {
                this.data = response;
            });
    }
}
