import { Component, OnInit, Input, OnChanges } from '@angular/core';

import { LoggingService } from '../../services/logging.service';
import { DataService } from '../../services/data.service';

@Component({
    selector: 'app-concrete-dailyreport',
    templateUrl: './concrete.dailyreport.component.html'
})
export class ConcreteDailyReportComponent implements OnInit, OnChanges {
    @Input() dateFilter: string;

    concreteList: any[];

    constructor(
        private dataService: DataService,
        private loggingService: LoggingService) {}

    ngOnInit(): void {

    }

    ngOnChanges(): void {
        this.dataService.getById('concrete/date', this.dateFilter)
        .subscribe((data) => {
            this.concreteList = data;
        });
    }
}
