<div class="login-body">
    <div class="login-panel ui-fluid">
        <div class="login-panel-header">
            <img alt="SiteTraxx Logo" src="../assets/images/sitetraxx-icon.png">
        </div>
        <div class="login-panel-content">
            <div class="ui-g">
                <form #thisForm='ngForm' name="form" (ngSubmit)="f.form.valid" #f="ngForm" novalidate>
                    <div class="ui-g-12">
                        <h1>Sign-in to SiteTraxx</h1>
                    </div>
                    <div class="ui-g-12">
                        <label for="username">Username</label><br />
                        <input pInputText type="text" class="ui-inputtext ui-corner-all ui-widget" name="username" [(ngModel)]="model.username" required #username="ngModel" required />
                        <div *ngIf="f.submitted && !username.valid" class="help-block">Username is required</div>
                    </div>
                    <div class="ui-g-12">
                        <label for="password">Password</label><br />
                        <input pInputText type="password" class="ui-inputtext ui-corner-all ui-widget" name="password" [(ngModel)]="model.password" required #password="ngModel" required />
                        <div *ngIf="f.submitted && !password.valid" class="help-block">Password is required</div>
                    </div>
                    <div class="ui-g-12">
                        <button id="loginbuttion" [disabled]="!thisForm.valid" (click)="login()" type="submit" class="ui-button ui-widget ui-corner-all ui-button-text-icon-left">
                            <span class="ui-button-icon-left fa ui-icon-person"></span>
                            <span class="ui-button-text">Sign In</span>
                        </button>
                    </div>
                </form>
            </div>
            <p-messages [(value)]="msgs"></p-messages>
        </div>
    </div>
</div>