<form #thisForm='ngForm' *ngIf="gateway">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <h3>{{gateway?.name}}</h3>
            </div>
            <div class="col-lg-6">
                <div class="float-right">
                    <button pButton (click)="save()" [disabled]="!thisForm.valid" icon="pi pi-save" label="Save"></button>
                    <button pButton (click)="cancel()" icon="pi pi-ban" label="Cancel"></button>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <label class="control-label">Gateway ID:</label>
                <input type="text" name="id" class="form-control" pInputText [(ngModel)]="gateway.id" maxlength="100" />
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4">
                <label class="control-label">Gateway Name:</label>
                <input type="text" name="name" class="form-control" pInputText [(ngModel)]="gateway.name" maxlength="100" />
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4">
                <label class="control-label">Project:</label><br/>
                <p-dropdown [options]="projectsList" name="projectId" [filter]="true" placeholder="Select Project" [(ngModel)]="gateway.projectId" [scrollHeight]="300"></p-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4">
                <label class="control-label">Threshold:</label><br/>
                <input type="text" name="threshold" class="form-control" pInputText [(ngModel)]="gateway.threshold" maxlength="100" />
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4">
                <label class="control-label">Motion Threshold:</label><br/>
                <input type="text" name="motionThreshold" class="form-control" pInputText [(ngModel)]="gateway.motionThreshold" maxlength="100" />
            </div>
        </div>
    </div>
    <div class="container">
        <st-update-block [object]='gateway' *ngIf="gateway"></st-update-block>
    </div>
</form>



<p-confirmDialog header='Delete?' icon='pi pi-trash'></p-confirmDialog>