<div class="panel-body" id="basicPanel">
  <div *ngIf="badgeHolder.isSuspended" class="alert alert-danger mx-5">
    Badge Holder was suspended on {{badgeHolder.suspendedDate | date:'MM/dd/yyyy'}}.
  </div>
  <div *ngIf="badgeHolder.isTerminated" class="alert alert-danger mx-5">
    Badge Holder was terminated on {{badgeHolder.terminationDate | date:'MM/dd/yyyy'}}.
  </div>
  <div *ngIf="showSignupForTextBanner" class="alert alert-info">
    <div>This Badge Holder has not been signed up for text alerts. Check here to allow texts:</div>
    <div class="mt-2 ml-4 align-items-center">
      <p-checkbox name="textReceived" class="flex align-items-center" binary="true" label="Text can be received" [(ngModel)]="badgeHolder.textPhone"></p-checkbox>
    </div>
  </div>
    <form #thisForm='ngForm'>
        <div class="row" style="margin-left: 0; margin-right: 0;">
            <div class="col-lg-4">
                <div class="row">
                    <label class="control-label">Badge / Sticker #:</label>
                    <input type="text" class="form-control" *ngIf="badgeHolder" pInputText name="badgeNum"
                        [(ngModel)]="badgeHolder.badgeNum" maxlength="36" (change)="onBadgeNumberChanged()" />
                </div>
                <div class="row">
                    <div id="badgeHolderPhoto">
                        <img width="200" alt="Profile Photo" id="profile" />
                        </div>
                    <form>
                        <div>
                            <button pButton (click)="showTakePhotoDialog()" [disabled]="showAddPhoto || isReadOnly"
                                icon="pi pi-camera" label="Take Photo"></button>
                            <button pButton (click)="showUploadDialog()" [disabled]="showAddPhoto || isReadOnly"
                                icon="pi pi-file-upload" label="Upload"></button>
                        </div>
                        <div style="padding-top: 10px">
                            <button *ngIf="badgeTemplates?.length == 0" pButton (click)="showBadge()" [disabled]="showPrintBadge || isReadOnly" icon="pi pi-print"
                                label="Print Badge"></button>
                            <button *ngIf="badgeTemplates?.length > 0" pButton (click)="selectBadge()" [disabled]="showPrintBadge || isReadOnly" icon="pi pi-print"
                                label="Select Badge to Print"></button>
                            <button *ngIf="user.faceCode !== ''" pButton (click)="compare()" [disabled]="showPrintBadge || isReadOnly" icon="pi pi-search"
                                label="Compare"></button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="row">
                    <div class="col-lg-6">
                        <label class="control-label">Site Specific ID #:</label>
                        <input type="text" class="form-control" *ngIf="badgeHolder" pInputText name="siteSpecificId"
                            [(ngModel)]="badgeHolder.siteSpecificId" maxlength="30" />
                    </div>
                    <div class="col-lg-6">
                        <label class="control-label">Employee ID #:</label>
                        <input type="text" class="form-control" *ngIf="badgeHolder" pInputText name="employeeId"
                            [(ngModel)]="badgeHolder.employeeId" maxlength="36" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <label class="control-label">Beacon ID:</label>
                        <input type="text" class="form-control" pInputText *ngIf="badgeHolder" name="beaconId"
                            [(ngModel)]="badgeHolder.beaconId" maxlength="50" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <label class="control-label">Other Information:</label>
                        <input type="text" class="form-control" pInputText *ngIf="badgeHolder" name="otherInfo"
                            [(ngModel)]="badgeHolder.otherInfo" maxlength="50" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <label class="control-label">Notes:</label>
                        <textarea class="form-control" pInputTextarea *ngIf="badgeHolder" name="notes"
                            [(ngModel)]="badgeHolder.notes" maxlength="500"></textarea>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="row">
                    <div class="col-lg-6">
                        <label class="control-label">First Name:</label>
                        <input *ngIf="badgeHolder" class="form-control" type="text" pInputText name="firstName"
                            id="firstName" [(ngModel)]="badgeHolder.firstName" maxlength="50" required />
                    </div>
                    <div class="col-lg-6">
                        <label class="control-label">Last Name:</label>
                        <input *ngIf="badgeHolder" class="form-control" type="text" pInputText name="lastName"
                            [(ngModel)]="badgeHolder.lastName" maxlength="50" required />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <label class="control-label">Company:</label><br />
                        <p-dropdown [options]="companies" placeholder="Select a Company" [filter]="true"
                            [(ngModel)]="badgeHolder.companyId" required name="companyId"
                            (onChange)="loadContracts(true)"></p-dropdown>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <label class="control-label">Contract:</label><br />
                        <p-dropdown [options]="contracts" placeholder="Select a Contract" [filter]="true"
                            [(ngModel)]="badgeHolder.contractId" required name="contractDropdown"
                            (onChange)="loadLaborClasses(true)"></p-dropdown>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <label class="control-label">Labor Class:</label><br />
                        <p-dropdown [options]="laborClasses" placeholder="Select a Labor Class" [filter]="true"
                            [(ngModel)]="badgeHolder.laborClassId" required name="laborClassDropdown"></p-dropdown>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <p-checkbox name="hasOffset" binary="true" label="Offset" [(ngModel)]="badgeHolder.hasOffset">
                        </p-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div *ngIf="user.faceCode !== '' || user.faceCode !== null">
        <h3>Possible Matches</h3>
        <div class="row">
            <div class="col-lg-3" *ngFor="let photo of photoMatch">
                <div>
                    <img id="profile-{{photo.matchBadgeHolderId}}" alt="" width="150" (click)="showCompare(photo.matchBadgeHolderId, photo.matchPercent)" />
                    <br />
                    <span>
                        {{photo.matchFirstName}} {{photo.matchLastName}}<br />
                        <a target="_blank" href="/badgeholders/detail/{{photo.matchBadgeHolderId}}">{{photo.matchBadgeNum}}</a>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>

<p-toast position="top-right"></p-toast>

<p-dialog header="Upload Profile" [(visible)]="displayUploadDialog" modal="modal"
    [style]="{ width: '500px', height: '360px', 'min-width': '200px' }" [minY]="70">
    <p-fileUpload name="files" url="{{uploadImageUrl}}" (onUpload)="onUpload($event)" accept="image/*"
        maxFileSize="5000000" showCancelButton="false">
        <ng-template pTemplate="content">
            <ul *ngIf="uploadedFiles.length">
                <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
            </ul>
            <span>Drag and Drop a Photo into this area or click the "Choose" button to select a file.</span>
        </ng-template>
    </p-fileUpload>
    <p-footer>
        <button type="button" pButton icon="pi pi-times" (click)="closeUploadDialog()" label="Close"></button>
    </p-footer>
</p-dialog>

<p-dialog header="Select Badge to Print" [(visible)]="showPrintBadgeDialog" modal="modal"
    [style]="{ width: '1000px', height: '800px', 'min-width': '500px' }" [minY]="70">
    <div class="container">
       <div class="row">    
            <div class="col-md-4" *ngFor="let badge of templateList">
                <button pButton (click)="showBadgeByTemplateId(badge.id)" [disabled]="showPrintBadge || isReadOnly" icon="pi pi-print"
                                label="Print Badge"></button>
                <div class="card" style="width: 18rem;padding: 30px">
                    <div class="card-body">
                        <div [innerHTML]="badge.html"></div>
                    </div>
                </div>
            </div>    
        </div>
    </div>

    <p-footer>
        <button type="button" pButton icon="pi pi-times" (click)="closePrintBadgeDialog()" label="Close"></button>
    </p-footer>
</p-dialog>

<p-dialog header="Take Photo" [(visible)]="displayTakePhotoDialog" modal="modal">
    <webcam *ngIf="showWebCam" [height]="500" [width]="500" [trigger]="photoObservable"
        (imageCapture)="onPictureTaken($event);"></webcam>
    <!--
        <webcam [height]="500" [width]="500" [trigger]="triggerObservable" (imageCapture)="handleImage($event)" *ngIf="showWebCam"></webcam>
    -->
    <br />
    <button type="button" pButton (click)="takePhoto()">Take A Photo</button>
    <p-footer>
        <button type="button" pButton icon="pi pi-times" (click)="closeTakePhotoDialog()" label="Close"></button>
    </p-footer>
</p-dialog>

<p-dialog
    header="Worker Compare"
    [(visible)]="showDialogCompare" modal="modal"
    [style]="{ width: '800px', height: '600px', 'min-width': '800px' }" [minY]="600">
    <div class="row">
        <div class="col-lg-6">
            <strong>Profile: {{this.badgeHolder.firstName}} {{this.badgeHolder.lastName}}</strong><br />
            {{this.badgeHolder.badgeNum}}<br />
            <img style="width: 350px;padding: 10px;" id="profilePhoto" />
            <br />
        </div>
        <div class="col-lg-6" *ngIf="this.compareBadgeHolder">
            <strong>Compare: {{this.compareBadgeHolder.firstName}} {{this.compareBadgeHolder.lastName}}</strong><br />
            {{this.compareBadgeHolder.badgeNum}}<br />
            <img style="width: 350px;padding: 10px;" id="compare-{{compareBadgeHolder.id}}" />
        </div>
    </div>
    <p-footer>
        <button type="button" (click)="closeCompareDialog()" pButton icon="pi pi-times" label="Close"></button>
    </p-footer>
</p-dialog>
