import { Component, Input, OnInit, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

import { DataService } from '../../services/data.service';
import { LoggingService } from '../../services/logging.service';
import { DateService } from '../../services/date.service';
import { Message } from 'primeng/api';
import { BreadCrumbService } from '../../services/breadcrumb.service';
import { ConfirmationService } from 'primeng/api';

@Component({
    selector: 'app-inspection-profile',
    templateUrl: './inspection.profile.component.html',
    providers: [ ConfirmationService ],
    encapsulation: ViewEncapsulation.None
})
export class InspectionProfileComponent implements OnInit, OnChanges {
@Input() data: any;

message: Message[] = [];
companies: any[];
inspection: any;
inspections: any[];
inspector: any = {};
category = "inspection";
showInspectionDialog: boolean;
duration: any[];
durationValue: string = "";
inspectors: any[];
selectedCompany: any;
tradeClass: any;
isNew: boolean;
scheduledDate: Date;
actualDate: Date;
passFail = 'true';
showTabFlag: boolean;
showUploadDialog: boolean;
showInspectorDialog: boolean;
refresh = new Date();

    constructor(
        private breadcrumbService: BreadCrumbService,
        private confirmService: ConfirmationService,
        private dataService: DataService,
        private loggingService: LoggingService,
        private dateService: DateService,
        private router: Router) {}

    ngOnInit(): void {
        this.inspection = this.data;
        if (this.data.id === undefined) {
            this.isNew = true;
            this.inspection.scheduledDate = this.dateService.convertDateUniversalToSiteTimeDate(new Date());
        } else {
            this.dataService.getById('inspections', this.data.id)
                .subscribe((dataInfo) => {
                    this.inspection = dataInfo;
                    this.durationValue = this.inspection.duration.toString();
                    this.scheduledDate = this.dateService.convertDateToLocalTimeDate(new Date(this.inspection.scheduledDate));
//                    this.actualDate = this.dateService.convertDateUniversalToSiteTimeDate(this.inspection.actualDate);
                    if (this.inspection.passFail === true) {
                        this.passFail = 'true';
                    }
                });
        }

        this.loadDuration();
        this.loadCompanies();
//        this.loadInspections();
        this.loadInspectors();
    }

    ngOnChanges(simpleChanges: SimpleChanges): void {
//        if (this.inspection) {
//            this.breadcrumbService.setItemsByCategoryAndName('Controls', 'Inspections', '/inspections/search', this.data.name, this.router.url);
//        }
    }

    save() {
        if (this.scheduledDate) {
            this.inspection.scheduledDate = this.dateService.convertDateLocalToSiteTimeDate(new Date(this.scheduledDate));
        }

//        if (this.inspection.actualDate) {
//            this.inspection.actualDate = this.dateService.convertDateLocalToSiteTimeDate(new Date(this.inspection.actualDate));
//        }

        if (this.durationValue != "") {
            this.inspection.duration = parseInt(this.durationValue);
        } else {
            this.inspection.duration = 0;
        }

        if (this.passFail === 'true') {
            this.inspection.passFail = true;
        } else {
            this.inspection.passFail = false;
        }

        this.dataService.save('inspections', this.inspection)
            .subscribe((data) => {
                this.loggingService.savedMessage();
            });
    }

    cancel() {
        const path = '/inspectors/search/';
        this.router.navigate([path]);
    }

    saveInspection(): void {
        this.inspection.inspectorId = this.inspection.id;
        this.dataService.save('inspections', this.inspection)
        .subscribe((data) => {
            this.loggingService.savedMessage();
            this.loadInspections();
        });
    }

    loadCompanies(): void {
        this.dataService.getAll('companies')
        .subscribe((companies) => {
            this.companies = companies;   
            if (this.selectedCompany !== undefined) {
                this.loadTradeClasses();
            }     
        });
    }

    loadTradeClasses(): void {
        this.setSelectedCompany(this.inspection.companyId);
        this.dataService.getById('laborclass/tradecode/detail', this.selectedCompany.tradeClass)
            .subscribe((data) => {
                this.tradeClass = data;
            });
    }

    setSelectedCompany(companyId: string):void {
        if (this.companies !== undefined) {
            this.companies.forEach(company => {
                if (company.id === companyId) {
                    this.selectedCompany = company;
                }
            });
        }
    }

    loadInspectors(): void {
        this.dataService.getById('lookups', 'inspectors')
            .subscribe((data) => {
                this.inspectors = data;
            });
    }

    loadInspections(): void {
        this.dataService.getById('inspections', this.inspection.id)
        .subscribe((inspections) => {
            this.inspections = inspections;
        });
    }

    loadDuration(): void {
        this.dataService.getById('lookups', 'duration')
            .subscribe((data) => {
                this.duration = data;
            });
    }

    showDialogChangedHandler(showDialog: boolean): void {
        this.refresh = new Date();
    }

    dialogInspectorOpenClose(flag: boolean) {
        this.showInspectorDialog = flag;
        this.loadInspectors();
    }

    openUploadDialog(): void {
        this.showUploadDialog = true;
    }

    closeUploadDialog(): void {
        this.showUploadDialog = false;
    }
}
