import { Component, Input, OnInit, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

import { DataService } from '../../services/data.service';
import { LoggingService } from '../../services/logging.service';
import { DateService } from '../../services/date.service';
import { Message } from 'primeng/api';
import { BreadCrumbService } from '../../services/breadcrumb.service';

@Component({
    selector: 'app-visitor-profile',
    templateUrl: './visitor.profile.component.html',
    encapsulation: ViewEncapsulation.None
})
export class VisitorProfileComponent implements OnInit, OnChanges {
@Input() visitor: any;

message: Message[] = [];
companies: any[];

    constructor(
        private breadcrumbService: BreadCrumbService,
        private dataService: DataService,
        private loggingService: LoggingService,
        private dateService: DateService,
        private router: Router) {}

    ngOnInit(): void {
        if (this.visitor.startDate !== undefined) {
            this.visitor.startDate = this.dateService.convertDateUniversalToSiteTimeDate(new Date(this.visitor.startDate));
        } else {
            this.visitor.startDate = new Date();
        }
        if (this.visitor.terminationDate !== undefined) {
            this.visitor.terminationDate = this.dateService.convertDateUniversalToSiteTimeDate(new Date(this.visitor.terminationDate));
        } else {
            const today = new Date();
            const tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 1);
            this.visitor.terminationDate = tomorrow;
        }

        this.loadCompanies();
    }

    ngOnChanges(simpleChanges: SimpleChanges): void {
        this.breadcrumbService.setItemsByCategoryAndName(
                                'Controls',
                                'Visitors',
                                '/visitors/search',
                                this.visitor.name,
                                this.router.url);
    }
 
    save() {
        this.visitor.badgeHolderType = 'V';
        if (this.visitor.startDate) {
            this.visitor.startDate = this.dateService.convertDateLocalToSiteTimeDate(new Date(this.visitor.startDate));
        }
        if (this.visitor.terminationDate) {
            this.visitor.terminationDate = this.dateService.convertDateLocalToSiteTimeDate(new Date(this.visitor.terminationDate));
        }
        this.dataService.save('badgeholders', this.visitor)
            .subscribe((data) => {
                this.loggingService.savedMessage();
            });
    }

    cancel() {
        const path = '/visitors/search/';
        this.router.navigate([path]);
    }

    loadCompanies(): void {
        this.dataService.getAll('companies')
        .subscribe((companies) => {
            this.companies = companies;
        });
    }
}
