    <div class="container" [style.width]="gridOptions.scrollWidth">
    <div class="row st-table">
        <p-table #dt [columns]="gridOptions.fields" class="st-table" [value]="gridData" [responsive]="gridOptions.responsive" [paginator]="gridOptions.paginator" scrollable="{{gridOptions.scrollable}}" scrollHeight="{{gridOptions.scrollHeight}}" [rows]="gridOptions.rows" [exportFilename]="gridOptions.exportFilename" (onFilter)="onFilter($event)">

            <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                    <col *ngIf="gridOptions.showEdit" style="min-width: 53px; max-width: 53px;">
                    <col *ngIf="gridOptions.showDelete" style="min-width: 53px; max-width: 53px;">
                    <col *ngFor="let col of columns" [ngStyle]="{ 'min-width': col.width, 'max-width': col.width }">
                </colgroup>
            </ng-template>
            <ng-template pTemplate="caption">
                <div style="text-align: left">
                    <i *ngIf="gridOptions.globalFilter" class="pi pi-search" style="margin:4px 4px 0 0"></i>
                    <input *ngIf="gridOptions.globalFilter" type="text" pInputText size="25" placeholder="" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                    <span *ngIf="dt && gridData">{{dt.totalRecords}} - {{gridData.length}} Records</span>
                    <span class="float-right">
                        <button *ngIf="gridOptions.showAdd" pButton icon="pi pi-plus" title="Add" (click)="addNew()" class="indigo-btn" style="float: right;"></button>
                        <button *ngIf="gridOptions.showExport" type="button" pButton title="Export" icon="pi pi-cloud-download" class="indigo-btn" (click)="exportToCSV()" style="float:right"></button>
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngIf="gridOptions.showCheckbox"><p-tableHeaderCheckbox></p-tableHeaderCheckbox></th>
                    <th *ngIf="gridOptions.showEdit" style="min-width: 53px; max-width: 53px; text-align: center; padding: 0;">Edit</th>
                    <th *ngIf="gridOptions.showDelete" style="min-width: 53px; max-width: 53px; text-align: center; padding: 0;">Delete</th>
                    <th *ngFor="let col of columns" class="p-table-column-{{col.field}}" [ngStyle]="{ 'min-width': col.width, 'max-width': col.width }" [pSortableColumn]="(!gridOptions.paginator && col.sortable) ? col.field : null">
                        {{col.header}}
                        <p-sortIcon *ngIf="!gridOptions.paginator && col.sortable" [field]="col.field"></p-sortIcon>
                    </th>
                </tr>
                <tr>
                    <th *ngIf="gridOptions.showEdit"style="min-width: 53px; max-width: 53px; text-align: center; padding: 0;">&nbsp;</th>
                    <th *ngIf="gridOptions.showDelete" style="min-width: 53px; max-width: 53px; text-align: center; padding: 0;">&nbsp;</th>
                    <th *ngIf="gridOptions.showCheckbox"></th>
                    <th *ngFor="let col of columns" class="p-table-column-{{col.field}}" [ngStyle]="{ 'min-width': col.width, 'max-width': col.width }" [ngSwitch]="col.field">
                        <input *ngIf="col.filter" pInputText type="text" (input)="dt.filter($event.target.value, col.field, 'contains')">
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr [pSelectableRow]="rowData" [ngClass]="{'undeletable': rowData['deleted']}">
                    <td *ngIf="gridOptions.showCheckbox"><p-tableCheckbox [value]="rowData"></p-tableCheckbox></td>
                    <td *ngIf="gridOptions.showEdit" style="min-width: 53px; max-width: 53px; text-align: center; padding: 0;"><button type="button" pButton (click)="edit(rowData)" icon="pi pi-pencil" style="width: 35px; height: 35px;"></button></td>
                    <td *ngIf="gridOptions.showDelete" style="min-width: 53px; max-width: 53px; text-align: center; padding: 0;">
                        <button type="button" pButton (click)="delete(rowData)" icon="pi pi-trash" style="width: 35px; height: 35px;" *ngIf="!rowData['deleted']"></button>
                        <button type="button" pButton (click)="undelete(rowData)" icon="pi pi-undo" style="width: 35px; height: 35px;" *ngIf="rowData['deleted']"></button>
                    </td>
                    <td *ngFor="let col of columns" class="p-table-column-{{col.field}}" [ngStyle]="{ 'min-width': col.width, 'max-width': col.width }">
                        <span class="ui-column-title">{{col.header}}</span>
                        <span *ngIf="col.type==='text'">{{rowData[col.field]}}</span>
                        <span *ngIf="col.type==='number'">{{rowData[col.field]}}</span>
                        <span *ngIf="col.type==='yesno' && (rowData[col.field]===0 || rowData[col.field]===false)">No</span>
                        <span *ngIf="col.type==='yesno' && (rowData[col.field]===1 || rowData[col.field]===true)">Yes</span>
                        <span *ngIf="col.type==='datetime'">{{rowData[col.field] | date:'MM/dd/yyyy HH:mm a'}}</span>
                        <span *ngIf="col.type==='datetimeutc'">{{this.dateService.convertDateUniversalToSiteTimeDate(rowData[col.field]) | date:'MM/dd/yyyy HH:mm a'}}</span>
                        <span *ngIf="col.type==='date'">{{this.dateService.convertDateUniversalToSiteTimeDate(rowData[col.field]) | date:'shortDate'}}</span>
                        <span *ngIf="col.type==='time'">{{this.dateService.convertDateUniversalToSiteTimeDate(rowData[col.field]) | date:'shortTime'}}</span>
                        <span *ngIf="col.type==='money'">{{rowData[col.field] | currency:'USD':true:'1.2-2'}}</span>
                        <span *ngIf="col.type==='image'">
                            <img style="width: 50px" id="profile-{{rowData['id']}}" />
                        </span>
                        <span *ngIf="col.type==='idlink'"><a href="{{dataTable}}/{{owData['id']}}">{{rowData[col.field]}}</a></span>
                        <span *ngIf="col.type==='passfail'">
                            <span *ngIf="rowData[col.field]===0 || rowData[col.field]===false">Fail</span>
                            <span *ngIf="rowData[col.field]===1 || rowData[col.field]===true">Pass</span>
                        </span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<p-toast position="top-right"></p-toast>

<p-confirmDialog header='Delete?' icon='pi pi-trash'></p-confirmDialog>