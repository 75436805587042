// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  baseAPIUrl: '/api/',
  baseImageUrl: 'https://tcnstphotos.blob.core.windows.net/photos/',
  baseDocumentUrl: 'https://tcnstphotos.blob.core.windows.net/documents/',
  appInsights: {
    instrumentationKey: 'd3161a0c-2c0c-470c-ac15-14670bb062fe'
  }
};
