import { Component, OnInit } from '@angular/core';
import { BreadCrumbService } from '../../services/breadcrumb.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { UserService } from '../../services/user.service';
import { BrowserModule, DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LoggingService } from '../../services/logging.service';
import { Subscription } from 'rxjs';
import { NgxPowerBiService } from 'ngx-powerbi';
import * as pbi from 'powerbi-client';
import { environment } from '../../../../environments/environment';
import { DateService } from '../../services/date.service';

@Component({
  selector: 'app-powerbi-report',
  templateUrl: './powerbi-report.component.html',
  styleUrls: ['./powerbi-report.component.css']
})
export class PowerBIReportComponent implements OnInit {

  env: any = environment;

  pdfPercentage: number;

  id: string;
  sub: any;
  report: any;
  user: any;
  reportUrl: string;
  siteSubscription: Subscription;
  iframeURL: string = '';
  santizeURL: SafeResourceUrl;

  private powerBiService: NgxPowerBiService;
  private pbiContainerElement: HTMLElement;
  private embeddedReport: pbi.Report;

  filters = [];
  pageNames = [];

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private userService: UserService,
    private sanitizer: DomSanitizer,
    private breadcrumbService: BreadCrumbService,
    private loggingService: LoggingService,
    private dateService: DateService,
    private router: Router) {
    this.powerBiService = new NgxPowerBiService();
    this.siteSubscription = this.userService.getSiteEvent()
      .subscribe(siteId => {
        this.user.siteId = siteId;
        this.loadData();
      });
  }

  ngOnInit() {
    this.loggingService.logPageView('Power BI');
    // this.breadcrumbService.setItemsByCategory('Reporting', 'Dashboard Reports', this.router.url);
    this.user = this.userService.currentUser();
    //this.reportUrl = 'https://sitetraxxstreports.azurewebsites.net/Home/EmbedReport?siteid=' + this.user.siteId;
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];
      this.loadReport();
    });
  }

  ngOnDestroy() {
    this.siteSubscription.unsubscribe();
  }

  loadData() {
    this.loadReport();
  }

  loadReport(): void {
    this.filters = [];
    this.pageNames = [];
    this.dataService.getById('reports/power-bi', this.id)
      .subscribe((report) => {
        this.report = report;
        this.loggingService.logPageView('PowerBI Report - ' + this.report.name);

        this.breadcrumbService.setItemsByCategory('Reporting', this.report.name, this.router.url);

        const config = {
          type: 'report',
          id: this.report.id,
          embedUrl:
            'https://app.powerbi.com/reportEmbed?' +
            'reportId=' + this.report.id +
            '&groupId=' + this.report.parameter5,
          tokenType: pbi.models.TokenType.Embed,
          accessToken: this.report.embeddedToken,
          settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: true,
            layoutType: pbi.models.LayoutType.Custom,
            customLayout: {
              displayOption: pbi.models.DisplayOption.FitToWidth
            }
          },
          permissions: pbi.models.Permissions.Read,
          filters: [{
            $schema: "http://powerbi.com/product/schema#basic",
            filterType: pbi.models.FilterType.Basic,
            target: {
              table: "vwPBI_Site", // filter table
              column: "SiteID" // filter column
            },
            operator: "In",
            values: [this.user.siteId]   // value that you need to filter
          }],
        };
        this.pbiContainerElement = <HTMLElement>(document.getElementById('pbi-container'));
        this.pbiContainerElement.style.setProperty('height', String(Math.max((window.innerHeight - 220), 500)) + 'px');
        this.embeddedReport = <pbi.Report>this.powerBiService.embed(this.pbiContainerElement, config);

        document.getElementById('pbi-filters').style.height = String(Math.max((window.innerHeight - 270), 450)) + 'px';

        const instance = this;
        this.embeddedReport.on('rendered', function (event) {
          const isSlicer = visual => visual.type === 'slicer';
          const isPageActive = page => page.isActive;
          instance.embeddedReport.getPages().then(pages => {
            const activePage = pages.filter(isPageActive)[0];
            activePage.getVisuals().then(visuals => {
              const slicers = visuals.filter(isSlicer);
              instance.filters = [];
              slicers.forEach(async slicer => {
                if (slicer.title == "Select Site")
                  slicer.getFilters().then((states: any[]) => {
                    if (states.length === 1 && states[0].values.length === 0) {
                      states[0].values.push(instance.user.siteName);
                      slicer.setFilters(states).catch(e => console.log('setFilters', e));
                      slicer.getSlicerState().then(state => {
                        if (state.filters.length === 0) {
                          state.filters.push({
                            $schema: "http://powerbi.com/product/schema#basic",
                            filterType: pbi.models.FilterType.Basic,
                            operator: "In",
                            requireSingleSelection: false,
                            target: { table: "vwPBI_Site", column: "SiteName" },
                            values: [instance.user.siteName]
                          });
                          slicer.setSlicerState(state).catch(e => console.log('setSlicerState', e));
                        }

                        instance.pageNames = [];
                        pages.forEach(p => instance.pageNames.push(p.name));
                      }).catch(e => console.log(e));
                    }
                  }).catch(e => console.log(e));

                slicer.getSlicerState().then(slicerState => {
                  if (slicerState.filters.length > 0) {
                    if (instance.filters.filter(m => m.title === slicer.title).length === 0) {
                      const f = <any>slicerState.filters[0];
                      if (slicerState.filters[0].filterType === 0) {
                        const toDate = new Date(f.conditions[1].value);
                        toDate.setDate(toDate.getDate() - 1);
                        instance.filters.push({ title: slicer.title, values: [`${instance.dateService.formatDateSlash(new Date(f.conditions[0].value))} to ${instance.dateService.formatDateSlash(toDate)}`] })
                      }
                      else
                        instance.filters.push({ title: slicer.title, values: f.values })
                      instance.filters.sort(function (a, b) { return ((a.title > b.title) ? 1 : -1) });
                    }
                  }
                });
              });
            });
          });
        });
      });
  }
  pdf(): void {
    this.pdfPercentage = 0;

    this.embeddedReport.bookmarksManager.capture({ personalizeVisuals: false }).then(m => {
      this.dataService.postForm(`reports/power-bi-export-start/${this.id}`, { pageNames: this.pageNames, state: m.state })
        .subscribe((data) => {
          setTimeout(() => this.checkPDF(data.id), 30000);
        });
    });
  }
  private checkPDF(exportId: string) {
    this.dataService.getById(`reports/power-bi-export-check/${this.id}`, exportId)
      .subscribe((data) => {
        if (data.percent !== '-')
          this.pdfPercentage = Number(data.percent);
        if (data.status === 'Succeeded')
          this.dataService.getById(`reports/power-bi-export-download/${this.id}`, exportId, { responseType: 'arraybuffer' })
            .subscribe((data) => {
              const file = new Blob([data], { type: 'application/pdf' });
              const fileURL = window.URL.createObjectURL(file);

              const a = document.createElement('a');
              document.body.appendChild(a);
              a.href = fileURL;
              a.download = this.report.name + '.pdf';
              a.click();

              window.URL.revokeObjectURL(fileURL);
              a.remove();
              this.pdfPercentage = null;
            });
        else if (data.status === "NotStarted" || data.status === "Running")
          setTimeout(() => this.checkPDF(exportId), ((data.percent === '-' || this.pdfPercentage < 50) ? 20000 : 10000));
        else {
          alert(`PDF generation failed (${data.status})`);
          this.pdfPercentage = null;
        }
      });
  }
  public getSantizeUrl(url: string) {
    if (this.iframeURL === url) {
      return this.santizeURL;
    }
    this.iframeURL = url;
    this.santizeURL = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    return this.santizeURL;
  }
}
