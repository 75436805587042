import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { BreadCrumbService } from '../services/breadcrumb.service';
import { DateService } from '../services/date.service';
import { Subscription } from 'rxjs';
import { LoggingService } from '../services/logging.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-delay',
  templateUrl: './delay.component.html',
  styleUrls: ['./delay.component.css']
})
export class DelayComponent implements OnInit {
  pageInfo: any;
  sub: any;
  id: string;
  delay: any;
  dt: string;
  subscription: Subscription;
  isGlobalAdmin = false;

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private dateService: DateService,
    private breadcrumbService: BreadCrumbService,
    private loggingService: LoggingService,
    private router: Router,
    private userService: UserService) {
      this.isGlobalAdmin = this.userService.currentUser().isGlobalAdmin;
      this.subscription = this.dateService.getCurrentDate()
            .subscribe(date => {
              this.dt = this.dateService.formatDate(date);
            });
    }

    ngOnInit() {
      if (this.dt === undefined) {
        this.dt = this.dateService.formatDate(new Date);
      }

      this.sub = this.route.params.subscribe(params => {
        this.id = params['id'];

        if (this.id === undefined) {
          this.loadData();
        } else {
            if (this.id === 'new') {
              this.loggingService.logPageView('Delay - New');
                this.delay = {};
                this.delay.id = 'new';
                this.delay.delayStart = this.dateService.getLocalTimeDate();
            } else {
                this.loggingService.logPageView('Delay - Profile');
                this.dataService.getById('delays/detail', this.id)
                .subscribe((data) => {
                    this.delay = data;
                });
            }
        }
      });
    }

    ngOnDestroy() {
      this.subscription.unsubscribe();
    }

    loadData() {
      this.loggingService.logPageView('Delay - Grid');
      this.loadTableData();
    }

    loadTableData(): void {
      this.delay = null;
      this.dataService.getById('pages', 'delays')
          .subscribe((data) => {
          this.pageInfo = data.result;
          this.breadcrumbService.setItemsByCategory('Controls', this.pageInfo.title, this.router.url);
        });
    }

    createExcel(): void {
        this.dataService.postForm('delays/excel', {}, {responseType: 'arraybuffer'})
            .subscribe((data) => {
                const file = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                const fileURL = window.URL.createObjectURL(file);

                const a = document.createElement('a');
                document.body.appendChild(a);
                a.href = fileURL;
                a.download = 'delays.xlsx';
                a.click();

                window.URL.revokeObjectURL(fileURL);
                a.remove();
            });
    }
}
