<form #thisForm='ngForm' style="min-height: 400px;">
    <div class="container">
        <div class="row">
            <div class="col">
                <p-dropdown [options]="resourceTypes" name="resourceType" [(ngModel)]="resourceType" (onChange)="resourceTypeChanged()"></p-dropdown>
            </div>
            <div class="col text-right">
                <button pButton (click)="createPDF()" id="btnPDF" label="PDF" icon="pi pi-file-pdf"></button>
                <button pButton (click)="createExcel()" label="Excel" icon="pi pi-file-excel"></button>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <p-fullCalendar #fc [events]="events" [options]="options"></p-fullCalendar>
            </div>
        </div>
    </div>
</form>

<p-dialog header="Event" [(visible)]="showViewDialogFlag" modal="modal" [style]="{ width: '620px', height: '630px', 'min-width': '200px' }" [minY]="70">
    <div style="width: 580px; height: 390px;" *ngIf="event">
        <div class="row">
            <div class="col-2">
                Date:
            </div>
            <div class="col-10">
                <span>{{event.start | date: 'M/dd/yyyy'}}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-2">
                Time:
            </div>
            <div class="col-10">
                <span>{{event.start | date: 'h:mm a'}} - {{event.end | date: 'h:mm a'}}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-2"></div>
            <div class="col-10 line-breaks" [innerHtml]="event.description"></div>
        </div>
        <div class="row" *ngIf="event.url && event.url !== 'null'">
            <div class="col-2"></div>
            <div class="col-10">
                <a [routerLink]="event.url">OPEN</a>
            </div>
        </div>
    </div>
    <p-footer>
        <p-button (click)="showViewDialogFlag = false" label="Close"></p-button>
    </p-footer>
</p-dialog>



<p-confirmDialog header='Delete?' icon='pi pi-trash'></p-confirmDialog>
<p-dialog header="Resource" [(visible)]="showEditDialogFlag" modal="modal" [style]="{ width: '520px', height: '670px', 'min-width': '200px' }" [minY]="70">
    <div style="width: 480px; height: 530px;">
        <form #dialogForm="ngForm">
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label>Schedule Time</label><br />
                        <p-calendar required [(ngModel)]="resourceBuildingMaterialScheduled" name="scheduled" [timeOnly]="true" [stepMinute]="15" [showIcon]="true" (onSelect)="loadResourceTypes()"></p-calendar>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Duration Needed</label><br />
                        <p-dropdown [options]="duration" [filter]="true" [(ngModel)]="resourceBuildingMaterialDuration" name="duration" (onChange)="loadResourceTypes()"></p-dropdown>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label>Resource Type</label><br />
                        <p-dropdown [options]="buildingMaterialResourceTypes" [filter]="true" required [(ngModel)]="resourceBuildingMaterialResourceType" name="resourceType" (onChange)="loadResources()"></p-dropdown>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Resource</label><br />
                        <p-dropdown [options]="resources" [filter]="true" required [(ngModel)]="resourceBuildingMaterial.resourceId" name="resourceId"></p-dropdown>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label>Description</label>
                        <input pInputText name="description" [(ngModel)]="resourceBuildingMaterial.name" class="form-control" type="text" placeholder="Enter brief description" maxlength="100" required />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label>Company</label><br />
                        <p-dropdown required name="companyId" [options]="companies" [filter]="true" placeholder="Select a Company"
                                    [(ngModel)]="resourceBuildingMaterial.companyId"></p-dropdown>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label>Receiving First Name</label>
                        <input pInputText name="receivingFirstName" [(ngModel)]="resourceBuildingMaterial.receivingFirstName" required type="text" class="form-control" placeholder="Receiving First Name" maxlength="50" />
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Receiving Last Name</label>
                        <input pInputText name="receivingLastName" [(ngModel)]="resourceBuildingMaterial.receivingLastName" required type="text" class="form-control" placeholder="Receiving Last Name" maxlength="50" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label>Receiving Phone</label><br />
                        <p-inputMask mask="(999)999-9999" [(ngModel)]="resourceBuildingMaterial.receivingPhone" required name="receivingPhone" placeholder="(999)999-9999"></p-inputMask>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <div class="row">
            <div class="col-4 text-left">
                <p-button (click)="delete()" label="Delete" *ngIf="resourceBuildingMaterial.id"></p-button>
            </div>
            <div class="col-8">
                <p-button [disabled]="true" label="Save" *ngIf="!dialogForm.valid"></p-button>
                <p-button (click)="save()" label="Save" *ngIf="dialogForm.valid"></p-button>
                <p-button (click)="showEditDialogFlag = false" label="Cancel"></p-button>
            </div>
        </div>
    </p-footer>
</p-dialog>