import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BreadCrumbService } from '../../services/breadcrumb.service';
import { DataService } from '../../services/data.service';
import { LoggingService } from '../../services/logging.service';

@Component({
  selector: 'app-survey-profile',
  templateUrl: './survey.profile.component.html',
  styleUrls: ['./survey.profile.component.css']
})
export class SurveyProfileComponent implements OnInit, OnChanges {
@Input() data: any;

showDialogFlag: boolean;
showQuestions: boolean;
survey: any;

  constructor(
    private dataService: DataService,
    private loggingService: LoggingService,
    private breadcrumbService: BreadCrumbService,
    private router: Router) { }

  ngOnInit() {
    this.showQuestions = false;
    this.survey = this.data;
    if (this.survey.id == 'new') {
      this.showQuestions = false;
    } else {
      this.showQuestions = true;
    }
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    this.breadcrumbService.setItemsByCategoryAndName(
                            'Surveys',
                            'Surveys',
                            '/surveys/search',
                            '',
                            this.router.url);
    }

  save() {
    this.dataService.save('surveys', this.survey)
        .subscribe((data) => {
            this.loggingService.savedMessage();
            this.showQuestions = true;
        });
  }

  cancel() {
      this.router.navigate(['/surveys/search/']);
  }

}
