import { Injectable } from '@angular/core';
// import { RequestOptions, Headers } from '@angular/common/http';
import { Subject ,  Observable } from 'rxjs';

@Injectable()
export class UserService {

    private subject = new Subject<any>();

    constructor() { }

    getSiteEvent(): Observable<any> {
        return this.subject.asObservable();
    }

    setSite(siteId: string) {
        this.subject.next(siteId);
    }

    currentUser() {
        const text = localStorage.getItem('currentUser');
        if (text === null || typeof text === 'undefined' || text === 'undefined') {
            return { id: '', siteId: '', token: '' };
        } else {
            return JSON.parse(text);
        }
    }

    public hasFeature(name: string): boolean {
        var features = JSON.parse(localStorage.getItem('features'));
        var match = false;
        
        features.forEach(feature => {
            if (feature.name.toLocaleUpperCase() === name.toLocaleUpperCase()) {
                match = true;
            }
        });

        return match;
    }

    public removeColumnFeatures(featureName: string, fieldName: string, columns: any[]): any[] {
        if (!this.hasFeature(featureName)) {
            var index = 0;
            var indexFound = -1;
            columns.forEach(col => {
                if (col.field === fieldName) {
                    indexFound = index;
                    console.log(indexFound);
                }
                index++;
            });

            if (indexFound > -1) {
                columns.splice(indexFound, 1);
            }
        }

        return columns;
    }


    // jwt() {
    //     const currentUser = this.currentUser();
    //     if (currentUser && currentUser.token) {
    //         const headers = new Headers({ 'Authorization': 'Bearer ' + currentUser.token });
    //         return new RequestOptions({ headers: headers });
    //     }
    // }
}
