<div class="float-left">
    <h3>Badge Holders TSMC</h3>
</div>
<div *ngIf="pageInfo">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="float-right" *ngIf="isGlobalAdmin">
                    <button pButton (click)="createExcel()" label="Excel" icon="pi pi-file-excel"
                        class="indigo-btn"></button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <st-table pageId="{{pageInfo.id}}" dataTable="{{pageInfo.dataName}}"></st-table>
            </div>
        </div>
    </div>
</div>

<div *ngIf="badgeHolder">
    <app-badgeholder-profile [badgeHolder]="badgeHolder"></app-badgeholder-profile>
</div>