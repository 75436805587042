<div *ngIf="delays">
    <div class="row">
        <div class="col-lg-12">
            <span class="grid-header">{{delays.length}} Delay</span>
            <span class="float-right">
                <button type="button" pButton title="Export" icon="pi pi-cloud-download" class="indigo-btn" (click)="exportToCSV()"></button>
                <button pButton icon="pi pi-plus" (click)="add()" title="Add" class="indigo-btn"></button>
            </span>
        </div>
    </div>
    <div class="row">
        <table class="table table-striped">
            <thead>
                <tr class="sitetraxx">
                    <th>Edit</th>
                    <th>Subject</th>
                    <th>Location</th>
                    <th>Type</th>
                    <th>Details</th>
                </tr>
            </thead>
            <tr *ngFor="let delay of delays">
                <td style="width: 5%"><button type="button" pButton (click)="edit(delay)" icon="pi pi-pencil"></button></td>
                <td>{{delay.subject}}</td>
                <td>{{delay.location}}</td>
                <td>{{delay.delayType}}</td>
                <td>{{delay.description}}</td>
            </tr>
        </table>
    </div>
</div>