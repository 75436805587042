import { Component, Input, OnInit, ViewEncapsulation, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LoggingService } from '../../services/logging.service';
import { DataService } from '../../services/data.service';
import { Message } from 'primeng/api';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-company-headcount',
    templateUrl: './company.headcount.component.html',
    encapsulation: ViewEncapsulation.None
})
export class CompanyHeadcountComponent implements OnInit, OnChanges {
@Input() dateFilter: string;

    companies: any[];
    onsite: any[];
    headCountTotal: number;
    showDialog: boolean;
    currentCompany: any;
    siteSubscription: Subscription;

    constructor(private dataService: DataService, 
        private loggingService: LoggingService,
        private userService: UserService) { 
        this.siteSubscription = this.userService.getSiteEvent()
            .subscribe(siteId => {
                this.loadCompanyHeadCount();
            });
    }

    ngOnInit(): void {
        this.showDialog = false;
        this.currentCompany = {};
    }

    ngOnDestroy() {
        this.siteSubscription.unsubscribe();
    }

    ngOnChanges(): void {
        this.loadCompanyHeadCount();
    }

    loadCompanyHeadCount() {
        this.headCountTotal = 0;
        this.dataService.getById('companies/headcount', this.dateFilter)
            .subscribe((data) => {
                this.companies = data;
                this.totalHeadCountSummary();
            });
    }

    totalHeadCountSummary() {
        this.companies.forEach(company => {
            this.headCountTotal += company.counter;
        });
    }

    loadOnsite(companyId: string) {
        this.dataService.getById('companies/' + companyId + '/onsite', this.dateFilter)
            .subscribe((data) => {
                this.onsite = data;
            });
    }

    onsiteDetail(_company: any): void {
        this.loadOnsite(_company.companyId);
        this.showDialog = true;
    }

    close(): void {
        this.showDialog = false;
    }


  private exportToCSV(): void {
      let csv = 'Name,Headcount';
      csv = csv + '\n';


      for (const row of this.companies) {
          let csvRow = row.companyName;
          csvRow += ',';
          csvRow += row.counter;

          csv += csvRow + '\n';
      }

      const blob = new Blob(['\uFEFF', csv], {type: 'text/csv'});
      const link = document.createElement('a');
      link.setAttribute('href', window.URL.createObjectURL(blob));
      link.setAttribute('download', 'ActivityLogCompanies.csv');
      document.body.appendChild(link); // Required for FF
      link.click();
  }
}
