import { MessageService } from 'primeng/api';
import { LoggingService } from './../components/services/logging.service';
import { Component, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../components/services/auth.service';
import { ProgressService } from '../components/services/progress.service';

@Component({
    selector: 'app-secure-layout',
    templateUrl: './secure-layout.component.html',
    styleUrls: ['./secure-layout.component.css']
})
export class SecureLayoutComponent {
    menuClick: boolean;
    menuButtonClick: boolean;
    topbarMenuButtonClick: boolean;
    topbarMenuClick: boolean;
    topbarMenuActive: boolean;
    activeTopbarItem: Element;
    layoutStatic: boolean;
    sidebarActive: boolean;
    mobileMenuActive: boolean;
    darkMenu: boolean;
    showSpinner = false;

    constructor(public renderer: Renderer2, private authService: AuthService, private router: Router, private progressService: ProgressService,
        private messageService: MessageService, private loggingService: LoggingService) {
        this.layoutStatic = (localStorage.getItem('layoutStatic') === 'true');

        this.loggingService.notify.subscribe(m => {
            this.messageService.clear();
            this.messageService.add(m);
        });

        const vm = this;
        let showSpinnerTimer;
        this.progressService.show.subscribe(display => {
            clearTimeout(showSpinnerTimer);
            if (display)
                showSpinnerTimer = setTimeout(() => vm.showSpinner = true, 500);
            else
                setTimeout(() => vm.showSpinner = false, 0);
        });
    }

    onWrapperClick() {
        if (!this.menuClick && !this.menuButtonClick) {
            this.mobileMenuActive = false;
        }

        if (!this.topbarMenuClick && !this.topbarMenuButtonClick) {
            this.topbarMenuActive = false;
            // this.activeTopbarItem = null;
        }

        this.menuClick = false;
        this.menuButtonClick = false;
        this.topbarMenuClick = false;
        this.topbarMenuButtonClick = false;
    }

    onMenuButtonClick(event: Event) {
        this.menuButtonClick = true;

        // if (this.isMobile()) {
        this.mobileMenuActive = !this.mobileMenuActive;
        // }

        event.preventDefault();
    }

    onTopbarMobileMenuButtonClick(event: Event) {
        this.topbarMenuButtonClick = true;
        this.topbarMenuActive = !this.topbarMenuActive;
        event.preventDefault();
    }

    onTopbarRootItemClick(event: Event, item: Element) {
        if (this.activeTopbarItem === item) {
            // this.activeTopbarItem = null;
        } else {
            this.activeTopbarItem = item;
        }

        event.preventDefault();
    }

    onTopbarMenuClick(event: Event) {
        this.topbarMenuClick = true;
    }

    onSidebarClick(event: Event) {
        this.menuClick = true;
    }

    onToggleMenuClick(event: Event) {
        this.layoutStatic = !this.layoutStatic;
        localStorage.setItem('layoutStatic', String(this.layoutStatic));
        event.preventDefault();
    }

    isMobile() {
        return window.innerWidth < 640;
    }

    logout() {
        // this.authService.logout();
        this.router.navigate(['/login']);
    }
}
