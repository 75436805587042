<div id="printArea">
    <div class="row">
        <div id="pbi-container"></div>
        <div class="col">
            <div class="row">
                <!--
                <div class="col text-right" *ngIf="pdfPercentage == null">
                   <button pButton (click)="pdf()" icon="pi pi-file-pdf" label="PDF" [disabled]="pageNames.length === 0"></button>
                </div>
                -->
                <div class="col text-center grid-header" *ngIf="pdfPercentage != null">
                    Generating PDF<br />
                    {{pdfPercentage}}% Complete<br />
                    Please wait...
                </div>
            </div>
            <div id="pbi-filters">
                <div class="row" *ngFor="let f of filters">
                    <div class="col text-center">
                        {{f.title}}
                        <div class="text-left">
                            <ng-container *ngFor="let v of f.values">
                                {{v}}<br />
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>