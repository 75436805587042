import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { BreadCrumbService } from '../services/breadcrumb.service';
import { DataService } from '../services/data.service';
import { ActivatedRoute } from '@angular/router';
import { LoggingService } from '../services/logging.service';
import { Subscription } from 'rxjs';
import { UserService } from '../services/user.service';

@Component({
    selector: 'app-concrete-list',
    templateUrl: './concrete.component.html'
})
export class ConcreteComponent implements OnInit {
    pageInfo: any;
    path: string;
    sub: any;
    id: string;
    concrete: any;
    category = 'concrete';

    constructor(
        private route: ActivatedRoute,
        private dataService: DataService,
        private breadcrumbService: BreadCrumbService,
        private loggingService: LoggingService,
        private userService: UserService,
        private router: Router) { }

    ngOnInit(): void {

        this.sub = this.route.params.subscribe(params => {
            this.id = params['id'];

            if (this.id === undefined) {
                this.loadData();
            } else {
                if (this.id === 'new') {
                    this.loggingService.logPageView('Concrete - New');
                    this.concrete = {};
                } else {
                    this.loggingService.logPageView('Concrete - New');
                    this.dataService.getById('concrete', this.id)
                    .subscribe((data) => {
                        this.concrete = data;
                    });
                }
            }
         });
    }

    loadData() {
        this.loggingService.logPageView('Concrete - Grid');
        this.concrete = null;
        this.dataService.getById('pages', 'concrete')
        .subscribe((data) => {
            this.pageInfo = data.result;
            this.breadcrumbService.setItemsByCategory('Controls', this.pageInfo.title, this.router.url);
        });
    }
}
