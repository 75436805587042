import { Component, OnInit, Input, OnChanges } from '@angular/core';

import { LoggingService } from '../../services/logging.service';
import { DataService } from '../../services/data.service';

@Component({
    selector: 'app-buildingmaterial-count',
    templateUrl: './buildingmaterial.count.component.html'
})
export class BuildingMaterialCountComponent implements OnInit, OnChanges {
    @Input() dateFilter: string;

    buildingMaterialCount: number;

    constructor(
        private dataService: DataService,
        private loggingService: LoggingService) {}

    ngOnInit(): void {

    }

    ngOnChanges(): void {
        this.loadCount();
    }

    loadCount(): void {
        this.dataService.getById('buildingmaterials/count', this.dateFilter)
            .subscribe((data) => {
                this.buildingMaterialCount = data;
            });
    }
}
