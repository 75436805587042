import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { environment } from '../../../environments/environment';
import { DataService } from '../services/data.service';
import { DateService } from '../services/date.service';

@Component({
    selector: 'app-injury-sms',
    templateUrl: './injury-sms.component.html',
    providers: [ ConfirmationService ],
    encapsulation: ViewEncapsulation.None
})
export class InjurySMSComponent implements OnInit {

    env: any = environment;

    sites: any[];

    siteId: string;

    data: any[];

    showEdit = false;
    editItem = { level: null, objectId: null, name: null, email: null, phone: null };

    constructor(private confirmationService: ConfirmationService, private messageService: MessageService, private dataService: DataService, private dateService: DateService) {}

    ngOnInit() {
        this.dataService.getById('lookups', 'site')
            .subscribe((lookup) => {
                this.sites = lookup;
                this.sites.unshift({});
        });
    }
    loadData(): void {
        if (this.siteId)
            this.dataService.getById('SMS', `notify-users/${this.siteId}`)
                .subscribe((response) => {
                    this.data = response;
            });
    }

    add(): void {
        this.editItem = { level: 'SITE', objectId: this.siteId, name: null, email: null, phone: null };
        this.showEdit = true;
    }
    edit(item): void {
        this.editItem = item;
        this.showEdit = true;
    }
    save(): void {
        this.dataService.save('SMS/notify-user', this.editItem)
            .subscribe((data) => {
                this.loadData();
                this.showEdit = false;
                this.messageService.add({severity: 'success', summary: 'Saved', detail: 'Record saved'});
            });
    }
    delete(item): void {
        this.confirmationService.confirm({
            message: 'Do you want to delete ' + item.name + '?',
            accept: () => {
                this.dataService.deleteById('SMS/notify-user', item.id)
                    .subscribe((data) => {
                    this.loadData();
                    this.showEdit = false;
                    this.messageService.add({severity: 'success', summary: 'Confirmed', detail: 'Record deleted'});
                });
            },
            reject: () => {
                this.messageService.add({severity: 'info', summary: 'Cancelled', detail: 'You have cancelled the request'});
            }
        });
    }
}
