import { Component, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { Router } from '@angular/router';

import { SecureLayoutComponent } from '../../layouts/secure-layout.component';
import { Subscription ,  Subject } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { DateService } from '../services/date.service';
import { UserService } from '../services/user.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.css']
})
export class TopBarComponent implements OnInit, OnDestroy {
    subscription: Subscription;
    user: any;
    currentUserId: string;
    siteLogo: string;
    users: any[];
    selectedDate: Date;
    private subject = new Subject<any>();

    constructor(
        public app: SecureLayoutComponent,
        private router: Router,
        private authService: AuthService,
        private dateService: DateService,
        private userService: UserService
    ) {
        this.subscription = this.authService.getUser()
            .subscribe(user => {
                this.user = user;
            });
    }

    ngOnInit() {
        this.user = JSON.parse(localStorage.getItem('currentUser'));
        this.currentUserId = JSON.parse(localStorage.getItem('currentUserId'));
        this.users = this.user.users;
        this.setUser(this.currentUserId);
    }

    ngOnDestroy() {
//        this.subscription.unsubscribe();
    }

    onChange(newUser) {
        this.setUser(newUser);
        this.userService.setSite(this.user.siteId);
        this.currentUserId = this.user.id;
        localStorage.setItem('currentUserId', JSON.stringify(this.user.id));
//        this.user.username = newUser.username;
//        this.user.companyName = newUser.companyName;
    }

    setUser(userId:string) {
        this.user.users.forEach(element => {
            if (element.id === userId) {
                this.user.name = element.name;
                this.user.id = element.id;
                this.user.companyName = element.companyName;
                this.user.companyId = element.companyId;
                this.user.siteName = element.siteName;
                this.user.siteId = element.siteId;
                this.user.faceCode = element.faceCode;
                this.siteLogo = `https://tcnstphotos.blob.core.windows.net/site/site_${this.user.siteId}/sitelogo.png`;
                localStorage.setItem('currentUser', JSON.stringify(this.user));
                this.subject.next(this.user);
            }
        });
    }

    changeTheme(theme: string) {
        const themeLink: HTMLLinkElement = <HTMLLinkElement> document.getElementById('theme-css');
        themeLink.href = 'assets/theme/theme-' + theme + '.css';

        const layoutLink: HTMLLinkElement = <HTMLLinkElement> document.getElementById('layout-css');
        layoutLink.href = 'assets/layout/css/layout-' + theme + '.css';
    }
}
