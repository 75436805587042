import { Component, OnInit, DoCheck } from '@angular/core';
import { BreadCrumbService } from '../services/breadcrumb.service';
import { DataService } from '../services/data.service';
import { ActivatedRoute } from '@angular/router';
import { LoggingService } from '../services/logging.service';
import { UserService } from '../services/user.service';
import { DateService } from '../services/date.service';

@Component({
    selector: 'app-crew',
    templateUrl: './crew.component.html'
})
export class CrewComponent implements OnInit, DoCheck {
    pageInfo: any;
    path: string;
    sub: any;
    data: any[];
    id: string;
    title = 'Crews';
    name = '';
    startDateStr: string;
    startDate: Date;

    projects: any[];

    dayOfTheWeek: number;
    subtractDays: number;
    adjustedDate: Date;
    site: any;
    firstDay: Date;
    lastDay: Date;

    cols = [
        { field: 'crew', header: 'Crew' },
        { field: 'foreman', header: 'Foreman' },
        { field: 'phaseCode', header: 'Phase Code' },
        { field: 'totalHours', header: 'Total Hours' }
    ];

    constructor(
        private route: ActivatedRoute,
        private dataService: DataService,
        private breadcrumbService: BreadCrumbService,
        private userService: UserService,
        private dateService: DateService,
        private loggingService: LoggingService) { }

    ngOnInit(): void {
        this.startDate = new Date();
        this.dayOfTheWeek = new Date(this.startDate).getDay();
        this.subtractDays = this.dayOfTheWeek - 1;

        this.firstDay = new Date(this.startDate);
        this.firstDay.setDate(this.startDate.getDate() - this.subtractDays);
        this.firstDay.setHours(6, 0, 0);

        this.lastDay = this.addDays(6, this.firstDay);
        
        this.startDateStr = this.dateService.formatDate(new Date(this.startDate));
        this.loadProjects();
        this.loadData();
    }

    loadProjects(): void {
        this.dataService.getAll('projects')
          .subscribe((projects) => {
            this.projects = projects;
            this.projects.forEach(m => {
              m.value = m.id;
              m.label = m.name + ' (' + m.code + ')';
            });
            this.projects.unshift({ value: 'all', label: 'All' });
          });
      }

    ngDoCheck(): void {
    }

    applyProject(crew: any) {
        console.log(crew);
        crew.workers.forEach(worker => {
            worker.projectId = crew.currentProjectId;
        });
    }

    saveCrew(crew: any) {
        this.dataService.addAll('crews/all', crew)
        .subscribe((data) => {
            this.loadData();
        });
    }

    addDays(num, date) {
        const dateVal = new Date(date);
        dateVal.setDate(dateVal.getDate() + num);
        dateVal.setHours(6, 0, 0);
        return dateVal;
    }

    loadData() {
        this.loggingService.logPageView('Crew - Grid');
        this.dataService.getById('crews', '')
        .subscribe((data) => {
            this.data = data;
        });
    }

    onStartDateChange() {
        this.startDateStr = this.dateService.formatDate(new Date(this.startDate));
        this.loadData();
    }

    onSubmitForm() {

    }

}
