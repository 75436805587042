import { Component, OnInit, ViewEncapsulation, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ConfirmationService, TreeNode } from 'primeng/api';
import { DataService } from '../../services/data.service';
import { LoggingService } from '../../services/logging.service';
import { Subscription } from 'rxjs';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-user-assignment',
    templateUrl: './user.assignment.component.html',
    providers: [ConfirmationService],
    encapsulation: ViewEncapsulation.None
})
export class UserAssignmentComponent implements OnInit, OnChanges {

    sites: any[];
    siteId: string;

    parentSiteId: string;

    users: any[];
    userId: string;
    reportId: string;

    parentUsers: any[];
    parentUserId: string;

    childUserId: string;
    childUsers: any[];
    childUsersCurrent: any[];
    childSiteId: string;
    childUser: any;

    user: any;

    showAddParentDialog: boolean = false;
    showAddChildDialog: boolean = false;
    showAddReportDialog: boolean = false;

    reports: any[];
    reportList: any[];

    constructor(private dataService: DataService,
        private confirmService: ConfirmationService,
        private loggingService: LoggingService) { }
    ngOnChanges(changes: SimpleChanges): void {
        throw new Error('Method not implemented.');
    }

    ngOnInit() {
        this.dataService.getById('lookups', 'site')
            .subscribe((lookup) => {
                this.sites = lookup;
                this.sites.unshift({});
        });
        
    }

    loadSite() {
        this.userId = null;
        this.dataService.getById('lookups', 'users/' + this.siteId)
        .subscribe((data) => {
            this.users = data;
            this.users.unshift({});
        });
    }

    loadParentUsers() {
        this.userId = null;
        this.dataService.getById('lookups', 'users/' + this.parentSiteId)
        .subscribe((data) => {
            this.parentUsers = data;
            this.parentUsers.unshift({});
        });
    }

    loadChildUsers() {
        // this.userId = null;
        this.dataService.getById('lookups', 'users/' + this.childSiteId)
        .subscribe((data) => {
            this.childUsers = data;
            this.childUsers.unshift({});
        });
    }

    loadUser() {
        this.dataService.getById('users', this.userId)
            .subscribe((data) => {
                this.user = data;
                this.loadChildCurrent();
                this.loadUserReports();
            });
    }

    loadUserById(_userId: string) {
        this.dataService.getById('users', _userId)
            .subscribe((data) => {
                this.childUser = data;
            });
    }

    loadChildCurrent() {
        this.dataService.getById('users/parent', this.userId)
        .subscribe((data) => {
            this.childUsersCurrent = data;
        });
    }

    loadUsers() {
        this.dataService.getById('users/parent', this.userId)
            .subscribe((data) => {
                this.loadUser();
                this.childUsersCurrent = data;
                this.childUsers = data;
            });
    }

    delete(_user): void {
        this.confirmService.confirm({
            message: 'Do you want to delete ' + _user.name + '?',
            accept: () => {
                _user.parentUserId = null
                this.dataService.save('users', _user)
                  .subscribe((data) => {
                    this.loadUsers();
                  });
            },
            reject: () => {
            }
        });
      }

    addParent() {
        this.cancelAddParentDialog();
        this.user.parentUserId = this.parentUserId;
        this.dataService.save('users', this.user)
          .subscribe((data) => {
            this.loadUser();
          });
    }

    addChild() {
        this.cancelAddChildDialog();
        this.childUser.parentUserId = this.user.id;
        this.dataService.save('users', this.childUser)
          .subscribe((data) => {
            this.loadUser();
          });
        this.childSiteId = null;
    }

    addReport() {
        console.log(this.reportId);
        var recordData = {id: 'new', userId: this.userId, reportId: this.reportId};
        this.cancelReportDialog();
        this.dataService.save('users/reports', recordData)
          .subscribe((data) => {
            this.loadUserReports();
          });
    }

    loadAddParentDialog(_user: any) {
        this.showAddParentDialog = true;
    }

    loadAddChildDialog(_user: any) {
        this.showAddChildDialog = true;
    }

    loadAddReportDialog() {
        this.showAddReportDialog = true;
        this.dataService.getAll("lookups/emailreports")
        .subscribe((data) => {
            this.reportList = data;
        });
    }

    loadDeleteParentDialog(_user: any) {
        this.confirmService.confirm({
            message: 'Do you want to Remove Parent ' + _user.name + '?',
            accept: () => {
                _user.parentUserId = null
                this.dataService.save('users', _user)
                  .subscribe((data) => {
                    this.loadUsers();
                  });
            },
            reject: () => {
            }
        });
    }

    cancelAddParentDialog() {
        this.showAddParentDialog = false;
    }

    cancelAddChildDialog() {
        this.showAddChildDialog = false;
        this.childSiteId = null;
    }

    cancelReportDialog() {
        this.showAddReportDialog = false;
    }

    save() {
        this.loggingService.savedMessage();
    }
 
    loadUserReports() {
        this.dataService.getById('users/global/all', this.userId)
            .subscribe((data) => {
                this.reports = data;
            });
    }

    deleteReport(reports): void {
        this.confirmService.confirm({
            message: 'Do you want to delete this report' + reports.reportName + '?',
            accept: () => {
                this.dataService.deleteById('users/reports', reports.id)
                  .subscribe((data) => {
                    this.loadUserReports();
                  });
            },
            reject: () => {
            }
        });
      }

      
}
