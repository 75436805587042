import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Message } from 'primeng/api';
import { LoggingService } from '../../services/logging.service';
import { DataService } from '../../services/data.service';
import { UserService } from '../../services/user.service';
import { SharedModule, ConfirmationService} from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { UUID } from 'angular2-uuid';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-site-document-categories',
    templateUrl: './site.document-categories.component.html',
    providers: [ ConfirmationService ],
    encapsulation: ViewEncapsulation.None
})
export class SiteDocumentCategoriesComponent implements OnInit {
@Input() site: any;

env: any = environment;
documentCategories: any[];
item: any;
cols: any[];
pageInfo: any;
isGlobalAdmin = false;
projectsList: any[];
siteSubscription: Subscription;

    constructor(private dataService: DataService,
                private userService: UserService,
                private confirmService: ConfirmationService,
                private http: HttpClient,
                private loggingService: LoggingService) {
                    this.isGlobalAdmin = this.userService.currentUser().isGlobalAdmin;
                    this.siteSubscription = this.userService.getSiteEvent()
                        .subscribe(siteId => {
                            this.site.id = siteId;
                            this.loadProjectList();
                            this.load();
                        });
                }

    ngOnInit() {

        this.cols = [
            { field: 'name', header: 'Name' }
        ];
        this.loadProjectList();
        this.load();
    }

    ngOnDestroy() {
        this.siteSubscription.unsubscribe();
    }

    load() {
        this.dataService.getAll('documentcategories')
        .subscribe((lookup) => {
            this.documentCategories = lookup;
        });
     }

     loadProjectList(): void {
        this.dataService.getAll('lookups/projects')
        .subscribe((data) => {
            this.projectsList = data;
        });
    }

     edit(item: any): void {
        this.item = item;
     }

     delete(item: any): void {
        this.confirmService.confirm({
            message: 'Do you want to delete ' + item.name + '?',
            accept: () => {
                this.dataService.deleteById('documentcategories', item.id)
                    .subscribe((data) => {
                        this.load();
                    });
            },
            reject: () => {
            }
        });
     }

     addNew(): void {
        this.item = { label: null, value: null };
     }

    save(): void {
        this.dataService.save('documentcategories', this.item)
            .subscribe((data) => {
            this.item = null;
            this.load();
        });
    }


     cancel(): void {
        this.item = null;
    }
}
