import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../../services/data.service';
import { DateService } from '../../services/date.service';
import { LoggingService } from '../../services/logging.service';

@Component({
  selector: 'app-badgeholder-survey-attempts',
  templateUrl: './surveyattempt.component.html',
  styleUrls: ['./surveyattempt.component.css']
})
export class BadgeHolderSurveyAttemptComponent implements OnInit {
  @Input() badgeHolder: any;

  pageInfo: any;
  path: string;
  sub: any;
  id: string;
  allAttempts: any[];
  attempts: any[];
  attemptData: any;
  badgeHolderName: string;
  surveyName: string;
  showAddDialog = false;
  dateFilter: Date;

  constructor(
    private dataService: DataService,
    private dateService: DateService,
    private loggingService: LoggingService) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.loggingService.logPageView('Attempts - Grid');
    this.attempts = [];

    this.dataService.getById('surveys/attempts/badgeholders', this.badgeHolder.id)
      .subscribe((data) => {
        data.forEach(m => m.createdDate = new Date(m.createdDate));
        this.allAttempts = data;
        this.attempts = data;
      });
  }

  dateFilterChaned() {
    if (this.dateFilter)
      this.attempts = this.allAttempts.filter(m => m.createdDate.getMonth() === this.dateFilter.getMonth() && m.createdDate.getDate() === this.dateFilter.getDate() && m.createdDate.getFullYear() === this.dateFilter.getFullYear());
    else
      this.attempts = this.allAttempts;
  }

  view(attempt: any) {
    this.attemptData = {};
    this.surveyName = attempt.surveyName;
    this.badgeHolderName = attempt.firstName + ' ' + attempt.lastName;
    this.dataService.getById('surveys/attempts/details', attempt.id)
      .subscribe((data) => {
        this.attemptData = data;
        console.log(this.attemptData)
      });
    this.showAddDialog = true;
  }

  cancel() {
    this.showAddDialog = false;
  }
  save() {
    this.dataService.save('surveys/attempt/notes', this.attemptData.surveyAttempt)
      .subscribe((data) => {
        this.showAddDialog = false;
      });
  }
}