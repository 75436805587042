import { Component, Input, OnInit, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

import { DataService } from '../../services/data.service';
import { LoggingService } from '../../services/logging.service';
import { DateService } from '../../services/date.service';
import { Message } from 'primeng/api';
import { BreadCrumbService } from '../../services/breadcrumb.service';
import { ConfirmationService } from 'primeng/api';

@Component({
    selector: 'app-inspector-profile',
    templateUrl: './inspector.profile.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [ ConfirmationService ]
})
export class InspectorProfileComponent implements OnInit, OnChanges {
@Input() inspector: any;
@Input() dialog: boolean;

message: Message[] = [];
companies: any[];
inspection: any;
inspections: any[];
showInspectionDialog: boolean;

    constructor(
        private breadcrumbService: BreadCrumbService,
        private confirmService: ConfirmationService,
        private dataService: DataService,
        private loggingService: LoggingService,
        private dateService: DateService,
        private router: Router) {}

    ngOnInit(): void {
        if (this.inspector.startDate !== undefined) {
            this.inspector.startDate = this.dateService.convertDateUniversalToSiteTimeDate(new Date(this.inspector.startDate));
        } else {
            this.inspector.startDate = new Date();
        }

        if (this.inspector.terminationDate !== undefined) {
            this.inspector.terminationDate = this.dateService.convertDateUniversalToSiteTimeDate(new Date(this.inspector.terminationDate));
        } else {
            const today = new Date();
            const tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 1);
            this.inspector.terminationDate = tomorrow;
        }

        this.loadCompanies();
    }

    ngOnChanges(simpleChanges: SimpleChanges): void {
//        if (this.inspector) {
//            this.breadcrumbService.setItemsByCategoryAndName(
//                                'Controls',
//                                'Inspectors',
//                                '/inspectors/search',
//                                this.inspector.name,
//                                this.router.url);
//        }
    }

    save() {
        this.inspector.badgeHolderType = 'I';
        if (this.inspector.startDate) {
            this.inspector.startDate = this.dateService.convertDateLocalToSiteTimeDate(new Date(this.inspector.startDate));
        }
        if (this.inspector.terminationDate) {
            this.inspector.terminationDate = this.dateService.convertDateLocalToSiteTimeDate(new Date(this.inspector.terminationDate));
        }
        this.dataService.save('badgeholders', this.inspector)
            .subscribe((data) => {
                this.loggingService.savedMessage();
            });
    }

    cancel() {
        const path = '/inspectors/search/';
        this.router.navigate([path]);
    }
    loadCompanies(): void {
        this.dataService.getAll('companies')
        .subscribe((companies) => {
            this.companies = companies;
        });
    }
}
