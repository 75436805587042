import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DataService } from '../services/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Message } from 'primeng/api';
import { UserService } from '../services/user.service';
import { BreadCrumbService } from '../services/breadcrumb.service';
import { LoggingService } from '../services/logging.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-site-profile',
    templateUrl: './siteprofile.component.html',
    encapsulation: ViewEncapsulation.None
})
export class SiteProfileComponent implements OnInit {

    showTabFlag: boolean;
    message: Message[] = [];
    site: any;
    sub: any;
    user: any;
    siteSubscription: Subscription;

    constructor(
        private route: ActivatedRoute,
        private dataService: DataService,
        private router: Router,
        private userService: UserService,
        private breadcrumbService: BreadCrumbService,
        private loggingService: LoggingService) {
        this.siteSubscription = this.userService.getSiteEvent()
            .subscribe(siteId => {
                this.loadData();
            });
    }

    ngOnInit() {
        this.breadcrumbService.setItemsByCategory('SiteTraxx Admin', 'Site', this.router.url);

        this.loadData();
    }

    ngOnDestroy() {
        this.siteSubscription.unsubscribe();
    }

    loadData() {
        this.user = this.userService.currentUser();
        this.loggingService.logPageView('Site - Profile');
        this.dataService.getById('sites', this.user.siteId)
            .subscribe((data) => {
                this.site = data;

                this.site.calculateOvertimeAfterHours = (this.site.calculateOvertimeAfterHours === 1);
                this.site.calculateOvertimeAfterHoursWeek = (this.site.calculateOvertimeAfterHoursWeek === 1);
                this.site.calculateOvertimeSevenDays = (this.site.calculateOvertimeSevenDays === 1);
                this.site.timeoutLabor = (this.site.timeoutLabor === 1);
                this.site.autoClockByTime = (this.site.autoClockByTime === true);
                this.site.autoClockTime = this.site.autoClockTime.toString();
                this.site.calculateDoubleTimeAfterHours = (this.site.calculateDoubleTimeAfterHours === 1);
                this.site.calculateDoubleTimeAfterHoursWeek = (this.site.calculateDoubleTimeAfterHoursWeek === 1);
                this.site.calculateDoubleTimeSevenDays = (this.site.calculateDoubleTimeSevenDays === 1);
            });
    }

    save() {
        this.site.demolitionRequired = (this.site.demolitionRequired === '1') ? 1 : 0;
        this.site.typeOfContruction = (this.site.typeOfContruction === '1') ? 1 : 0;
        this.site.bondRequired = (this.site.bondRequired === '1') ? 1 : 0;
        this.site.calculateOvertimeAfterHours = (this.site.calculateOvertimeAfterHours ? 1 : 0);
        this.site.calculateOvertimeAfterHoursWeek = (this.site.calculateOvertimeAfterHoursWeek ? 1 : 0);
        this.site.calculateOvertimeSevenDays = (this.site.calculateOvertimeSevenDays ? 1 : 0);
        this.site.timeoutLabor = (this.site.timeoutLabor ? 1 : 0);
        this.site.autoClockByTime = (this.site.autoClockByTime ? true : false);
        this.site.autoClockTime = parseInt(this.site.autoClockTime);
        this.site.timeoutLaborAfterHours = parseInt(this.site.timeoutLaborAfterHours)
        this.site.calculateDoubleTimeAfterHours = (this.site.calculateDoubleTimeAfterHours ? 1 : 0);
        this.site.calculateDoubleTimeAfterHoursWeek = (this.site.calculateDoubleTimeAfterHoursWeek ? 1 : 0);
        this.site.calculateDoubleTimeSevenDays = (this.site.calculateDoubleTimeSevenDays ? 1 : 0);

        this.dataService.save('sites', this.site)
            .subscribe((data) => {
                this.loggingService.savedMessage();
            });
    }

    cancel() {
        this.router.navigate(['/']);
    }

    showTab(_tab: boolean) {
        this.showTabFlag = _tab;
    }

}
