<div *ngIf="pageInfo">
    <div class="container">
        <div class="row">
            <div class="col">
                <h3>Rewards</h3>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <st-table pageId="{{pageInfo.id}}" dataTable="{{pageInfo.dataName}}"></st-table>
            </div>
        </div>
    </div>
</div>

<div *ngIf="reward">
    <app-reward-profile [reward]="reward"></app-reward-profile>
</div>