<form #thisForm='ngForm'>
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <h3>{{contract.name}}</h3>
            </div>
            <div class="col-lg-6">
                <div class="float-right">
                    <button pButton (click)="save()" [disabled]="!thisForm.valid" icon="pi pi-save" label="Save"></button>
                    <button pButton (click)="cancel()" icon="pi pi-ban" label="Cancel"></button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-lg-12">
                        <label class="control-label">Contract Name:</label>
                        <input type="text" name="name" pInputText class="form-control" [(ngModel)]="contract.name" maxlength="50" required />
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-lg-12">
                        <label class="control-label">Company Name:</label><br />
                        <p-dropdown [options]="companies" [filter]="true" placeholder="Select a Company" [(ngModel)]="contract.companyId" name="companyDropdown"></p-dropdown>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="row">
                    <div class="col-lg-12">
                        <label class="control-label">Prime Delivery Method:</label><br />
                        <p-dropdown [options]="deliveryMethods" [filter]="true" placeholder="Select a Delivery Method" [(ngModel)]="contract.deliveryMethod" name="deliveryMethodsDropdown"></p-dropdown>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <label class="control-label">Est. Contract Value:</label>
                        <input type="number" class="form-control" pInputText name="estimatedValue" [(ngModel)]="contract.estimatedValue" />
                    </div>
                    <div class="col-lg-6">
                        <label class="control-label">Final Contract Value:</label>
                        <input type="number" class="form-control" pInputText name="finalValue" [(ngModel)]="contract.finalValue" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <label class="control-label">Est. Total Man Hours:</label>
                        <input type="number" class="form-control" pInputText name="estimatedTotalManHours" [(ngModel)]="contract.estimatedTotalManHours" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <label class="control-label">Assigned Emergency Refuge Station</label>
                        <input type="text" class="form-control" pInputText name="refugeStation" [(ngModel)]="contract.refugeStation" maxlength="50" />
                    </div>
                </div>
                <div class="row">
                    <div class="ui-g-12">
                        <label class="control-label">Start Date</label><br />
                        <p-calendar name="startDate" [(ngModel)]="startDate" (onSelect)="onStartDateChange()" (onInput)="onStartDateChange()" [showIcon]="true"></p-calendar>
                    </div>
                </div>
                <div class="row">
                    <div class="ui-g-12">
                        <label class="control-label">End Date</label><br />
                        <p-calendar name="endDate" [(ngModel)]="endDate" (onSelect)="onEndDateChange()" (onInput)="onEndDateChange()" [showIcon]="true"></p-calendar>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="row">
                    <div class="ui-g" style="width:250px;margin-bottom:10px">
                        <div><b>P&amp;P Bond Required:</b></div>
                        <div class="ui-g-6">
                            <p-radioButton name="projectBonded" value="true" label="Yes" [(ngModel)]="bonded"></p-radioButton>
                        </div>
                        <div class="ui-g-6">
                            <p-radioButton name="projectBonded" value="false" label="No" [(ngModel)]="bonded"></p-radioButton>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="ui-g">
                        <div><b>Enrolled in Subguard:</b></div>
                        <div class="ui-g-6">
                            <p-radioButton name="enrolledSubgaurd" value="true" label="Yes" [(ngModel)]="subguard"></p-radioButton>
                        </div>
                        <div class="ui-g-6">
                            <p-radioButton name="enrolledSubgaurd" value="false" label="No" [(ngModel)]="subguard"></p-radioButton>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="ui-g">
                        <div><b>Enrolled in Wrap-Up Insurance Program:</b></div>
                        <div class="ui-g-6">
                            <p-radioButton name="enrolledInsurance" value="true" label="Yes" [(ngModel)]="insurance"></p-radioButton>
                        </div>
                        <div class="ui-g-6">
                            <p-radioButton name="enrolledInsurance" value="false" label="No" [(ngModel)]="insurance"></p-radioButton>
                        </div>
                    </div>
                    <div class="ui-g-12">
                        <p-checkbox name="generalLiability" binary="true" label="General Liability" [(ngModel)]="contract.hasGeneralLiability"></p-checkbox>
                    </div>
                    <div class="ui-g-12">
                        <p-checkbox name="workersComp" binary="true" label="Workers Compensation" [(ngModel)]="contract.workersComp"></p-checkbox>
                    </div>
                </div>
            </div>
        </div>
        <st-update-block [object]='contract' *ngIf="contract"></st-update-block>
    </div>
</form>