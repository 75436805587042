import { Injectable } from '@angular/core';
//import { Http, Response, RequestOptions, Headers } from '@angular/http';

import { LoggingService } from './logging.service';
import { DataService } from './data.service';

@Injectable()
export class UserNameService {

    cachedUsers = [];

    constructor(private dataService: DataService, private loggingService: LoggingService) { }

    getUserName(id: string): any {
        const user = { id: id, name: null };
        if (id) {
            const users = this.cachedUsers.filter(m => m.id === id);
            if (users.length > 0) {
                user.name = users[0].name;
            }
            else {
                this.dataService.getById('users', id)
                    .subscribe((data) => {
                        user.name = data.name;
                        this.cachedUsers.push(user);
                    });
            }
        }
        return user;
    }
}
