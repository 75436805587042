<div class="container">
    <div class="row">
        <div class="col-lg-6">
            <form #thisForm='ngForm' *ngIf="data">
                <div class="row">
                    <div class="col-lg-12 text-right">
                        <button pButton (click)="save()" [disabled]="!thisForm.valid" icon="pi pi-save"
                            label="Save"></button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <label class="control-label">Site Name:</label><br />
                        <input type="text" class="form-control" pInputText [(ngModel)]="data.name" name="name"
                            maxlength="100" required />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <label class="control-label">Parent Company Name:</label>
                        <input [(ngModel)]="data.companyName" maxlength="50" pInputText name="companyName" type="text"
                            class="form-control" required />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <label class="control-label">Company Category:</label><br />
                        <p-dropdown [options]="companyCategories" placeholder="Select a Category" [filter]="true"
                            required [(ngModel)]="data.companyType" name="categoryDropdown"
                            (onChange)="loadCSIDivisions()"></p-dropdown>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <label class="control-label" for="trade">CSI Division:</label><br />
                        <p-dropdown [options]="divisions" placeholder="Select a Division" [filter]="true" required
                            [(ngModel)]="data.csiDivision" name="divisionDropdown" (onChange)="loadTrades()">
                        </p-dropdown>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <label class="control-label" for="trade">Trade Classification:</label><br />
                        <p-dropdown [options]="trades" placeholder="Select a Trade" [filter]="true" required
                            [(ngModel)]="data.tradeClass" name="tradeDropdown" (onChange)="setup()"></p-dropdown>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <label class="control-label">Username:</label>
                        <input [(ngModel)]="data.userName" maxlength="50" pInputText name="userName" type="text"
                            class="form-control" required />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <label class="control-label">Weather Station Id:</label><br />
                        <input type="text" class="form-control" pInputText [(ngModel)]="data.weatherStationId"
                            name="weatherStationId" maxlength="36" required />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <label class="control-label">Accu Weather Id:</label>
                        <input [(ngModel)]="data.accuWeatherId" maxlength="20" pInputText name="accuWeatherId"
                            type="text" class="form-control" required />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <label class="control-label">Time Zone:</label><br />
                        <p-dropdown [options]="dataService.timeZones" [filter]="false" [(ngModel)]="data.timeZone" name="timeZone">
                        </p-dropdown>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-lg-6">
            <p-table [value]="defaultLaborClasses" [scrollable]="true" scrollHeight="335px" *ngIf="data.tradeClass">
                <ng-template pTemplate="colgroup">
                    <colgroup>
                        <col [style.width.px]="50">
                        <col>
                        <col>
                    </colgroup>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th></th>
                        <th>Trade</th>
                        <th>Labor Class</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr>
                        <td>
                            <p-checkbox [(ngModel)]="rowData.selected" binary="true"></p-checkbox>
                        </td>
                        <td>
                            {{rowData.tradeName}}
                        </td>
                        <td>
                            {{rowData.name}}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>