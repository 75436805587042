<div>
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="float-left">
                    <h3>Notify Badge Holders</h3>
                </div>
            </div>
        </div>
        <div class="row">
            <label>Message:</label>
            <textarea [rows]="5" [cols]="30" style="border-width: 1px" [(ngModel)]="body" pInputTextarea
                autoResize="autoResize"></textarea>
            <button pButton (click)="send()" [disabled]="hasSMSNumber">Send</button>&nbsp;&nbsp;&nbsp;
            <span class="alert alert-danger" *ngIf="errorMessage!==''">{{errorMessage}}</span>
            <span class="alert alert-success" *ngIf="message!==''">{{message}}</span>
        </div>
        <div class="row">
            Filter by Company:
            <p-dropdown [options]="companies" (onChange)="filter()" [showClear]="true" placeholder="Select a Company"
                [filter]="true" [(ngModel)]="companyId"></p-dropdown>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <p-checkbox name="emergencyTeam" binary="true" (click)="filter()" label="Emergency Management Team"
                [(ngModel)]="emergencyTeam"></p-checkbox>
        </div>
        <div class="row">
            <div class="col">
                <p-table #dt [value]="filteredRows" [columns]="cols" [(selection)]="selectedRows" dataKey="id"
                    [exportFilename]="'badgeholders'">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th></th>
                            <th *ngFor="let col of columns">
                                {{col.header}}
                            </th>
                        </tr>
                        <tr>
                            <th><p-checkbox (click)="checkAll()"></p-checkbox></th>
                            <th style="overflow: hidden" *ngFor="let col of columns">
                                <input pInputText type="text"
                                    (input)="dt.filter($event.target.value, col.field, 'contains')"
                                    *ngIf="col.filter===true">
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData">
                            <td>
                                <p-tableCheckbox [value]="rowData"
                                    *ngIf="rowData['phone']!=null && rowData['textPhone']===true"></p-tableCheckbox>
                            </td>
                            <td *ngFor="let col of columns" [ngSwitch]="col.field">
                                <span *ngIf="col.type==='image'"><img style="width: 50px"
                                        src="{{rowData[col.field]}}" /></span>
                                <span *ngIf="col.type==='yesno'">
                                    <span *ngIf="rowData[col.field]===true">True</span>
                                </span>
                                <span *ngIf="col.type==='yesno2'">
                                    <span *ngIf="rowData[col.field]===1">True</span>
                                </span>
                                <span *ngIf="col.type==='text'">{{rowData[col.field]}}</span>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>