<div class="row" *ngIf="photos">
    <div class="col-lg-4" *ngFor="let photo of photos">
        <div class="row">
            <div class="col-12">
                <img style="width: 100px" src="{{baseImageUrl}}site_{{user.siteId}}/{{category}}/{{photo?.objectId}}_{{photo?.id}}.jpg" alt="">
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <button type="button" pButton (click)="show(photo)" icon="pi pi-search"></button>
            </div>
            <div class="col-6">
                <button type="button" pButton (click)="delete(photo)" icon="pi pi-trash"></button>
            </div>
        </div>
    </div>
</div>

<p-dialog header="Photo" [(visible)]="showPhotoDialog" modal="modal">
    <img style="width: 500px;margin: 5px;" src="{{baseImageUrl}}site_{{user.siteId}}/{{category}}/{{selectedPhoto?.objectId}}_{{selectedPhoto?.id}}.jpg" alt="" *ngIf="selectedPhoto !== undefined">
    <p-footer>
        <button type="button" (click)="close()" pButton icon="pi pi-times" label="Close"></button>
    </p-footer>
</p-dialog>

<p-confirmDialog header='Delete?' icon='pi pi-trash'></p-confirmDialog>