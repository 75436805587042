<div>
    <div class="container">
        <div class="row">
            <p-table #dt [columns]="cols" [value]="documentCategories">
                <ng-template pTemplate="caption">
                    <div style="text-align: left">
                        <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                        <input type="text" pInputText size="50" placeholder="Global Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                        <span class="float-right">
                            <button pButton icon="pi pi-plus" title="Add" (click)="addNew()" class="indigo-btn"></button>
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th style="width: 46px; padding: .625em 0; text-align: center">Edit</th>
                        <th style="width: 52px; padding: .625em 0; text-align: center">Delete</th>
                        <th *ngFor="let col of columns">
                            {{col.header}}
                        </th>
                    </tr>
                    <tr>
                        <th></th>
                        <th></th>
                        <th *ngFor="let col of columns" [ngSwitch]="col.field">
                            <input pInputText type="text" (input)="dt.filter($event.target.value, col.field, 'contains')">
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pSelectableRow]="rowData">
                        <td style="width: 46px; padding: .625em 0; text-align: center"><button type="button" pButton (click)="edit(rowData)" icon="pi pi-pencil"></button></td>
                        <td style="width: 52px; padding: .625em 0; text-align: center"><button type="button" pButton (click)="delete(rowData)" icon="pi pi-trash"></button></td>
                        <td *ngFor="let col of columns" [ngSwitch]="col.field">
                            <span *ngSwitchCase="'baseRate'">{{rowData[col.field] | currency:'USD':true:'1.2-2'}}</span>
                            <span *ngSwitchDefault>{{rowData[col.field]}}</span>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<p-dialog header="Document Category" [(visible)]="item" modal="modal" [style]="{ width: '450px', height: '410px', 'min-width': '450px' }" [minY]="350">
    <div class="row" *ngIf="item">
        <div class="col-lg-12">
            <label class="control-label">Name:</label>
            <input type="text" class="form-control" [(ngModel)]="item.name" maxlength="50" />
        </div>
        <div class="col-lg-12" *ngIf="isGlobalAdmin">
            <label class="control-label">Project:</label><br/>
            <p-dropdown [options]="projectsList" name="projectId" [filter]="true" placeholder="Select Project" [(ngModel)]="item.projectId" [scrollHeight]="300"></p-dropdown>
        </div>
        <div class="col-lg-12" *ngIf="isGlobalAdmin">
            <p-checkbox name="access" binary="true" label="Active Access" [(ngModel)]="item.access"></p-checkbox>
        </div>
    </div>

    <button type="button" (click)="save()" class="btn btn-primary"><i class="pi pi-save"></i> Save</button>
    <button type="button" (click)="cancel()" class="btn btn-primary"><i class="pi pi-ban"></i> Cancel</button>

</p-dialog>

<p-confirmDialog header='Delete?' icon='pi pi-trash'></p-confirmDialog>