import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BreadCrumbService } from '../../services/breadcrumb.service';
import { DataService } from '../../services/data.service';
import { LoggingService } from '../../services/logging.service';

@Component({
    selector: 'app-parkingsearch',
    templateUrl: './parkingsearch.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ParkingSearchComponent implements OnInit {

    pageInfo: any;
    id: string;
    private sub: any;
    badgeHolder: any;
    showTabFlag: boolean;
    years: any[];
    section = 'Security';
    title = 'Parking Search';

    constructor(
        private route: ActivatedRoute,
        private dataService: DataService,
        private breadcrumbService: BreadCrumbService,
        private router: Router,
        private loggingService: LoggingService) {}


    ngOnInit(): void {
        this.breadcrumbService.setItemsByCategory(this.section, this.title, this.router.url);
        this.showTabFlag = false;

        this.sub = this.route.params.subscribe(params => {
            this.id = params['id'];

            if (this.id === undefined) {
                this.loggingService.logPageView('Parking Search - Grid');
                this.badgeHolder = null;
                this.dataService.getById('pages', 'parkingsearch')
                .subscribe((data) => {
                    this.pageInfo = data.result;
                    this.title = this.pageInfo.title;
                });
            } else {
                if (this.id === 'new') {
                    this.loggingService.logPageView('Badge Holders - New');
                    this.badgeHolder = {};
                } else {
                    this.loggingService.logPageView('Badge Holders - Profile');
                    this.dataService.getById('badgeholders', this.id)
                    .subscribe((data) => {
                        this.badgeHolder = data;
                    });
                }
            }
         });
    }
}
