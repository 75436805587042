<div class="panel-body" id="termPanel">
    <div class="row">
        <div class="col-lg-6">
            <div class="row">
                <div class="col-lg-12">
                    <label class="control-label">Badge Suspension Date:</label>
                    <p-calendar [(ngModel)]="suspendedDate" (onSelect)="onSuspendedDateChange()" (onInput)="onSuspendedDateChange()" [showIcon]="true"></p-calendar>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <label class="control-label">Suspension Notes:</label>
                    <textarea class="form-control" pInputTextarea *ngIf="badgeHolder" [(ngModel)]="badgeHolder.suspendedNotes" maxlength="255"></textarea>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="row">
                <div class="col-lg-12">
                    <label class="control-label">Termination Date:</label>
                    <p-calendar [(ngModel)]="terminationDate" (onSelect)="onTerminationDateChange()" (onInput)="onTerminationDateChange()" [showIcon]="true"></p-calendar>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <label class="control-label">Termination Notes:</label>
                    <textarea class="form-control" pInputTextarea *ngIf="badgeHolder" [(ngModel)]="badgeHolder.terminationNotes" maxlength="255"></textarea>
                </div>
            </div>
        </div>
    </div>
</div>