

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class WeatherService {
    env: any = environment;

    constructor(private http: HttpClient) {
    }

    public getAll<T>(_stationId: string, _date: string) {
        const _url = 'weather/' + _stationId + '/' + _date + '/overview';
        return this.http.get<any[]>(_url).pipe(share());
    }
}
