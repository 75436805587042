<div class="global-admin-container">
    <div class="row">
        <div class="col-lg-3">
            <label class="control-label">Site:</label><br />
            <p-dropdown [options]="sites" [(ngModel)]="siteId" (onChange)="loadSite()"></p-dropdown>
        </div>
        <div class="col-lg-3">
            <label class="control-label">Role:</label><br />
            <p-dropdown [options]="roles" [(ngModel)]="roleId" (onChange)="loadRole()" *ngIf="siteId"></p-dropdown>
        </div>
        <div class="col-lg-4">
            <label class="control-label">Menus:</label><br />
            <p-tree [value]="menus" selectionMode="checkbox" [(selection)]="selected" *ngIf="roleId"></p-tree>
        </div>
        <div class="col-lg-2">
            <button pButton (click)="save()" icon="pi pi-save" label="Save"></button>
        </div>
    </div>
</div>