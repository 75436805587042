<div class="global-admin-container">
    <div class="row" *ngIf="data">
        <div class="col-lg-12">
            <p-table #dt [value]="data" [scrollable]="true" scrollHeight="500px" [columns]="cols">
               <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                            {{col.header}}
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                    </tr>
                    <tr>
                        <th style="overflow: hidden" *ngFor="let col of columns">
                            <input pInputText type="text" (input)="dt.filter($event.target.value, col.field, 'contains')" >
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr>
                        <td>{{rowData.siteName}}</td>
                        <td>{{rowData.name}}</td>
                        <td>{{rowData.lastSyncDate}}</td>
                        <td>{{rowData.versionInfo}}</td>
                        <td>{{rowData.deleted}}</td>
                        <td>{{rowData.status}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
