import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataService } from '../services/data.service';
import { DateService } from '../services/date.service';
import { BreadCrumbService } from '../services/breadcrumb.service';
import { LoggingService } from '../services/logging.service';
import { Message } from 'primeng/api';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-forecast',
  templateUrl: './forecast.component.html',
  styleUrls: ['./forecast.component.css']
})
export class ForecastComponent implements OnInit {

  subscription: Subscription;
  dt: string;
  data: any;
  siteSubscription: Subscription;

  frozenColumns = [{ field: 'name', header: 'Name' }];
  dataColumns = [];
  allItems = null;
  optType = "0";


  constructor(
    private dataService: DataService,
    private dateService: DateService,
    private breadcrumbService: BreadCrumbService,
    private loggingService: LoggingService,
    private userService: UserService,
    private router: Router) {
      this.subscription = this.dateService.getCurrentDate()
      .subscribe(date => {
          this.dt = this.dateService.formatDate(date);
          this.loadData();
        });

      this.siteSubscription = this.userService.getSiteEvent()
        .subscribe(siteId => {
            this.loadData();
        });
  }

  loadData() {
    this.dataService.getById('companies/forecast/true', this.dt)
      .subscribe((data) => {
        data.items.forEach(m=> m.forecastedValue = m.forecasted);
        this.data = data;
        this.data.weeks.forEach(w => w.name = 'w' + w.year + w.week);
        this.data.items.forEach(m => {
          for (let weekNumber = 0; weekNumber < m.weeks.length; weekNumber++)
              m['w' + m.weeks[weekNumber].year + m.weeks[weekNumber].week] = (m.weeks[weekNumber] ? Number(m.weeks[weekNumber].trend) : 0);
        });

        if (!this.allItems)
          setTimeout(() => {
            this.dataService.getById('companies/forecast/false', this.dt)
              .subscribe((data2) => {
                  this.setup(data2);
                });
          }, 10);
      });
  }

  setup(data: any){
    this.dataColumns = [];
    let weekNumber = 0;
    for (; weekNumber < data.weeks.length && weekNumber < 20; weekNumber++)
      this.dataColumns.push({ 
        field: ('w' + data.weeks[weekNumber].year + data.weeks[weekNumber].week),
        year: data.weeks[weekNumber].year, 
        week: data.weeks[weekNumber].week, 
      });

    this.allItems = [];
    data.items.forEach(m => {
      const item = Object.assign({}, m);
      item.weeks = [];
      this.allItems.push(item);
    });
    setTimeout(() => {
      this.allItems = Object.assign([], data.items);

      this.allItems.forEach(m => m.weeks.forEach(mw=> m['w' + mw.year + mw.week] = (mw.trend ? mw.trend : 0)));

      if (weekNumber > 0)
        setTimeout(() => {
          for (; weekNumber < data.weeks.length; weekNumber++)
              this.dataColumns.push({ 
                field: ('w' + data.weeks[weekNumber].year + data.weeks[weekNumber].week),
                year: data.weeks[weekNumber].year, 
                week: data.weeks[weekNumber].week, 
              });
       }, 10);
    }, 10);
  }

  className(trend: number): string {
    switch (Math.abs(trend)) {
      case 3:
        return 'red';
      case 2:
        return 'yellow';
      case 1:
        return 'green';
    }
    return '';
  }

  ngOnInit() {
    this.breadcrumbService.setItemsByCategory('Controls', 'Forecast', this.router.url);
    this.loggingService.logPageView('Forecast');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.siteSubscription.unsubscribe();
  }

  forecastedChanged(item) {
    item.forecastedHeadCount = Math.round(item.forecasted / 8.5 * 10) / 10;
  }
  forecastedHeadCountChanged(item) {
    item.forecasted = Math.round(item.forecastedHeadCount * 8.5 * 10) / 10;
  }

  save() {
    const forecasts = [];
    this.data.items.forEach(m => {
      if (m.forecastedValue != m.forecasted)
        forecasts.push({ CompanyId: m.id, Value: m.forecasted });
    });
    this.dataService.save('companies/forecast', {dateFilter: this.dt, items: forecasts})
        .subscribe((data) => {
          data.items.forEach(m=> m.forecastedValue = m.forecasted);
          this.data = data;
          this.loggingService.savedMessage();
        });
  }
}
