<div class="container">
    <div class="row">
        <div class="col-lg-6">
            <h3>{{incident?.name}}</h3>
        </div>
        <div class="col-lg-6">
            <div class="float-right">
                <button pButton (click)="save()" icon="pi pi-save" label="Save" *ngIf="incident.id"></button>
                <button pButton (click)="cancel()" icon="pi pi-ban" label="Cancel"></button>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <form>
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="form-group">
                        <label>Incident Subject</label>
                        <input pInputText name="subject" [(ngModel)]="incident.subject" type="text" maxlength="50" class="form-control" />
                    </div>
                </div>
                <div class="col-12 col-lg-3">
                    <div class="form-group">
                        <label>Location</label>
                        <input pInputText name="location" [(ngModel)]="incident.location" type="text" maxlength="50" class="form-control" />
                    </div>
                </div>
                <div class="col-12 col-lg-3">
                    <div class="form-group">
                        <label>Event Date</label><br />
                        <p-calendar name="eventDate" [(ngModel)]="eventDate" [showIcon]="true"></p-calendar>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label>Incident Description</label>
                        <textarea pTextArea name="description" [(ngModel)]="incident.description" maxlength="500" rows="3" class="form-control"></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="form-group">
                        <label>Classification</label><br />
                        <p-dropdown [options]="classificationOptions" [filter]="true" placeholder="Select a Classification" name="classification" [(ngModel)]="incident.classification"></p-dropdown>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="form-group">
                        <label>Type of Injury</label>
                        <input pInputText name="injuryType" [(ngModel)]="incident.injuryType" maxlength="20" class="form-control" />
                    </div>
                </div>
            </div>
            <div class="row">
                <h3>Add Badge Holder to Incident Report</h3>
                <div>
                    <p-table #dt [columns]="cols" [value]="involvedParties">
                        <ng-template pTemplate="caption">
                            <div style="text-align: left">
                                <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                                <input type="text" pInputText size="50" placeholder="Global Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                                <span class="float-right">
                                            <button pButton icon="pi pi-plus" title="Add" (click)="addNew()" class="indigo-btn"></button>
                                        </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th style="width: 46px; padding: .625em 0; text-align: center">Edit</th>
                                <th style="width: 52px; padding: .625em 0; text-align: center">Delete</th>
                                <th *ngFor="let col of columns">
                                    {{col.header}}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr [pSelectableRow]="rowData">
                                <td style="width: 46px; padding: .625em 0; text-align: center"><button type="button" pButton (click)="edit(rowData)" icon="pi pi-pencil"></button></td>
                                <td style="width: 52px; padding: .625em 0; text-align: center"><button type="button" pButton (click)="delete(rowData)" icon="pi pi-trash"></button></td>
                                <td *ngFor="let col of columns" [ngSwitch]="col.field">
                                    <span *ngSwitchDefault>{{rowData[col.field]}}</span>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </form>
    </div>
    <st-update-block [object]='incident' *ngIf="incident"></st-update-block>
</div>



<p-confirmDialog header='Delete?' icon='pi pi-trash'></p-confirmDialog>

<p-dialog header="Add Involved Party" [(visible)]="showAddDialog" modal="modal" [style]="{ width: '500px' }">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-lg-6">
                <div class="form-group">
                    <label class="control-label">Badge Holder</label><br />
                    <p-dropdown [options]="badgeHolders" [filter]="true" required [(ngModel)]="involvedParty.badgeHolderId" name="badgeHolderId"></p-dropdown>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="form-group">
                    <label>Injury</label>
                    <input name="injury" [(ngModel)]="involvedParty.injury" maxlength="100" pInputText type="text" class="form-control" />
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="form-group">
                    <label>Treatment</label>
                    <input [(ngModel)]="involvedParty.treatment" maxlength="100" pInputText type="text" class="form-control" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label>Comments</label>
                    <textarea [(ngModel)]="involvedParty.comments" maxlength="500" pTextArea rows="3" class="form-control"></textarea>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label>Exited?</label>
                    <input [(ngModel)]="involvedParty.exitToInjury" type="checkbox" />
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <label>Corrective Plan Required?</label>
                    <input [(ngModel)]="involvedParty.correctiveAction" type="checkbox" />
                </div>
            </div>
        </div>
    </div>
    <p-footer>
        <button type="button" pButton icon="pi pi-save" style="background-color: #012169;color: white;" (click)="saveInvolved()" label="Save"></button>
    </p-footer>
</p-dialog>