import { Component, Input, OnInit, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DataService } from '../../services/data.service';
import { LoggingService } from '../../services/logging.service';
import { Message } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { BreadCrumbService } from '../../services/breadcrumb.service';
import { DateService } from '../../services/date.service';

@Component({
    selector: 'app-concrete-profile',
    templateUrl: './concrete.profile.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [ ConfirmationService ]
})
export class ConcreteProfileComponent implements OnInit, OnChanges {
@Input() concrete: any;

showTabFlag: boolean;
showUploadDialog: boolean;
companyContracts: any[];
materialTypes: any[];
materialCategories: any[];
unitTypes: any[];
materialCategory: any;
message: Message[] = [];
isNew = false;
isReady = false;
category = 'concrete';
refresh = new Date();

    constructor(
        private dataService: DataService,
        private loggingService: LoggingService,
        private breadcrumbService: BreadCrumbService,
        private dateService: DateService,
        private router: Router) {}

    ngOnInit(): void {
        if (this.concrete.id === undefined) {
            this.isNew = true;
            this.concrete.enterTime = new Date();
            this.concrete.exitTime = new Date();
            this.isReady = true;
        } else {
            this.dataService.getById('concrete', this.concrete.id)
                .subscribe((data) => {
                    this.concrete = data;

                    if (this.concrete.enterTime === undefined) {
                        this.concrete.enterTime = new Date();
                    } else {
                        this.concrete.enterTime = this.dateService.convertDateUniversalToSiteTimeDate(new Date(this.concrete.enterTime));
                    }

                    if (this.concrete.exitTime === undefined) {
                        this.concrete.exitTime = new Date();
                    } else {
                        this.concrete.exitTime = this.dateService.convertDateUniversalToSiteTimeDate(new Date(this.concrete.exitTime));
                    }

                    if (this.isNew) {
                        // this.concrete.pouredOutTime = this.concrete.enterTime;
                    } else if (this.concrete.pouredOutTime != null) {
                            this.concrete.pouredOutTime = this.dateService.convertDateUniversalToSiteTimeDate(new Date(this.concrete.pouredOutTime));
                    }

                    this.isReady = true;
                });
        }

        this.loadCompanyContracts();
        this.loadUnitTypes();
    }

    focusOnPouredOutTime(): void {
        if (!(this.concrete.pouredOutTime != null)) {
            this.concrete.pouredOutTime = new Date();
        }
    }
    updatePouredOutTime(): void {
        if (this.concrete.pouredOutTime != null) {
            this.concrete.pouredOutTime.setDate(this.concrete.enterTime.getDate());
        }
    }

    ngOnChanges(simpleChanges: SimpleChanges): void {
        this.breadcrumbService.setItemsByCategoryAndName(
                                'Controls',
                                'Concrete',
                                '/' + this.category + '/search',
                                this.concrete.name,
                                this.router.url);
    }

    showDialogChangedHandler(showDialog: boolean): void {
        this.refresh = new Date();
    }

    save() {
        if (this.concrete.contractId != null && this.concrete.contractId !== '' && this.concrete.enterTime != null) {
            this.concrete.exitTime = this.concrete.enterTime = this.dateService.convertDateLocalToSiteTimeDate(new Date(this.concrete.enterTime));
            if (this.concrete.pouredOutTime != null) {
                this.concrete.pouredOutTime = this.dateService.convertDateLocalToSiteTimeDate(new Date(this.concrete.pouredOutTime));
            }
            this.concrete.location = String(this.concrete.location);
            this.concrete.mixDesignNum = String(this.concrete.mixDesignNum);
            this.dataService.save('concrete', this.concrete)
                .subscribe((data) => {
                    this.loggingService.savedMessage();
                });
            }
    }

    cancel() {
        this.router.navigate(['/concrete/search/']);
    }

    showTab(_tab: boolean) {
        this.showTabFlag = _tab;
    }

    openUploadDialog(): void {
        this.showUploadDialog = true;
    }

    closeUploadDialog(): void {
        this.showUploadDialog = false;
    }

    loadCompanyContracts(): void {
        this.dataService.getById('lookups', 'companycontracts')
            .subscribe((data) => {
                this.companyContracts = data;
            });
    }

    loadUnitTypes(): void {
        this.dataService.getById('lookups', 'unittypes')
            .subscribe((data) => {
                this.unitTypes = data;
            });
    }
}
