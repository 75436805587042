<div>
    <div class="container">
        <div class="row">
            <div class="col">
                <h3>Phase Quantity</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4">
                <label class="control-label">Select Week:</label>
                <p-calendar [(ngModel)]="startDate" (onSelect)="onStartDateChange()" [showIcon]="true"></p-calendar>
            </div>
            <div class="col-lg-6">
                <h3>Date Range: {{firstDay | date:"MM/dd/yyyy"}} - {{lastDay | date:"MM/dd/yyyy"}}</h3>
            </div>  
            <div class="col-lg-2">
                <button pButton (click)="save()" label="Save" icon="pi pi-save"></button>
            </div>
        </div>
        <p-table #dt [value]="data" [scrollable]="true" scrollHeight="500px">
            <ng-template pTemplate="header">
                <tr>
                    <th>Phase</th>
                    <th>Code</th>
                    <th>Unit of Measure</th>
                    <th>Hours</th>
                    <th>Quantity</th>
                    <th>Quantity per Hour</th>
                    <th>Status</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr>
                    <td>{{rowData.projectname}}</td>
                    <td>{{rowData.projectCode}}</td>
                    <td>{{rowData.uom}}</td>
                    <td>{{rowData.hours}}</td>
                    <td>
                        <span><input pInput type="number" style="width: 120px;" [(ngModel)]="rowData.quantity" (onChange)="calcQtyperHour(rowData)" /></span>
                    </td>
                    <td><span *ngIf="rowData.quantity!==0 && rowData.quantity !== ''">{{ round(rowData.quantity / rowData.hours, 2) }}</span></td>
                    <td>{{rowData.status}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>