import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { DataService } from '../../services/data.service';
import { DateService } from '../../services/date.service';
import { Subscription } from 'rxjs';
import { NvD3Module } from 'ng2-nvd3';
import { WeatherService } from '../../services/weather.service';
import { BreadCrumbService } from '../../services/breadcrumb.service';
import { LoggingService } from '../../services/logging.service';
import { UserService } from '../../services/user.service';


@Component({
  selector: 'app-daily-report',
  templateUrl: './daily-report.component.html',
  styleUrls: ['./daily-report.component.css']
})
export class DailyReportComponent implements OnInit {

  dt: string;
  subscription: Subscription;
  siteSubscription: Subscription;
  manpowerMatrix: any[];
  delays: any[];
  manpowerChart: any[];
  totalSkilled = 0;
  totalSupervisor = 0;
  totalLaborer = 0;
  totalSafety = 0;
  totalConsultant = 0;
  totalOther = 0;
  overalllTotal = 0;

  optionsTemp: any;
  weatherData: any[];
  tempChartData: any[];
  windChartData: any[];
  rainChartData: any[];

  manpowerChartOptions: any;
  tempChartOptions: any;
  windChartOptions: any;
  rainChartOptions: any;

  constructor(
    private dataService: DataService,
    private dateService: DateService,
    private weatherService: WeatherService,
    private breadcrumbService: BreadCrumbService,
    private loggingService: LoggingService,
    private userService: UserService,
    private router: Router) {
    this.loadManpowerOptions();
    this.loadTempOptions();
    this.loadWindOptions();
    this.loadRainOptions();

    this.subscription = this.dateService.getCurrentDate()
      .subscribe(date => {
        this.dt = this.dateService.formatDate(date);
        this.loadData();
      });

    this.siteSubscription = this.userService.getSiteEvent()
      .subscribe(siteId => {
        this.loadData();
      });
  }

  ngOnInit() {
    this.loggingService.logPageView('Daily Report');
    this.breadcrumbService.setItemsByCategory('Reporting', 'Daily Report', this.router.url);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.siteSubscription.unsubscribe();
  }

  loadData() {
    this.loadDailyReportMatrix();
    this.loadDailyReportChart();
    this.loadWeatherData();
    this.loadDelays();
  }

  loadManpowerOptions(): void {
    this.manpowerChartOptions = {
      view: [1000, 500],
      showXAxis: true,
      showYAxis: true,
      gradient: true,
      showLegend: true,
      showXAxisLabel: true,
      xAxisLabel: 'Hours',
      showYAxisLabel: true,
      yAxisLabel: 'FTE',
      autoScale: true,
      colorScheme: {
        domain: ['#f98504', '#15216a', '#C7B42C', '#AAAAAA']
      }
    };
  }

  loadTempOptions(): void {
    this.tempChartOptions = {
      view: [300, 250],
      showXAxis: true,
      showYAxis: true,
      gradient: true,
      showGridLines: false,
      showLegend: false,
      showXAxisLabel: true,
      xAxisLabel: 'Hours',
      showYAxisLabel: true,
      yAxisLabel: 'Fahrenheit',
      autoScale: true,
      colorScheme: {
        domain: ['#f98504', '#15216a', '#C7B42C', '#AAAAAA']
      }
    };
  }
  L
  loadWindOptions(): void {
    this.windChartOptions = {
      view: [300, 250],
      showXAxis: true,
      showYAxis: true,
      gradient: true,
      showGridLines: false,
      showLegend: false,
      showXAxisLabel: true,
      xAxisLabel: 'Hours',
      showYAxisLabel: true,
      yAxisLabel: 'Wind Speed',
      autoScale: true,
      colorScheme: {
        domain: ['#f98504', '#15216a', '#C7B42C', '#AAAAAA']
      }
    };
  }

  loadRainOptions(): void {
    this.rainChartOptions = {
      view: [300, 250],
      showXAxis: true,
      showYAxis: true,
      gradient: true,
      showGridLines: false,
      showLegend: false,
      showXAxisLabel: true,
      xAxisLabel: 'Hours',
      showYAxisLabel: true,
      yAxisLabel: 'Inches',
      autoScale: true,
      colorScheme: {
        domain: ['#f98504', '#15216a', '#C7B42C', '#AAAAAA']
      }
    };
  }

  loadWeatherData() {
    this.dataService.getById('weather/date', this.dt)
      .subscribe((data) => {
        this.weatherData = data;
        this.tempChartData = this.loadChart('currentTempF');
        this.windChartData = this.loadChart('currentWindSpeed');
        this.rainChartData = this.loadChart('currentRainRate');
      });
  }

  loadDelays(): void {
    this.dataService.getAll('delays/' + this.dt)
      .subscribe((data) => {
        this.delays = data;
      });
  }

  loadChart(field: string): any[] {

    const data = [
      {
        'name': field,
        'series': []
      }
    ];

    for (const entry of this.weatherData) {
      data[0].series.push({
        'name': this.dateService.formatTime(new Date(entry['localTime'])),
        'value': entry[field]
      });
    }

    return data;
  }

  loadDailyReportMatrix() {
    this.clearMatrixSummary();
    this.dataService.getById('dailyreport/matrix', this.dt)
      .subscribe((data) => {
        this.manpowerMatrix = data;
        this.totalMatrixSummary();
      });
  }

  loadDailyReportChart() {
    this.dataService.getById('dailyreport/chart', this.dt)
      .subscribe((data) => {
        this.manpowerChart = data;
      });
  }

  clearMatrixSummary() {
    this.totalSkilled = 0;
    this.totalSupervisor = 0;
    this.totalLaborer = 0;
    this.totalSafety = 0;
    this.totalConsultant = 0;
    this.totalOther = 0;
    this.overalllTotal = 0;
  }

  totalMatrixSummary() {
    this.clearMatrixSummary();
    this.manpowerMatrix.forEach(manpower => {
      this.totalSkilled += manpower.skilled;
      this.totalSupervisor += manpower.supervisor;
      this.totalLaborer += manpower.laborer;
      this.totalSafety += manpower.safety;
      this.totalConsultant += manpower.consultant;
      this.totalOther += manpower.other;
      this.overalllTotal += manpower.total;
    });
  }

  exportToCSV(): void {
    let csv = 'Name,Skilled,Supervisor,Laborer,Safety,Consultant,Other,Total';
    csv = csv + '\n';


    for (const row of this.manpowerMatrix) {
      let csvRow = '"' + row.companyName;
      csvRow += '",';
      if (row.skilled)
        csvRow += row.skilled;
      csvRow += ',';
      if (row.supervisor)
        csvRow += row.supervisor;
      csvRow += ',';
      if (row.laborer)
        csvRow += row.laborer;
      csvRow += ',';
      if (row.safety)
        csvRow += row.safety;
      csvRow += ',';
      if (row.consultant)
        csvRow += row.consultant;
      csvRow += ',';
      if (row.other)
        csvRow += row.other;
      csvRow += ',';
      csvRow += row.total;

      csv += csvRow + '\n';
    }

    const blob = new Blob(['\uFEFF', csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.setAttribute('href', window.URL.createObjectURL(blob));
    link.setAttribute('download', 'DailyReport.csv');
    document.body.appendChild(link); // Required for FF
    link.click();
  }

}
