<div class="layout-topbar">
    <img alt="logo" src="../assets/images/sitetraxx-icon.png" class="mobile-logo" />

    <a href="#" class="menu-btn" (click)="app.onMenuButtonClick($event)">
        <i class="material-icons">&#xE5D2;</i>
    </a>

    <a href="#" class="topbar-menu-btn" (click)="app.onTopbarMobileMenuButtonClick($event)">
        <i class="material-icons">&#xE853;</i>
    </a>

    <div class="layout-topbar-menu-wrapper">
        <ul class="topbar-menu fadeInDown" [ngClass]="{'topbar-menu-active': app.topbarMenuActive}" (click)="app.onTopbarMenuClick($event)">
            <li #profile class="profile-item" [ngClass]="{'active-topmenuitem': app.activeTopbarItem === profile}" (click)="app.onTopbarRootItemClick($event, profile)">
                <a href="#">
                    <span class="profile-image-wrapper">
                        <img class="img-circle" src="../assets/images/user_profile.jpg"/>
                    </span>
                    <span class="topbar-item-name profile-name">{{user.name}} - {{user.companyName}}</span>
                </a>
            </li>
            <li #logout [ngClass]="{'active-topmenuitem':app.activeTopbarItem === logout}" (click)="app.onTopbarRootItemClick($event, logout)">
                <a href="" (click)="app.logout()">
                    <i class="topbar-icon material-icons">power_settings_new</i>
                    <span class="topbar-item-name">Logout</span>
                </a>
            </li>
            <li class="profile-site-logo">
                <img [src]="siteLogo" *ngIf="siteLogo" />
            </li>
            <li class="profile-site">
                {{user.siteName}}
            </li>
            <li *ngIf="users.length>1">
                <select [ngModel]="currentUserId" (ngModelChange)="onChange($event)" name="sel2">
                    <option *ngFor="let u of users" [value]="u.id" [selected]="user.id==u.id">{{u.siteName}}</option>
                </select>
            </li>
        </ul>
    </div>
</div>