import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { BreadCrumbService } from '../services/breadcrumb.service';
import { LoggingService } from '../services/logging.service';
import { Subscription } from 'rxjs';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-terminal',
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.css']
})
export class TerminalComponent implements OnInit {

  pageInfo: any;
  path: string;
  sub: any;
  id: string;
  terminal: any;

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private breadcrumbService: BreadCrumbService,
    private loggingService: LoggingService,
    private userService: UserService,
    private router: Router) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];

      if (this.id === undefined) {
        this.loadData();
      } else {
          if (this.id === 'new') {
            this.loggingService.logPageView('Terminal - New');
              this.terminal = {};
              this.terminal.id = 'new';
          } else {
            this.loggingService.logPageView('Terminal - Profile');
              this.dataService.getById('terminals/detail', this.id)
              .subscribe((data) => {
                  this.terminal = data;
              });
          }
      }
   });
  }

  loadData() {
    this.loggingService.logPageView('Terminals - Grid');
    this.terminal = null;
    this.dataService.getById('pages', 'terminals')
    .subscribe((data) => {
        this.pageInfo = data.result;
        this.breadcrumbService.setItemsByCategory('Sitetraxx Admin', this.pageInfo.title, this.router.url);
      });
  }
}
