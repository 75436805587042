import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

import { UserNameService } from '../../services/user-name.service';
import { LoggingService } from '../../services/logging.service';
import { DataService } from '../../services/data.service';
import { DateService } from '../../services/date.service';
import { filter } from 'rxjs/operators';


@Component({
    selector: 'st-company-matrix',
    templateUrl: './companymatrix.component.html',
    encapsulation: ViewEncapsulation.None
})
export class CompanyMatrixComponent implements OnInit {
    @Input() filter: any;

    dt: string;
    manpowerMatrix: any[];
    totalSkilled = 0;
    totalSupervisor = 0;
    totalLaborer = 0;
    totalSafety = 0;
    totalConsultant = 0;
    totalOther = 0;
    overalllTotal = 0;

    constructor(private userNameService: UserNameService,
        private dateService: DateService,
        private dataService: DataService,
        private loggingService: LoggingService) { }

    ngOnInit(): void {
        this.loadDailyReportMatrix();
    }

    loadDailyReportMatrix() {
        this.clearMatrixSummary();
        this.dataService.getById('dailyreport/matrix', this.filter.dt + '/' + this.filter.userId)
            .subscribe((data) => {
                this.manpowerMatrix = data;
                this.totalMatrixSummary();
            });
    }

    clearMatrixSummary() {
        this.totalSkilled = 0;
        this.totalSupervisor = 0;
        this.totalLaborer = 0;
        this.totalSafety = 0;
        this.totalConsultant = 0;
        this.totalOther = 0;
        this.overalllTotal = 0;
    }

    totalMatrixSummary() {
        this.manpowerMatrix.forEach(manpower => {
            this.totalSkilled += manpower.skilled;
            this.totalSupervisor += manpower.supervisor;
            this.totalLaborer += manpower.laborer;
            this.totalSafety += manpower.safety;
            this.totalConsultant += manpower.consultant;
            this.totalOther += manpower.other;
            this.overalllTotal += manpower.total;
        });
    }
}
