<div class="panel-body" id="emergencyPanel">
    <div class="row">
        <div class="col-lg-6">
            <div class="row">
                <div class="col">
                    <h4>Emergency Contact Information</h4>
                    <div class="row">
                        <div class="col-lg-12">
                            <label class="control-label">Name:</label>
                            <input type="text" class="form-control" *ngIf="badgeHolder" pInputText name="contact"
                                [(ngModel)]="badgeHolder.emergencyContact" maxlength="50" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <label class="control-label">Relationship:</label>
                            <select class="form-control" name="relationship"
                                [(ngModel)]="badgeHolder.emergencyRelationship">
                                <option value="S">Spouse</option>
                                <option value="F">Family</option>
                                <option value="O">Other</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <label class="control-label">Phone Number:</label>
                            <p-inputMask mask="(999)999-9999" [(ngModel)]="badgeHolder.emergencyPhone"
                                name="emergencyPhone" placeholder="(999)999-9999" *ngIf="badgeHolder"></p-inputMask>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            COVID 19 Vaccinated:
                            <p-radioButton name="isVaccinated" value="1" [(ngModel)]="badgeHolder.isVaccinated" label="Yes">
                            </p-radioButton>
                            <p-radioButton name="isVaccinated" value="0" [(ngModel)]="badgeHolder.isVaccinated" label="No">
                            </p-radioButton>        
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <label class="control-label">Last Dose Date:</label>
                            <p-calendar [disabled]="badgeHolder.isVaccinated===0" [(ngModel)]="lastDoseDate" (onSelect)="onLastDoseDateChange()" (onInput)="onLastDoseDateChange()" [showIcon]="true"></p-calendar>
                        </div>
                    </div>  
                    <div class="row">
                        <div class="col-lg-12">
                            Vaccine Card Verified:
                            <p-radioButton name="vaccineCardVerified" value="1" [(ngModel)]="badgeHolder.vaccineCardVerified" label="Yes">
                            </p-radioButton>
                            <p-radioButton name="vaccineCardVerified" value="0" [(ngModel)]="badgeHolder.vaccineCardVerified" label="No">
                            </p-radioButton>        
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <label>Photo</label><br />
                            <button [disabled]="badgeHolder.isVaccinated===0" pButton icon="pi pi-plus" [disabled]="badgeHolder.id===undefined" (click)="openUploadDialog()" label="Add Photo" class="indigo-btn"></button>
                            <app-photo-list [disabled]="badgeHolder.isVaccinated===0" [objectId]="badgeHolder.id" [(refresh)]="refresh" [category]="category" *ngIf="badgeHolder.id !== undefined"></app-photo-list>
                        </div>
                    </div>        
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="row">
                <div class="col">
                    <div class="row">
                        <div class="col-lg-12">
                            <h4>Medical History Information</h4>
                            <label class="control-label">Medical Conditions:</label>
                            <input type="text" class="form-control" pInputText name="medicalConditions"
                                [(ngModel)]="badgeHolder.medicalConditions" maxlength="50" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <label class="control-label">Known Allergies:</label>
                            <input type="text" class="form-control" pInputText name="allergies"
                                [(ngModel)]="badgeHolder.allergies" maxlength="50" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <label class="control-label">Current Medications:</label>
                            <input type="text" class="form-control" pInputText name="medications"
                                [(ngModel)]="badgeHolder.medications" maxlength="100" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <label class="control-label">Height:</label>
                            <input type="text" class="form-control" pInputText name="height"
                                [(ngModel)]="badgeHolder.height" maxlength="20" />
                        </div>
                        <div class="col-lg-6">
                            <label class="control-label">Weight:</label>
                            <input type="text" class="form-control" pInputText name="weight"
                                [(ngModel)]="badgeHolder.weight" maxlength="20" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <label class="control-label">Notes:</label>
                            <textarea class="form-control" pInputText name="medicalNotes"
                                [(ngModel)]="badgeHolder.medicalNotes" maxlength="100"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-upload-photo [(showDialog)]="showUploadDialog" (showDialogChange)="showDialogChangedHandler($event)" [category]="category" [objectId]="badgeHolder.id" *ngIf="badgeHolder.id !== undefined"></app-upload-photo>