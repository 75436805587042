<div class="ui-g dashboard">
    <div class="ui-g-12 ui-lg-4">
        <div class="task-box task-box-3">
            <div class="task-box-header">
                <span style="color: white">Current People On-Site</span>
<!--                <i (click)="loadOnsiteCount()" class="material-icons">refresh</i> -->
            </div>
            <div class="task-box-content" style="text-align: center">
                <h1><a href="/onsite">{{onSiteCount.total}}</a></h1>
                <p></p>
            </div>
            <div class="task-box-footer">
                <span class="task-status">{{onSiteCount.visitors}}</span> Visitors
                <span class="task-status">{{onSiteCount.inspectors}}</span> Inspectors
            </div>
        </div>
    </div>
    <div class="ui-g-12 ui-lg-4">
        <div class="task-box task-box-3">
            <div class="task-box-header">
                <span style="color: white">Companies On-Site During the Day</span>
<!--                <i (click)="loadOnsiteCompanyCount()" class="material-icons">refresh</i> -->
            </div>
            <div class="task-box-content" style="text-align: center">
                <h1>{{companyTotal}}</h1>
                <p></p>
            </div>
            <div class="task-box-footer">
                <span> </span>
            </div>
        </div>
    </div>
    <div class="ui-g-12 ui-lg-4" *ngIf="isToday===true">
        <div class="task-box task-box-3">
            <div class="task-box-header">
                <span style="color: white">Weather</span>
<!--                <i (click)="loadWeather()" class="material-icons">refresh</i> -->
            </div>
            <div class="task-box-content" style="text-align: center">
                <h1>{{weather?.temperatureString}}</h1>
                <p>{{weather?.weatherString}} <img [src]="weatherIcon" /></p>
            </div>
            <div class="task-box-footer">
                <span class="task-status">{{weather?.currentWindSpeed}}</span> Mph
                <span class="task-status">{{weather?.currentRainRate}}</span> Inches
            </div>
        </div>
    </div>
    <div class="ui-g-12 ui-lg-4" *ngIf="isToday===false">
        <div class="task-box task-box-3">
            <div class="task-box-header">
                <span style="color: white">Weather</span>
<!--                <i (click)="loadWeather()" class="material-icons">refresh</i> -->
            </div>
            <div class="task-box-content" style="text-align: center">
                <h1>{{tempF}}</h1>
                <p><img [src]="minIcon" /> / <img [src]="maxIcon" /></p>
            </div>
            <div class="task-box-footer">
                <span class="task-status">{{windSpeed}}</span> Mph
                <span class="task-status">{{rainRate}}</span> Inches
            </div>
        </div>
    </div>

    <div class="ui-g-12 ui-md-6 ui-lg-4">
        <app-hauloff-count [dateFilter]="dt"></app-hauloff-count>
    </div>
    <div class="ui-g-12 ui-md-6 ui-lg-4">
        <app-buildingmaterial-count [dateFilter]="dt"></app-buildingmaterial-count>
    </div>
    <div class="ui-g-12 ui-md-6 ui-lg-4">
        <app-concrete-count [dateFilter]="dt"></app-concrete-count>
    </div>

    <div class="ui-g-12 ui-md-3" *ngFor="let terminal of terminalCount">
        <div class="overview-box overview-box-2">
            <div class="overview-box-header"></div>
            <div class="overview-box-content">
<!--                <div class="overview-box-icon"><i class="material-icons">computer</i></div> -->
                <div class="overview-box-icon"><i class="pi pi-computer"></i></div>
                <div class="overview-box-title">{{terminal.name}}</div>
                <div class="overview-box-count">Active</div>
            </div>
        </div>
    </div>

    <div class="ui-g-12 ui-md-6 ui-lg-4" *ngIf="safetyCount.incidents != 0">
        <p-panel header="Incidents">
            <h1 style="text-align: center">{{safetyCount.incidents}}</h1>
        </p-panel>
    </div>

    <div class="ui-g-12 ui-md-6 ui-lg-4" *ngIf="safetyCount.violations != 0">
        <p-panel header="Safety Violations">
            <h1 style="text-align: center">{{safetyCount.violations}}</h1>
        </p-panel>
    </div>

    <div class="ui-g-12 ui-md-6 ui-lg-4" *ngIf="safetyCount.rewards != 0">
        <p-panel header="Safety Rewards">
            <h1 style="text-align: center">{{safetyCount.rewards}}</h1>
        </p-panel>
    </div>
</div>