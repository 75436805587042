<form #thisForm='ngForm'>
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <h3>{{concrete?.name}}</h3>
            </div>
            <div class="col-lg-6">
                <div class="float-right">
                    <button pButton (click)="save()" [disabled]="!thisForm.valid" icon="pi pi-save" label="Save"></button>
                    <button pButton (click)="cancel()" icon="pi pi-ban" label="Cancel"></button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isReady" class="container">
                <div class="row">
                    <div class="col-8">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Description/Pour Area</label>
                                    <input pInputText name="name" [(ngModel)]="concrete.name" class="form-control" type="text" placeholder="Enter brief description" maxlength="100" required />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-12">
                                <label>Enter Date</label><br />
                                <p-calendar name="enterTime" [(ngModel)]="concrete.enterTime" [showIcon]="true" (onInput)="updatePouredOutTime()" (onSelect)="updatePouredOutTime()"></p-calendar>
                            </div>
                            <div class="col-lg-6 col-12">
                                <label>Exit Date</label><br />
                                <p-calendar name="exitTime" [(ngModel)]="concrete.enterTime" [showIcon]="true"></p-calendar>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <div class="form-group">
                                    <label>Concrete Provider</label>
                                    <input pInputText name="provider" [(ngModel)]="concrete.provider" maxlength="50" type="text" class="form-control" placeholder="Company" required />
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <div class="form-group">
                                    <label>Purchasing Company</label><br/>
                                    <p-dropdown name="companyContracts" [options]="companyContracts" [filter]="true" placeholder="Select a Company/Contract" required [(ngModel)]="concrete.contractId"></p-dropdown>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <div class="form-group">
                                    <label>Batch Ticket #</label>
                                    <input pInputText name="ticketNum" [(ngModel)]="concrete.ticketNum" class="form-control" type="text" placeholder="####" required />
                                </div>
                            </div>
                            <div class="col-lg-6 col-12">
                                <div class="form-group">
                                    <label>Truck #</label>
                                    <input pInputText name="truckNum" [(ngModel)]="concrete.truckNum" maxlength="50" class="form-control" type="text" required />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <div class="form-group">
                                    <label>Total Yards</label>
                                    <input pInputText name="quantity" [(ngModel)]="concrete.quantity" class="form-control" type="number" required />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <div class="form-group">
                                    <label>Added Water</label>
                                    <input pInputText name="addedWater" [(ngModel)]="concrete.addedWater" class="form-control" type="number" />
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <div class="form-group">
                                    <label>Mix Design #</label><br />
                                    <input pInputText name="mixDesignNum" [(ngModel)]="concrete.mixDesignNum" class="form-control" type="number" required />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <div class="form-group">
                                    <label>Poured Out Time</label>
                                    <p-calendar name="pouredOutTime" [showTime]="true" [timeOnly]="true" [(ngModel)]="concrete.pouredOutTime" [showIcon]="true" (onFocus)="focusOnPouredOutTime()" (onInput)="updatePouredOutTime()" (onSelect)="updatePouredOutTime()"></p-calendar>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <div class="form-group">
                                    <label>Gate</label><br />
                                    <input pInputText name="gate" [(ngModel)]="concrete.location" class="form-control" type="number" required />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="row">
                <div class="col-12">
                    <label>Photo</label><br />
                    <button pButton icon="pi pi-plus" [disabled]="concrete.id===undefined" (click)="openUploadDialog()" label="Add Photo" class="indigo-btn"></button>
                    <app-photo-list [objectId]="concrete.id" [(refresh)]="refresh" [category]="category" *ngIf="concrete.id !== undefined"></app-photo-list>
                </div>
            </div>
        </div>
    </div>
        <st-update-block [object]='concrete' *ngIf="concrete"></st-update-block>
    </div>
</form>



<app-upload-photo [(showDialog)]="showUploadDialog" (showDialogChange)="showDialogChangedHandler($event)" [category]="category" [objectId]="concrete.id" *ngIf="concrete.id !== undefined"></app-upload-photo>