import { Component, Input, OnInit, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import {SelectItem} from 'primeng/api';

import { DataService } from '../../services/data.service';
import { LoggingService } from '../../services/logging.service';
import { MessageService } from 'primeng/api';
import { environment } from '../../../../environments/environment';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-badgeholder-search',
    templateUrl: './badgeholdersearch.component.html',
    encapsulation: ViewEncapsulation.None
})
export class BadgeHolderSearchComponent implements OnInit {
  showDialogValue: boolean;

  @Output()
  showDialogChange = new EventEmitter<boolean>();

  @Output()
  badgeHolderChange = new EventEmitter<any>();

  @Input() category: string;

  @Input()
  get showDialog() {
    return this.showDialogValue;
  }

  @Input() objectId: string;

  set showDialog(val) {
    this.showDialogValue = val;
    this.showDialogChange.emit(this.showDialogValue);
  }

    env: any = environment;
    user: any;
    searchTerm: string;
    badgeHolders: any[];

    constructor(
        private dataService: DataService,
        private messageService: MessageService,
        private loggingService: LoggingService,
        private router: Router,
        private userService: UserService) { }

    ngOnInit(): void {
        this.searchTerm = '';
        this.badgeHolders = [];
        this.user = this.userService.currentUser();
    }

    searchBadgeHolders(): void {
        this.badgeHolders = [];
        this.dataService.getById('badgeholders/search', this.searchTerm)
            .subscribe((data) => {
                this.badgeHolders = data;
            });
    }

    selectedBadgeHolder(badgeHolder: any): void {
        this.badgeHolderChange.emit(badgeHolder);
        this.showDialog = false;
    }

    closeDialog(): void {
        this.showDialog = false;
    }
}
