import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../../services/data.service';

@Component({
    selector: 'st-card',
    templateUrl: './card.component.html'
})
export class STCardComponent implements OnInit {
    @Input() data: any;

    dt: string;

    constructor() {
    }

    ngOnInit(): void {

    }
}
