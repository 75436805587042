<div class="global-admin-container">
    <div class="row">
        <div class="col-lg-12">
            <label class="control-label">Site:</label>
            <p-dropdown [options]="sites" [filter]="true" [(ngModel)]="siteId" (onChange)="loadData()"></p-dropdown>
            <label class="control-label">Date Range:</label>
            <p-calendar [(ngModel)]="fromDate" [showIcon]="true" (onSelect)="loadData()"></p-calendar>
            <label class="control-label">-</label>
            <p-calendar [(ngModel)]="toDate" [showIcon]="true" (onSelect)="loadData()"></p-calendar>
            <button *ngIf="clocks" type="button" pButton title="Export" icon="pi pi-cloud-download" class="indigo-btn float-right" (click)="exportToCSV()"></button>
        </div>
    </div>
    <div class="row" *ngIf="siteId && fromDate && toDate && (fromDate <= toDate)">
        <div class="col-lg-12">
            <p-table #dt [columns]="cols" [value]="clocks" [scrollable]="true" scrollHeight="500px" sortField="clockTime" sortOrder="1">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th [pSortableColumn]="col.field" *ngFor="let col of columns" class="p-table-column-{{col.field}}">
                            {{col.header}}<p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                    </tr>
                    <tr>
                        <th *ngFor="let col of columns" class="p-table-column-{{col.field}}" [ngStyle]="{ 'min-width': col.width, 'max-width': col.width }" [ngSwitch]="col.field">
                            <input pInputText type="text" (input)="dt.filter($event.target.value, col.field, 'contains')">
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr>
                        <td [ngStyle]="style(rowData.terminalNumber, rowData.terminalNumberRaw)">
                            {{rowData.terminalNumber}}</td>
                        <td [ngStyle]="style(rowData.terminalNumber, rowData.terminalNumberRaw)">
                            {{rowData.terminalNumberRaw}}</td>
                        <td [ngStyle]="style(rowData.badgeNumber, rowData.badgeNumberRaw)">{{rowData.badgeNumber}}</td>
                        <td [ngStyle]="style(rowData.badgeNumber, rowData.badgeNumberRaw)">{{rowData.badgeNumberRaw}}
                        </td>
                        <td [ngStyle]="style(rowData.name, rowData.nameRaw)">{{rowData.name}}</td>
                        <td [ngStyle]="style(rowData.name, rowData.nameRaw)">{{rowData.nameRaw}}</td>
                        <td [ngStyle]="style(rowData.action, rowData.actionRaw)">{{rowData.action}}</td>
                        <td [ngStyle]="style(rowData.action, rowData.actionRaw)">{{rowData.actionRaw}}</td>
                        <td [ngStyle]="styleDateTime(rowData.clockTime, rowData.clockTimeRaw)">
                            {{rowData.clockTime | date:'MM/dd/yyyy HH:mm a'}}</td>
                        <td [ngStyle]="styleDateTime(rowData.clockTime, rowData.clockTimeRaw)">
                            {{rowData.clockTimeRaw | date:'MM/dd/yyyy HH:mm a'}}</td>
                        <td [ngStyle]="styleDateTime(rowData.createdDate, rowData.createdDateRaw)">
                            {{rowData.createdDate | date:'MM/dd/yyyy HH:mm a'}}</td>
                        <td [ngStyle]="styleDateTime(rowData.createdDate, rowData.createdDateRaw)">
                            {{rowData.createdDateRaw | date:'MM/dd/yyyy HH:mm a'}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>