
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable ,  Subject } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AuthUser } from '../models/authUser';

@Injectable()
export class AuthService {
    env: any = environment;
    private subject = new Subject<any>();

    constructor(private http: HttpClient) { }

    login2(userInfo: any) {
        return this.http.post<AuthUser>(this.env.baseAPIUrl + 'users/authenticate',
            userInfo,
            { headers: new HttpHeaders().set('Content-Type', 'application/json')}).pipe(
            map(user => {
                if (user && user.token) {
                    // this.subject.next(user);
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    localStorage.removeItem('currentDate');
                }
            }));
    }

    login(userInfo: any) {
        const _url = this.env.baseAPIUrl + 'users/authenticate';
        return this.http.post<any>(_url, userInfo);
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('currentDate');
    }

    getUser(): Observable<any> {
        return this.subject.asObservable();
    }

    public getToken(): string {
        return localStorage.getItem('token');
    }

    public isAuthenticated(): boolean {
        const token = this.getToken();
        return true;
      }
}
