<form #thisForm='ngForm' *ngIf="hauloff && isReady">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <h3>{{hauloff?.name}}</h3>
            </div>
            <div class="col-lg-6">
                <div class="float-right">
                    <button pButton (click)="save()" [disabled]="!thisForm.valid" icon="pi pi-save"
                        label="Save"></button>
                    <button pButton (click)="cancel()" icon="pi pi-ban" label="Cancel"></button>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-8">
                <div class="row">
                    <div class="col-6">
                        <label>Company / Contract</label><br />
                        <p-dropdown name="companyContracts" [filter]="true" [options]="companyContracts"
                            placeholder="Select a Company/Contract" [(ngModel)]="hauloff.contractId"></p-dropdown>
                    </div>
                    <div class="col-6">
                        <label>Enter Date</label><br />
                        <p-calendar [(ngModel)]="hauloff.enterTime" name="enterTime" [showIcon]="true"></p-calendar>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 form-group">
                        <label>Truck #</label>
                        <input pInputText [(ngModel)]="hauloff.truckNumber" name="truckNumber" maxlength="50"
                            type="text" placeholder="11" class="form-control" required />
                    </div>
                    <div class="col-6 form-group">
                        <label>Ticket #</label>
                        <input pInputText [(ngModel)]="hauloff.ticketNumber" name="ticketNumber" maxlength="50"
                            type="text" placeholder="42" class="form-control" required />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 form-group">
                        <label>Transportation Company</label>
                        <input pInputText [(ngModel)]="hauloff.transportCompany" name="transportCompany" maxlength="36"
                            type="text" placeholder="Hanks Trucking" class="form-control" required />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 form-group">
                        <label>Description</label>
                        <textarea pInputTextArea [(ngModel)]="hauloff.name" name="name" maxlength="50"
                            placeholder="Enter brief description" class="form-control" required></textarea>
                    </div>
                </div>
                <div *ngIf="!isNew">
                    <div class="row">
                        <div class="col-12">
                            <label>Exit Date</label><br />
                            <p-calendar [(ngModel)]="hauloff.exitTime" name="exitTime" [showIcon]="true"></p-calendar>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 form-group">
                            <label>Category</label><br />
                            <p-dropdown [options]="materialCategories" [filter]="true" (onChange)="loadMaterialTypes()"
                                placeholder="Select a Category" [(ngModel)]="materialCategory" name="materialCategory">
                            </p-dropdown>
                        </div>
                        <div class="col-6 form-group">
                            <label>Material Type</label><br />
                            <p-dropdown [options]="materialTypes" [filter]="true" placeholder="Select a Material Type"
                                [(ngModel)]="hauloff.materialType" name="materialType"></p-dropdown>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 form-group">
                            <label>Quantity</label>
                            <input pInputText [(ngModel)]="hauloff.quantity" name="quantity" placeholder="200"
                                class="form-control" required />
                        </div>
                        <div class="col-6 form-group">
                            <label>Unit</label><br />
                            <p-dropdown [options]="unitTypes" [filter]="true" placeholder="Select an Unit Type"
                                [(ngModel)]="hauloff.unitType" name="unitType"></p-dropdown>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="row">
                    <div class="col-12">
                        <label>Photo</label><br />
                        <button pButton icon="pi pi-plus" [disabled]="hauloff.id===undefined"
                            (click)="openUploadDialog()" label="Add Photo" class="indigo-btn"></button>
                        <app-photo-list [objectId]="hauloff.id" [(refresh)]="refresh" [category]="category"
                            *ngIf="hauloff.id !== undefined"></app-photo-list>
                    </div>
                </div>
            </div>
        </div>
        <st-update-block [object]='hauloff' *ngIf="hauloff"></st-update-block>
    </div>
</form>



<p-confirmDialog header='Delete?' icon='pi pi-trash'></p-confirmDialog>

<app-upload-photo [(showDialog)]="showUploadDialog" (showDialogChange)="showDialogChangedHandler($event)"
    [category]="category" [objectId]="hauloff.id" *ngIf="hauloff.id !== undefined"></app-upload-photo>