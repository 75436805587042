<p-dialog header="Search" [(visible)]="showDialog" modal="modal" [responsive]="true" [style]="{ width: '500px', height: '460px' }">
    <div class="col-md-8">
        <label>Search:</label>
        <input pInputText type="text" [(ngModel)]="searchTerm" maxlength="50">    
    </div>
    <div class="col-md-4">
        <button pButton (click)="searchBadgeHolders()" icon="pi pi-search" label="Search"></button>
    </div>
    <div class="col-md-12">
        <table class="table sitetraxx">
            <tr>
                <th></th>
                <th>Name</th>
                <th>Company</th>
                <th>Labor Class</th>
            </tr>
            <tr *ngFor="let badgeHolder of badgeHolders">
                <td style="width: 5%"><button (click)="selectedBadgeHolder(badgeHolder)" type="button" pButton icon="pi pi-search"></button></td>
                <td>{{badgeHolder.name}}</td>
                <td>{{badgeHolder.companyName}}</td>
                <td>{{badgeHolder.laborClassDesc}}</td>
            </tr>
        </table>    
    </div>
    <p-footer>
        <button type="button" pButton icon="pi pi-times" (click)="closeDialog()" label="Close"></button>
    </p-footer>
</p-dialog>