import { Component, Input, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-badgeholder-emergency',
    templateUrl: './badgeholder.emergency.component.html',
    encapsulation: ViewEncapsulation.None
})
export class BadgeHolderEmergencyComponent implements OnInit {
@Input() badgeHolder: any;

lastDoseDate: Date;
showUploadDialog: boolean;
category = 'covid';
refresh = new Date();

    constructor() { }

    ngOnInit(): void { 
        this.badgeHolder.isVaccinated = (this.badgeHolder.isVaccinated === 1) ? '1' : '0';
        this.badgeHolder.vaccineCardVerified = (this.badgeHolder.vaccineCardVerified === 1) ? '1' : '0';
        if (this.badgeHolder.lastDoseDate != undefined) {
            this.lastDoseDate = new Date(this.badgeHolder.lastDoseDate);
        }
    }

    onLastDoseDateChange() {
        this.badgeHolder.lastDoseDate = new Date(this.lastDoseDate);
    }

    showDialogChangedHandler(showDialog: boolean): void {
        this.refresh = new Date();
    }

    openUploadDialog(): void {
        this.showUploadDialog = true;
    }

    closeUploadDialog(): void {
        this.showUploadDialog = false;
    }
}