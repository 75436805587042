import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { environment } from '../../../../environments/environment';
import { DataService } from '../../services/data.service';
import { Subscription } from 'rxjs';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-site-badges',
    templateUrl: './site.badges.component.html',
    encapsulation: ViewEncapsulation.None
})
export class SiteBadgesComponent implements OnInit {

    env: any = environment;

    @Input() site: any;

siteLogo: string;
gcLogo: string;
badgedown: string;

uploadImageUrl: string;
uploadedFiles = [];
displayUploadDialog = false;
siteSubscription: Subscription;


    constructor(private dataService: DataService,
                private messageService: MessageService,
                private userService: UserService) {
        this.siteSubscription = this.userService.getSiteEvent()
            .subscribe(siteId => {
                this.site.id = siteId;
                this.updateFileNames();
            });
    }

    ngOnInit() {
        this.updateFileNames();
    }

    ngOnDestroy() {
        this.siteSubscription.unsubscribe();
    }

    updateFileNames() {
        this.siteLogo = `https://tcnstphotos.blob.core.windows.net/site/site_${this.site.id}/sitelogo.png?t=${(new Date()).getTime()}`;
        this.gcLogo = `https://tcnstphotos.blob.core.windows.net/site/site_${this.site.id}/gclogo.jpg?t=${(new Date()).getTime()}`;
        this.badgedown = `https://tcnstphotos.blob.core.windows.net/site/site_${this.site.id}/badgedown.png?t=${(new Date()).getTime()}`;
    }
    showUploadDialog(logoType: string) {
        this.uploadedFiles = [];
        this.displayUploadDialog = true;
        this.uploadImageUrl = this.env.baseAPIUrl
        + 'images/upload/logo/'
        + this.site.id
        + '/'
        + logoType;
    }
    onUpload(event) {
        for (const file of event.files) {
            this.uploadedFiles.push(file);
        }

        this.messageService.clear();
            this.messageService.add({severity: 'info', summary: 'File Uploaded', detail: ''});
    }
    closeUploadDialog() {
        this.displayUploadDialog = false;
        this.updateFileNames();
    }

    showBadge(): void {
        window.open(this.env.baseAPIUrl + 'pdf/sample/' + this.site.id);
    }
}
