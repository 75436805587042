<div id="printArea">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="col-lg-12 sitetraxx">
                    <h3>Overview</h3>
                </div>
                <div class="col-lg-12">
                    <ul>
                    </ul>
                </div>
                <div class="col-lg-12 sitetraxx">
                    <h4>Delays</h4>
                </div>
                <div class="col-lg-12">
                    <table class="table table-striped sitetraxx">
                        <thead>
                            <tr class="sitetraxx">
                                <th>Cause</th>
                                <th>Type</th>
                                <th>Delay</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tr *ngFor="let delay of delays">
                            <td>{{ delay.cause }}</td>
                            <td>{{ delay.delayType }}</td>
                            <td>{{ delay.delayStart | date:"MM/dd/yyyy" }}</td>
                            <td>{{ delay.description }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <table class="table table-striped sitetraxx">
                    <thead>
                        <tr class="sitetraxx">
                            <th>Name</th>
                            <th>Skilled</th>
                            <th>Supervisor</th>
                            <th>Laborer</th>
                            <th>Safety</th>
                            <th>Consultant</th>
                            <th>Other</th>
                            <th>
                                <button type="button" pButton title="Export" icon="pi pi-cloud-download" class="indigo-btn float-right" (click)="exportToCSV()"></button>
                                <br clear="all"/>
                                Total
                            </th>
                        </tr>
                    </thead>
                    <tr *ngFor="let man of manpowerMatrix">
                        <td>{{man.companyName}}</td>
                        <td>{{man.skilled}}</td>
                        <td>{{man.supervisor}}</td>
                        <td>{{man.laborer}}</td>
                        <td>{{man.safety}}</td>
                        <td>{{man.consultant}}</td>
                        <td>{{man.other}}</td>
                        <td><b>{{man.total}}</b></td>
                    </tr>
                    <tr>
                        <td><b>Total:</b></td>
                        <td><b>{{totalSkilled}}</b></td>
                        <td><b>{{totalSupervisor}}</b></td>
                        <td><b>{{totalLaborer}}</b></td>
                        <td><b>{{totalSafety}}</b></td>
                        <td><b>{{totalConsultant}}</b></td>
                        <td><b>{{totalOther}}</b></td>
                        <td><b>{{overalllTotal}}</b></td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="panel">
                    <div class="panel-heading sitetraxx">
                        <h4>Manpower</h4>
                    </div>
                    <div class="panel-body">
                        <ngx-charts-area-chart-stacked [view]="manpowerChartOptions?.view" [scheme]="manpowerChartOptions?.colorScheme" [results]="manpowerChart" [gradient]="manpowerChartOptions?.gradient" [xAxis]="manpowerChartOptions?.showXAxis" [yAxis]="manpowerChartOptions?.showYAxis"
                            [legend]="manpowerChartOptions?.showLegend" [showXAxisLabel]="manpowerChartOptions?.showXAxisLabel" [showYAxisLabel]="manpowerChartOptions?.showYAxisLabel" [xAxisLabel]="manpowerChartOptions?.xAxisLabel" [yAxisLabel]="manpowerChartOptions?.yAxisLabel">
                        </ngx-charts-area-chart-stacked>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4">
                <div class="panel">
                    <div class="panel-heading sitetraxx">
                        <h4>Temperature</h4>
                    </div>
                    <div class="panel-body">
                        <ngx-charts-area-chart [view]="tempChartOptions?.view" [scheme]="tempChartOptions?.colorScheme" [results]="tempChartData" [gradient]="tempChartOptions?.gradient" [xAxis]="tempChartOptions?.showXAxis" [yAxis]="tempChartOptions?.showYAxis" [legend]="tempChartOptions?.showLegend"
                            [showGridLines]="tempChartOptions?.showGridLines" [showXAxisLabel]="tempChartOptions?.showXAxisLabel" [showYAxisLabel]="tempChartOptions?.showYAxisLabel" [xAxisLabel]="tempChartOptions?.xAxisLabel" [yAxisLabel]="tempChartOptions?.yAxisLabel"
                            [autoScale]="tempChartOptions?.autoScale">
                        </ngx-charts-area-chart>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="panel">
                    <div class="panel-heading sitetraxx">
                        <h4>Wind</h4>
                    </div>
                    <div class="panel-body">
                        <ngx-charts-area-chart [view]="windChartOptions?.view" [scheme]="windChartOptions?.colorScheme" [results]="windChartData" [gradient]="windChartOptions?.gradient" [xAxis]="windChartOptions?.showXAxis" [yAxis]="windChartOptions?.showYAxis" [legend]="windChartOptions?.showLegend"
                            [showGridLines]="windChartOptions?.showGridLines" [showXAxisLabel]="windChartOptions?.showXAxisLabel" [showYAxisLabel]="windChartOptions?.showYAxisLabel" [xAxisLabel]="windChartOptions?.xAxisLabel" [yAxisLabel]="windChartOptions?.yAxisLabel"
                            [autoScale]="windChartOptions?.autoScale">
                        </ngx-charts-area-chart>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="panel">
                    <div class="panel-heading sitetraxx">
                        <h4>Rain</h4>
                    </div>
                    <div class="panel-body">
                        <ngx-charts-area-chart [view]="rainChartOptions?.view" [scheme]="rainChartOptions?.colorScheme" [results]="rainChartData" [gradient]="rainChartOptions?.gradient" [xAxis]="rainChartOptions?.showXAxis" [yAxis]="rainChartOptions?.showYAxis" [legend]="rainChartOptions?.showLegend"
                            [showGridLines]="rainChartOptions?.showGridLines" [showXAxisLabel]="rainChartOptions?.showXAxisLabel" [showYAxisLabel]="rainChartOptions?.showYAxisLabel" [xAxisLabel]="rainChartOptions?.xAxisLabel" [yAxisLabel]="rainChartOptions?.yAxisLabel"
                            [autoScale]="rainChartOptions?.autoScale">
                        </ngx-charts-area-chart>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4">
                <app-hauloff-dailyreport [dateFilter]="dt"></app-hauloff-dailyreport>
            </div>
            <div class="col-lg-4">
                <app-buildingmaterial-dailyreport [dateFilter]="dt"></app-buildingmaterial-dailyreport>
            </div>
            <div class="col-lg-4">
                <app-concrete-dailyreport [dateFilter]="dt"></app-concrete-dailyreport>
            </div>
        </div>
    </div>
</div>