import { Component, OnInit, DoCheck } from '@angular/core';
import { DataService } from '../../services/data.service';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../../services/user.service';
import { DateService } from '../../services/date.service';
import { BreadCrumbService } from '../../services/breadcrumb.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-badgeholder-nobadge',
  templateUrl: './badgeholder-nobadge.component.html'
})
export class BadgeholderNoBadgeComponent implements OnInit {

  html: any;
  startDate: Date;
  endDate: Date;
  startDateStr: string;
  endDateStr: string;
  companies: any[];
  cols: any[];
  companyId: string = 'all';
  data: any[];
  siteSubscription: Subscription;
  section = 'Reports';
  title = 'Badge Holders - No Badge';
  user: any;
  reportData: any = {
    startDate: '20000101',
    endDate: '20000101',
    userId: '',
    companyIds: []
  };

  constructor(private dataService: DataService,
    private userService: UserService,
    private dateService: DateService,
    private breadcrumbService: BreadCrumbService,
    private router: Router) {
      this.siteSubscription = this.userService.getSiteEvent()
        .subscribe(siteId => {
            this.loadData();
        });
    }

  ngOnInit() {
    this.breadcrumbService.setItemsByCategory(this.section, this.title, this.router.url);
    this.loadData();
    this.cols = [
      { field: 'companyName', header: 'Company' },
      { field: 'badgeNum', header: 'Badge #' },
      { field: 'parkingNumber', header: 'Parking #' },
      { field: 'firstName', header: 'First Name' },
      { field: 'lastName', header: 'Last Name' },
      { field: 'laborClass', header: 'Labor Class' },
      { field: 'deletedFormated', header: 'Deleted?' },
      { field: 'createdDateFormated', header: 'Created' },
      { field: 'updatedDateFormated', header: 'Last Update' },
      { field: 'suspendedDateFormated', header: 'Suspended' },
      { field: 'terminationDateFormated', header: 'Terminated' }
    ];
    this.onSubmitForm();
  }

  ngOnDestroy() {
    this.siteSubscription.unsubscribe();
  }

  onSubmitForm() {
    //badgeholder/lastscan/{userId}/{companyId}
    var path = 'badgeholder/nobadge/' + this.user.id
      + '/' + this.companyId;
    this.dataService.getById('reports', path)
      .subscribe((data) => {
        this.data = data;
      });
  }

  onStartDateChange() {
    this.startDateStr = this.dateService.formatDate(new Date(this.startDate));
  }

  onEndDateChange() {
    this.endDateStr = this.dateService.formatDate(new Date(this.endDate));
  }

  loadData() {
    this.loadCompanies();
    this.user = this.userService.currentUser();
  }

  loadCompanies(): void {
    this.dataService.getAll('companies')
      .subscribe((companies) => {
        this.companies = companies;
        this.companies.unshift({ value: 'all', label: 'All' });
      });
  }

  createPDF(): void {
    this.reportData.userId = this.user.id;
    this.reportData.companyIds = [this.companyId];

    this.reportData.userId = this.user.id;
    this.reportData.companyIds = [this.companyId];

    const path = 'reports/badgeholder/nobadge/pdf';
    this.dataService.postForm(path, this.reportData, {responseType: 'arraybuffer'})
        .subscribe((data) => {
          const file = new Blob([data], {type: 'application/pdf'});
          const fileURL = window.URL.createObjectURL(file);

          const a = document.createElement('a');
          document.body.appendChild(a);
          a.href = fileURL;
          a.download = 'badgeholder.pdf';
          a.click();

          window.URL.revokeObjectURL(fileURL);
          a.remove();
        });

  }

  createExcel(): void {

    this.reportData.userId = this.user.id;
    this.reportData.companyIds = [this.companyId];

    const path = 'reports/badgeholder/nobadge/excel';
    this.dataService.postForm(path, this.reportData, {responseType: 'arraybuffer'})
        .subscribe((data) => {
          const file = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
          const fileURL = window.URL.createObjectURL(file);

          const a = document.createElement('a');
          document.body.appendChild(a);
          a.href = fileURL;
          a.download = 'badgeholder.xlsx';
          a.click();

          window.URL.revokeObjectURL(fileURL);
          a.remove();
        });
  }
}
