import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'badgeholder-vehicle',
    templateUrl: './badgeholder.vehicle.component.html',
    encapsulation: ViewEncapsulation.None
})
export class BadgeHolderVehicleComponent implements OnInit {
@Input() badgeHolder: any;
@Input() states: any[];

parkingPass: string;

    constructor() { }

    ngOnInit(): void { 
        this.badgeHolder.parkingPass = (this.badgeHolder.parkingPass === 1) ? '1' : '0';
        this.badgeHolder.commercialVehicle = (this.badgeHolder.commercialVehicle === 1) ? '1' : '0';
        this.badgeHolder.driving = (this.badgeHolder.driving === 1) ? '1' : '0';
    }
}