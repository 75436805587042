<div *ngIf="activityLogs">
    <div class="row">
        <div class="col-lg-12">
            <span class="grid-header">Activities</span>
            <span class="float-right">
                <button type="button" pButton title="Export" icon="pi pi-cloud-download" class="indigo-btn" (click)="exportToCSV()"></button>
                <button pButton icon="pi pi-plus" (click)="add()" title="Add" class="indigo-btn"></button>
            </span>
        </div>
    </div>
    <div class="row">
        <table class="table table-striped">
            <thead>
                <tr class="sitetraxx">
                    <th>Edit</th>
                    <th>Delete</th>
                    <th>Date/Time</th>
                    <th>Description</th>
                    <th>Created By</th>
                    <th>Work Delay</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let activity of activityLogs">
                    <td style="width: 5%"><button type="button" pButton (click)="edit(activity)" icon="pi pi-pencil"></button></td>
                    <td style="width: 5%"><button type="button" pButton (click)="delete(activity)" icon="pi pi-trash"></button></td>
                    <td>{{this.dateService.convertDateToLocalTimeDate(activity.activityDate) | date:"MM/dd/yyyy"}}</td>
                    <td>{{activity.description}}</td>
                    <td>{{activity.createdByUserName}}</td>
                    <td>
                        <span *ngIf="activity.delay">Yes</span>
                        <span *ngIf="!activity.delay">No</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<p-dialog header="Activity Log" [(visible)]="showDialog" modal="modal" [style]="{ width: '500px', height: '460px' }">
    <div class="row">
        <div class="col-12 form-group">
            <p-calendar name="activityDate" [(ngModel)]="activityLog.activityDate" [showIcon]="true"></p-calendar>
        </div>
        <div class="col-12 form-group">
            <label>Description</label>
            <textarea pTextArea [rows]="5" name="description" maxlength="250" [(ngModel)]="activityLog.description" class="form-control" required></textarea>
        </div>
        <div class="col-12 form-group">
            <p-checkbox name="delay" binary="true" label="Delay in Work" [(ngModel)]="activityLog.delay"></p-checkbox>
        </div>
        <div>
            <br />
            <br />
        </div>
    </div>
    <p-footer>
        <button type="button" pButton icon="pi pi-save" [disabled]="activityLog.description === undefined || activityLog.description === ''" (click)="save()" label="Save"></button>
    </p-footer>
</p-dialog>