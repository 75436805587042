import { Component, Input, OnInit, ViewEncapsulation, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { FullCalendar } from 'primeng';
import { ConfirmationService } from 'primeng/api';

import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import { BreadCrumbService } from '../../services/breadcrumb.service';
import { DataService } from '../../services/data.service';
import { LoggingService } from '../../services/logging.service';
import { DateService } from '../../services/date.service';
import { UserService } from '../../services/user.service';
import { ReturnStatement } from '@angular/compiler';

@Component({
    selector: 'app-resource-schedule',
    templateUrl: './resource.schedule.component.html',
    providers: [ ConfirmationService ],
    encapsulation: ViewEncapsulation.None
})
export class ResourceScheduleComponent implements OnInit {

user: any;
category = 'resources';


resourceType;
resourceTypes = [
    {label:'All Resource Types', value: null},
    {label:'Crane', value: 1},
    {label:'Man Hoist', value: 2},
    {label:'Loading Dock', value: 3},
    {label:'Gate', value: 4},
    {label:'Equipment', value: 5},
];

events = [];
options;

@ViewChild('fc') fc: FullCalendar;
event;
showViewDialogFlag = false;

showEditDialogFlag = false;
duration = [];
buildingMaterialResourceTypes = [
    {label:'Crane', value: 1},
    {label:'Man Hoist', value: 2},
    {label:'Loading Dock', value: 3},
    {label:'Gate', value: 4},
    {label:'Equipment', value: 5},
];
cranes = [];
hoists = [];
docks = [];
resources = [];
companies = [];
resourceBuildingMaterial: any = {};
resourceBuildingMaterialScheduled: Date;
resourceBuildingMaterialDuration: string;
resourceBuildingMaterialResourceType: number;

    constructor(
        private route: ActivatedRoute,
        private dataService: DataService,
        private loggingService: LoggingService,
        private dateService: DateService,
        private confirmService: ConfirmationService,
        private userService: UserService,
        private breadcrumbService: BreadCrumbService,
        private router: Router) { }

    ngOnInit(): void {
        this.user = this.userService.currentUser();

        this.dataService.getAll('resources/all-events/null/null/null')
        .subscribe((data) => {
            this.setEvents(data);
        });

        this.loadDuration();
        this.loadCompanies();

        const instance = this;
        this.options = {
            plugins:[ dayGridPlugin, timeGridPlugin, interactionPlugin ],
            header: {
                left: 'prev,next',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay'
            },
            dateClick: function(info) {
                info.jsEvent.preventDefault(); // don't let the browser navigate

                if (instance.fc.getCalendar().view.type === 'dayGridMonth') {
                    instance.fc.getCalendar().gotoDate(info.date);
                    instance.fc.getCalendar().changeView('timeGridDay');
                }
                else
                {
                    instance.resourceBuildingMaterial = {};
                    instance.resourceBuildingMaterialScheduled = info.date;
                    instance.resourceBuildingMaterialDuration = '30';
                    if (instance.resourceType && instance.resourceType < 4)
                        instance.resourceBuildingMaterialResourceType = instance.resourceType;
                    else
                        instance.resourceBuildingMaterialResourceType = 1;

                    instance.loadResourceTypes();
                    instance.showEditDialogFlag = true;
                }
            },
            eventClick: function(info) {
                info.jsEvent.preventDefault(); // don't let the browser navigate

                if (info.event.url === 'rbm')
                    instance.dataService.getById('resources/buildingmaterial', info.event.id)
                        .subscribe((data) => {
                            instance.resourceBuildingMaterial = data;
                            instance.resourceBuildingMaterialScheduled = new Date(instance.resourceBuildingMaterial.scheduled);
                            instance.resourceBuildingMaterialDuration = String(instance.resourceBuildingMaterial.duration);
                            instance.resourceBuildingMaterialResourceType = null;

                            instance.loadResourceTypes();

                            instance.showEditDialogFlag = true;
                        });
                else {
                    instance.event = info.event;
                    const items = instance.events.filter(m=>m.id === info.event.id);
                    if (items.length > 0)
                        instance.event.description = items[0].description;
                    instance.showViewDialogFlag = true;
                }
              }
        };

        this.breadcrumbService.setItemsByCategory('Controls', 'Resource Schedule', this.router.url);
    }

    private setEvents(data) {
        if (!data) {
            return;
        }

        data.forEach(m => {
            m.start = new Date(m.start);
            m.end = new Date(m.end);
        });
        data.forEach(m => {
            let overlaps = 1;
            let lastEnd = m.end;
            data.forEach(m2 => {
                if (m2.id !== m.id && m2.start < lastEnd && ((m2.start >= m.start && m2.start <= m.end) || (m2.end >= m.start && m2.end <= m.end))) {
                    overlaps++;
                    lastEnd = m2.end;
                }
            });

            const widthClassName = `event-width-${Math.min(overlaps, 5)}`;
            if (m.classNames) {
                if (typeof(m.classNames) === 'string')
                    m.classNames = m.classNames.split(',');
                m.classNames.push(widthClassName);
            }
            else
                m.classNames = [widthClassName];
        });

        this.events = data;
    }
    resourceTypeChanged(): void{
        this.dataService.getAll(`resources/all-events/null/${this.resourceType}/null`)
        .subscribe((data) => {
            this.setEvents(data);
        });
    }

    loadDuration(): void {
        this.dataService.getById('lookups', 'duration')
            .subscribe((data) => {
                this.duration = data;
            });
    }

    loadResourceTypes(): void {
        this.dataService.getById('lookups', `resourcetypes/${this.resourceBuildingMaterial.id}/${this.dateService.formatDate(this.resourceBuildingMaterialScheduled)}/${this.dateService.formatTime(this.resourceBuildingMaterialScheduled)}/${this.resourceBuildingMaterialDuration}`)
            .subscribe((data) => {
                this.cranes = [{label:'Select Crane', value: null}];
                this.hoists = [{label:'Select Man Hoist', value: null}];
                this.docks = [{label:'Select Loading Dock', value: null}];
                data.forEach(m => {
                    m.data = JSON.parse(m.data);
                    if (m.data.resourceType === 1)
                        this.cranes.push(m);
                    else if (m.data.resourceType === 2)
                        this.hoists.push(m);
                    else if (m.data.resourceType === 3)
                        this.docks.push(m);

                    if (m.value === this.resourceBuildingMaterial.resourceId)
                        this.resourceBuildingMaterialResourceType = m.data.resourceType;
                });
                this.loadResources();
            });
    }

    loadResources(): void {
        if (this.resourceBuildingMaterialResourceType === 1)
            this.resources = this.cranes;
        else if (this.resourceBuildingMaterialResourceType === 2)
            this.resources = this.hoists;
        else
            this.resources = this.docks;

        if (this.resourceBuildingMaterial.resourceId) {
            let found = false;

            this.resources.forEach(m => {
                if ((this.resourceBuildingMaterial.id || !m.disabled) && m.value === this.resourceBuildingMaterial.resourceId)
                    found = true;
            });

            if (!found)
                this.resourceBuildingMaterial.resourceId = null;
        }
    }

    loadCompanies(): void {
        this.dataService.getAll('companies')
        .subscribe((companies) => {
            this.companies = companies;
        });
    }

    delete(): void {
        this.confirmService.confirm({
            message: 'Do you want to delete this Resource?',
            accept: () => {
                this.dataService.deleteById('resources/buildingmaterial', this.resourceBuildingMaterial.id)
                    .subscribe(() => {
                        this.showEditDialogFlag = false;
                        this.loggingService.deletedMessage();

                        this.resourceTypeChanged();
                    });
            },
            reject: () => {
                this.loggingService.infoMessage('Cancelled', 'You have cancelled the request');
            }
        });
    }

    save(): void {
        this.resourceBuildingMaterial.scheduled = this.dateService.convertDateLocalToSiteTimeDate(this.resourceBuildingMaterialScheduled);
        this.resourceBuildingMaterial.duration = Number(this.resourceBuildingMaterialDuration);
        this.dataService.save('resources/buildingmaterial', this.resourceBuildingMaterial)
            .subscribe((data) => {
                this.showEditDialogFlag = false;
                this.loggingService.savedMessage();

                this.resourceTypeChanged();
            });
    }

    private getFilters(){
        return {
            resourceType: this.resourceType ,
            calendarView: this.fc.getCalendar().view.type,
            dateFilter: this.dateService.formatDate(this.fc.getCalendar().getDate()),
        };
    }
    createPDF(): void {

        this.dataService.postForm('resources/export/true', this.getFilters(), {responseType: 'arraybuffer'})
            .subscribe((data) => {
              const file = new Blob([data], {type: 'application/pdf'});
              const fileURL = window.URL.createObjectURL(file);

              const a = document.createElement('a');
              document.body.appendChild(a);
              a.href = fileURL;
              a.download = 'resourceSchedule.pdf';
              a.click();

              window.URL.revokeObjectURL(fileURL);
              a.remove();
            });

      }

      createExcel(): void {

        this.dataService.postForm('resources/export/false', this.getFilters(), {responseType: 'arraybuffer'})
            .subscribe((data) => {
              const file = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
              const fileURL = window.URL.createObjectURL(file);

              const a = document.createElement('a');
              document.body.appendChild(a);
              a.href = fileURL;
              a.download = 'resourceSchedule.xlsx';
              a.click();

              window.URL.revokeObjectURL(fileURL);
              a.remove();
            });
      }}
