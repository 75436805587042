import { Component, Input, OnInit, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BreadCrumbService } from '../../services/breadcrumb.service';
import { DataService } from '../../services/data.service';
import { LoggingService } from '../../services/logging.service';
import { Message } from 'primeng/api';
import { DateService } from '../../services/date.service';

@Component({
    selector: 'app-contract-profile',
    templateUrl: './contract.profile.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ContractProfileComponent implements OnInit, OnChanges {
@Input() contract: any;

showTabFlag: boolean;
message: Message[] = [];
companies: any[];
deliveryMethods: any[];
bonded = 'false';
subguard = 'false';
insurance = 'false';
category = 'contracts';
substantialCompletionDate: Date;
startDate: Date;
endDate: Date;

    constructor(
        private route: ActivatedRoute,
        private dataService: DataService,
        private loggingService: LoggingService,
        private dateService: DateService,
        private breadcrumbService: BreadCrumbService,
        private router: Router) {}

    ngOnInit(): void {
        if (this.contract.isBonded === true) {
            this.bonded = 'true';
        }

        if (this.contract.subGuard === true) {
            this.subguard = 'true';
        }

        if (this.contract.hasInsurance === true) {
            this.insurance = 'true';
        }

        if (this.contract.substantialCompletionDate === undefined) {
            this.substantialCompletionDate = new Date();
        } else {
            this.substantialCompletionDate = this.dateService.convertDateUniversalToSiteTimeDate(new Date(this.contract.substantialCompletionDate));
        }

        if (this.contract.startDate === undefined) {
            this.startDate = new Date();
        } else {
            this.startDate = this.dateService.convertDateUniversalToSiteTimeDate(new Date(this.contract.startDate));
        }

        if (this.contract.endDate === undefined) {
            this.endDate = new Date();
        } else {
            this.endDate = this.dateService.convertDateUniversalToSiteTimeDate(new Date(this.contract.endDate));
        }

        this.loadCompanies();
        this.loadDeliveryMethods();
    }

    ngOnChanges(simpleChanges: SimpleChanges): void {
        this.breadcrumbService.setItemsByCategoryAndName(
                                'Sitetraxx Admin',
                                'Contracts',
                                '/' + this.category + '/search',
                                this.contract.name,
                                this.router.url);
    }

    save() {
        if (this.bonded === 'true') {
            this.contract.isBonded = true;
        } else {
            this.contract.isBonded = false;
        }

        if (this.subguard === 'true') {
            this.contract.subGuard = true;
        } else {
            this.contract.subGuard = false;
        }

        if (this.insurance === 'true') {
            this.contract.hasInsurance = true;
        } else {
            this.contract.hasInsurance = false;
        }

        this.dataService.save(this.category, this.contract)
            .subscribe((data) => {
                this.contract = data;
                this.loggingService.savedMessage();
            });
    }

    cancel() {
        this.router.navigate(['/' + this.category + '/search/']);
    }

    showTab(_tab: boolean) {
        this.showTabFlag = _tab;
    }

    loadCompanies(): void {
        this.dataService.getAll('companies')
        .subscribe((companies) => {
            this.companies = companies;
        });
    }

    loadDeliveryMethods(): void {
        this.dataService.getById('lookups', 'deliverymethod')
            .subscribe((lookup) => {
                this.deliveryMethods = lookup;
            });
    }

    onSubstantialCompletionDateChange() {
        this.contract.substantialCompletionDate = this.dateService.convertDateLocalToSiteTimeDate(new Date(this.substantialCompletionDate));
    }

    onStartDateChange() {
        this.contract.startDate = this.dateService.convertDateLocalToSiteTimeDate(new Date(this.startDate));
    }

    onEndDateChange() {
        this.contract.endDate = this.dateService.convertDateLocalToSiteTimeDate(new Date(this.endDate));
    }
}
