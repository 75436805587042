<form #thisForm='ngForm'>
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <h3>{{inspection?.name}}</h3>
            </div>
            <div class="col-lg-6">
                <div class="float-right">
                    <button pButton (click)="save()" [disabled]="!thisForm.valid" icon="pi pi-save"
                        label="Save"></button>
                    <button pButton (click)="cancel()" icon="pi pi-ban" label="Cancel"></button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="inspection" class="container">
        <div class="row">
            <div class="col-8">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-8">
                            <div class="row">
                                <div class="col-lg-6 col-12">
                                    <div class="form-group">
                                        <label>Company</label><br />
                                        <p-dropdown required name="companies" [options]="companies" [filter]="true"
                                            (onChange)="loadTradeClasses()" placeholder="Select a Company"
                                            [(ngModel)]="inspection.companyId"></p-dropdown>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-12">
                                    <div class="form-group">
                                        <label>Trade Class</label><br />
                                        <span *ngIf="tradeClass">{{tradeClass.label}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-12">
                                    <div class="form-group">
                                        <label>Company Site Contact</label><br />
                                        <input pInputText name="companySiteContact"
                                            [(ngModel)]="inspection.companySiteContact" class="form-control" type="text"
                                            placeholder="Enter Company Site Contact" maxlength="100" required />
                                    </div>
                                </div>
                                <div class="col-lg-6 col-12">
                                    <div class="form-group">
                                        <label>Contact Phone</label><br />
                                        <p-inputMask mask="(999)999-9999" [(ngModel)]="inspection.contactPhone"
                                            name="contactPhone" placeholder="(999)999-9999"></p-inputMask>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-12">
                                    <label>Scheduled Date</label><br />
                                    <p-calendar required name="scheduledDate" [(ngModel)]="scheduledDate"
                                        [showIcon]="true"></p-calendar>
                                </div>
                                <div class="col-lg-6 col-12">
                                    <label>Scheduled Time</label><br />
                                    <p-calendar name="scheduledDate" [(ngModel)]="scheduledDate" [stepMinute]="15" [timeOnly]="true" [showTime]="true"
                                        [showIcon]="true"></p-calendar>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-12">
                                    <p-radioButton name="ampm" value="AM" [(ngModel)]="inspection.amPm" label="AM"></p-radioButton>
                                    <p-radioButton name="ampm" value="PM" [(ngModel)]="inspection.amPm" label="PM"></p-radioButton>
                                </div>
                                <div class="col-lg-6 col-12">
                                    <label>Duration Onsite</label><br />
                                    <p-dropdown [options]="duration" [filter]="true" [(ngModel)]="durationValue"
                                        placeholder="Select a Duration" name="duration"></p-dropdown>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Area / Floor</label><br />
                                        <input pInputText name="name" [(ngModel)]="inspection.name" class="form-control"
                                            type="text" placeholder="Enter Area" maxlength="100" required />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-12">
                                    <div class="form-group">
                                        <label>Inspector</label><br />
                                        <p-dropdown required [options]="inspectors" [filter]="true"
                                            [(ngModel)]="inspection.inspectorId" placeholder="Select an Inspector"
                                            name="inspector"></p-dropdown>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-12">
                                    <br />
                                    <button pButton (click)="dialogInspectorOpenClose(true)" icon="pi pi-plus" label="Add New Inspector"></button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <div class="form-group">
                                        <label>Inspection Code</label>
                                        <input pInputText name="inspectionCode" [(ngModel)]="inspection.code"
                                            maxlength="50" class="form-control" type="text" placeholder="####" />
                                    </div>
                                </div>
                            </div>
                            <!--
                            <div class="row">
                                <div class="col-lg-6 col-12">
                                    <label>Status</label><br />
                                    <p-radioButton name="passFail" value="true" [(ngModel)]="passFail" label="Pass">
                                    </p-radioButton>
                                    <p-radioButton name="passFail" value="false" [(ngModel)]="passFail" label="Fail">
                                    </p-radioButton>
                                </div>
                            </div>
                            -->
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Comments</label><br />
                                        <textarea pInputTextarea [rows]="5" [cols]="60" name="notes"
                                            [(ngModel)]="inspection.notes"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="row">
                    <div class="col-12">
                        <label>Photo</label><br />
                        <button pButton icon="pi pi-plus" [disabled]="inspection.id===undefined"
                            (click)="openUploadDialog()" label="Add Photo" class="indigo-btn"></button>
                        <app-photo-list [objectId]="inspection.id" [(refresh)]="refresh" [category]="category"
                            *ngIf="inspection !== undefined"></app-photo-list>
                    </div>
                </div>
            </div>
        </div>
        <st-update-block [object]='inspection' *ngIf="inspection"></st-update-block>
    </div>
</form>

<app-upload-photo [(showDialog)]="showUploadDialog" (showDialogChange)="showDialogChangedHandler($event)"
    [category]="category" [objectId]="inspection.id" *ngIf="inspection.id !== undefined"></app-upload-photo>

    <p-dialog header="New Inspector" [(visible)]="showInspectorDialog" modal="modal" [style]="{ width: '600px', height: '650px', 'min-width': '400px' }" [minY]="70">
        <div>
            <app-inspector-profile [inspector]="inspector" [dialog]="true"></app-inspector-profile>
        </div>
        <p-footer>
            <button pButton icon="pi pi-times" (click)="dialogInspectorOpenClose(false)" label="Close"></button>
        </p-footer>
    </p-dialog>