import { Component, Input, OnInit, ViewEncapsulation, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import { BreadCrumbService } from '../../services/breadcrumb.service';
import { DataService } from '../../services/data.service';
import { LoggingService } from '../../services/logging.service';
import { DateService } from '../../services/date.service';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-resource-profile',
    templateUrl: './resource.profile.component.html',
    styleUrls: ['./resource.profile.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class ResourceProfileComponent implements OnInit, OnChanges {
    @Input() resource: any;

    resourceTypes = [
        { label: 'Select Resource Type', value: null },
        { label: 'Crane', value: 1 },
        { label: 'Man Hoist', value: 2 },
        { label: 'Loading Dock', value: 3 },
        { label: 'Gate', value: 4 },
        { label: 'Equipment', value: 5 },
    ];

    colorTypes = [
        { label: 'Select Color Type', value: null },
        { label: 'Green', value: 1 },
        { label: 'Red', value: 2 },
        { label: 'Purple', value: 3 },
        { label: 'Blue', value: 4 },
        { label: 'Brown', value: 5 },
        { label: 'Black', value: 6 },
    ];

    startDate: Date;
    startTime: Date;
    endDate: Date;
    endTime: Date;

    user: any;
    category = 'resources';

    events = [];
    options;

    showDialogFlag = false;
    resourceSchedule: any;
    resourceScheduleStartDate: Date;
    resourceScheduleStartTime: Date;
    resourceScheduleEndDate: Date;

    constructor(
        private route: ActivatedRoute,
        private dataService: DataService,
        private loggingService: LoggingService,
        private dateService: DateService,
        private userService: UserService,
        private breadcrumbService: BreadCrumbService,
        private router: Router) { }

    ngOnInit(): void {
        this.user = this.userService.currentUser();

        if (this.resource.id === 'new') {
            this.startDate = this.dateService.convertDateUniversalToSiteTimeDate(new Date());
            this.startTime = this.dateService.convertDateUniversalToSiteTimeDate(new Date());
            this.startTime.setHours(5);
            this.startTime.setMinutes(0);
            this.startTime.setSeconds(0);
            this.startTime.setMilliseconds(0);

            this.endDate = this.dateService.convertDateUniversalToSiteTimeDate(new Date());
            this.endTime = this.dateService.convertDateUniversalToSiteTimeDate(new Date());
            this.endTime.setHours(19);
            this.endTime.setMinutes(0);
            this.endTime.setSeconds(0);
            this.endTime.setMilliseconds(0);
        } else {
            console.log(this.resource);
//            this.startDate = this.dateService.convertDateUniversalToSiteTimeDate(this.resource.startDate);
//            this.startTime = this.dateService.convertDateUniversalToSiteTimeDate(this.resource.startDate);

//            this.endDate = this.dateService.convertDateUniversalToSiteTimeDate(this.resource.endDate);
//            this.endTime = this.dateService.convertDateUniversalToSiteTimeDate(this.resource.endDate);

            this.startDate = new Date(this.resource.startDate);
            this.startTime = new Date(this.resource.startDate);

            this.endDate = new Date(this.resource.endDate);
            this.endTime = new Date(this.resource.endDate);
        }
        this.startDate.setHours(0);
        this.startDate.setMinutes(0);
        this.startDate.setSeconds(0);
        this.startDate.setMilliseconds(0);

        this.endDate.setHours(0);
        this.endDate.setMinutes(0);
        this.endDate.setSeconds(0);
        this.endDate.setMilliseconds(0);

        this.cancelUnavailble();
        this.dataService.getById('resources/events', this.resource.id)
            .subscribe((data) => {
                console.log(data)
                this.events = data;
                this.setupEvents();
            });

        const instance = this;
        this.options = {
            plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
            header: {
                left: 'prev,next',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay'
            },
            eventClick: function (info) {
                info.jsEvent.preventDefault(); // don't let the browser navigate

                if (info.event.url && info.event.url !== 'null')
                    instance.router.navigate([info.event.url]);
                else {
                    var diff = (info.event.end.getTime() - info.event.start.getTime()) / 1000;
                    diff /= 60;

                    instance.resourceSchedule.id = info.event.id;
                    instance.resourceSchedule.name = info.event.title;
                    instance.resourceSchedule.duration = Math.abs(Math.round(diff));
                    instance.resourceScheduleStartDate = info.event.start;
                    instance.resourceScheduleStartTime = info.event.start;

                    var resourceSchedules = instance.events.filter(m => m.id === info.event.id);
                    if (resourceSchedules) {
                        const resourceSchedule = resourceSchedules[0];
                        if (resourceSchedules[0].startRecur)
                            instance.resourceScheduleStartDate = new Date(resourceSchedules[0].startRecur);
                        if (resourceSchedules[0].endRecur)
                            instance.resourceScheduleEndDate = new Date(resourceSchedules[0].endRecur);
                        if (resourceSchedules[0].daysOfWeek)
                            resourceSchedules[0].daysOfWeek.forEach(m => {
                                switch (m) {
                                    case 0:
                                        instance.resourceSchedule.showOnSunday = true;
                                        break;
                                    case 1:
                                        instance.resourceSchedule.showOnMonday = true;
                                        break;
                                    case 2:
                                        instance.resourceSchedule.showOnTuesday = true;
                                        break;
                                    case 3:
                                        instance.resourceSchedule.showOnWednesday = true;
                                        break;
                                    case 4:
                                        instance.resourceSchedule.showOnThursday = true;
                                        break;
                                    case 5:
                                        instance.resourceSchedule.showOnFriday = true;
                                        break;
                                    case 6:
                                        instance.resourceSchedule.showOnSaturday = true;
                                        break;
                                }
                            });
                    }
                    instance.showDialogFlag = true;
                }
            }
        };
    }
    setupEvents(): void {
        let classNames = 'green';
        switch (this.resource.colorType) {
            case 2:
                classNames = 'red';
                break;
            case 3:
                classNames = 'purple';
                break;
            case 4:
                classNames = 'blue';
                break;
            case 5:
                classNames = 'brown';
                break;
            case 6:
                classNames = 'black';
                break;
        }
        const events = []
        this.events.forEach(m => {
            m.classNames = classNames;
            events.push(m);
        });
        this.events = events;
    }

    ngOnChanges(simpleChanges: SimpleChanges): void {
        this.breadcrumbService.setItemsByCategoryAndName(
            'Sitetraxx Admin',
            'Resources',
            '/' + this.category + '/search',
            this.resource.name,
            this.router.url);
    }



    saveUnavailble() {
        this.resourceSchedule.resourceId = this.resource.id;

        this.resourceSchedule.start = this.resourceScheduleStartDate;
        this.resourceSchedule.start.setHours(this.resourceScheduleStartTime.getHours());
        this.resourceSchedule.start.setMinutes(this.resourceScheduleStartTime.getMinutes());
        this.resourceSchedule.start = this.dateService.convertDateLocalToSiteTimeDate(this.resourceSchedule.start);

        this.resourceSchedule.endDate = this.dateService.convertDateLocalToSiteTimeDate(this.resourceScheduleEndDate);

        this.dataService.save('resources/schedule', this.resourceSchedule)
            .subscribe((data) => {
                this.loggingService.successMessage('Saved', 'Unavailable saved');
                this.cancelUnavailble();

                this.dataService.getById('resources/events', this.resource.id)
                    .subscribe((data) => {
                        this.events = data;
                        this.setupEvents();
                    });
            });
    }

    cancelUnavailble() {
        this.resourceSchedule = { id: 'new', showOnSunday: false, showOnMonday: false, showOnTuesday: false, showOnWednesday: false, showOnThursday: false, showOnFriday: false, showOnSaturday: false };
        this.resourceScheduleStartDate = null;
        this.resourceScheduleStartTime = null;
        this.resourceScheduleEndDate = null;
        this.showDialogFlag = false;
    }

    deleteUnavailble() {
        this.dataService.deleteById('resources/schedule', this.resourceSchedule.id)
            .subscribe((data) => {
                this.loggingService.successMessage('Deleted', 'Unavailable Deleted');
                this.cancelUnavailble();

                this.dataService.getById('resources/events', this.resource.id)
                    .subscribe((data) => {
                        this.events = data;
                        this.setupEvents();
                    });
            });
    }


    canSave(): boolean {
        try {
            const startDate = new Date(this.startDate);
            startDate.setHours(this.startTime.getHours());
            startDate.setMinutes(this.startTime.getMinutes());

            const endDate = new Date(this.endDate);
            endDate.setHours(this.endTime.getHours());
            endDate.setMinutes(this.endTime.getMinutes());

            return (startDate < endDate);
        }
        catch (e) {
            console.log(e)
            return false;
        }
    }
    save(andNew: boolean) {
        this.resource.siteId = this.user.siteId;

        this.resource.startDate = this.startDate;
        this.resource.startDate.setHours(this.startTime.getHours());
        this.resource.startDate.setMinutes(this.startTime.getMinutes());
        this.resource.startDate = this.dateService.convertDateLocalToSiteTimeDate(this.resource.startDate);

        this.resource.endDate = this.endDate;
        this.resource.endDate.setHours(this.endTime.getHours());
        this.resource.endDate.setMinutes(this.endTime.getMinutes());
        this.resource.endDate = this.dateService.convertDateLocalToSiteTimeDate(this.resource.endDate);

        this.dataService.save(this.category, this.resource)
            .subscribe((data) => {
                if (!andNew)
                    this.resource = data;
                else if (this.router.url.indexOf('new') > 0) {
                    this.resource = { id: 'new' };
                }
                else
                    this.router.navigate(['/resources/detail/new/']);
                this.loggingService.savedMessage();
            });
    }

    cancel() {
        this.router.navigate(['/resources/search/']);
    }
}
