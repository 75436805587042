import { Component, Input, OnInit, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BreadCrumbService } from '../../services/breadcrumb.service';
import { DataService } from '../../services/data.service';
import { LoggingService } from '../../services/logging.service';
import { DateService } from '../../services/date.service';
import { Message } from 'primeng/api';

@Component({
    selector: 'app-violation-profile',
    templateUrl: './violation.profile.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ViolationProfileComponent implements OnInit, OnChanges {
@Input() violation: any;

message: Message[] = [];
trackAction = 'false';
badgeHolders: any[];
correctiveActionDate: Date;

    constructor(
        private route: ActivatedRoute,
        private dataService: DataService,
        private loggingService: LoggingService,
        private dateService: DateService,
        private breadcrumbService: BreadCrumbService,
        private router: Router) {}

    ngOnInit(): void {
        if (this.violation.correctiveActionDate === undefined) {
            this.correctiveActionDate = new Date();
        } else {
            this.correctiveActionDate = this.dateService.convertDateUniversalToSiteTimeDate(new Date(this.violation.correctiveActionDate));
        }
        this.loadBadgeHolders();
    }

    ngOnChanges(simpleChanges: SimpleChanges): void {
        this.breadcrumbService.setItemsByCategoryAndName(
                                'Safety',
                                'Violations',
                                '/violations/search',
                                this.violation.observation,
                                this.router.url);
    }

    save() {
        if (this.violation.badgeHolderId != null && this.correctiveActionDate != null) {
            this.violation.correctiveActionDate = this.dateService.convertDateLocalToSiteTimeDate(this.correctiveActionDate);
            this.dataService.save('violations', this.violation)
                .subscribe((data) => {
                    this.violation = data;
                    this.loggingService.savedMessage();
                });
        }
    }

    cancel() {
        this.router.navigate(['/violations/search/']);
    }

    loadBadgeHolders(): void {
        this.dataService.getById('lookups', 'badgeholders')
        .subscribe((badgeHolders) => {
            this.badgeHolders = badgeHolders;
            if(this.violation.badgeHolderId == null)
                this.badgeHolders.unshift({id: null});
        });
    }
}
