import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { LoggingService } from '../../services/logging.service';
import { DataService } from '../../services/data.service';
import { UserService } from '../../services/user.service';
import { DateService } from '../../services/date.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-activitylog-grid',
  templateUrl: './activitylog.grid.component.html',
  styleUrls: ['./activitylog.grid.component.css']
})
export class ActivityLogGridComponent implements OnInit, OnChanges {
  @Input() dateFilter: string;

  activityLogs: any[];
  showDialog: boolean;
  activityLog: any;
  onsite: any[];
  subscription: Subscription;
  siteSubscription: Subscription;

  constructor(
    private dataService: DataService,
    private dateService: DateService,
    private userService: UserService,
    private loggingService: LoggingService) {
      this.subscription = this.dateService.getCurrentDate()
        .subscribe(date => {
          this.dateFilter = this.dateService.formatDate(date);;
          this.loadActivityLogs();
        });

      this.siteSubscription = this.userService.getSiteEvent()
        .subscribe(siteId => {
          this.loadActivityLogs();
        });
     }

  ngOnInit() {
    this.showDialog = false;
    this.activityLog = {};
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.siteSubscription.unsubscribe();
  }

  ngOnChanges() {
    this.loadActivityLogs();
  }

  loadActivityLogs(): void {
    this.dataService.getById('activitylogs', this.dateFilter)
        .subscribe((data) => {
            this.activityLogs = data;
        });
  }

  add(): void {
    this.activityLog = {};
    this.activityLog.activityDate = new Date();
    this.showDialog = true;
  }

  edit(_activityLog: any): void {
    this.showDialog = true;
    this.activityLog = Object.assign({}, _activityLog);
    if (this.activityLog.activityDate === undefined) {
      this.activityLog.activityDate = new Date();
    } else {
      this.activityLog.activityDate = this.dateService.convertDateToLocalTimeDate(new Date(this.activityLog.activityDate));
    }
  }

  delete(_activityLog): void {
    this.dataService.deleteById('activitylogs', _activityLog.id)
      .subscribe((data) => {
        this.loadActivityLogs();
        this.showDialog = false;
      });
  }

  save(): void {
    if (this.activityLog.activityDate != null) {
      const user = this.userService.currentUser();
      this.activityLog.companyId = user.companyId;
      this.dataService.save('activitylogs', this.activityLog)
          .subscribe((data) => {
            this.loadActivityLogs();
            this.showDialog = false;
          });
    }
  }


  private exportToCSV(): void {
      let csv = 'Date/Time,Description,Created By,Work Delay';
      csv = csv + '\n';


      for (const row of this.activityLogs) {
          const dt = this.dateService.convertDateToLocalTimeDate(row.activityDate);
          let csvRow = `${dt.getMonth() + 1}/${dt.getDate()}/${dt.getFullYear()}`;
          csvRow += ',';
          csvRow += row.description;
          csvRow += ',';
          if (row.createdByUserName)
            csvRow += row.createdByUserName;
          csvRow += ',';
          csvRow += (row.delay ? 'Yes' : 'No');

          csv += csvRow + '\n';
      }

      const blob = new Blob(['\uFEFF', csv], {type: 'text/csv'});
      const link = document.createElement('a');
      link.setAttribute('href', window.URL.createObjectURL(blob));
      link.setAttribute('download', 'ActivityLogActivities.csv');
      document.body.appendChild(link); // Required for FF
      link.click();
  }
}

