<div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-lg-6">
                        <table class="table table-striped">
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="form-inline">
                                            Regional Cost Index <input type="text" [(ngModel)]="site.regionalCostIndex"
                                                class="form-control" style="width: 100px" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td><strong>Calculated Overtime</strong></td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="form-inline">
                                            <p-checkbox name="calculateOvertimeAfterHours" binary="true" label=""
                                                [(ngModel)]="site.calculateOvertimeAfterHours"></p-checkbox>
                                            Overtime after <input type="text" name="overtimeAfterHours"
                                                [(ngModel)]="site.overtimeAfterHours" style="width: 100px"
                                                class="form-control" /> hours per day
                                        </div>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="form-inline">
                                            <p-checkbox name="calculateOvertimeAfterHoursWeek" binary="true" label=""
                                                [(ngModel)]="site.calculateOvertimeAfterHoursWeek"></p-checkbox>
                                            Overtime after <input type="text" name="overtimeAfterHoursWeek"
                                                [(ngModel)]="site.overtimeAfterHoursWeek" class="form-control"
                                                style="width: 100px" /> hours per week
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p-checkbox name="calculateOvertimeSevenDays" binary="true" label=""
                                            [(ngModel)]="site.calculateOvertimeSevenDays"></p-checkbox>
                                        Overtime on first 8 hours of the 7th consecutive work day
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="form-inline">
                                            <p-checkbox name="timeoutLabor" binary="true" label=""
                                                [(ngModel)]="site.timeoutLabor"></p-checkbox>
                                            Time Out Labor after <input type="number" name="timeoutLaborAfterHours"
                                                [(ngModel)]="site.timeoutLaborAfterHours" class="form-control"
                                                style="width: 100px" /> Hours
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <p-checkbox name="hasOffset" binary="true" label="Offset"
                                                    [(ngModel)]="site.hasOffset"></p-checkbox>
                                            </div>
                                            <div class="col-lg-6">
                                                Default Offset:
                                                <input type="number" [(ngModel)]="site.defaultOffset"
                                                    class="form-control" />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-lg-6">
                        <table class="table table-striped">
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="form-inline">
                                            Workweek begins on
                                            <select name="workWeekBegins" [(ngModel)]="site.workWeekBegins"
                                                class="form-control">
                                                <option>Sunday</option>
                                                <option>Monday</option>
                                                <option>Tuesday</option>
                                                <option>Wednesday</option>
                                                <option>Thursday</option>
                                                <option>Friday</option>
                                                <option>Saturday</option>
                                            </select>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Calculated Double-time</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="form-inline">
                                            <p-checkbox name="calculateDoubleTimeAfterHours" binary="true" label=""
                                                [(ngModel)]="site.calculateDoubleTimeAfterHours"></p-checkbox>
                                            Double-time after <input type="text" [(ngModel)]="site.doubleTimeAfterHours"
                                                style="width: 100px" class="form-control" /> hour per day
                                        </div>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="form-inline">
                                            <p-checkbox name="calculateDoubleTimeAfterHoursWeek" binary="true" label=""
                                                [(ngModel)]="site.calculateDoubleTimeAfterHoursWeek"></p-checkbox>
                                            Double-time after <input type="text"
                                                [(ngModel)]="site.doubleTimeAfterHoursWeek" class="form-control"
                                                style="width: 100px" /> hour per week
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p-checkbox name="calculateDoubleTimeSevenDays" binary="true" label=""
                                            [(ngModel)]="site.calculateDoubleTimeSevenDays"></p-checkbox>
                                        Double-time on first 8 hours of the 7th consecutive work day
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="form-inline">
                                            <p-checkbox name="autoClockByTime" binary="true" label=""
                                                [(ngModel)]="site.autoClockByTime"></p-checkbox>
                                            Auto Clock at Specific Time
                                            <select name="autoClockTime" [(ngModel)]="site.autoClockTime"
                                                class="form-control">
                                                <option value="0">12:00 AM</option>
                                                <option value="1">1:00 AM</option>
                                                <option value="2">2:00 AM</option>
                                                <option value="3">3:00 AM</option>
                                                <option value="4">4:00 AM</option>
                                                <option value="5">5:00 AM</option>
                                                <option value="6">6:00 AM</option>
                                                <option value="7">7:00 AM</option>
                                                <option value="8">8:00 AM</option>
                                                <option value="9">9:00 AM</option>
                                                <option value="10">10:00 AM</option>
                                                <option value="11">11:00 AM</option>
                                                <option value="12">12:00 PM</option>
                                                <option value="13">1:00 PM</option>
                                                <option value="14">2:00 PM</option>
                                                <option value="15">3:00 PM</option>
                                                <option value="16">4:00 PM</option>
                                                <option value="17">5:00 PM</option>
                                                <option value="18">6:00 PM</option>
                                                <option value="19">7:00 PM</option>
                                                <option value="20">8:00 PM</option>
                                                <option value="21">9:00 PM</option>
                                                <option value="22">10:00 PM</option>
                                                <option value="23">11:00 PM</option>
                                            </select>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <p-table #dt [columns]="cols" [value]="laborClasses">
                <ng-template pTemplate="caption">
                    <div style="text-align: left">
                        <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                        <input type="text" pInputText size="50" placeholder="Global Filter"
                            (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                        <span class="float-right">
                            <button pButton icon="pi pi-plus" title="Add" (click)="addNew()"
                                class="indigo-btn"></button>
                            <button type="button" pButton title="Export" icon="pi pi-cloud-download" class="indigo-btn"
                                (click)="dt.exportCSV()" style="float:left"></button>
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th style="width: 46px; padding: .625em 0; text-align: center">Edit</th>
                        <th style="width: 52px; padding: .625em 0; text-align: center">Delete</th>
                        <th *ngFor="let col of columns">
                            {{col.header}}
                        </th>
                    </tr>
                    <tr>
                        <th></th>
                        <th></th>
                        <th *ngFor="let col of columns" [ngSwitch]="col.field">
                            <input pInputText type="text"
                                (input)="dt.filter($event.target.value, col.field, 'contains')">
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pSelectableRow]="rowData">
                        <td style="width: 46px; padding: .625em 0; text-align: center"><button type="button" pButton
                                (click)="edit(rowData)" icon="pi pi-pencil"></button></td>
                        <td style="width: 52px; padding: .625em 0; text-align: center"><button type="button" pButton
                                (click)="delete(rowData)" icon="pi pi-trash"></button></td>
                        <td *ngFor="let col of columns" [ngSwitch]="col.field">
                            <span *ngSwitchCase="'baseRate'">{{rowData[col.field] |
                                currency:'USD':true:'1.2-2'}}</span>
                            <span *ngSwitchDefault>{{rowData[col.field]}}</span>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<p-dialog header="Add Labor Class" [(visible)]="showAddDialog" modal="modal"
    [style]="{ width: '800px', height: '685px', 'min-width': '800px' }" [minY]="625">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3">
                <label class="control-label">Company Category:</label><br />
                <p-dropdown [options]="defaultCompanyCategories" [filter]="true" [(ngModel)]="filter.companyCode"
                    (onChange)="loadCSIDivisions()" placeholder="Select Category"></p-dropdown>
            </div>
            <div class="col-md-3">
                <label class="control-label" for="trade">CSI Division:</label><br />
                <p-dropdown [options]="defaultCSIDivisions" [filter]="true" [(ngModel)]="filter.divisionCode"
                    (onChange)="loadTrades()" placeholder="Select Division"></p-dropdown>
            </div>
            <div class="col-md-3">
                <label class="control-label" for="trade">Trade Classification:</label><br />
                <p-dropdown [options]="defaultTrades" [filter]="true" [(ngModel)]="filter.tradeCode"
                    (onChange)="filterLabor()" placeholder="Select Trade"></p-dropdown>
            </div>
            <div class="col-md-3">
                <label class="control-label">Search:</label>
                <div class="form-inline">
                    <div class="form-group">
                        <div class="input-group">
                            <input type="text" ng-model="searchTerm" class="form-control" />
                            <div class="input-group-addon">
                                <i class="pi pi-search"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-12">
                <div style="overflow-y:scroll; overflow-x:hidden; height:400px;">
                    <table class="table table-striped">
                        <thead class="sitetraxx">
                            <tr>
                                <th></th>
                                <th>Category</th>
                                <th>Division</th>
                                <th>Trade</th>
                                <th>Labor Class</th>
                                <th>Type</th>
                                <th>Rate</th>
                            </tr>
                        </thead>
                        <tr *ngFor="let labor of laborClassDefaults">
                            <td><input type="checkbox" (click)="selected(labor)" /></td>
                            <td>{{labor.categoryName}}</td>
                            <td>{{labor.divisionName}}</td>
                            <td>{{labor.tradeName}}</td>
                            <td>{{labor.name}}</td>
                            <td>{{labor.laborType}}</td>
                            <td>{{labor.baseRate | currency}}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer sitetraxx">
        <button type="button" (click)="addLabor()" class="btn btn-primary"><i class="pi pi-plus-circle"></i>
            Add</button>
        <button type="button" (click)="cancel()" class="btn btn-primary"><i class="pi pi-ban"></i> Cancel</button>
    </div>
</p-dialog>

<p-dialog header="Edit Labor Class" [(visible)]="showEditDialog" modal="modal"
    [style]="{ width: '450px', height: '410px', 'min-width': '450px' }" [minY]="350">
    <div class="row">
        <div class="col-lg-12">
            <label class="control-label">Labor Class:</label>
            <input type="text" class="form-control" [(ngModel)]="selectedLaborClass.name" maxlength="50" />
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <label class="control-label">Labor Type:</label><br />
            <p-dropdown [options]="laborTypes" [filter]="true" [(ngModel)]="selectedLaborClass.laborType"></p-dropdown>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <label class="control-label">Rate:</label>
            <input type="number" class="form-control" [(ngModel)]="selectedLaborClass.baseRate" />
        </div>
    </div>

    <button type="button" (click)="saveLabor()" class="btn btn-primary"><i class="pi pi-save"></i> Save</button>
    <button type="button" (click)="cancelLabor()" class="btn btn-primary" style="margin-left: 5px;"><i
            class="pi pi-ban"></i> Cancel</button>

</p-dialog>

<p-confirmDialog header='Delete?' icon='pi pi-trash'></p-confirmDialog>