import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BreadCrumbService } from '../services/breadcrumb.service';
import { DataService } from '../services/data.service';
import { Message } from 'primeng/api';
import { LoggingService } from '../services/logging.service';
import { Subscription } from 'rxjs';
import { UserService } from '../services/user.service';

@Component({
    selector: 'app-visitors',
    templateUrl: './visitors.component.html',
    encapsulation: ViewEncapsulation.None
})
export class VisitorsComponent implements OnInit {

    pageInfo: any;
    path: string;
    id: string;
    private sub: any;
    visitor: any;
    message: Message[] = [];
    
    constructor(
        private route: ActivatedRoute,
        private dataService: DataService,
        private breadcrumbService: BreadCrumbService,
        private router: Router,
        private userService: UserService,
        private loggingService: LoggingService) { }

    ngOnInit(): void {

        this.sub = this.route.params.subscribe(params => {
            this.id = params['id'];

            if (this.id === undefined) {
                this.loadData();
            } else {
                if (this.id === 'new') {
                    this.loggingService.logPageView('Visitor - New');
                    this.visitor = {};
                } else {
                    this.loggingService.logPageView('Visitor - Profile');
                    this.dataService.getById('visitors', this.id)
                    .subscribe((data) => {
                        this.visitor = data;
                    });
                }
            }
         });
    }

    loadData() {
        this.loggingService.logPageView('Visitors - Grid');
        this.visitor = null;
        this.dataService.getById('pages', 'visitors')
        .subscribe((data) => {
            this.pageInfo = data.result;
            this.breadcrumbService.setItemsByCategory('Controls', this.pageInfo.title, this.router.url);
        });
    }
}
