import { Component, OnInit } from '@angular/core';

import { BreadCrumbService } from '../services/breadcrumb.service';
import { DataService } from '../services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggingService } from '../services/logging.service';
import { Subscription } from 'rxjs';
import { UserService } from '../services/user.service';

@Component({
    selector: 'app-violations',
    templateUrl: './violation.component.html'
})
export class ViolationComponent implements OnInit {
    pageInfo: any;
    path: string;
    sub: any;
    id: string;
    violation: any;

    constructor(
        private route: ActivatedRoute,
        private dataService: DataService,
        private breadcrumbService: BreadCrumbService,
        private loggingService: LoggingService,
        private userService: UserService,
        private router: Router) { }

    ngOnInit(): void {

        this.sub = this.route.params.subscribe(params => {
            this.id = params['id'];

            if (this.id === undefined) {
                this.loadData();
            } else {
                if (this.id === 'new') {
                    this.loggingService.logPageView('Violation - New');
                    this.violation = {badgeHolderId: null};
                } else {
                    this.loggingService.logPageView('Violation - Profile');
                    this.dataService.getById('violations', this.id)
                    .subscribe((data) => {
                        this.violation = data;
                    });
                }
            }
         });
    }

    loadData() {
        this.loggingService.logPageView('Violation - Grid');
        this.violation = null;
        this.dataService.getById('pages', 'violations')
        .subscribe((data) => {
            this.pageInfo = data.result;
            this.breadcrumbService.setItemsByCategory('Safety', this.pageInfo.title, this.router.url);
        });
    }
}
