import { Component, Input, OnInit, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BreadCrumbService } from '../../services/breadcrumb.service';
import { DataService } from '../../services/data.service';
import { LoggingService } from '../../services/logging.service';
import { UserService } from '../../services/user.service';
import { Message } from 'primeng/api';
import { SelectItem } from 'primeng/api';

@Component({
    selector: 'app-user-profile',
    templateUrl: './user.profile.component.html',
    encapsulation: ViewEncapsulation.None
})
export class UserProfileComponent implements OnInit, OnChanges {
    @Input() user: any;

    showTabFlag: boolean;
    users: any[];
    companies: any[];
    roles: any[];
    emailReports: any[];
    message: Message[] = [];
    msgs: Message[] = [];
    showDialogFlag: boolean;
    showAddDialogFlag: boolean = false;
    userPwChange: any = { userId: '', password: '', confirmPassword: '' };
    password: string;
    confirmPassword: string;
    isGlobalAdmin = false;
    sendTypes = [{ label: 'None', value: null }, { label: 'Email', value: 1 }, { label: 'SMS', value: 2 }, { label: 'Both', value: 3 }];

    constructor(
        private route: ActivatedRoute,
        private dataService: DataService,
        private loggingService: LoggingService,
        private breadcrumbService: BreadCrumbService,
        private router: Router,
        private userService: UserService) {
        this.isGlobalAdmin = this.userService.currentUser().isGlobalAdmin;
    }

    ngOnInit(): void {
        this.loadCompanies();
        this.loadRoles();
        if (this.isGlobalAdmin)
            this.loadEmailReports();
        this.userPwChange.userId = this.user.id;
    }

    ngOnChanges(simpleChanges: SimpleChanges): void {
        this.breadcrumbService.setItemsByCategoryAndName(
            'Sitetraxx Admin',
            'Users',
            '/users/search',
            this.user.name,
            this.router.url);
    }

    save() {
        if (this.isGlobalAdmin) {
            this.user.emailReports = [];
            this.emailReports.filter(m => m.selected).forEach(m => this.user.emailReports.push(m.value));
        }
        this.dataService.save('users', this.user)
            .subscribe((data) => {
                //                this.userPwChange = data;
                this.loggingService.savedMessage();

                if (this.userPwChange.userId === 'new') {
                    this.userPwChange.userId = this.user.id;
                    if (this.userPwChange.password !== '' &&
                        this.userPwChange.password === this.userPwChange.confirmPassword) {
                        this.dataService.save('users/changepassword', this.userPwChange)
                            .subscribe((data2) => {
                                this.showDialog(false);
                                this.loggingService.savedMessage('Saved');
                            });
                    }
                }
            }, error => {
                this.loggingService.errorMessage('Error', error.error);
                //                                        this.msgs.push({severity: 'error', summary: 'Username already in use', detail: 'Error'});
            });
    }

    changePassword() {
        if (this.userPwChange.password !== this.userPwChange.confirmPassword) {
            this.msgs.push({ severity: 'error', summary: 'Password and Confirm Password must Match', detail: 'Error' });
        } else if (this.userPwChange.userId !== 'new') {
            this.dataService.save('users/changepassword', this.userPwChange)
                .subscribe((data) => {
                    this.showDialog(false);
                    this.loggingService.savedMessage('Saved');
                });
        } else {
            this.showDialog(false);
        }
    }

    cancelPassword() {
        this.showDialog(false);
    }

    showDialog(status: boolean) {
        this.msgs = [];
        this.userPwChange = { userId: this.user.id };
        this.showDialogFlag = status;
    }

    showAddDialog(status: boolean) {
        this.showAddDialogFlag = status;
    }

    cancel() {
        this.router.navigate(['/users/search/']);
    }

    showTab(_tab: boolean) {
        this.showTabFlag = _tab;
    }

    loadCompanies(): void {
        this.dataService.getAll('companies')
            .subscribe((companies) => {
                this.companies = companies;
            });
    }

    loadRoles(): void {
        this.dataService.getById('lookups', 'roles')
            .subscribe((roles) => {
                this.roles = roles;
            });
    }

    loadEmailReports(): void {
        this.dataService.getById('lookups', 'emailreports')
            .subscribe((data) => {
                this.emailReports = data;
                this.emailReports.forEach(m => m.selected = (this.user.emailReports.filter(id => id == m.value).length > 0));
            });
    }
}
