import { Component, OnInit, Input, OnChanges } from '@angular/core';

import { LoggingService } from '../../services/logging.service';
import { DataService } from '../../services/data.service';

@Component({
    selector: 'app-concrete-count',
    templateUrl: './concrete.count.component.html'
})
export class ConcreteCountComponent implements OnInit, OnChanges {
    @Input() dateFilter: string;

    countItem: { dayCount: 0, totalCount: 0 };
    title = 'Concrete';
    pageUrl = '/concrete/search';

    constructor(
        private dataService: DataService,
        private loggingService: LoggingService) {}

    ngOnInit(): void {

    }

    ngOnChanges(): void {
        this.loadCount();
    }

    loadCount(): void {
        this.dataService.getById('concrete/count', this.dateFilter)
            .subscribe((data) => {
                this.countItem = data;
            });
    }
}
