import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataService } from '../services/data.service';
import { DateService } from '../services/date.service';
import { BreadCrumbService } from '../services/breadcrumb.service';
import { LoggingService } from '../services/logging.service';
import { Message } from 'primeng/api';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-injuries',
  templateUrl: './injuries.component.html'
})
export class InjuriesComponent implements OnInit {

  fromDate: Date;
  toDate: Date;
  data = null;
  user: any;
  noteItem = null;
  message: Message[] = [];
  siteSubscription: Subscription;

  constructor(
    private dataService: DataService,
    private dateService: DateService,
    private breadcrumbService: BreadCrumbService,
    private loggingService: LoggingService,
    private userService: UserService,
    private router: Router) { 
      this.siteSubscription = this.userService.getSiteEvent()
        .subscribe(siteId => {
            this.loadData();
        });
    }

  ngOnInit() {

    this.breadcrumbService.setItemsByCategory('Saftey', 'Injuries', this.router.url);

    this.loadData();
  }

  ngOnDestroy() {
    this.siteSubscription.unsubscribe();
  }

  loadData() {
    this.loggingService.logPageView('Injuries');
    
    this.user = this.userService.currentUser();

    this.fromDate = new Date();
    this.fromDate.setHours(0, 0, 0);
    this.fromDate.setDate(this.fromDate.getDate() - 30);
    
    this.toDate = new Date();
    this.toDate.setHours(0, 0, 0);

    this.dataService.getAll(`clock/injuries/${this.dateService.formatDate(this.fromDate)}/${this.dateService.formatDate(this.toDate)}`)
      .subscribe((data) => {
          this.data = data;
      });
  }

  saveInjury(item){
    this.dataService.postForm(`clock/injury/${item.id}`, { injury: (item.injury ? 1 : 0) })
    .subscribe((data) => {
    });
  }
  addNote(item){
    this.noteItem = item;
    this.noteItem.approvalNote = null;
  }
  saveNote(){
    if (this.noteItem.approvalNote && this.noteItem.approvalNote.length > 0)
      this.dataService.postForm(`clock/injury/${this.noteItem.id}`, { injury: (this.noteItem.injury ? 1 : 0) , approvalNote: `${this.user.name} on  ${this.dateService.formatDateDash(new Date())} ${this.dateService.formatTime(new Date())}\n\n${this.noteItem.approvalNote}` })
      .subscribe((data) => {
        this.noteItem = null;
      });
  }

}