import { Component, OnInit, DoCheck } from '@angular/core';
import { BreadCrumbService } from '../../services/breadcrumb.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { UserService } from '../../services/user.service';
import { LoggingService } from '../../services/logging.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-static-report',
  templateUrl: './static-report.component.html',
  styleUrls: ['./static-report.component.css']
})
export class StaticReportComponent implements OnInit {

  id: string;
  sub: any;
  report: any;
  user: any;
  siteSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private userService: UserService,
    private breadcrumbService: BreadCrumbService,
    private loggingService: LoggingService,
    private router: Router) {
      this.siteSubscription = this.userService.getSiteEvent()
          .subscribe(siteId => {
            this.loadData();
        });
    }

  ngOnInit() {
    this.user = this.userService.currentUser();
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];
      this.loadReport();
   });
  }

  ngOnDestory() {
    this.siteSubscription.unsubscribe();
  }

  loadData() {
    this.loadReport();
  }

  loadReport(): void {
    this.dataService.getById('reports/detail', this.id)
          .subscribe((report) => {
              this.report = report;
              this.loggingService.logPageView('Static Report - ' + this.report.name);

              this.breadcrumbService.setItemsByCategory('Reporting', this.report.name, this.router.url);

              if (this.report.parameter1 !== null && this.report.parameter1 !== '') {
                if (this.report.parameter1 === 'user.companyId') {
                  this.report.url += '&Parameter1=' + this.user.companyId;
                } else if (this.report.parameter1 === 'user.siteId') {
                  this.report.url += '&Parameter1=' + this.user.siteId;
                }
              }

              if (this.report.parameter2 !== null && this.report.parameter2 !== '') {
                if (this.report.parameter2 === 'user.companyId') {
                  this.report.url += '&Parameter2=' + this.user.companyId;
                } else if (this.report.parameter2 === 'user.siteId') {
                  this.report.url += '&Parameter2=' + this.user.siteId;
                }
              }

              if (this.report.parameter3 !== null && this.report.parameter3 !== '') {
                if (this.report.parameter3 === 'user.companyId') {
                  this.report.url += '&Parameter3=' + this.user.companyId;
                } else if (this.report.parameter3 === 'user.siteId') {
                  this.report.url += '&Parameter3=' + this.user.siteId;
                }
              }

              if (this.report.parameter4 !== null && this.report.parameter4 !== '') {
                if (this.report.parameter4 === 'user.companyId') {
                  this.report.url += '&Parameter4=' + this.user.companyId;
                } else if (this.report.parameter4 === 'user.siteId') {
                  this.report.url += '&Parameter4=' + this.user.siteId;
                }
              }

              if (this.report.parameter5 !== null && this.report.parameter5 !== '') {
                if (this.report.parameter5 === 'user.companyId') {
                  this.report.url += '&Parameter5=' + this.user.companyId;
                } else if (this.report.parameter5 === 'user.siteId') {
                  this.report.url += '&Parameter5=' + this.user.siteId;
                }
              }
      });
  }

}
