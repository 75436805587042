<form #thisForm='ngForm'>
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <h3>{{reward?.badgeHolderName}}</h3>
            </div>
            <div class="col-lg-6">
                <div class="float-right">
                    <button pButton (click)="save()" [disabled]="!thisForm.valid" icon="pi pi-save" label="Save"></button>
                    <button pButton (click)="cancel()" icon="pi pi-ban" label="Cancel"></button>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-4 col-md-4">
                    <div class="form-group">
                        <label class="control-label">Badge Holder</label><br />
                        <p-dropdown [options]="badgeHolders" [filter]="true" [(ngModel)]="reward.badgeHolderId" name="badgeHolderId" required></p-dropdown>
                    </div>
                </div>
                <div class="col-4 col-md-4">
                    <div class="form-group">
                        <label class="control-label">Event Date</label><br />
                        <p-calendar name="eventDate" [(ngModel)]="eventDate" name="eventDate" [showIcon]="true"></p-calendar>
                    </div>
                </div>
                <div class="col-4 col-md-4">
                    <div class="form-group">
                        <label class="control-label">Location</label>
                        <input type="text" pInputText name="location" [(ngModel)]="reward.location" maxlength="100" class="form-control" required />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label>Reward Description</label>
                        <textarea pInputText name="observation" [(ngModel)]="reward.observation" name="observation" maxlength="500" class="form-control"></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-12">
                    <label class="control-label">Included Badge Holder in Safety Awards Program:</label>
                    <p-radioButton name="rewardProgram" value="true" [(ngModel)]="rewardProgram" label="Yes"></p-radioButton>
                    <p-radioButton name="rewardProgram" value="false" [(ngModel)]="rewardProgram" label="No"></p-radioButton>
                </div>
            </div>
        </div>
        <st-update-block [object]='reward' *ngIf="reward"></st-update-block>
    </div>
</form>

