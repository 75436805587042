import { Component, Input, OnInit, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BreadCrumbService } from '../../services/breadcrumb.service';
import { DataService } from '../../services/data.service';
import { LoggingService } from '../../services/logging.service';
import { DateService } from '../../services/date.service';
import { Message } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';

@Component({
    selector: 'app-incident-profile',
    templateUrl: './incident.profile.component.html',
    providers: [ ConfirmationService ],
    encapsulation: ViewEncapsulation.None
})
export class IncidentProfileComponent implements OnInit, OnChanges {
@Input() incident: any;

message: Message[] = [];
eventDate: Date;
involvedParties: any[];
badgeHolders: any[];
involvedParty: any = { badgeHolderId: null };
showAddDialog = false;
classificationOptions: any[] = [];
cols: any[];

    constructor(
        private route: ActivatedRoute,
        private dataService: DataService,
        private loggingService: LoggingService,
        private dateService: DateService,
        private confirmService: ConfirmationService,
        private breadcrumbService: BreadCrumbService,
        private router: Router) {}

    ngOnInit(): void {
        if (this.incident.eventDate === undefined) {
            this.eventDate = new Date();
        } else {
            this.eventDate = this.dateService.convertDateUniversalToSiteTimeDate(new Date(this.incident.eventDate));
        }

        this.cols = [
            { field: 'badgeHolderName', header: 'Badge Holder' },
            { field: 'injury', header: 'Injury' },
            { field: 'treatment', header: 'Treatment' }
        ];

        this.classificationOptions.push({label: 'Non-Injury', value: 'Non-Injury' });
        this.classificationOptions.push({label: 'First Aid Only', value: 'First Aid Only' });
        this.classificationOptions.push({label: 'Non-Recordable', value: 'Non-Recordable' });
        this.classificationOptions.push({label: 'Recordable', value: 'Recordable' });
        this.classificationOptions.push({label: 'Lost Time', value: 'Lost Time' });

        this.loadInvolvedParties();
    }

    ngOnChanges(simpleChanges: SimpleChanges): void {
        this.breadcrumbService.setItemsByCategoryAndName(
                                'Safety',
                                'Incidents',
                                '/incidents/search',
                                this.incident.subject,
                                this.router.url);
    }

    save() {
        this.incident.eventDate = this.dateService.convertDateLocalToSiteTimeDate(new Date(this.eventDate));
        this.dataService.save('incidents', this.incident)
            .subscribe((data) => {
                this.incident = data;
                if (this.showAddDialog && this.involvedParty.badgeHolderId != null) {
                    this.saveInvolved();
                } else {
                    this.loggingService.savedMessage();
                }
            });
    }

    saveInvolved(): void {
        if (this.involvedParty.badgeHolderId != null) {
            if (!this.incident.id) {
                this.save();
            } else {
                this.involvedParty.incidentId = this.incident.id;
                this.dataService.save('incidents/involved', this.involvedParty)
                    .subscribe((data) => {
                        this.involvedParty = data;
                        this.showAddDialog = false;
                        this.loggingService.savedMessage();
                        this.loadInvolvedParties();
                    });
            }
        }
    }

    addNew(): void {
        this.loadBadgeHolders();
        this.involvedParty = { badgeHolderId: null };
        this.showAddDialog = true;
    }

    edit(involvedParty: any): void {
        this.dataService.getById('incidents/involved', involvedParty.id)
            .subscribe((data) => {
                this.involvedParty = data;
                this.loadBadgeHolders();
            });

        this.involvedParty = involvedParty;
        this.showAddDialog = true;
    }

    delete(involvedParty: any): void {
        this.involvedParty = involvedParty;

            this.confirmService.confirm({
                message: 'Do you want to delete ' + involvedParty.injury + '?',
                accept: () => {
                    this.dataService.deleteById('incidents/involved', involvedParty.id)
                        .subscribe((data) => {
                            this.loadInvolvedParties();
                            this.loggingService.deletedMessage();
                        });
                },
                reject: () => {
                    this.loggingService.infoMessage('Cancelled', 'You have cancelled the request');
                }
            });
    }

    closeAddDialog(): void {
        this.showAddDialog = false;
    }

    cancel() {
        this.router.navigate(['/incidents/search/']);
    }

    loadInvolvedParties(): void {
        this.dataService.getAll('incidents/involved/' + this.incident.id + '/list')
            .subscribe((data) => {
                this.involvedParties = data;
            });
    }

    loadBadgeHolders(): void {
        this.dataService.getById('lookups', 'badgeholders')
        .subscribe((badgeHolders) => {
            this.badgeHolders = badgeHolders;
            this.badgeHolders.unshift({ id: null, name: '' });
        });
    }
}
