<div class="container">
    <div class="row">
        <div class="col-lg-6">
            <h3>{{project.name}}</h3>
        </div>
        <div class="col-lg-6">
            <div class="float-right">
                <button pButton (click)="save()" icon="pi pi-save" label="Save"></button>
                <button pButton (click)="cancel()" icon="pi pi-ban" label="Cancel"></button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-lg-12">
                    <label class="control-label">Project Task:</label>
                    <input type="text" maxlength="100" name="name" pInputText class="form-control"
                        [(ngModel)]="project.name" />
                </div>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="row">
                <div class="col-lg-12">
                    <label class="control-label">Code:</label>
                    <input type="text" maxlength="25" name="code" pInputText class="form-control"
                        [(ngModel)]="project.code" />
                </div>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="row">
                <div class="col-lg-12">
                    <label class="control-label">Unit of Measure:</label><br />
                    <p-dropdown [options]="uoms" [showClear]="true" placeholder="Select a UOM" [filter]="true"
                        [(ngModel)]="project.uom"></p-dropdown>
                </div>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="row">
                <div class="col-lg-12">
                    <label class="control-label">Area:</label>
                    <input type="text" maxlength="100" name="area" pInputText class="form-control"
                        [(ngModel)]="project.area" />
                </div>
            </div>
        </div>
    </div>
    <st-update-block [object]='project'></st-update-block>
</div>