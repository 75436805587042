<div *ngIf="pageInfo">
    <div class="container">
        <div class="row">
            <div class="col">
                <h3>Inspections</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                Start Date:
                <p-calendar name="scheduledStartDate" [(ngModel)]="scheduledStartDate" [showIcon]="true"></p-calendar>
                End Date:
                <p-calendar name="scheduledEndDate" [(ngModel)]="scheduledEndDate" [showIcon]="true"></p-calendar>
            </div>
            <div class="col-lg-6">
                <button pButton (click)="filter()" icon="pi pi-search" label="Filter"></button>
                <button pButton (click)="clear()" icon="pi pi-ban" label="Clear"></button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <st-table pageId="{{pageInfo.id}}" dataTable="{{pageInfo.dataName}}" filter={{filterInfo}}></st-table>
            </div>
        </div>
    </div>
</div>

<div *ngIf="inspection">
    <app-inspection-profile [data]="inspection"></app-inspection-profile>
</div>