import { Component, Input, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm, FormGroup } from '@angular/forms';

import { DataService } from '../../services/data.service';
import { DateService } from '../../services/date.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { environment } from '../../../../environments/environment';
import { LoggingService } from '../../services/logging.service';
import { UserService } from '../../services/user.service';
import { HttpResponse } from '@angular/common/http';

@Component({
    selector: 'app-badgeholder-documents',
    templateUrl: './badgeholder.documents.component.html',
    providers: [ConfirmationService],
    encapsulation: ViewEncapsulation.None
})
export class BadgeHolderDocumentsComponent implements OnInit {
    @Input() badgeHolder: any;

    documents: any[];
    currentDocument: any;
    showEditDialog = false;
    showDeleteDialog = false;
    uploadedFiles: any[] = [];
    showPreviewDialog = false;
    documentCategories: any[];
    env: any = environment;
    uploadImageUrl: string;
    documentUrl: string;
    user: any;
    expirationDate: Date;

    constructor(
        private route: ActivatedRoute,
        private dataService: DataService,
        private dateService: DateService,
        private confirmService: ConfirmationService,
        private messageService: MessageService,
        private userService: UserService,
        private loggingService: LoggingService) { }

    ngOnInit(): void {
        this.user = this.userService.currentUser();

        this.currentDocument = {};
        this.loadDocuments();
        this.loadCategories();

        this.uploadImageUrl = this.env.baseAPIUrl
            + 'documents/upload/documents/'
            + this.user.siteId
            + '/'
            + this.badgeHolder.id;

        this.documentUrl = this.env.baseAPIUrl + "files/documents/"

//        this.documentUrl = this.env.baseDocumentUrl
//            + 'site_'
//            + this.user.siteId
//            + '/bh_'
//            + this.badgeHolder.id;

        if (this.currentDocument.expirationDate === undefined) {
            this.expirationDate = new Date();
        } else {
            this.expirationDate = this.dateService.convertDateUniversalToSiteTimeDate(new Date(this.currentDocument.expirationDate));
        }
    }

    loadDocuments(): void {
        this.dataService.getById('documents', this.badgeHolder.id)
            .subscribe((data) => {
                this.documents = data;
            });
    }

    loadDocument(documentId: string): void {
        if (documentId !== undefined) {
            this.dataService.getById('documents/info', documentId)
                .subscribe((data) => {
                    this.currentDocument = data;
                    this.expirationDate = this.dateService.convertDateUniversalToSiteTimeDate(new Date(this.currentDocument.expirationDate));
                });
        } else {
            this.currentDocument.id = 'new';
            this.currentDocument.badgeHolderId = this.badgeHolder.id;
            this.currentDocument.categoryId = '';
            this.currentDocument.name = '';
            this.currentDocument.verified = false;
            const dt = new Date();
            this.expirationDate = new Date(dt.setFullYear(dt.getFullYear() + 1));
        }
    }

    loadCategories(): void {
        this.dataService.getById('lookups', 'documentcategories')
            .subscribe((lookup) => {
                this.documentCategories = lookup;
            });
    }

    download(document) {
        this.dataService.postForm('documents/download', { badgeHolderId: this.badgeHolder.id, fileName: document.fileName, maxWidth: Math.min(Math.max(window.screen.availWidth, window.screen.availHeight), 1000) }, { responseType: 'arraybuffer' })
            .subscribe((data) => {
                const blob = new Blob([data], { type: 'application/pdf' });

                const url = (window.URL || window.webkitURL).createObjectURL(blob);
                window.open(url, '_blank');

                // rewoke URL after 1 minutes
                setTimeout(() => {
                  window.URL.revokeObjectURL(url);
                }, 1 * 60 * 1000);
            });
    }


    preview(document) {
        this.currentDocument = document;
        this.showPreviewDialog = true;
        setTimeout(() => {
            const img = window.document.getElementById('preview-image')
            img.style.display = 'none';
            this.dataService.postForm('documents/asImage', { badgeHolderId: this.badgeHolder.id, fileName: document.fileName, maxWidth: Math.min(Math.max(window.screen.availWidth, window.screen.availHeight), 1000) }, { responseType: 'arraybuffer' })
                .subscribe((data) => {
                    const file = new Blob([data], { type: 'image/jpeg' });
                    const fileURL = window.URL.createObjectURL(file);

                    img.setAttribute('src', fileURL);
                    img.style.display = '';

                    setTimeout(() => window.URL.revokeObjectURL(fileURL), 1000);
                });
        });
        return false;
    }

    edit(document): void {
        this.showEditDialog = true;

        this.loadDocument(document.id);
    }

    delete(document): void {
        this.showDeleteDialog = true;
        this.currentDocument = document;

        this.confirmService.confirm({
            message: 'Do you want to delete ' + document.name + '?',
            accept: () => {
                this.dataService.deleteById('documents', document.id)
                    .subscribe((data) => {
                        this.loadDocuments();
                        this.messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'Record deleted' });
                    });
            },
            reject: () => {
                this.messageService.add({ severity: 'info', summary: 'Cancelled', detail: 'You have cancelled the request' });
            }
        });
    }

    onUpload(event): void {
        for (const file of event.files) {
            this.uploadedFiles.push(file);
            this.currentDocument.fileName = file.name;
        }

        this.messageService.clear();
        this.messageService.add({ severity: 'info', summary: 'File Uploaded', detail: '' });
    }

    save(): void {
        this.currentDocument.expirationDate = this.dateService.convertDateLocalToSiteTimeDate(new Date(this.expirationDate));
        this.dataService.save('documents', this.currentDocument)
            .subscribe((data) => {
                this.loadDocuments();
            });
        this.showEditDialog = false;
    }

}
