<div *ngIf="pageInfo">
    <div class="container">
        <div class="row">
            <h3>Parking Search</h3>
        </div>
        <div class="col">
            <st-table pageId="{{pageInfo.id}}" dataTable="{{pageInfo.dataName}}"></st-table>
        </div>
    </div>
</div>

<div *ngIf="badgeHolder">
    <app-badgeholder-profile [badgeHolder]="badgeHolder"></app-badgeholder-profile>
</div>