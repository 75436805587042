<div class="row">
    <div class="col-lg-10">
         <label class="control-label">Menus:</label><br />
         <p-tree [value]="menus" draggableNodes="true" droppableNodes="true" (onNodeDrop)="onDrop($event)">
            <ng-template let-node pTemplate="default">
                <input [(ngModel)]="node.data.name" required placeholder="name" type="text" class="ui-inputtext">
                <input [(ngModel)]="node.data.icon" placeholder="icon" type="text" class="ui-inputtext">
                <input [(ngModel)]="node.data.route" required placeholder="route" type="text" style="width:250px" class="ui-inputtext" *ngIf="node.data.id === '1' || node.parent">
                <label><input [(ngModel)]="node.data.deleted" type="checkbox" class="ui-inputtext">Deleted</label>
                <button pButton (click)="add(node)" icon="pi pi-plus" *ngIf="node.data.id !== '1' && !node.parent"></button>
            </ng-template>
         </p-tree>
     </div>
    <div class="col-lg-2">
        <button pButton (click)="save()" icon="pi pi-save" label="Save"></button>
    </div>
</div>
 