<div class="panel-body" id="vehiclePanel">
    <div class="row">
        <div class="col">
            <div class="row">
                <div class="col">
                    Parking Pass Issued:
                    <p-radioButton name="optParkingPass" value="1" [(ngModel)]="badgeHolder.parkingPass" label="Yes">
                    </p-radioButton>
                    <p-radioButton name="optParkingPass" value="0" [(ngModel)]="badgeHolder.parkingPass" label="No">
                    </p-radioButton>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    Badge Holder will be driving a commercial vehicle to the Site:
                    <p-radioButton name="optCommVehicle" value="1" [(ngModel)]="badgeHolder.commercialVehicle"
                        label="Yes">
                    </p-radioButton>
                    <p-radioButton name="optCommVehicle" value="0" [(ngModel)]="badgeHolder.commercialVehicle"
                        label="No">
                    </p-radioButton>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    Badge Holder will be driving to the Site:
                    <p-radioButton name="optDrivingOnsite" value="1" [(ngModel)]="badgeHolder.driving" label="Yes">
                    </p-radioButton>
                    <p-radioButton name="optDrivingOnsite" value="0" [(ngModel)]="badgeHolder.driving" label="No">
                    </p-radioButton>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <h4>Vehicle Information</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4">
                    <label class="control-label">Make:</label>
                    <input type="text" class="form-control" [(ngModel)]="badgeHolder.vehicleMake" />
                </div>
                <div class="col-lg-4">
                    <label class="control-label">Model:</label>
                    <input type="text" class="form-control" [(ngModel)]="badgeHolder.vehicleModel" />
                </div>
                <div class="col-lg-4">
                    <label class="control-label">Color:</label>
                    <input type="text" class="form-control" [(ngModel)]="badgeHolder.vehicleColor" />
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <h4>License Plate</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <label class="control-label">License Plate Number:</label>
                    <input type="text" class="form-control" [(ngModel)]="badgeHolder.licensePlate" />
                </div>
                <div class="col-lg-6">
                    <label class="control-label">License State:</label><br />
                    <p-dropdown [options]="states" [filter]="true" [(ngModel)]="badgeHolder.licenseState"></p-dropdown>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <label class="control-label">Parking Permit Number:</label>
                    <input type="text" class="form-control" [(ngModel)]="badgeHolder.parkingNumber" />
                </div>
            </div>
        </div>
    </div>
</div>